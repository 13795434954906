import { useEffect, useState } from 'react'
import { getFileUrl } from '../utils/fileAction'
import AuthorImg from './AuthorImg'
import { set } from 'lodash'
import TextMore from './TextMore'
import axios from '../utils/axios'
import DeleteModel from './DeleteModel'
import ErrorToaster from './tosters/ErrorToasters'
let time = null
export default function CommentItem(props: {
	data: any
	id: string
	success?: Function
}) {
	const [data, setData] = useState<any>({})
	useEffect(() => {
		const timeDiff = Math.abs(
			new Date().getTime() -
				new Date(props.data?.createddate?.substring(0, 10)).getTime()
		)
		let createdate = props.data?.createddate?.substring(0, 10)
		let arr = createdate?.split('-')
		const diffDays = props.data?.createddate
			? Math.floor(timeDiff / (1000 * 3600 * 24))
			: 0
		setData({
			...JSON.parse(JSON.stringify(props.data)),
			diffDays,
			createdate: arr?.length ? arr[2] + '/' + arr[1] + '/' + arr[0] : ''
		})
		if (
			props.data.contactPhotoUrl &&
			props.data.contactPhotoUrl !== '/profilephoto/005/F'
		) {
			getFileUrl(props.data.contactPhotoUrl).then((url) => {
				setData({
					...JSON.parse(JSON.stringify(props.data)),
					url,
					diffDays,
					createdate: arr?.length ? arr[2] + '/' + arr[1] + '/' + arr[0] : ''
				})
			})
		}
	}, [JSON.stringify(props.data)])
	const [showEdit, setShowEdit] = useState<boolean>(false)

	const setComment = (text: string) => {
		setData({
			...props.data,
			diffDays: data.diffDays,
			commentC: text
		})
	}
	const cancelAddComment = (save?: boolean) => {
		time = setTimeout(() => {
			let temp = {
				...props.data,
				diffDays: data.diffDays
			}
			if (save) {
				temp = {
					...temp,
					commentC: data.commentC
				}
			}
			setData({
				...temp
			})
			setShowEdit(false)
		}, 200)
	}
	const submitAddComment = () => {
		console.log(data)
		axios
			.post('/api/archive_application/application_comment/update', {
				commentId: data.id,
				comment: data.commentC
			})
			.then((res: any) => {
				console.log(res)
				if (res && res.data && res.data.code === 200) {
					if (props.success) {
						props.success('Edit successfully')
					}
					cancelAddComment(true)
				} else {
					setShowErrorToaster(true)
					setMsg('Update error')
				}
			})
			.catch(() => {
				setShowErrorToaster(true)
				setMsg('Update error')
			})
	}
	const [showErrorToaster, setShowErrorToaster] = useState<boolean>(false)
	const [msg, setMsg] = useState<string>('')
	const deleteComment = () => {
		axios
			.post('/api/archive_application/application_comment/delete', {
				commentId: data.id
			})
			.then((res: any) => {
				if (res && res.data && res.data.code === 200) {
					if (props.success) {
						props.success('Delete successfully', data.id)
					}
					cancelAddComment(true)
				} else {
					setShowErrorToaster(true)
					setMsg('Delete error')
				}
			})
			.catch(() => {
				setShowErrorToaster(true)
				setMsg('Delete error')
			})
	}
	const [show, setShow] = useState<boolean>(false)
	return (
		<>
			<DeleteModel
				show={show}
				submit={() => {
					deleteComment()
					setShow(false)
				}}
				toggle={(value: boolean) => {
					setShow(value)
				}}
			></DeleteModel>
			<ErrorToaster
				show={showErrorToaster}
				toggle={(val: boolean) => {
					setShowErrorToaster(val)
				}}
				msg={msg}
			/>
			<div className="flex mt-3 w-full">
				<AuthorImg url={data.url} name={data.contactName} size="mini" />
				<div className="ml-2 flex-1">
					<div className="text-sm  text-gray-700 flex">
						<span className=" max-w-24 mr-3">{props.data.contactName}</span>
						<span className="text-gray-500 text-sm">
							{data.createdate} ({data.diffDays} days ago)
						</span>
					</div>
					{showEdit ? (
						<>
							<div className="w-full">
								<textarea
									className="w-full border ml-2 border-gray-200 rounded-md"
									placeholder="Enter your comment here..."
									rows={5}
									onChange={(e) => setComment(e.target.value)}
									value={data.commentC}
									onBlur={() => cancelAddComment()}
								></textarea>
								<div className="flex justify-end place-content-end">
									<button
										onClick={() => cancelAddComment()}
										className="shadow-sm text-gray-700 px-3 py-2 rounded-md border"
									>
										Cancel
									</button>
									<button
										onClick={() => submitAddComment()}
										className="shadow-sm text-white ml-2 bg-primary px-3 py-2 rounded-md border"
									>
										Save
									</button>
								</div>
							</div>
						</>
					) : (
						<TextMore text={data.commentC}></TextMore>
					)}

					{!showEdit && data.canEdit && (
						<div className="flex">
							<span
								className="text-base text-primary cursor-pointer"
								onClick={() => setShowEdit(true)}
							>
								Edit
							</span>
							<span className="w-px mx-3 h-5 bg-gray-200"></span>
							<span
								className="text-base text-primary cursor-pointer"
								onClick={() => setShow(true)}
							>
								Delete
							</span>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
