import React, { useEffect, useState } from "react";
import AppSlideOver from "./AppSlideOver";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import attachmentIcon from "../assets/Icons/attachmentIcon.svg";
import moment from "moment";
import AuthorImg from "./AuthorImg";
import { getFileUrl } from "../utils/fileAction";

export default function IndividualViewReportSlide(props: any) {
  const [individualReport, setIndividualReport] = useState<any>(
    { ...props.selectedReport } ?? {}
  );

  const bytesToSize = (bytes: number) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
    if (i === 0) return bytes + " " + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  };

  useEffect(() => {
    if (props.selectedReport) {
      setIndividualReport({ ...props.selectedReport });
    }
  }, [props.selectedReport]);

  const ragRatingRender = (rag: any) => {
    if (rag === "Red") {
      return (
        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-poppins-400  text-red-800">
          Red
        </span>
      );
    } else if (rag !== "Green" && rag !== "Red") {
      return (
        <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-poppins-400  text-yellow-800">
          {rag}
        </span>
      );
    } else {
      return (
        <span className="inline-flex rounded-full bg-green-100 px-4 text-xs font-poppins-400  text-green-800">
          Green
        </span>
      );
    }
  };

  // history or other places learner name
  const learnerName =
    `${individualReport?.firstName} ${individualReport?.lastName}`.trim() ||
    "-";
  // history or other places uln
  const uln = individualReport?.uln ?? "-";
  // history or other places training provider
  const trainingProvider = individualReport.lastModifyName;
  // history or other places reported date
  const reportedDate =
    props.source === "history"
      ? individualReport?.reportdate
      : individualReport?.ReportDate;
  // history or other places start date
  const startDate =
    props.source === "history"
      ? individualReport.startdate
      : individualReport?.additional?.startdate;
  const plannedEndDate =
    props.source === "history"
      ? individualReport?.planned_end_date
      : individualReport?.additional?.planned_end_date;
  // history or other places actual end date
  const actualEndDate =
    props.source === "history"
      ? individualReport?.actual_end_date
      : individualReport?.CompletionDate;
  const bilStartDate =
    props.source === "history"
      ? individualReport?.bil_start_date
      : individualReport?.BilStartDate;
  const bilEndDate =
    props.source === "history"
      ? individualReport?.bil_end_date
      : individualReport?.BilEndDate;
  // history or other places course progress
  const courseProgress =
    props.source === "history"
      ? individualReport?.courseprogress
      : individualReport?.CourseProgress;
  const epaStartDate =
    props.source === "history"
      ? individualReport?.epa_start_date
      : individualReport?.additional?.epa_start_date;
  const withdrawnReason =
    props.source === "history"
      ? individualReport?.withdrawn_reason
      : individualReport?.additional?.withdrawn_reason;
  // history or other places rag rating
  const ragRating =
    props.source === "history"
      ? individualReport?.ragrating
      : individualReport?.Rag;
  const percentage =
    props.source === "history"
      ? individualReport?.percentage
      : individualReport?.additional?.percentage;
  const progression =
    props.source === "history"
      ? individualReport?.progression
      : individualReport?.additional?.progression;
  const fsEnglishStatus =
    props.source === "history"
      ? individualReport?.fs_english_status
      : individualReport?.additional?.fs_english_status;
  const fsMathStatus =
    props.source === "history"
      ? individualReport?.fs_math_status
      : individualReport?.additional?.fs_math_status;
  // history or other places next review
  const nextDate =
    props.source === "history"
      ? individualReport?.nextdate
      : individualReport?.additional?.nextdate;
  // history or other places flag
  const flag =
    props.source === "history"
      ? individualReport?.furtheraction
      : individualReport?.Flag;
  // history or other places comment
  const comment =
    props.source === "history"
      ? individualReport?.comment
      : individualReport?.Comment;

  const handle4DownloadFile = (key: string) => {
    getFileUrl(key).then((url) => window.open(url));
  };

  return (
    <AppSlideOver toggle={props.toggle} show={props.show}>
      <div className="flex min-h-0 flex-1 flex-col pt-4 slide-over-input">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <div className="text-lg font-inter-500 leading-7 text-gray-700">
              {/* [Learner’s first_name last_name] */}
              {`${individualReport?.firstName || "-"} ${
                individualReport?.lastName || "-"
              }`}
              <div className="sm:px-1 text-sm text-gray-500">
                {individualReport?.uln || "-"}
              </div>
            </div>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => props.toggle(false)}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <div className=" relative overflow-y-auto mt-6 pb-6 flex-1 px-4 sm:px-6">
          {/* Replace with your content */}
          <form>
            <div className="border-t">
              <dl className="divide-y divide-gray-200">
                <div className="py-2 flex items-center justify-between px-4 sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Updated by
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {individualReport?.lastModifyName ||
                    individualReport?.lastModifyPhoto ? (
                      <>
                        <div className="m-1">
                          <AuthorImg
                            size="mini"
                            name={individualReport?.lastModifyName}
                            url={individualReport?.lastModifyPhoto}
                          ></AuthorImg>
                        </div>

                        {individualReport?.lastModifyName}
                      </>
                    ) : (
                      <></>
                    )}
                  </dd>
                </div>
                <div className="py-2 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Update on
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {individualReport?.lastModifiedDate
                      ? moment(
                          new Date(individualReport?.lastModifiedDate)
                        ).format("DD/MM/yyyy")
                      : "-"}
                  </dd>
                </div>
              </dl>
            </div>

            <div className="flex-1">
              <div className="pt-6 pb-2 border-t border-b border-gray-200 sm:px-3">
                <div className="font-poppins-500 text-gray-900 text-md">
                  Course dates
                </div>
              </div>

              <dl className="divide-y divide-gray-200">
                <div className="py-2 pb-2  border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Start date
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {/* [training_start_date] */}
                    {individualReport?.startDate
                      ? moment(new Date(individualReport?.startDate)).format(
                          "DD/MM/yyyy"
                        )
                      : "-"}
                  </dd>
                </div>

                <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Planned end date
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {/* [planned_end_date] */}
                    {individualReport?.plannedEndDate
                      ? moment(
                          new Date(individualReport?.plannedEndDate)
                        ).format("DD/MM/yyyy")
                      : "-"}
                  </dd>
                </div>

                <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Revised end date
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {/* [revised_end_date] */}
                    {individualReport?.endDate
                      ? moment(new Date(individualReport?.endDate)).format(
                          "DD/MM/yyyy"
                        )
                      : "-"}
                  </dd>
                </div>

                <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Actual end date
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-500 flex items-center">
                    {/* [actual_end_date] */}
                    {individualReport?.completionDate
                      ? moment(
                          new Date(individualReport?.completionDate)
                        ).format("DD/MM/yyyy")
                      : "-"}
                  </dd>
                </div>
              </dl>
            </div>

            <div className="flex-1">
              <div className="pt-6 pb-2 border-t border-b border-gray-200 sm:px-3">
                <div className="font-poppins-500 text-gray-900 text-md">
                  Report update
                </div>
              </div>

              <dl className="divide-y divide-gray-200">
                <div className="py-2 pb-2 border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Course status
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {/* [Course_status] */}
                    {individualReport?.courseProgress
                      ? individualReport?.courseProgress
                      : "-"}
                  </dd>
                </div>

                {individualReport?.courseProgress === "Completed" && (
                  <>
                    <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                      <dt className="text-sm font-inter-500 text-gray-500">
                        Completion Date
                      </dt>
                      <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                        {individualReport?.completionDate
                          ? moment(
                              new Date(individualReport?.completionDate)
                            ).format("DD/MM/yyyy")
                          : "-"}
                      </dd>
                    </div>
                    <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                      <dt className="text-sm font-inter-500 text-gray-500">
                        Completion Status
                      </dt>
                      <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                        {/* [completion_grade] */}
                        {individualReport?.completionStatus
                          ? individualReport?.completionStatus
                          : "-"}
                      </dd>
                    </div>
                  </>
                )}

                {individualReport?.courseProgress === "Withdrawn" && (
                  <>
                    <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                      <dt className="text-sm font-inter-500 text-gray-500">
                        Withdrawn date
                      </dt>
                      <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                        {individualReport?.completionDate
                          ? moment(
                              new Date(individualReport?.completionDate)
                            ).format("DD/MM/yyyy")
                          : "-"}
                      </dd>
                    </div>
                    <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                      <dt className="text-sm font-inter-500 text-gray-500">
                        Withdrawn reason
                      </dt>
                      <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                        {/* [withdrawn_reson] */}
                        {individualReport?.withdrawnReasons
                          ? individualReport?.withdrawnReasons
                          : "-"}
                      </dd>
                    </div>
                  </>
                )}

                {individualReport?.courseProgress === "Break in learning" && (
                  <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                    <dt className="text-sm font-inter-500 text-gray-500">
                      {/* Bill dates */}
                      BIL dates
                    </dt>
                    <dd className="text-sm font-inter-500 text-gray-500 flex items-center">
                      {/* [bil_start_date] - [bil_end_date] */}
                      {!individualReport?.bilStartDate && "-"}
                      {individualReport?.bilStartDate &&
                        moment(new Date(individualReport?.bilStartDate)).format(
                          "DD/MM/yyyy"
                        )}
                      {individualReport?.bilStartDate && "-"}
                      {individualReport?.bilEndDate &&
                        moment(new Date(individualReport?.bilEndDate)).format(
                          "DD/MM/yyyy"
                        )}
                    </dd>
                  </div>
                )}

                {individualReport?.courseProgress === "In EPA" && (
                  <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                    <dt className="text-sm font-inter-500 text-gray-500">
                      {/* Bill dates */}
                      EPA start date
                    </dt>
                    <dd className="text-sm font-inter-500 text-gray-500 flex items-center">
                      {individualReport?.epaStartDate
                        ? moment(
                            new Date(individualReport?.epaStartDate)
                          ).format("DD/MM/yyyy")
                        : "-"}
                    </dd>
                  </div>
                )}

                {individualReport?.courseProgress === "Failed" && (
                  <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                    <dt className="text-sm font-inter-500 text-gray-500">
                      Date training ended
                    </dt>
                    <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                      {individualReport?.completionDate
                        ? moment(
                            new Date(individualReport?.completionDate)
                          ).format("DD/MM/yyyy")
                        : "-"}
                    </dd>
                  </div>
                )}

                <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">RAG</dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {ragRatingRender(individualReport?.ragRating)}
                  </dd>
                </div>

                <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Percentage of Completion
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {individualReport?.percentage} %
                  </dd>
                </div>

                <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    English FS
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-500 flex items-center">
                    {/* [fs_english] */}
                    {individualReport?.fsenglishStatus
                      ? individualReport?.fsenglishStatus
                      : "-"}
                  </dd>
                </div>
                <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Math FS
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-500 flex items-center">
                    {/* [fs_math] */}
                    {individualReport?.fsmathStatus
                      ? individualReport?.fsmathStatus
                      : "-"}
                  </dd>
                </div>

                <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">
                    Next review
                  </dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {individualReport?.nextDate
                      ? moment(new Date(individualReport?.nextDate)).format(
                          "DD/MM/yyyy"
                        )
                      : "-"}
                  </dd>
                </div>

                <div className="py-2 pb-2 border-t border-b border-gray-200 flex items-center justify-between sm:px-3">
                  <dt className="text-sm font-inter-500 text-gray-500">Flag</dt>
                  <dd className="text-sm font-inter-500 text-gray-900 flex items-center">
                    {individualReport?.furtherAction
                      ? individualReport?.furtherAction
                      : "-"}
                  </dd>
                </div>
              </dl>
            </div>

            {/* Comment section */}
            {individualReport?.comment && (
              <div className="flex-1">
                <div className="pt-6 border-t  ">
                  <div className="font-poppins-500 text-gray-900 text-md">
                    Comment
                  </div>
                </div>

                <div className="py-4  text-gray-500 text-sm">
                  {individualReport?.comment}
                </div>
              </div>
            )}

            {/* File and file size */}
            <div className=" my-2">
              {individualReport.detailFiles?.map((i: any, index: any) => (
                <div
                  className="flex items-center py-3 pl-3 pr-4 text-sm shadow-sm border border-gray-300 rounded-lg cursor-pointer"
                  onClick={() => handle4DownloadFile(i.fileurl)}
                >
                  <div className="flex w-0 flex-1 items-center">
                    <img
                      src={attachmentIcon}
                      alt="attachment"
                      className="flex-shrink-0"
                    />

                    <span className="ml-2 w-0 flex-1 truncate text-xs font-poppins-500 text-gray-900">
                      {i.filetitle}
                      <span className="font-poppins-400 text-gray-600 inline-flex ml-2">
                        {bytesToSize(i.filesize)}
                      </span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </form>
        </div>
      </div>
    </AppSlideOver>
  );
}
