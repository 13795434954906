import React, { useEffect, useState } from 'react'
import AppSlideOver from './AppSlideOver'
import {
	ChevronDownIcon,
	ChevronUpIcon,
	MagnifyingGlassIcon,
	XMarkIcon
} from '@heroicons/react/20/solid'
import { Dialog } from '@headlessui/react'
import axios from '../utils/axios'
import apiUrl from '../utils/apiUrl'
import useAuth from '../hooks/useAuth'
import { values } from 'lodash'
import {
	Controller,
	FormProvider,
	useForm,
	useFormContext
} from 'react-hook-form'
import SuccsessToaster from './tosters/SuccsessToaster'
import DatePicker from 'react-datepicker'
import calenderIcon from '../assets/Icons/calenderIcon.svg'
import moment from 'moment'
import ErrorToaster from './tosters/ErrorToasters'
import AuthorImg from './AuthorImg'

const Cell = (props: any) => {
	const {
		register,
		unregister,
		formState: { errors },
		control
	} = useFormContext()
	const [canEdit, setCanEdit] = useState(false)
	const { value, edit, label, placeholder } = props
	const [, n1, i, n2] =
		/^([a-zA-Z]+)\[([0-9]+)\].([a-zA-Z]+)$/.exec(props.name) || []
	const error = (errors[n1] as any)
		? (errors[n1] as any)[i]
			? (errors[n1] as any)[i][n2]
			: ''
		: ''

	useEffect(() => {
		if (!canEdit) {
			unregister(props.name)
		}
		props.changeEditStatu(canEdit)
	}, [canEdit])

	useEffect(() => {
		setCanEdit(false)
	}, [props.edit])
	return (
		<>
			{canEdit ? (
				<div className="py-4 border-y border-gray-200">
					<label
						htmlFor="Title"
						className={
							error
								? 'block text-sm font-poppins-400 text-red-500'
								: 'block text-sm font-poppins-400 text-gray-500'
						}
					>
						{label}*
					</label>
					<div className="relative w-full rounded-md shadow-sm layout-search my-2">
						<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-index">
							<img src={calenderIcon} alt=""></img>
						</div>
						<Controller
							control={control}
							defaultValue={value ? new Date(value) : ''}
							render={({ field: { onChange, value } }) => (
								<DatePicker
									dateFormat="dd/MM/yyyy"
									placeholderText={placeholder}
									className={
										error
											? 'block  pl-10 w-full rounded-md border border-red-500 shadow-sm font-poppins-400 text-red-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
											: 'block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm'
									}
									selected={value ? new Date(value) : ''}
									onChange={onChange}
								/>
							)}
							{...register(props.name, {
								required: true
							})}
						/>
					</div>

					<p className="mt-2 text-sm text-red-500" id="email-error">
						<>{error ? 'Filed Required' : ''}</>
					</p>
				</div>
			) : (
				<div className="py-4 border-b border-gray-200">
					<div className="flex justify-between">
						<p className="flex text-sm font-poppins-400 text-gray-500">
							{label}
						</p>
						<h3 className="flex text-sm font-poppins-400 text-gray-800 cursor-pointer">
							{value}
							<svg
								className="ml-1"
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={() => setCanEdit(true)}
							>
								<path
									d="M17.4024 2.07528C18.1723 2.84415 18.1723 4.08973 17.4024 4.85965L6.40899 15.8565C6.00821 16.2538 5.51602 16.5456 4.97462 16.7073L0.721412 17.9589C0.523833 18.0151 0.310329 17.9624 0.164783 17.8147C0.0192215 17.6706 -0.0352215 17.4561 0.0228778 17.2593L1.27372 13.0054C1.43297 12.4639 1.72583 11.9718 2.12485 11.571L13.1203 0.576571C13.8902 -0.19219 15.1348 -0.19219 15.9047 0.576571L17.4024 2.07528ZM11.9883 3.29872L14.6813 5.96004L16.6078 4.06512C16.9383 3.73465 16.9383 3.20028 16.6078 2.87087L15.1102 1.37216C14.7797 1.04274 14.2453 1.04274 13.9149 1.37216L11.9883 3.29872ZM11.1938 4.09325L2.92044 12.369C2.6543 12.6327 2.45919 12.9632 2.35301 13.3253L1.39325 16.5878L4.65469 15.628C5.0168 15.5225 5.34727 15.3257 5.61095 15.0585L13.8551 6.78621L11.1938 4.09325Z"
									fill="#9CA3AF"
								/>
							</svg>
						</h3>
					</div>
				</div>
			)}
		</>
	)
}

export default function LearnersProfile(props: any) {
	const { user } = useAuth()

	const [trainer, setTrainer] = useState<any>([])
	const [trainerId, setTrainerId] = useState<any>(props?.trainerId)
	const [learnerId, setLearnerId] = useState<any>(props?.learnerId)
	const [showToaster, setShowToaster] = useState<boolean>(false)
	const [showErrorToaster, setShowErrorToaster] = useState(false)
	const [selectedTrainer, setSelectedTrainer] = useState<any>([])
	const [showUln, setShowUln] = useState(false)
	const [ulnError, setUlnError] = useState(false)
	const [learner, setLearner] = useState<any>([])
	const [uln, setUln] = useState<any>('')
	const [remove, setRemove] = useState(false)
	const [isAddedTrainer, setAddedTrainer] = useState(false)
	const [showSaveBtn, setShowSaveBtn] = useState(false)
	const [courseList, setCourseList] = useState<any>([])
	const [resetEdit, setResetEdit] = useState(0)
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setError,
		reset,
		trigger,
		control
	} = useForm()
	// console.log('errors===', errors)
	const methods = useForm()
	const getLearnerProfile = async () => {
		const response = await axios.post('/api/profile/learner/detail', {
			//trainer_id: trainerId,
			learnerId: learnerId
		})
		const result = response?.data?.data
		const learner = { ...result }
		learner.learner_name = `${learner.firstName} ${learner.lastName}`
		setLearner(learner)
		setUln(result?.uln)
		let courseList = result.courseList
		if (props.courseId) {
			courseList = courseList.filter(
				(item: any) => item.coursenameC === props.courseId
			)
		}
		setCourseList(courseList)
	}

	useEffect(() => {
		// setTrainerId(props?.trainerId);
		setLearnerId(props?.learnerId)
	}, [props?.learnerId])

	useEffect(() => {
		// getFilter();
		if (learnerId) {
			getLearnerProfile()
		}
	}, [learnerId])

	const submit = async (data: any) => {
		console.log(data)
		if (uln === '' || uln === null) {
			setShowUln(true)
			return false
		}
		const params = {
			uln: uln,
			courseList: courseList as any,
			contactC: learnerId
		}
		if (data && data.courseList) {
			data.courseList.forEach((item: any, index: number) => {
				if (item) {
					item.startdateC = item.startdateC
						? moment(item.startdateC).format('YYYY-MM-DD')
						: ''
					item.plannedenddateC = item.plannedenddateC
						? moment(item.plannedenddateC).format('YYYY-MM-DD')
						: ''
					item.enddateC = item.enddateC
						? moment(item.enddateC).format('YYYY-MM-DD')
						: ''
					item.completiondateC = item.completiondateC
						? moment(item.completiondateC).format('YYYY-MM-DD')
						: ''
					params.courseList[index] = { ...courseList[index], ...item }
				}
			})
		}
		axios
			.post('/api/profile/learner/save', params)
			.then(({ data }) => {
				if (data.code === 200) {
					toggleFileToaster(true)
					getLearnerProfile()
					setShowSaveBtn(false)
					setShowUln(false)
					setResetEdit(resetEdit + 1)
				} else {
					toggleErrorToaster(true)
					setUlnError(true)
				}
			})
			.catch((err) => {
				toggleErrorToaster(true)
				setUlnError(true)
			})
		// const submitCourses: any = [];
		// const trainerTemp: any = [];
		// const deleteTrainersTemp: any = [];
		// learner.courses?.map((item: any, index: any) => {
		//   if (item.course_sfid !== null) {
		//     selectedTrainer.map((each: any) => {
		//       // { ind: ind, id:each.id, text: each.trainer_name }
		//       if (index === each.ind && each.text !== "Select trainer...") {
		//         trainerTemp.push(each.id);
		//       }
		//     });
		//     item.trainers?.map((e: any) => {
		//       trainerTemp.push(e.id);
		//     });
		//     item.deleteTrainers?.map((e: any) => {
		//       deleteTrainersTemp.push(e.id);
		//     });

		//     const trainerCheck = trainerTemp.filter((e: any) => e !== undefined);
		//     const deleteTrainers = deleteTrainersTemp.filter(
		//       (e: any) => e !== undefined
		//     );
		//     submitCourses.push({
		//       course_id: item.course_sfid,
		//       trainer_ids: [...trainerCheck],
		//       delete_trainers: [...deleteTrainers],
		//     });
		//   }
		// });

		// try {
		//   const response = await axios.post(apiUrl.assignTrainer, {
		//     uln: uln,
		//     courses: submitCourses,
		//     // learner_id: "0033z00002fYGogAAG",
		//     learner_id: learnerId,
		//   });
		//   if (response.status === 200) {
		//     setShowUln(false);
		//     props.toggle(false);
		//     toggleFileToaster(true);
		//     setLearner([]);
		//     window.location.reload();
		//   } else {
		//     setUlnError(true);
		//     setUln(uln);
		//     props.toggle(true);
		//   }
		// } catch (error) {
		//   setUlnError(true);
		//   props.toggle(true);
		//   setUln(uln);
		// }
	}

	const toggleFileToaster = (v: any) => {
		setShowToaster(true)
		setTimeout(() => {
			setShowToaster(false)
		}, 2000)
	}

	const toggleErrorToaster = (v: any) => {
		setShowErrorToaster(true)
		setTimeout(() => {
			setShowErrorToaster(false)
		}, 2000)
	}

	return (
		<>
			<SuccsessToaster
				show={showToaster}
				toggle={(val: boolean) => {
					setShowToaster(val)
				}}
				message={'Profile details updated successfully'}
				// message={setMessage}
			/>
			<ErrorToaster
				show={showErrorToaster}
				toggle={(val: boolean) => {
					setShowErrorToaster(val)
				}}
				msg={'Profile details updated failed'}
			/>
			<AppSlideOver toggle={props.toggle} show={props.show}>
				<FormProvider {...methods}>
					<form
						className="flex min-h-0 flex-1 flex-col py-6"
						onSubmit={methods.handleSubmit(submit)}
					>
						{/* <div > */}
						<div className=" px-4 sm:px-6">
							<div className="flex items-start justify-between">
								<div className="text-lg font-inter-500 leading-7 text-gray-900">
									Profile
								</div>
								<div className="ml-3 flex h-7 items-center">
									<button
										type="button"
										className=" text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={() => props.toggle(false)}
									>
										<span className="sr-only">Close panel</span>
										<XMarkIcon className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
							</div>
						</div>

						<div className=" relative overflow-y-auto mt-6 pb-6 flex-1 px-0">
							<div className="mb-12" id="personal_detail">
								<div className="mb-6">
									<div>
										<div className="h-24 w-full object-cover lg:h-24 bg-lavender"></div>
									</div>
									<div className="mx-auto px-4 sm:px-6 lg:px-6">
										<div className="-mt-10 sm:-mt-12 sm:flex sm:items-end sm:space-x-5">
											<div className="flex h-24 w-24 rounded-lg bg-white items-center justify-center">
												<span className="h-10 w-10 flex-shrink-0 rounded-full bg-lavender text-white font-inter-400 text-sm inline-flex items-center justify-center p-1">
													{/* {learner?.company_name?.trim()?.split(" ")?.length > 1
                            ? learner?.company_name
                                ?.split(" ")[0][0]
                                .toUpperCase() +
                              learner?.company_name
                                ?.split(" ")
                                [
                                  learner?.company_name?.split(" ").length - 1
                                ][0].toUpperCase()
                            : learner?.company_name
                                ?.split(" ")[0][0]
                                .toUpperCase()} */}
													<AuthorImg
														name={learner?.learner_name}
														url={learner?.photourl}
													></AuthorImg>
												</span>
											</div>
										</div>
										{/* Learner name and client name  */}
										<div className="mt-1 min-w-0 flex-1 block">
											<h1 className="truncate text-xl font-poppins-600 text-gray-900 mb-1">
												{/* [Learner’s full name] */}
												{learner?.learner_name}
											</h1>
											<div>
												<span className="font-poppins-400 text-gray-500 mb-1">
													{/* [company name] */}
													{learner?.company_name}
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="mx-auto px-4 sm:px-6 lg:px-6">
									{!showUln ? (
										<div className="py-4 border-y border-gray-200">
											<div className="flex items-center justify-between">
												<div
													className={
														errors.uln || ulnError
															? 'flex items-center  text-red-500 text-sm '
															: 'flex items-center  text-gray-500 text-sm '
													}
												>
													ULN
												</div>
												<div className="flex-shrink-0">
													<h3 className="flex text-sm font-poppins-400 text-gray-800 cursor-pointer">
														{/* [uln]  */}
														{learner?.uln}
														<svg
															className="ml-1"
															width="18"
															height="18"
															viewBox="0 0 18 18"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
															onClick={() => setShowUln(true)}
														>
															<path
																d="M17.4024 2.07528C18.1723 2.84415 18.1723 4.08973 17.4024 4.85965L6.40899 15.8565C6.00821 16.2538 5.51602 16.5456 4.97462 16.7073L0.721412 17.9589C0.523833 18.0151 0.310329 17.9624 0.164783 17.8147C0.0192215 17.6706 -0.0352215 17.4561 0.0228778 17.2593L1.27372 13.0054C1.43297 12.4639 1.72583 11.9718 2.12485 11.571L13.1203 0.576571C13.8902 -0.19219 15.1348 -0.19219 15.9047 0.576571L17.4024 2.07528ZM11.9883 3.29872L14.6813 5.96004L16.6078 4.06512C16.9383 3.73465 16.9383 3.20028 16.6078 2.87087L15.1102 1.37216C14.7797 1.04274 14.2453 1.04274 13.9149 1.37216L11.9883 3.29872ZM11.1938 4.09325L2.92044 12.369C2.6543 12.6327 2.45919 12.9632 2.35301 13.3253L1.39325 16.5878L4.65469 15.628C5.0168 15.5225 5.34727 15.3257 5.61095 15.0585L13.8551 6.78621L11.1938 4.09325Z"
																fill="#9CA3AF"
															/>
														</svg>
													</h3>
												</div>
											</div>
										</div>
									) : (
										<div className="py-4 border-y border-gray-200">
											<label
												htmlFor="Title"
												className={
													ulnError || !uln
														? 'block text-sm font-poppins-400 text-red-500'
														: 'block text-sm font-poppins-400 text-gray-500'
												}
											>
												Unique learner number*
											</label>
											<div className="relative w-full rounded-md shadow-sm layout-search my-2">
												<input
													type="text"
													name="search"
													id="search"
													placeholder={
														(!ulnError && uln?.length === 0) ||
														uln === '' ||
														uln === null
															? 'Enter learners ULN...'
															: ' '
													}
													value={uln}
													onChange={(e: any) => setUln(e.target.value)}
													autoComplete="off"
													className={
														ulnError || !uln
															? 'block w-full rounded-md  border border-red-300 pr-10 border-red-500  shadow-sm sm:text-sm'
															: 'block w-full rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm'
													}
												/>
												<p
													className="mt-2 text-sm text-red-500"
													id="email-error"
												>
													<>
														{ulnError || !uln
															? !uln
																? 'Please provide a valid uln number.'
																: 'Oops, it appears ULN is already taken.'
															: ''}
													</>
												</p>
											</div>
										</div>
									)}

									{courseList?.map((each: any, index: any) => {
										return (
											<div key={index}>
												<div className="pb-2 pt-6 border-b border-gray-200">
													<div className="font-poppins-500 break-all text-gray-900 text-md">
														{/* [course] */}
														{each?.coursenameC}
													</div>
												</div>

												<div className="py-4 border-b border-gray-200">
													<div className="flex justify-between">
														<p className="flex text-sm font-poppins-400 text-gray-500">
															Course status
														</p>
														<h3 className="flex text-sm font-poppins-400 text-gray-800 cursor-pointer">
															{each?.courseprogressC || 'Not Started'}
														</h3>
													</div>
												</div>
												<Cell
													label="Start Date"
													value={each.startdateC}
													placeholder="Enter start date..."
													name={'courseList[' + index + '].startdateC'}
													changeEditStatu={(v: boolean) => setShowSaveBtn(v)}
													edit={resetEdit}
												></Cell>
												<Cell
													label="Planned end Date"
													value={each.plannedenddateC}
													placeholder="Enter planned end date..."
													name={'courseList[' + index + '].plannedenddateC'}
													changeEditStatu={(v: boolean) => setShowSaveBtn(v)}
													edit={resetEdit}
												></Cell>

												<Cell
													label="Revised end date"
													value={each.enddateC}
													placeholder="Enter revised end date..."
													name={'courseList[' + index + '].enddateC'}
													changeEditStatu={(v: boolean) => setShowSaveBtn(v)}
													edit={resetEdit}
												></Cell>
												<Cell
													label="Actual end Date"
													value={each.completiondateC}
													placeholder="Enter actual end date..."
													name={'courseList[' + index + '].completiondateC'}
													changeEditStatu={(v: boolean) => setShowSaveBtn(v)}
													edit={resetEdit}
												></Cell>
											</div>
										)
									})}
								</div>
							</div>
						</div>

						{/* Cancel and Save button */}
						{(showUln || showSaveBtn) && (
							<div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
								<button
									type="button"
									className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
									onClick={() => {
										// window.location.reload();
										// props.toggle(false);
										setShowSaveBtn(false)
										setShowUln(false)
										getLearnerProfile()
										setResetEdit(resetEdit + 1)
									}}
								>
									Cancel
								</button>
								<button
									type="submit"
									className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
								>
									Save
								</button>
							</div>
						)}

						{/* </div> */}
					</form>
				</FormProvider>
			</AppSlideOver>
		</>
	)
}
