import React, { useState } from "react";
import lockIcon from "./../../assets/Icons/lock.svg";
import eyeclosedIcon from "../../assets/Icons/eyeclosed.svg";
import eyeopenIcon from "../../assets/Icons/eyeopen.svg";
import correctIconGray from "../../assets/Icons/correctIconGray.svg";
import correctIconGreen from "../../assets/Icons/correctIconGreen.svg";
import crossIconRed from "../../assets/Icons/crossIconRed.svg";
import "../../pages/styles/Login.scss";
import { useForm } from "react-hook-form";
import axios from "../../utils/axios";
import apiUrl from "../../utils/apiUrl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import eyeIcon from "../../assets/Icons/eyeIcon.svg";
import visibleEyeRed from "../../assets/Icons/visibleEyeRed.svg";
import conveyaLogo from "./../../assets/images/conveya-c-logo.png";
function CreateNewPassword() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  const par = useParams();
  const { user, setUser } = useAuth();
  let navigate = useNavigate();
  const { state }: { state: any } = useLocation();

  let password = watch("new_password");
  const onSubmit = (data: any) => {
    let obj = {
      email: state?.email,
      password: data.new_password,
      confirmedPassword: data.passwordConfirm,
    };
    if (
      password.length < 8 ||
      password?.search(/^(?=.*\d)/) === -1 ||
      password?.search(/^(?=.*[a-zA-Z])/) === -1
    ) {
      setError("new_password", {
        type: "min_length_required",
        message:
          // "Password should be atleast 8 characters ",
          "Password must be at least 8 characters long, include at least 1 letter, 1 number",
      });
    } else if (obj.password !== obj.confirmedPassword) {
      setError("new_password", {
        type: "not_match",
        message:
          // "Password should be atleast 8 characters ",
          "",
      });
      setError("passwordConfirm", {
        type: "not_match",
        message:
          // "Password should be atleast 8 characters ",
          "Oops, the passwords don't match. Please re-enter to confirm",
      });
    } else {
      axios.post(apiUrl.createNewPassword, obj).then(
        (res) => {
          setUser({});
          navigate("/login");
        },
        (error) => {
          // setUser(user);
        }
      );
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="flex min-h-full flex-col  py-12 sm:px-6 lg:px-8 auth-pages">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="circledIcon bg-azure text-center mx-auto mt-auto display-flex p-2.5">
            <img
              className="mx-auto w-auto "
              src={lockIcon}
              alt="Password Lock"
            />{" "}
          </div>
          <h2 className="mt-6 text-center text-3xl font-inter-800 text-gray-900">
            Create new password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600 font-inter-400">
            Please, enter your new password below.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <label
                  htmlFor="new_password"
                  className={
                    errors.new_password
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  Password
                </label>

                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="new_password"
                    {...register("new_password", { required: true })}
                    autoComplete="current-password"
                    maxLength={20}
                    className={
                      errors.new_password
                        ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                        : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                    }
                    placeholder=""
                  />
                  <div
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <img
                      src={
                        passwordVisible
                          ? errors.new_password
                            ? visibleEyeRed
                            : eyeopenIcon
                          : errors.new_password
                          ? eyeIcon
                          : eyeclosedIcon
                      }
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                      alt=""
                    />
                  </div>
                </div>
                <p className="mt-2 text-sm text-red-700" id="email-error">
                  <>
                    {errors.new_password?.type == "required"
                      ? "Required field"
                      : ""}
                    {errors.new_password?.type == "min_length_required"
                      ? errors.new_password.message
                      : ""}
                  </>
                </p>
              </div>

              <div>
                <label
                  htmlFor="passwordConfirm"
                  className={
                    errors.passwordConfirm
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  Confirm password
                </label>
                {/* <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none  sm:text-sm"
                    />
                  </div> */}
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    maxLength={20}
                    id="passwordConfirm"
                    {...register("passwordConfirm", { required: true })}
                    autoComplete="current-password"
                    className={
                      errors.passwordConfirm
                        ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                        : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                    }
                    placeholder=""
                  />
                  <div
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <img
                      src={
                        passwordVisible
                          ? errors.passwordConfirm
                            ? visibleEyeRed
                            : eyeopenIcon
                          : errors.passwordConfirm
                          ? eyeIcon
                          : eyeclosedIcon
                      }
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                      alt=""
                    />
                  </div>
                </div>
                <p className="mt-2 text-sm text-red-700">
                  <>
                    {errors.passwordConfirm?.type == "required"
                      ? "Required field"
                      : ""}
                    {errors.passwordConfirm?.type == "not_match"
                      ? errors.passwordConfirm.message
                      : ""}
                  </>
                </p>
              </div>
              {/* <div className="flex justify-evenly">
                <div>
                  <img
                    src={
                      password?.length > 7
                        ? correctIconGreen
                        : errors.new_password
                        ? crossIconRed
                        : correctIconGray
                    }
                    className="inline-block mr-1"
                    alt=""
                  />

                  <span
                    className={
                      errors.new_password && password?.length < 8
                        ? "inline-block text-xs font-poppins-400 text-red-600"
                        : "inline-block text-xs font-poppins-400 text-gray-600"
                    }
                  >
                    Min 8 characters
                  </span>
                </div>
                <div>
                  <img
                    src={
                      password?.search(/^(?=.*\d)/) === 0
                        ? correctIconGreen
                        : errors.new_password
                        ? crossIconRed
                        : correctIconGray
                    }
                    className="inline-block mr-1"
                    alt=""
                  />
                  <span
                    className={
                      errors.new_password && password.search(/^(?=.*\d)/) === -1
                        ? "inline-block text-xs font-poppins-400 text-red-600"
                        : "inline-block text-xs font-poppins-400 text-gray-600"
                    }
                  >
                    Number(s)
                  </span>
                </div>
                <div>
                  <img
                    src={
                      password?.search(/^(?=.*[a-zA-Z])/) === 0
                        ? correctIconGreen
                        : errors.new_password
                        ? crossIconRed
                        : correctIconGray
                    }
                    className="inline-block mr-1"
                    alt=""
                  />
                  <span
                    className={
                      errors.new_password &&
                      password.search(/^(?=.*[a-zA-Z])/) === -1
                        ? "inline-block text-xs font-poppins-400 text-red-600"
                        : "inline-block text-xs font-poppins-400 text-gray-600"
                    }
                  >
                    Letter(s)
                  </span>
                </div>
              </div> */}
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center btn-lavender "
                >
                  Create password
                </button>
              </div>
            </form>
          </div>
        </div>
        <footer className="mt-auto">
          <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
            <p className="text-base font-poppins-500 text-gray-400 text-center">
              <img
                src={conveyaLogo}
                className="inline-block h-5 w-5 -mt-px"
                alt=""
              />{" "}
              Powered by <span className="text-lavender">Conveya</span> -
              Formally known as Scopesuite
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default CreateNewPassword;
