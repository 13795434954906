import { Fragment, useContext, useEffect, useState } from 'react'

import LogoIcon from '../../../assets/images/logoIcon.svg'

import {
	ChevronLeftIcon,
	MagnifyingGlassIcon,
	ChevronRightIcon
} from '@heroicons/react/20/solid'
import { Outlet, useLocation, Link, useNavigate } from 'react-router-dom'

import HistoryFilter from '../../../components/HistoryFilter'
import ReportsHistoryTable from '../../../components/ReportsHistoryTable'
import apiUrl from '../../../utils/apiUrl'
import axios from '../../../utils/axios'
import moment from 'moment'
import ScreenLoader from '../../../utils/screenLoader'
import NoData from '../../../utils/NoData'
import { searchContext } from '../Layout'
import useAuth from '../../../hooks/useAuth'
import AuthorImg from '../../../components/AuthorImg'

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}
let listType = 'general'
export default function History(props: any) {
	let layoutSearch = useContext(searchContext)
	const [coursesArray, setCoursesArray] = useState<any>([])
	const cols = {
		'Course Name': 'courseName',
		Client: 'client',
		'Date Submitted': 'dateSubmitted'
	}
	const [isHistoryFilter, setIsHistoryFilter] = useState(false)
	const [loading, setLoading] = useState(false)
	const [filterConfig, setFilterConfig] = useState<any>([])
	const [filterTrainer, setFilterTrainer] = useState<any>([])
	const [filterCourses, setFilterCourses] = useState<any>([])
	const [filterClients, setFilterClients] = useState<any>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pages, setPages] = useState<number[]>([])
	const [courseName, setCourseName] = useState<any>([])
	const [trainerName, setTrainerName] = useState<any>([])
	const [clientName, setClientName] = useState<any>([])
	const { user } = useAuth()

	const [totalCountOfReports, setTotalCountOfReports] = useState(0)
	const [sortedColumns, setSortedColumns] = useState<any>([
		{
			sort_key: '',
			sort_type: ''
		}
	])
	const [editColumnsConfig, setEditColumnsConfig] = useState({})
	const [searchText, setSearchText] = useState<string>('')

	useEffect(() => {
		if (layoutSearch?.searchIn === 'history') {
			searchOptions(layoutSearch?.search)
		}
	}, [layoutSearch?.search])
	const searchOptions = (val: string) => {
		setSearchText(val)
		setCurrentPage(1)
		if (!val) {
			GetHistory()
		} else {
			GetHistory(val)
		}
	}

	useEffect(() => {
		GetHistory()
		setCurrentPage(1)
	}, [filterConfig])

	useEffect(() => {
		createEditColumnsConfig(['Course Name', 'Client', 'Date Submitted'])
		GetHistoryFilter()
		GetHistory()
	}, [])
	const [Cols, setCols] = useState<any>([])

	const createPaginationPages = () => {
		let pagesArray = []
		if (totalCountOfReports > 90) {
			setPages([1, 2, 3, 4, 5, 6, 7, 8, 9])
		} else {
			for (let i = 1; i <= Math.ceil(totalCountOfReports / 10); i++) {
				pagesArray.push(i)
			}
			setPages(pagesArray)
		}
	}

	useEffect(() => {
		createPaginationPages()
	}, [totalCountOfReports])

	useEffect(() => {
		GetHistory(searchText)
	}, [currentPage])

	const sortCols = async (id: string) => {
		let type: any
		listType = 'sort'

		if (!sortedColumns[0].sort_key) {
			type = 'asc'
		} else {
			if (sortedColumns[0].sort_key === id) {
				type = sortedColumns[0].sort_type === 'asc' ? 'desc' : 'asc'
			} else {
				type = 'asc'
			}
		}

		setSortedColumns([
			{
				sort_key: id,
				sort_type: type
			}
		])
	}
	useEffect(() => {
		GetHistory()
	}, [sortedColumns])
	const changePage = (e: any, page: number) => {
		e.preventDefault()
		const totalPage = Math.ceil(totalCountOfReports / 10)
		if (page < 0 || page > totalPage) {
			return
		} else {
			if (page > 4 && page < totalPage - 3) {
				setPages([
					page - 4,
					page - 3,
					page - 2,
					page - 1,
					page,
					page + 1,
					page + 2,
					page + 3,
					page + 4
				])
			}
			setCurrentPage(page)
		}
	}
	const toggleHistoryFilter = (v: boolean) => {
		if (v && isHistoryFilter) {
			setIsHistoryFilter(false)
			setTimeout(() => {
				setIsHistoryFilter(true)
			}, 100)
		} else {
			setIsHistoryFilter(v)
		}
	}

	const navigate = useNavigate()

	const viewHistory = (each: any) => {
		navigate(
			`/a/history-submitted-individual-course/${
				each.clientId
			}/${encodeURIComponent(each.course)}`,
			{
				state: { submitDate: each.submitDate }
			}
		)
	}
	const createEditColumnsConfig = (data: any) => {
		//create cols
		let colsCopy: any = []
		data.forEach((each: any) => {
			colsCopy.push({
				heading: each,
				key: cols[each as keyof typeof cols]
			})
		})
		setCols([...colsCopy])
	}

	const filterArrayCourse = (data: any) => {
		let arrayTemp: any = []

		Object.values(data).forEach((each: any) => {
			let temp = {
				label: each.courseName,
				value: each.courseName,
				selected: false,
				removeFromSearch: false
			}
			arrayTemp.push(temp)
		})
		return arrayTemp
	}
	const filterArrayClient = (data: any) => {
		let arrayTemp: any = []

		Object.values(data).forEach((each: any) => {
			let temp = {
				label: each.clientName,
				value: each.sfid,
				selected: false,
				removeFromSearch: false
			}
			arrayTemp.push(temp)
		})
		return arrayTemp
	}
	const filterArrayTrainer = (data: any) => {
		let arrayTemp: any = []

		Object.values(data).forEach((each: any) => {
			let temp = {
				label: each.trainer_name,
				id: each.tcr_sfid,
				value: each.trainer_name,
				selected: false,
				removeFromSearch: false
			}
			arrayTemp.push(temp)
		})
		return arrayTemp
	}

	const GetHistoryFilter = async () => {
		// let res = await axios.get(apiUrl.historyFilter);
		// if (res.status === 200) {
		//   setCourseName(filterArray(res.data.courses));
		//   setTrainerName(filterArrayTrainer(res.data.trainername));
		//   setClientName(filterArray(res.data.clients));
		// }
		axios.post('/api/report/courselist', {}).then(({ data }) => {
			if (data.code === 200) {
				setCourseName(filterArrayCourse(data.data))
			}
		})
		axios.post('/api/report/clientlist', {}).then(({ data }) => {
			if (data.code === 200) {
				setClientName(filterArrayClient(data.data))
			}
		})
	}

	const GetHistory = async (searchText?: string) => {
		console.log(filterConfig)
		// setLoading(true);
		// let req: any = {
		//   ...filterConfig[0],
		//   ...sortedColumns[0],

		//   search_key: searchText ?? "",
		// };
		// let resp = await axios.post(apiUrl.getTCRHistory, req, {
		//   params: {
		//     skip: searchText
		//       ? currentPage > 1
		//         ? 0
		//         : currentPage - 1
		//       : currentPage
		//         ? currentPage - 1
		//         : 0,
		//     limit: 10,
		//   },
		// });
		// if (resp.status === 200) {
		//   setCoursesArray(resp?.data[0]?.records);
		//   setTotalCountOfReports(resp.data[0].total);
		//   setLoading(false);
		// }
		setLoading(true)
		axios
			.post(
				`/api/report/history?pageNo=${currentPage}&pageSize=10&sort=${sortedColumns
					.map((item: any) => `${item.sort_key} ${item.sort_type}`)
					.join(',')}`,
				{
					clientId: (filterConfig[0]?.clients || []).join(','),
					course: (filterConfig[0]?.courses || []).join(','),
					keyword: searchText,
					startTime: filterConfig[0]?.from_date,
					endTime: filterConfig[0]?.to_date
				}
			)
			.then(({ data }) => {
				setCoursesArray(data?.data?.list || [])
				setTotalCountOfReports(data?.data?.total || 0)
			})
			.finally(() => setLoading(false))
	}

	return (
		<>
			<HistoryFilter
				show={isHistoryFilter}
				toggle={toggleHistoryFilter}
				setFilterConfig={setFilterConfig}
				filterConfig={filterConfig}
				clientName={clientName}
				trainerName={trainerName}
				courseName={courseName}
			/>

			<div className="w-full px-4 sm:px-6 lg:px-8">
				<div className="py-6 flex justify-between flex-col sm:flex-row">
					<div>
						<h3 className="text-lg font-medium leading-6 text-gray-900">
							History
						</h3>
						<p className="mt-1 text-sm text-gray-500">
							Course reports submitted in the past.
						</p>
					</div>

					<div className="flex items-center">
						<span
							onClick={() => toggleHistoryFilter(true)}
							className="text-gray-400 hover:text-gray-500 cursor-pointer flex items-center sm:mt-0 sm:px-3 py-1.5 rounded-md mt-2"
						>
							<svg
								width="18"
								height="16"
								className="h-5 w-5 flex-shrink-0"
								viewBox="0 0 18 16"
								fill="currentColor"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M0 1.51473C0 0.747266 0.622266 0.125 1.38973 0.125H16.6113C17.3777 0.125 18 0.747266 18 1.51473C18 1.83816 17.8875 2.15141 17.6801 2.40066L11.25 10.1727V14.6832C11.25 15.3406 10.7156 15.875 10.0582 15.875C9.79453 15.875 9.53789 15.7871 9.32695 15.6254L7.18594 13.9555C6.91172 13.7445 6.75 13.4176 6.75 13.0695V10.1727L0.319078 2.40066C0.112852 2.15141 0 1.83816 0 1.51473ZM1.38973 1.25C1.24348 1.25 1.125 1.36848 1.125 1.51473C1.125 1.57625 1.14645 1.63602 1.18582 1.68348L7.74492 9.61016C7.8293 9.71211 7.875 9.83867 7.875 9.96875V13.0695L9.98789 14.7359C10.0301 14.7465 10.0441 14.75 10.0582 14.75C10.0969 14.75 10.125 14.7219 10.125 14.6832V9.96875C10.125 9.83867 10.1707 9.71211 10.2551 9.61016L16.8152 1.68348C16.8539 1.63602 16.875 1.57625 16.875 1.51473C16.875 1.36848 16.7555 1.25 16.6113 1.25H1.38973Z" />
							</svg>
							<button
								type="button"
								className="hidden sm:flex text-sm focus:outline-none font-poppins-500 text-gray-700 ml-2"
							>
								Filter
							</button>
						</span>

						<span className="hidden lg:flex border-r h-6 mr-2"></span>

						<div className="hidden lg:flex sm:mt-0 sm:ml-2">
							<label htmlFor="search" className="sr-only">
								Search
							</label>
							<div className="relative  rounded-md shadow-sm layout-search">
								<input
									type="text"
									autoComplete="off"
									name="search"
									value={searchText}
									id="search"
									className="block w-72 sm:w-80 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
									placeholder="Search"
									onChange={(e) => searchOptions(e.target.value ?? '')}
								/>
								<div
									className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
									aria-hidden="true"
								>
									<MagnifyingGlassIcon
										className="h-4 w-4 text-gray-400"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-col mb-4 bg-white shadow-base rounded-lg">
					<div className="overflow-x-auto">
						<div className="inline-block min-w-full align-middle">
							<div className="overflow-hidden ring-1 ring-black ring-opacity-5 rounded-t-lg">
								{loading ? (
									<ScreenLoader />
								) : !coursesArray.length ? (
									<NoData />
								) : (
									<table className="min-w-full divide-y">
										<thead className="bg-gray-50">
											<tr className="">
												<th
													scope="col"
													className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-medium text-gray-500 w-2/5"
												>
													<a href="#" className="group inline-flex">
														Client
														<span
															className="ml-2 flex-none rounded-sm bg-gray-300 text-gray-700 group-hover:bg-gray-300"
															onClick={(e) => {
																e.preventDefault()
																sortCols('name')
															}}
														>
															<svg
																className="h-4 w-4"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 20 20"
																fill="currentColor"
																aria-hidden="true"
																transform={
																	sortedColumns[0]?.sort_key === 'name'
																		? sortedColumns[0].sort_type === 'asc'
																			? 'rotate(180 0 0)'
																			: 'rotate(0 0 0)'
																		: 'rotate(0 0 0)'
																}
															>
																<path
																	fill-rule="evenodd"
																	d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
																	clip-rule="evenodd"
																/>
															</svg>
														</span>
													</a>
												</th>

												<th
													scope="col"
													className="py-3.5 pl-3 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6 w-2/5"
												>
													<a href="#" className="group inline-flex">
														Course name
														<span
															className="ml-2 flex-none rounded-sm bg-gray-300 text-gray-700 group-hover:bg-gray-300"
															onClick={(e) => {
																e.preventDefault()
																sortCols('coursename__c')
															}}
														>
															<svg
																className="h-4 w-4"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 20 20"
																fill="currentColor"
																aria-hidden="true"
																transform={
																	sortedColumns[0]?.sort_key === 'coursename__c'
																		? sortedColumns[0].sort_type === 'asc'
																			? 'rotate(180 0 0)'
																			: 'rotate(0 0 0)'
																		: 'rotate(0 0 0)'
																}
															>
																<path
																	fill-rule="evenodd"
																	d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
																	clip-rule="evenodd"
																/>
															</svg>
														</span>
													</a>
												</th>

												<th
													scope="col"
													className="px-3 whitespace-nowrap py-3.5 text-left text-xs font-medium text-gray-500"
												>
													<a href="#" className="group inline-flex">
														Date submitted
														<span
															className="ml-2 flex-none rounded-sm bg-gray-300 text-gray-700 group-hover:bg-gray-300"
															onClick={(e) => {
																e.preventDefault()
																sortCols('tcr_submit_datetime__c')
															}}
														>
															<svg
																className="h-4 w-4"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 20 20"
																fill="currentColor"
																aria-hidden="true"
																transform={
																	sortedColumns[0]?.sort_key ===
																	'tcr_submit_datetime__c'
																		? sortedColumns[0].sort_type === 'asc'
																			? 'rotate(180 0 0)'
																			: 'rotate(0 0 0)'
																		: 'rotate(0 0 0)'
																}
															>
																<path
																	fill-rule="evenodd"
																	d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
																	clip-rule="evenodd"
																/>
															</svg>
														</span>
													</a>
												</th>
												<th
													scope="col"
													className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 "
												>
													<span className="sr-only">Actions</span>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{coursesArray?.map((each: any, index: any) => {
												return (
													<tr
														onClick={() => viewHistory(each)}
														className="cursor-pointer"
														key={index}
													>
														<td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-gray-500">
															<div className="flex items-center">
																{/* <img src={LogoIcon} className="w-5 h-5 mr-2" /> */}
																<span className="h-6 w-6 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-sm inline-flex items-center justify-center p-1">
																	<AuthorImg
																		url={each.clientLogo}
																		name={each.client}
																		size="mini"
																	></AuthorImg>
																</span>
																<span className="ml-2">{each.client}</span>
																{/* [company name] */}
															</div>
														</td>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
															{/* [Course name] */}
															{each.course}
														</td>

														<td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-gray-900">
															{/* [date] */}
															{each.submitDate !== null
																? moment(each.submitDate).format('DD MMM, YYYY')
																: '-'}
														</td>
														<td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
															View
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								)}
							</div>
						</div>
					</div>

					<div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
						<div className="flex flex-1 justify-between sm:hidden">
							<a
								href="#"
								className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
							>
								Previous
							</a>
							<a
								href="#"
								className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
							>
								Next
							</a>
						</div>
						<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
							<div>
								<p className="text-sm text-gray-700 font-inter-400">
									Showing
									<span className=" inline-flex pl-1 pr-1">
										{10 * currentPage - 9}
									</span>
									to
									<span className=" inline-flex pl-1 pr-1">
										{10 * currentPage > totalCountOfReports
											? totalCountOfReports
											: 10 * currentPage}
									</span>
									of
									<span className=" inline-flex pl-1 pr-1">
										{totalCountOfReports}
									</span>
									results
								</p>
							</div>
							{totalCountOfReports > 10 && (
								<div>
									<nav
										className="isolate inline-flex -space-x-px rounded-md shadow-sm"
										aria-label="Pagination"
									>
										<a
											href="#"
											onClick={(e) => changePage(e, currentPage - 1)}
											className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
										>
											<span className="sr-only">Previous</span>
											{/* <!-- Heroicon name: mini/chevron-left --> */}
											<svg
												className="h-5 w-5"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												aria-hidden="true"
											>
												<path
													fill-rule="evenodd"
													d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
													clip-rule="evenodd"
												/>
											</svg>
										</a>
										{pages.map((page) => (
											<a
												key={page}
												href="#"
												onClick={(e) => changePage(e, page)}
												className={
													currentPage === page
														? 'relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20'
														: 'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20'
												}
											>
												{page}
											</a>
										))}
										<a
											href="#"
											onClick={(e) => changePage(e, currentPage + 1)}
											className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
										>
											<span className="sr-only">Next</span>
											{/* <!-- Heroicon name: mini/chevron-right --> */}
											<svg
												className="h-5 w-5"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												aria-hidden="true"
											>
												<path
													fill-rule="evenodd"
													d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
													clip-rule="evenodd"
												/>
											</svg>
										</a>
									</nav>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* </div> */}
		</>
	)
}
