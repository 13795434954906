import React from "react";
import oopsIcon from "./../../assets/Icons/oopsIcon.svg";

export default function DisabledAccount() {
  const LogOut = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };
  return (
    <div className="px-4 sm:px-0 sm:w-full sm:max-w-lg mx-auto text-center pt-[20%] md:pt-[15%]">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="text-center mx-auto mt-auto display-flex p-2.5">
          <img className="mx-auto w-auto " src={oopsIcon} alt="Send Email" />{" "}
        </div>

        <h2 className="mt-6 text-center text-5xl font-poppins-700 text-gray-700">
          Oops...
        </h2>
        <p className="mt-2 text-center text-sm text-gray-500 font-inter-400">
          You are not conencted to a service
        </p>
      </div>
      <button
        type="button"
        onClick={LogOut}
        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
      >
        Logout
      </button>
    </div>
  );
}
