import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ClaimAccount1 from './pages/auth/ClaimAccount1'
import Login from './pages/auth/Login'
import Layout from './pages/dashboard/Layout'

import ResetPassword from './pages/auth/ResetPassword'
import ClaimAccount2 from './pages/auth/ClaimAccount2'
import LinkExpired from './pages/auth/LinkExpired'
import CreateNewPassword from './pages/auth/CreateNewPassword'
import PasswordUpdated from './pages/auth/PasswordUpdated'

import AuthGuard from './guards/GuestGuard'
import LoginGuard from './guards/LoginGuard'

import VerifyToken from './pages/auth/VerifyToken'
import ResetCode from './pages/auth/ResetCode'
import NewPassword from './pages/auth/NewPassword'
import Reports from './pages/dashboard/reports/Reports'
import PairLearners from './pages/dashboard/reports/PairLearners'
import IndividualCourse from './pages/dashboard/reports/IndividualCourse'
import SubmittedIndividualCourse from './pages/dashboard/reports/SubmittedIndividualCourse'

import History from './pages/dashboard/history/History'
import Applications from './pages/dashboard/applications/Applications'
import Trainers from './pages/dashboard/trainers/Trainers'
import Profile from './pages/dashboard/profile/Profile'
import ViewTrainer from './pages/dashboard/trainers/ViewTrainer'
import EditProfile from './pages/dashboard/profile/EditProfile'
import NotFoundPage from './utils/common/NotFound'
import SubmittedIndividualCourseHistory from './pages/dashboard/history/SubmittedIndividualCourseHistory'
import RegisterTrainer from './pages/dashboard/trainers/RegisterTrainer'
import SetupAccount from './pages/dashboard/trainers/SetupAccount'
import WelcomeTrainer from './pages/dashboard/trainers/WelcomeTrainer'
import VerifyTrainer from './pages/auth/VerifyTrainer'
import AssignLearners from './pages/dashboard/trainers/AssignLearners'
import PairLearnersTrainer from './pages/dashboard/trainers/PairLearnersTrainer'
import IndividualCourseTrainer from './pages/dashboard/trainers/IndividualCourseTrainer'
import SubmittedIndividualCourseTrainer from './pages/dashboard/trainers/SubmittedIndividualCourseTrainer'
import CourseValidation from './pages/dashboard/reports/CourseValidation'
import RegisterByLink from './pages/dashboard/trainers/RegisterByLink'
import RegisterByLinkVerifyEmail from './pages/dashboard/trainers/RegisterByLinkVerifyEmail'
import VerifyEmailByToken from './pages/dashboard/trainers/VerifyEmailByToken'

const AppRoutes = () => {
	return (
		<div className="h-full">
			<BrowserRouter>
				<Routes>
					<Route path="*" element={<NotFoundPage />} />
					<Route path="/" element={<Navigate to="login" replace />} />
					<Route path="register-trainer" element={<RegisterTrainer />} />
					<Route path="register-by-link" element={<RegisterByLink />} />
					<Route
						path="register-by-link/verify-email"
						element={<RegisterByLinkVerifyEmail />}
					/>
					<Route
						path="register-by-link/verify-email/:token"
						element={<VerifyEmailByToken />}
					/>
					<Route path="setup-account" element={<SetupAccount />} />
					<Route
						path="welcome-trainer"
						element={
							//  <LoginGuard>
							<WelcomeTrainer />
							// </LoginGuard>
						}
					/>

					<Route
						path="login"
						element={
							<LoginGuard>
								<Login />
							</LoginGuard>
						}
					/>
					<Route path="claim-account-1" element={<ClaimAccount1 />} />
					<Route path="claim-account-2/:type" element={<ClaimAccount2 />} />
					<Route path="reset-password" element={<ResetPassword />} />
					<Route path="reset-code" element={<ResetCode />} />
					<Route path="new-password" element={<NewPassword />} />
					<Route path="link-expired" element={<LinkExpired />} />
					<Route path="create-new-password" element={<CreateNewPassword />} />
					<Route path="password-updated" element={<PasswordUpdated />} />
					<Route
						path="verifyemail/:token"
						element={<VerifyToken type="email" />}
					/>
					<Route
						path="verifynewpassword/:token"
						element={<VerifyToken type="password" />}
					/>
					<Route
						path="invitetrainer/:token"
						element={<VerifyTrainer type="email" />}
					/>
					<Route path="share/:token" element={<VerifyTrainer type="link" />} />

					<Route
						path="a"
						element={
							<AuthGuard>
								<Layout />
							</AuthGuard>
						}
					>
						<Route path="reports" element={<Reports />} />
						<Route path="coursevalidation" element={<CourseValidation />} />
						<Route
							path="assign-learners/:trainerId"
							element={<AssignLearners />}
						/>
						<Route path="pair-learners/:clientId" element={<PairLearners />} />
						<Route
							path="trainer-pair-learners/:clientId/:trainerId"
							element={<PairLearnersTrainer />}
						/>
						<Route
							path="individual-course/:accountId/:courseId"
							element={<IndividualCourse />}
						/>
						<Route
							path="trainer-individual-course/:accountId/:courseId/:trainerId"
							element={<IndividualCourseTrainer />}
						/>
						<Route
							path="submitted-individual-course/:accountId/:courseId"
							element={<SubmittedIndividualCourse />}
						/>
						<Route
							path="trainer-submitted-individual-course/:accountId/:courseId/:trainerId"
							element={<SubmittedIndividualCourseTrainer />}
						/>

						<Route path="history" element={<History />} />
						<Route path="applications" element={<Applications />} />
						<Route
							path="history-submitted-individual-course/:accountId/:courseId"
							element={<IndividualCourse type="history" />}
						/>
						<Route path="trainers" element={<Trainers />} />
						<Route path="view-trainer/:id" element={<ViewTrainer />} />

						<Route path="profile" element={<Profile />} />
						<Route path="edit-profile/:trainerId" element={<EditProfile />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	)
}

export default AppRoutes
