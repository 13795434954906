import { useEffect, useState } from "react"
import { getFileUrl } from "../utils/fileAction"

export default function AuthorImg(props: {name: string, url: string, size?: string} = {name: '', url: '', size: 'small'}){
  const [short, setShort] = useState('')
  const [img, setImg] = useState('')
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if(props.name) {
      const splitValue = props.name?.replace(/\s+/g, " ").trim()?.split(" ")
      setShort(splitValue.length > 1
        ? (splitValue[0] ?? '').substring(0, 1).toUpperCase() + (splitValue[1] ?? '').substring(0, 1).toUpperCase()
        : (splitValue[0] ?? '').substring(0, 1).toUpperCase())
    } else {
      setShort('')
    }
  }, [props.name])

  useEffect(() => {
    if(props.url?.startsWith('https://')) {
      setImg(props.url)
      setLoading(false)
    }
  }, [props.url])
  const wrapSize = props.size === 'mini' ? 'w-8 h-8' : 'w-12 h-12'
  return <>
  {img ? (
      loading ? (
        <div className={`rounded-full inline-block ${wrapSize} overflow-hidden bg-gray-100 object-cover`}>
          <div
            className="half-ring"
            style={{
              marginLeft: 20,
              marginTop: 20,
            }}
          ></div>
        </div>
      ) : (
        <img
          className={`rounded-full inline-block ${wrapSize} overflow-hidden bg-gray-100 object-contain`}
          src={img}
          alt="user image"
        />
      )
    ) : (
      <span className={`${wrapSize} flex-shrink-0 bg-lavender rounded-full text-white font-inter-400 text-sm inline-flex items-center justify-center p-1`}>
        {short}
      </span>
    )}
  </>
}
