import { Fragment, useContext, useEffect, useState } from "react";

import LogoIconBlack from "../../../assets/Icons/LogoIconBlack.svg";
import userImage from "../../../assets/images/userImage.jpg";

import {
  Outlet,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Dialog, Menu, Transition } from "@headlessui/react";
import LogoIcon from "../../../assets/images/logoIcon.svg";

import {
  MagnifyingGlassIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";

import AssignCourse from "../../../components/AssignCourseSlide";

import LearnersFilter from "../../../components/LearnersFilter";
import axios from "../../../utils/axios";
import apiUrl from "../../../utils/apiUrl";
import RemoveTrainerModel from "../../../components/RemoveTrainerModel";
import LearnersProfile from "../../../components/LearnersProfileSlide";
import { each } from "lodash";
import ScreenLoader from "../../../utils/screenLoader";
import React from "react";
import NoData from "../../../utils/NoData";
import SuccsessToaster from "../../../components/tosters/SuccsessToaster";
import { searchContext } from "../Layout";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function AssignLearners() {
  let { trainerId } = useParams();
  let layoutSearch = useContext(searchContext);
  const [showRemoveTrainerModel, setShowRemoveTrainerModel] = useState(false);
  const [removeTrainerID, setRemoveTrainerID] = useState("");
  const [removeTrainer, setRemoveTrainer] = useState(" ");
  const [isLearnersFilter, setIsLearnersFilter] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [trainer, setTrainer] = useState<any>({});
  const [trainerLoading, setTrainerLoading] = useState<any>([]);
  const [isLearnersProfile, setIsLearnersProfile] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number[]>([]);
  const [totalCountOfCourses, setTotalCountOfCourses] = useState(100);
  const [totalAssigned, setTotalAssigned] = useState(100);
  const [learnerLoading, setLearnerLoading] = useState<any>([]);
  const [learnerReports, setLearnerReports] = useState<any>([]);
  const [learnerSearch, setLearnerSearch] = useState<any>("");
  const [learnerId, setLearnerId] = useState<any>("");
  const [client, setClient] = useState<any>([]);
  const [course, setCourse] = useState<any>([]);
  const [sortedColumns, setSortedColumns] = useState<any>([
    {
      key: "",
      type: "",
    },
  ]);
  const toggleLearnersFilter = (v: boolean) => {
    if (v && isLearnersFilter) {
      setIsLearnersFilter(false);
      setTimeout(() => {
        setIsLearnersFilter(true);
      }, 100);
    } else {
      setIsLearnersFilter(v);
    }
  };
  const [isAssignCourse, setIsAssignCourse] = useState(false);
  const toggleAssignCourse = (v: boolean) => {
    // e.preventDefault();
    if (v && isAssignCourse) {
      setIsAssignCourse(false);
      setTimeout(() => {
        setIsAssignCourse(true);
      }, 100);
    } else {
      setIsAssignCourse(v);
    }
  };

  const getTrainers = async () => {
    setTrainerLoading(true);
    let response = await axios.post(apiUrl.viewTrainerProfile, {
      trainer_id: trainerId,
    });
    setTrainer(response.data);
    // setClientReports(response.data.client_reports)
    setTrainerLoading(false);
  };

  useEffect(() => {
    getTrainers();
  }, []);
  const toggleRemoveTrainerModel = (value: boolean) => {
    setShowRemoveTrainerModel(value);
    if (value === false) {
      // getAllTrainers();
    }
  };

  const AllLearnerReport = async () => {
    setLearnerLoading(true);

    let res = await axios.post(
      apiUrl.getLearnersReport,
      {
        trainer_id: trainerId,
        // trainer_id: "3b19e143-e7c6-4d95-9527-f587588ce6bd",
        company_ids: client,
        course_ids: course,
        search_key: learnerSearch ? learnerSearch : "",
        sort_key: sortedColumns[0].key.length
          ? sortedColumns[0].key
          : "learner_name",
        sort_type: sortedColumns[0].type.length ? sortedColumns[0].type : "ASC",
        type: 'assign',
      },
      {
        params: {
          skip: learnerSearch
            ? currentPage > 1
              ? 0
              : currentPage - 1
            : currentPage
              ? currentPage - 1
              : 0,
          limit: 10,
        },
      }
    );
    setLearnerReports(res.data.records);
    setCurrentPage(res.data.page);
    setTotalCountOfCourses(res.data.total);
    setTotalAssigned(res.data.total_assigned);
    // setTotalCountOfCourses(60);
    setLearnerLoading(false);
  };

  useEffect(() => {
    // if (firstTimeLoad) {
    //     return;
    // }

    if (currentPage === 1) {
      AllLearnerReport();
    } else {
      createPaginationPages();
      setCurrentPage(1);
    }
  }, [sortedColumns]);

  const createPaginationPages = () => {
    let pagesArray = [];
    if (totalCountOfCourses > 50) {
      setPages([1, 2, 3, 4, 5]);
    } else {
      for (let i = 1; i <= Math.ceil(totalCountOfCourses / 10); i++) {
        pagesArray.push(i);
      }
      setPages([...pagesArray]);
    }
  };

  useEffect(() => {
    createPaginationPages();
  }, [totalCountOfCourses]);

  useEffect(() => {
    setCurrentPage(1);
    if (learnerSearch?.trim().length > 0) {
      AllLearnerReport();
    } else {
      AllLearnerReport();
      // setSearchTotal(0);
    }
  }, [learnerSearch]);

  useEffect(() => {
    AllLearnerReport();
  }, [currentPage, client, course]);

  useEffect(() => {
    AllLearnerReport();
  }, []);

  useEffect(() => {
    if (layoutSearch?.searchIn === "assign") {
      setLearnerSearch(layoutSearch?.search)

    }
  }, [layoutSearch?.search])
  const changePage = (e: any, page: number | string) => {
    e.preventDefault();
    if (typeof page === "number") {
      setCurrentPage(page);
    } else {
      if (page === "next") {
        if (currentPage === Math.ceil(totalCountOfCourses / 10)) {
          return;
        }
        if (currentPage % 5 == 0) {
          let skip = currentPage / 5;
          let diff = totalCountOfCourses - 5 * 10 * skip;
          let pagesArray = [];
          let updatedPages = [];
          if (diff > 50) {
            updatedPages = pages.map((page) => page + 5);
            setPages(updatedPages);
          } else {
            for (let i = 1; i <= Math.ceil(diff / 10); i++) {
              updatedPages.push(i + currentPage);
            }
            setPages(updatedPages);
          }
        }
        setCurrentPage(currentPage + 1);
      } else {
        if (currentPage === 1) {
          return;
        }
        if ((currentPage - 1) % 5 === 0) {
          let updatedPages = [];
          for (let i = currentPage - 5; i < currentPage; i++) {
            updatedPages.push(i);
          }
          setPages(updatedPages);
        }
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const toggleLearnersProfile = (v: boolean) => {
    if (!v) {
      // setTimeout(() => {
        // window.location.reload();
        // AllLearnerReport();
      // }, 2000);
    }
    if (v && isLearnersProfile) {
      setIsLearnersProfile(false);
      AllLearnerReport();
      setTimeout(() => {
        setIsLearnersProfile(true);
      }, 100);
    } else {
      setIsLearnersProfile(v);
    }
  };

  const sortCols = async (id: string) => {
    let type;

    if (!sortedColumns[0].key) {
      type = "ASC";
    } else {
      if (sortedColumns[0].key === id) {
        type = sortedColumns[0].type === "ASC" ? "DESC" : "ASC";
      } else {
        type = "ASC";
      }
    }

    setSortedColumns([
      {
        key: id,
        type: type,
      },
    ]);
  };

  const assign = async (data: any) => {
    const courseArr: any = [];
    data.courses?.map((item: any) => {
      courseArr.push(item.course_sfid);
    });
    const submitCourses: any = [];
     submitCourses.push({
      course_id: data.courses[0].course_sfid,
      trainer_ids: [trainerId],
      delete_trainers: []
    });
    const response = await axios.post(apiUrl.assignTrainer, {
      uln: data.uln,
      courses: submitCourses,
      learner_id: data?.learner_sfid,
    });
    // assignLearner
    if (response.status === 200) {
      togletoaster();
      setTimeout(() => {
     // AllLearnerReport();
        //getTrainers();
       window.location.reload();
      }, 2000);
    }
  };
  const togletoaster = () => {
    {
      setShowToaster(true);
      // setToastMessage("Permissions assigned successfully.");
      setTimeout(() => {
        setShowToaster(false);
        // setToastMessage("");
      }, 2000);
    }
  };

  return (
    <>
      <AssignCourse show={isAssignCourse} toggle={toggleAssignCourse} />
      <LearnersFilter
        show={isLearnersFilter}
        toggle={toggleLearnersFilter}
        setClient={setClient}
        setCourse={setCourse}
      />
      {/* <AssignCourse show={isAssignCourse} toggle={toggleAssignCourse} /> */}

      <LearnersProfile
        show={isLearnersProfile}
        toggle={toggleLearnersProfile}
        trainerId={trainerId}
        learnerId={learnerId}
      />
      <RemoveTrainerModel
        show={showRemoveTrainerModel}
        toggle={toggleRemoveTrainerModel}
        name={removeTrainer}
        id={removeTrainerID}
      />
      <SuccsessToaster
        show={showToaster}
        toggle={(val: boolean) => {
          setShowToaster(val);
        }}
        message={"Permissions assigned successfully."}
        name=""
      />
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex items-center">
          <Link to="/a/trainers" className="flex items-center">
            <span className="pl-3 text-gray-500 font-poppins-500">Trainer</span>
          </Link>
          <ChevronRightIcon
            className="h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <Link
            to={`/a/view-trainer/${trainerId}`}
            className="flex items-center"
          >
            <span className="pl-3 text-gray-500 font-poppins-500">
              Profile
              {/* {trainer.trainer_name} */}
            </span>
          </Link>
          <ChevronRightIcon
            className="h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <Link
            // to={`/a/view-trainer/${trainerId}`}
            to=""
            className="flex items-center"
          >
            <span className="pl-3 text-gray-500 font-poppins-500">Assign Learners</span>
          </Link>
        </div>

        <div className="mb-4 px-4 py-5 flex items-start  sm:items-center justify-between  bg-white shadow-base rounded-lg">
          {trainerLoading && <ScreenLoader />}
          {!trainerLoading && (
            <div>
              <div className="flex flex-col sm:flex-row items-top sm:items-center">
                <div className="flex-shrink-0">
                  {/* <img className="h-8 w-8" src={LogoIconBlack} alt="" /> */}
                  {/* <span className="h-16 w-16 flex-shrink-0 rounded-full bg-lavender text-white font-medium text-2xl inline-flex items-center justify-center p-1">
                  HD
                </span> */}
                  <span className="h-16 w-16 flex-shrink-0 rounded-full bg-lavender text-white font-medium text-2xl inline-flex items-center justify-center p-1">
                    {trainer.trainer_name?.trim()?.split(" ")?.length > 1
                      ? trainer.trainer_name?.trim()?.split(" ")[0][0]?.toUpperCase() +
                      trainer.trainer_name?.split(" ")
                      [
                        trainer.trainer_name?.trim().split(" ")?.length - 1
                      ][0]?.toUpperCase()
                      : trainer.trainer_name?.split(" ")[0][0]?.toUpperCase()}
                  </span>
                </div>

                <div className="mt-4 sm:mt-0 sm:ml-5">
                  <div>
                    <h1 className="text-2xl  leading-7 font-poppins-600 text-gray-900 block sm:inline-flex sm:truncate sm:leading-9 sm:text-3xl">
                      {/* [Trainer’s first name last name] */}
                      {trainer.trainer_name}
                    </h1>
                  </div>

                  <div className="hidden mt-2 sm:flex flex-col sm:mt-1 sm:flex-row sm:flex-wrap  sm:items-center">
                    <div className="mt-2 flex items-center text-sm text-gray-500 font-poppins-400">
                      <svg
                        width="19"
                        height="14"
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                        viewBox="0 0 19 14"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M16.25 0H2.75C1.50723 0 0.5 1.00723 0.5 2.25V11.25C0.5 12.4928 1.50723 13.5 2.75 13.5H16.25C17.4928 13.5 18.5 12.4928 18.5 11.25V2.25C18.5 1.00723 17.491 0 16.25 0ZM2.75 1.125H16.25C16.8702 1.125 17.375 1.62984 17.375 2.25V3.51598L10.5125 8.66285C9.91484 9.11004 9.0841 9.11004 8.48645 8.66285L1.625 3.51562V2.25C1.625 1.63125 2.12984 1.125 2.75 1.125ZM17.375 11.25C17.375 11.8702 16.8702 12.375 16.25 12.375H2.75C2.12984 12.375 1.625 11.8702 1.625 11.25V4.89023L7.8125 9.5625C8.30891 9.93551 8.90445 10.1257 9.5 10.1257C10.0955 10.1257 10.6918 9.93516 11.1875 9.5625L17.375 4.89023V11.25Z" />
                      </svg>
                      {/* [trainers email] */}
                      {trainer.email}
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:hidden mt-4 sm:mt-2 flex items-center text-sm text-gray-500 font-poppins-400">
                <svg
                  width="19"
                  height="14"
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                  viewBox="0 0 19 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16.25 0H2.75C1.50723 0 0.5 1.00723 0.5 2.25V11.25C0.5 12.4928 1.50723 13.5 2.75 13.5H16.25C17.4928 13.5 18.5 12.4928 18.5 11.25V2.25C18.5 1.00723 17.491 0 16.25 0ZM2.75 1.125H16.25C16.8702 1.125 17.375 1.62984 17.375 2.25V3.51598L10.5125 8.66285C9.91484 9.11004 9.0841 9.11004 8.48645 8.66285L1.625 3.51562V2.25C1.625 1.63125 2.12984 1.125 2.75 1.125ZM17.375 11.25C17.375 11.8702 16.8702 12.375 16.25 12.375H2.75C2.12984 12.375 1.625 11.8702 1.625 11.25V4.89023L7.8125 9.5625C8.30891 9.93551 8.90445 10.1257 9.5 10.1257C10.0955 10.1257 10.6918 9.93516 11.1875 9.5625L17.375 4.89023V11.25Z" />
                </svg>
                {/* kathryn.murphy@email.com */}
                {trainer.email}
              </div>
            </div>
          )}
          <div className="flex flex-shrink-0">
            <Menu as="div" className="relative sm:ml-3 mt-4 sm:mt-0">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm ring-lavender-500 focus:outline-none  focus:ring-offset-2 mr-4">
                  <button
                    type="button"
                    className="flex items-center rounded-md sm:rounded-full text-gray-400 hover:text-gray-600"
                    id="menu-0-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open options</span>

                    <svg
                      className="sm:block h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                    </svg>

                    {/* <div className="flex sm:hidden">
                      <span className="px-6 py-2 font-medium text-gray-700">
                        {" "}
                        More
                      </span>
                      <span className="border-l p-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </div> */}
                  </button>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/a/edit-profile/${trainer.id}`}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "flex px-4 py-2 text-sm font-poppins-400"
                          )}
                        >
                          <svg
                            className="mr-3 w-4 text-gray-400"
                            viewBox="0 0 18 18"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.4024 2.07528C18.1723 2.84415 18.1723 4.08973 17.4024 4.85965L6.40899 15.8565C6.00821 16.2538 5.51602 16.5456 4.97462 16.7073L0.721412 17.9589C0.523833 18.0151 0.310329 17.9624 0.164783 17.8147C0.0192215 17.6706 -0.0352215 17.4561 0.0228778 17.2593L1.27372 13.0054C1.43297 12.4639 1.72583 11.9718 2.12485 11.571L13.1203 0.576571C13.8902 -0.19219 15.1348 -0.19219 15.9047 0.576571L17.4024 2.07528ZM11.9883 3.29872L14.6813 5.96004L16.6078 4.06512C16.9383 3.73465 16.9383 3.20028 16.6078 2.87087L15.1102 1.37216C14.7797 1.04274 14.2453 1.04274 13.9149 1.37216L11.9883 3.29872ZM11.1938 4.09325L2.92044 12.369C2.6543 12.6327 2.45919 12.9632 2.35301 13.3253L1.39325 16.5878L4.65469 15.628C5.0168 15.5225 5.34727 15.3257 5.61095 15.0585L13.8551 6.78621L11.1938 4.09325Z" />
                          </svg>
                          Edit profile
                        </Link>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to=""
                          onClick={() => {
                            toggleRemoveTrainerModel(true);
                            setRemoveTrainerID(trainer.id);
                            setRemoveTrainer(trainer.trainer_name);
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "flex px-4 py-2 text-sm font-poppins-400"
                          )}
                        >
                          <svg
                            className="mr-3"
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.29395 5.79395C5.47559 5.60938 5.77441 5.60938 5.95605 5.79395L7.5 7.33789L9.04395 5.79395C9.22559 5.60938 9.52441 5.60938 9.70605 5.79395C9.89062 5.97559 9.89062 6.27441 9.70605 6.45605L8.16211 8L9.70605 9.54395C9.89062 9.72559 9.89062 10.0244 9.70605 10.2061C9.52441 10.3906 9.22559 10.3906 9.04395 10.2061L7.5 8.66211L5.95605 10.2061C5.77441 10.3906 5.47559 10.3906 5.29395 10.2061C5.10938 10.0244 5.10938 9.72559 5.29395 9.54395L6.83789 8L5.29395 6.45605C5.10938 6.27441 5.10938 5.97559 5.29395 5.79395ZM15 8C15 12.1426 11.6426 15.5 7.5 15.5C3.35742 15.5 0 12.1426 0 8C0 3.85742 3.35742 0.5 7.5 0.5C11.6426 0.5 15 3.85742 15 8ZM7.5 1.4375C3.87598 1.4375 0.9375 4.37598 0.9375 8C0.9375 11.624 3.87598 14.5625 7.5 14.5625C11.124 14.5625 14.0625 11.624 14.0625 8C14.0625 4.37598 11.124 1.4375 7.5 1.4375Z"
                              fill="#9CA3AF"
                            />
                          </svg>
                          Remove
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <div className="mb-4 bg-white shadow-base rounded-lg">
          <div className="border-b  px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-col sm:flex-row items-left sm:items-center justify-between">
              <div className="ml-4 mt-4">
                <div className="flex items-center">
                  <div className="ml-0">
                    <h3 className="text-lg font-normal leading-6 text-gray-900">
                      Assign learners to {trainer.profile_info?.first_name}
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                      {/* [x] learners listed, [y assigned to the trainer] */}
                      {totalCountOfCourses} learners listed, [{totalAssigned}{" "}
                      assigned to the trainer]
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4 flex flex-shrink-0">
                <div className="flex  items-center">
                  {/* <span onClick={() => toggleFullScrollSlideOver(true)}  className="text-gray-400 hover:text-gray-500 cursor-pointer flex items-center border-2 sm:border-0 sm:mt-0 px-3 py-1.5 rounded-md mt-2">
                  Full Scroll                   
                  </span> */}

                  <span
                    onClick={() => toggleLearnersFilter(true)}
                    className="text-gray-400 hover:text-gray-500   cursor-pointer flex items-center border-2 border-gray-300 sm:border-0 sm:mt-0 px-3 py-1.5 rounded-md mt-2"
                  >
                    <svg
                      width="18"
                      height="16"
                      className="h-5 w-5 flex-shrink-0"
                      viewBox="0 0 18 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 1.51473C0 0.747266 0.622266 0.125 1.38973 0.125H16.6113C17.3777 0.125 18 0.747266 18 1.51473C18 1.83816 17.8875 2.15141 17.6801 2.40066L11.25 10.1727V14.6832C11.25 15.3406 10.7156 15.875 10.0582 15.875C9.79453 15.875 9.53789 15.7871 9.32695 15.6254L7.18594 13.9555C6.91172 13.7445 6.75 13.4176 6.75 13.0695V10.1727L0.319078 2.40066C0.112852 2.15141 0 1.83816 0 1.51473ZM1.38973 1.25C1.24348 1.25 1.125 1.36848 1.125 1.51473C1.125 1.57625 1.14645 1.63602 1.18582 1.68348L7.74492 9.61016C7.8293 9.71211 7.875 9.83867 7.875 9.96875V13.0695L9.98789 14.7359C10.0301 14.7465 10.0441 14.75 10.0582 14.75C10.0969 14.75 10.125 14.7219 10.125 14.6832V9.96875C10.125 9.83867 10.1707 9.71211 10.2551 9.61016L16.8152 1.68348C16.8539 1.63602 16.875 1.57625 16.875 1.51473C16.875 1.36848 16.7555 1.25 16.6113 1.25H1.38973Z" />
                    </svg>
                    {/* <button
                      type="button"
                      className="sm:hidden  text-sm focus:outline-none font-poppins-500 text-gray-700 ml-2"
                    >
                      Filter
                    </button> */}
                  </span>

                  <div className="hidden  md:flex sm:mt-0 sm:ml-2">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative  rounded-md shadow-sm layout-search">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        autoComplete="off"
                        value={learnerSearch}
                        onChange={(e) => setLearnerSearch(e.target.value)}
                        className="block w-72 sm:w-80 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search"
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <div className="ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6"
                    >
                      <div className="flex">
                        Name
                        <span
                          className="bg-gray-300 w-4 h-4 ml-2 text-center items-center justify-center flex rounded-sm"
                          onClick={(e) => {
                            e.preventDefault();
                            sortCols("learner_name");
                          }}
                        >
                          <svg
                            className="text-gray-700"
                            width="8"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            transform={
                              sortedColumns[0]?.key === "learner_name"
                                ? sortedColumns[0].type === "ASC"
                                  ? "rotate(180 0 0)"
                                  : "rotate(0 0 0)"
                                : "rotate(0 0 0)"
                            }
                          >
                            {/* <path d="M9.84909 1.57032L5.32936 5.66109C5.15536 5.81646 4.89443 5.81646 4.72029 5.66109L0.150272 1.57032C-0.0360856 1.37715 -0.0511419 1.11579 0.116466 0.928298C0.294869 0.732282 0.582359 0.735123 0.759342 0.894209L4.99982 4.71226L9.24116 0.894209C9.42848 0.727254 9.71529 0.741912 9.88375 0.927957C10.0508 1.11579 10.0366 1.37715 9.84909 1.57032Z" />
                          </svg> */}
                            <path d="M9.84909 1.57032L5.32936 5.66109C5.15536 5.81646 4.89443 5.81646 4.72029 5.66109L0.150272 1.57032C-0.0360856 1.37715 -0.0511419 1.11579 0.116466 0.928298C0.294869 0.732282 0.582359 0.735123 0.759342 0.894209L4.99982 4.71226L9.24116 0.894209C9.42848 0.727254 9.71529 0.741912 9.88375 0.927957C10.0508 1.11579 10.0366 1.37715 9.84909 1.57032Z" />
                          </svg>
                        </span>
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-medium text-gray-500"
                    >
                      <div className="flex">
                        Client
                        <span
                          className="bg-gray-300 w-4 h-4 ml-2 text-center items-center justify-center flex rounded-sm"
                          onClick={(e) => {
                            e.preventDefault();
                            sortCols("company_name");
                          }}
                        >
                          <svg
                            className="text-gray-700"
                            width="8"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            transform={
                              sortedColumns[0]?.key === "company_name"
                                ? sortedColumns[0].type === "ASC"
                                  ? "rotate(180 0 0)"
                                  : "rotate(0 0 0)"
                                : "rotate(0 0 0)"
                            }
                          >
                            <path d="M9.84909 1.57032L5.32936 5.66109C5.15536 5.81646 4.89443 5.81646 4.72029 5.66109L0.150272 1.57032C-0.0360856 1.37715 -0.0511419 1.11579 0.116466 0.928298C0.294869 0.732282 0.582359 0.735123 0.759342 0.894209L4.99982 4.71226L9.24116 0.894209C9.42848 0.727254 9.71529 0.741912 9.88375 0.927957C10.0508 1.11579 10.0366 1.37715 9.84909 1.57032Z" />
                          </svg>
                        </span>
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-medium text-gray-500"
                    >
                      <div className="flex">
                        Course
                        <span
                          className="bg-gray-300 w-4 h-4 ml-2 text-center items-center justify-center flex rounded-sm"
                          onClick={(e) => {
                            e.preventDefault();
                            sortCols("coursename");
                          }}
                        >
                          <svg
                            className="text-gray-700"
                            width="8"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            transform={
                              sortedColumns[0]?.key === "coursename"
                                ? sortedColumns[0].type === "ASC"
                                  ? "rotate(180 0 0)"
                                  : "rotate(0 0 0)"
                                : "rotate(0 0 0)"
                            }
                          >
                            <path d="M9.84909 1.57032L5.32936 5.66109C5.15536 5.81646 4.89443 5.81646 4.72029 5.66109L0.150272 1.57032C-0.0360856 1.37715 -0.0511419 1.11579 0.116466 0.928298C0.294869 0.732282 0.582359 0.735123 0.759342 0.894209L4.99982 4.71226L9.24116 0.894209C9.42848 0.727254 9.71529 0.741912 9.88375 0.927957C10.0508 1.11579 10.0366 1.37715 9.84909 1.57032Z" />
                          </svg>
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Assign </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {learnerLoading && <ScreenLoader />}
                  {!learnerLoading && learnerReports?.length === 0 && (
                    <NoData />
                  )}
                  {!learnerLoading &&
                    learnerReports?.map((each: any) => {
                      return (
                        <tr
                          className="cursor-pointer hover:bg-gray-50"
                          onClick={() => {
                            toggleLearnersProfile(true);
                            setLearnerId(each.learner_sfid);
                          }}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                            {/* [Learner’s first_name last_name]{" "} */}
                            {each.learner_name}
                            <span className="text-gray-500">
                              {/* [uln] */} {each.uln}
                            </span>
                          </td>
                          <td className="whitespace-nowrap flex items-center px-3 py-4 text-sm text-gray-900">
                            {/* <img src={LogoIcon} className="w-5 h-5 mr-2" /> */}
                            <span className="h-6 w-6 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-sm inline-flex items-center justify-center p-1">
                              {each.company_name?.trim()?.split(" ")?.length > 1
                                ? each.company_name
                                  ?.split(" ")[0][0]
                                  .toUpperCase() +
                                each.company_name
                                  ?.split(" ")
                                [
                                  each.company_name?.split(" ").length - 1
                                ][0].toUpperCase()
                                : each.company_name
                                  ?.split(" ")[0][0]
                                  .toUpperCase()}
                            </span>
                            {/* [clients name] */}
                            <div className="ml-2">{each.company_name}</div>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="text-gray-700">
                              {/* [Course] */}
                              {each.courses.map((item: any) => {
                                return (
                                  <div className="ml-2 mb-2">
                                    {item.coursename}
                                  </div>
                                );
                              })}
                            </span>
                            {/* , [Course]{" "} */}
                          </td>

                          {each.assigned === true && (
                            <td
                              className="flex whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-normal sm:pr-6 justify-end"
                              onClick={() => toggleLearnersProfile(true)}
                            >
                              <span className="flex cursor-pointer">
                                View profile
                              </span>
                            </td>
                          )}

                          {
                            each.assigned === false &&
                            (
                              <td
                                className="flex whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-normal sm:pr-6 justify-end"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  setLearnerId(each.learner_sfid);
                                  assign(each);
                                }}
                              >
                                <span className="flex cursor-pointer">
                                  Assign
                                  <svg
                                    className="ml-2"
                                    width="18"
                                    height="15"
                                    viewBox="0 0 18 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.72594 8.55H4.87406C2.18278 8.55 0 10.7325 0 13.4241C0 13.9641 0.4365 14.4 0.974812 14.4H11.6257C12.1641 14.4 12.6 13.9641 12.6 13.4241C12.6 10.7325 10.4175 8.55 7.72594 8.55ZM11.6241 13.5H0.974812C0.93375 13.5 0.9 13.4662 0.9 13.4241C0.9 11.2331 2.68313 9.45 4.87406 9.45H7.72594C9.91687 9.45 11.7 11.2331 11.7 13.4241C11.7 13.4662 11.6662 13.5 11.6241 13.5ZM6.3 7.2C8.28844 7.2 9.9 5.58816 9.9 3.6C9.9 1.61184 8.28844 0 6.3 0C4.31156 0 2.7 1.61184 2.7 3.6C2.7 5.58816 4.31156 7.2 6.3 7.2ZM6.3 0.9C7.78894 0.9 9 2.11106 9 3.6C9 5.08866 7.78894 6.3 6.3 6.3C4.81106 6.3 3.6 5.08781 3.6 3.6C3.6 2.11106 4.81219 0.9 6.3 0.9ZM17.55 5.85H15.75V4.05C15.75 3.8025 15.5475 3.6 15.3 3.6C15.0525 3.6 14.85 3.80126 14.85 4.05V5.85H13.05C12.8025 5.85 12.6 6.0525 12.6 6.3C12.6 6.5475 12.8013 6.75 13.05 6.75H14.85V8.55C14.85 8.79874 15.0513 9 15.3 9C15.5487 9 15.75 8.79874 15.75 8.55V6.75H17.55C17.7975 6.75 18 6.5475 18 6.3C18 6.0525 17.7975 5.85 17.55 5.85Z"
                                      fill="#9CA3AF"
                                    />
                                  </svg>
                                </span>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">{10 * currentPage - 9}</span> to{" "}
                  <span className="font-medium">
                    {10 * currentPage > totalCountOfCourses
                      ? totalCountOfCourses
                      : 10 * currentPage}
                  </span>{" "}
                  of <span className="font-medium">{totalCountOfCourses}</span>{" "}
                  results
                </p>
              </div>
              {totalCountOfCourses > 10 && (
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md"
                    aria-label="Pagination"
                  >
                    <a
                      href="#"
                      onClick={(e) => changePage(e, "previous")}
                      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </a>
                    {/* <> */}
                    {pages?.length > 0 &&
                      pages?.map((page) => {
                        return (
                          <a
                            key={page}
                            href="#"
                            onClick={(e) => changePage(e, page)}
                            className={
                              currentPage === page
                                ? "relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20"
                                : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                            }
                          >
                            {page}
                          </a>
                        );
                      })}
                    <a
                      href="#"
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                      onClick={(e) => changePage(e, "next")}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </a>
                  </nav>
                </div>
              )}
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </>
  );
}

export default AssignLearners;
