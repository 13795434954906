import { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ChevronLeftIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import IndividualReport from '../../../components/IndividualReportSlide'
import IndividualViewReport from '../../../components/IndividualViewReportSlide'

import SubmitReportModel from '../../../components/SubmitReportModel'
import FileUploadModel from '../../../components/FileUploadModel'
import axios from '../../../utils/axios'
import apiUrl from '../../../utils/apiUrl'
import ScreenLoader from '../../../utils/screenLoader'
import NoData from '../../../utils/NoData'
import SuccsessToaster from '../../../components/tosters/SuccsessToaster'
import ErrorToaster from '../../../../src/components/tosters/ErrorToasters'
import { FileObj } from '../../../utils/common/FileDrop'
import LearnersProfile from '../../../components/LearnersProfileSlide'
import ReportTemplate from '../../../../src/assets/FileDrop.xlsx'
import { Menu, Transition } from '@headlessui/react'
import AssignTrainerSlide from '../../../components/AssignTrainerSlide'
import AuthorImg from '../../../components/AuthorImg'

/**
 *
 * @param url 目标下载接口
 * @param query 查询参数
 * @param fileName 文件名称
 * @returns {*}
 */
// export function downBlobFile(url: any, query: any, fileName: string) {
// 	return request({
// 		url: url,
// 		method: 'get',
// 		responseType: 'blob',
// 		params: query,
// 	}).then((response) => {
// 		handleBlobFile(response, fileName);
// 	});
// }

function handleBlobFile(response: any, fileName: string) {
	const blob = response
	if (blob && blob.size === 0) {
		return
	}
	const link = document.createElement('a')

	var binaryData = [] as any
	binaryData.push(response)
	link.href = window.URL.createObjectURL(new Blob(binaryData))
	link.download = fileName
	document.body.appendChild(link)
	link.click()
	window.setTimeout(function () {
		// @ts-ignore
		URL.revokeObjectURL(blob)
		document.body.removeChild(link)
	}, 0)
}

export interface IReport {
	trainer_info: any
	LearnerName: string
	CourseProgress: string
	Rag: string
	Percentage: string
	accountSfid: string
	MathFS: string
	EnglishFS: string
	courseSfid: string
	learnerSfid: string
	trainingProviderSfid: string
	trainingproviderid: string
	LastModifiedDate: Date
	TrainingProviderName: string
	NextVisit: any
	Comment: string
	FunctionalSkills: string
	Flag: string
	CompanyName: string
	CourseName: string
	ReportDate: string
	CompanyLogo: string
	clientid: string
	courseid: string
	learnerid: string
	CompletionDate: Date
	CompletionStatus: string
	BILStartDate: Date
	BILEndDate: Date
	EPAreadyDate: Date
	LeaverDate: Date
	AdditionalTag: string
	isupdate: boolean
	BILReturnDate: Date
	EPAReadyDate: Date
	files?: any[]
	NextDate: Date
	additional: any
	uln: Number
}

function IndividualCourse(props: any) {
	let { accountId, courseId } = useParams()
	if (courseId) {
		courseId = decodeURIComponent(courseId)
	}
	const navigate = useNavigate()
	const { state, key, search }: { state: any; key: any; search: any } =
		useLocation()
	const [enrollmentId, setEnrollmentId] = useState<string>('')

	const [isIndividualReport, setIsIndividualReport] = useState(false)
	const [isLearnersProfile, setIsLearnersProfile] = useState(false)
	const [learnerId, setLearnerId] = useState<any>()
	const [trainerId, settrainerId] = useState<any>()
	const [message, setMessage] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [fileArray, setFileArray] = useState<FileObj[]>([])
	const [toastMessage, setToastMessage] = useState('')
	const [sortType, setSortType] = useState<any>('createdate desc')
	const [isHistory, setIsHistory] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [reportDate, setReportDate] = useState('')

	useEffect(() => {
		setIsHistory(props.type === 'history')
	}, [props.type])
	// useEffect(() => {
	// 	console.log('search')
	// 	if (search) {
	// 		const searchParams = new URLSearchParams(search)
	// 		const params = Object.fromEntries(searchParams.entries())
	// 		console.log(params)
	// 		setEnrollmentId(params.enrollmentId)
	// 	}
	// }, [search])
	const toggleIndividualReport = (v: boolean, isToaster?: boolean) => {
		if (v && isIndividualReport) {
			setIsIndividualReport(false)
			setTimeout(() => {
				setIsIndividualReport(true)
			}, 100)
		} else {
			setIsIndividualReport(v)
			if (!v) {
				getAllReports()
				setFileArray([])
			}
		}
		if (isToaster) {
			setShow(true)
			setToastMessage('Data updated successfully')
			setTimeout(() => {
				setShow(false)
				setToastMessage('')
			}, 2000)
		}
	}

	const ragRatingRender = (rag: any) => {
		if (rag === 'Green') {
			return (
				<span className="inline-flex rounded-full bg-green-100 py-1 px-2 text-xs font-poppins-400  text-green-800">
					Green
				</span>
			)
		} else if (rag === 'Red') {
			return (
				<span className="inline-flex rounded-full bg-red-100 py-1 px-2 text-xs font-poppins-400  text-red-800">
					Red
				</span>
			)
		} else {
			return (
				<span className="inline-flex rounded-full bg-yellow-100 py-1 px-2 text-xs font-poppins-400  text-yellow-800">
					{rag}
				</span>
			)
		}
	}

	const getFiles = (files?: FileObj[]) => {
		files?.forEach((file: FileObj) => {
			file.size = file.FileSize ?? file.size
			var request = new XMLHttpRequest()
			request.open('GET', file.FileUrl, true)
			request.responseType = 'blob'
			request.onload = function () {
				var newFile = new File(
					[request.response],
					file.FileTitle ?? file.name,
					{ lastModified: file.lastModified, type: file.FileType ?? file.type }
				) as any
				newFile.FileSize = file.FileSize ?? file.size
				newFile.FileTitle = newFile.name
				newFile.FileType = file.FileType ?? file.type
				Object.defineProperty(newFile, 'size', { value: newFile.FileSize })
				fileArray.push(file.FileUrl ? newFile : file)
				setFileArray([...fileArray])
			}
			request.send()
		})
		return files
	}

	const isBefore = (date1: Date, date2: Date) => {
		return date1 && date2
			? moment(date1).isBefore(
					moment(new Date(date2).setDate(new Date(date2).getDate() + 1))
			  )
			: true
	}
	const [isIndividualViewReport, setIsIndividualViewReport] = useState(false)
	const [show, setShow] = useState<boolean>(false)
	const [showToaster, setShowToaster] = useState<boolean>(false)
	const [showErrorToaster, setShowErrorToaster] = useState<boolean>(false)
	const [showError, setShowError] = useState<boolean>(false)
	const [errorMsg, setErrorMsg] = useState<string>('')
	const [courseReports, setCourseReports] = useState<any[]>([])

	const [isAssignTrainerSlide, setIsAssignTrainerSlide] = useState(false)
	const [assignedTrainers, setAssignedTrainers] = useState<any>([])
	const [company, setCompany] = useState<any>({})
	const [clientId, setClientId] = useState<any>()

	const [searchText, setSearchText] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const searchOptions = (val: string) => {
		setCurrentPage(1)
		setSearchText(val ?? '')
		if (!val) {
			getAllReports()
		} else {
			getAllReports(val)
		}
	}
	const toggleIndividualViewReport = (v: boolean) => {
		if (v && isIndividualViewReport) {
			setIsIndividualViewReport(false)
			setTimeout(() => {
				setIsIndividualViewReport(true)
			}, 100)
		} else {
			setIsIndividualViewReport(v)
		}
	}

	useEffect(() => {
		if (!isIndividualReport && !isIndividualViewReport) {
			setSelectedReport({})
		}
	}, [isIndividualReport, isIndividualViewReport])

	const [showSubmitReportModel, setSubmitReportModel] = useState(false)
	const toggleSubmitReportModel = (value: boolean) => {
		setSubmitReportModel(value)
	}
	const [hasShow, setHasShow] = useState<boolean>(false)
	const [showFileUploadModel, setFileUploadModel] = useState(false)
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pages, setPages] = useState<number[]>([])
	const [completedReports, setCompletedReports] = useState<number>(0)
	const [totalCountOfReports, setTotalCountOfReports] = useState(0)
	const [isNotValidSrc, setIsNotValidSrc] = useState<boolean>(false)
	const [selectedReport, setSelectedReport] = useState<any>()
	const [clientInfo, setClientInfo] = useState<any>()
	const [modifiedDate, setModifiedDate] = useState<any>(null)
	const toggleFileUploadModel = (value: boolean) => {
		setFileUploadModel(value)
	}

	// const toggleFileUploadToaster = (value: boolean) => {
	//   setFileUploadModel(value);
	// };

	const createPaginationPages = () => {
		let pagesArray = []
		if (totalCountOfReports > 90) {
			setPages([1, 2, 3, 4, 5, 6, 7, 8, 9])
		} else {
			for (let i = 1; i <= Math.ceil(totalCountOfReports / 10); i++) {
				pagesArray.push(i)
			}
			setPages(pagesArray)
		}
	}

	useEffect(() => {
		getAllReports()
		getClientInfo()
	}, [])

	useEffect(() => {
		createPaginationPages()
	}, [totalCountOfReports])

	useEffect(() => {
		getAllReports(searchText)
	}, [currentPage])

	useEffect(() => {
		getAllReports()
	}, [sortType])

	const getClientInfo = () => {
		axios
			.post('/api/report/clientInfo', {
				clientId: accountId
			})
			.then(({ data }) => {
				console.log(data)
				setClientInfo(data.data)
			})
	}

	const changePage = (e: any, page: number) => {
		e.preventDefault()
		const totalPage = Math.ceil(totalCountOfReports / 10)
		if (page < 0 || page > totalPage) {
			return
		} else {
			if (page > 4 && page < totalPage - 3) {
				setPages([
					page - 4,
					page - 3,
					page - 2,
					page - 1,
					page,
					page + 1,
					page + 2,
					page + 3,
					page + 4
				])
			}
			setCurrentPage(page)
		}
	}

	const getAllReports = async (searchKey?: string) => {
		setLoading(true)
		const params = {
			keyword: searchKey,
			course: courseId,
			clientId: accountId
		} as any
		if (props.type === 'history') {
			params.submitTime = state?.submitDate
		}
		axios
			.post(
				`/api/report/courselearners?pageNo=${currentPage}&pageSize=10&sort=${sortType}`,
				params
			)
			.then(({ data }) => {
				const result = data.data
				setTotalCountOfReports(result.total)
				setCompletedReports(result.submittedNum)
				setCourseReports(result.list)
				console.log(search)
				if (result.list.length && search && !hasShow) {
					const searchParams = new URLSearchParams(search)
					const params = Object.fromEntries(searchParams.entries())
					console.log(params)
					const temp = result.list.filter(
						(f: any) => f.enrollmentId === params.enrollmentId
					)
					if (temp.length > 0) {
						toggleLearnersProfile(true, temp[0])
						setLearnerId(temp[0]?.learnerId)
						setHasShow(true)
					}
				}
				setReportDate(result.reportDate)
				setIsSubmitted(+result.submittedNum === +result.total)
			})
			.finally(() => setLoading(false))
	}

	const handle4Export = () => {
		// window.open('/api/report/export')
		axios
			.post('/api/report/export', {
				keyword: searchText,
				course: courseId,
				clientId: accountId
			})
			.then(({ data }) => {
				if (data) {
					handleBlobFile(
						data,
						`Report_${clientInfo.client}_${courseId}_${moment(
							new Date()
						).format('DD/MM/YYYY')}.csv`
					)
				} else {
					setShowError(true)
					setErrorMsg('Export report failed. Please try again')
					setTimeout(() => {
						setShowError(false)
					}, 3000)
				}
			})
			.catch(() => {
				setShowError(true)
				setErrorMsg('Export report failed. Please try again')
				setTimeout(() => {
					setShowError(false)
				}, 3000)
			})
	}

	const submitReports = async () => {
		// await axios
		//   .post(
		//     apiUrl.getIndividualReports,
		//     {
		//       clientid: Number(accountId) ?? "",
		//       courseid: courseId,
		//       search_learner: "",
		//       submit_reports: "True",
		//       sort_type: "is_update",
		//     },
		//     {
		//       params: { skip: 0, limit: 5 },
		//     }
		//   )
		//   .then((e) => {
		//     if (e.status === 200) {
		//       if (courseReports.length > 0) {
		//         const editedCourseList: any = [];
		//         courseReports.forEach((coureseList: any) => {
		//           if (coureseList.isupdate === false) {
		//             editedCourseList.push(coureseList);
		//           }
		//         });
		//         if (editedCourseList.length > 1) {
		//           setName("");
		//         }
		//       }
		//       setSubmitReportModel(false);
		//       setShowToaster(true);
		//       setMessage("Report submitted successfully");
		//       setTimeout(() => {
		//         navigate(`/a/submitted-individual-course/${accountId}/${courseId}`);
		//       }, 2000);
		//     }
		//   })
		//   .catch((err) => {
		//     setSubmitReportModel(false);
		//     setShowError(true);
		//     setShow(false);
		//     setLoading(false);
		//     setErrorMsg(err.response.data.detail);
		//     setTimeout(() => {
		//       setShowError(false);
		//     }, 3000);
		//   });
		axios
			.post('/api/report/submit', {
				clientId: accountId,
				course: courseId
			})
			.then((res) => {
				if (res.data.code === 200) {
					setSubmitReportModel(false)
					setShowToaster(true)
					setMessage('Report submitted successfully')
					setTimeout(() => {
						// navigate(`/a/submitted-individual-course/${accountId}/${courseId}`);
						window.location.reload()
					}, 2000)
				} else {
					setSubmitReportModel(false)
					setShowError(true)
					setShow(false)
					setLoading(false)
					setErrorMsg('Submit report failed. Please try again')
					setTimeout(() => {
						setShowError(false)
					}, 3000)
				}
			})
			.catch((err) => {
				setSubmitReportModel(false)
				setShowError(true)
				setShow(false)
				setLoading(false)
				setErrorMsg('Submit report failed. Please try again')
				setTimeout(() => {
					setShowError(false)
				}, 3000)
			})
	}

	const getSelectedReport = (report: any) => {
		return axios
			.post(
				`/api/report/detail?enrollmentId=${report.enrollmentId}&refId=${report.refId}`
			)
			.then(({ data }) => {
				return data.data
			})
	}

	const toggleFileToaster = (v: any) => {
		setShowToaster(true)
		setTimeout(() => {
			setShowToaster(false)
		}, 2000)
	}

	const toggleFileErrorToaster = (v: any) => {
		setShowErrorToaster(true)
		setTimeout(() => {
			setShowErrorToaster(false)
		}, 3000)
	}

	const toggleLearnersProfile = (v: boolean, item: any) => {
		if (!v) {
			// window.location.reload();
			// AllLearnerReport();
		}
		if (v && isLearnersProfile) {
			setIsLearnersProfile(false)
			setTimeout(() => {
				setIsLearnersProfile(true)
			}, 100)
		} else {
			setIsLearnersProfile(v)
		}
	}

	const toggleAssignTrainerSlide = (v: boolean) => {
		if (v && isAssignTrainerSlide) {
			setIsAssignTrainerSlide(false)
			setTimeout(() => {
				setIsAssignTrainerSlide(true)
			}, 100)
		} else {
			setIsAssignTrainerSlide(v)
		}
	}

	return (
		<>
			{showError && (
				<ErrorToaster
					show={showError}
					msg={errorMsg}
					toggle={(val: boolean) => {
						setShowError(val)
					}}
				/>
			)}
			<LearnersProfile
				show={isLearnersProfile}
				toggle={toggleLearnersProfile}
				trainerId={trainerId}
				learnerId={learnerId}
				courseId={courseId}
				setMessage={setMessage}
				setShowToaster={setShowToaster}
				setShowErrorToaster={setShowErrorToaster}
			/>

			<SuccsessToaster
				show={show}
				toggle={(val: boolean) => {
					setShow(val)
				}}
				message={message}
				name={name}
			/>
			<SuccsessToaster
				show={showToaster}
				toggle={(val: boolean) => {
					setShowToaster(val)
				}}
				message={message}
				name={name}
			/>
			{/* <AssignTrainerSlide
        show={isAssignTrainerSlide}
        toggle={toggleAssignTrainerSlide}
        assignedTrainers={assignedTrainers}
        company={company}
        clientId={clientId}
      /> */}
			{isIndividualReport && (
				<IndividualReport
					show={isIndividualReport}
					toggle={toggleIndividualReport}
					selectedReport={selectedReport}
					fileArray={fileArray}
					setFileArray={setFileArray}
					setMessage={setMessage}
					setName={setName}
					setShowErrorToaster={setShowErrorToaster}
				/>
			)}

			{isIndividualViewReport && (
				<IndividualViewReport
					show={isIndividualViewReport}
					toggle={toggleIndividualViewReport}
					selectedReport={selectedReport}
					source={'reports'}
				/>
			)}
			<SubmitReportModel
				show={showSubmitReportModel}
				submitReports={submitReports}
				toggle={toggleSubmitReportModel}
			/>
			<FileUploadModel
				show={showFileUploadModel}
				toggle={toggleFileUploadModel}
				toaster={toggleFileToaster}
				errorToggle={toggleFileErrorToaster}
				message={setMessage}
				clientid={clientId}
			/>

			<ErrorToaster
				show={showErrorToaster}
				toggle={(val: boolean) => {
					setShowErrorToaster(val)
				}}
				msg={message}
			/>
			<div className="w-full px-4 sm:px-6 lg:px-8">
				<div className="py-6 flex items-center">
					<a
						href="javascript:void(0);"
						onClick={() => window.history.back()}
						className="flex items-center"
					>
						<ChevronLeftIcon
							className="h-5 w-5 flex-shrink-0 text-gray-400"
							aria-hidden="true"
						/>
						<span className="pl-3 text-gray-500 font-poppins-500">Back</span>
					</a>

					{/* <div className="justify-end text-right font-poppins-300 text-gray-500 text-xs ml-auto">
            {modifiedDate
              ? "Last saved " + moment.utc(modifiedDate).local().fromNow()
              : ""}
          </div> */}
				</div>

				<div className="mb-4 px-4 py-5 flex flex-col sm:flex-row flex-wrap sm:items-center sm:justify-between sm:flex-nowrap  bg-white shadow-base rounded-lg">
					<div className="mb-2 sm:mb-0">
						{isHistory || (!isHistory && isSubmitted) ? (
							<div className="flex items-center">
								<div className="flex-shrink-0">
									<AuthorImg
										url={clientInfo?.clientLogo}
										name={clientInfo?.client}
									></AuthorImg>
								</div>
								<div className="ml-4">
									<h3 className="text-lg font-medium leading-6 text-gray-700">
										{clientInfo?.client}
										<span className="ml-2 font-inter-400 text-gray-900">
											{courseId}
										</span>
									</h3>
									{reportDate ? (
										<p className="text-sm text-gray-500 mt-1">
											Report submitted on {reportDate ? reportDate : ''}.
										</p>
									) : (
										<></>
									)}
								</div>
							</div>
						) : (
							<div className="flex items-center">
								<div className="flex-shrink-0">
									<AuthorImg
										url={clientInfo?.clientLogo}
										name={clientInfo?.client}
									></AuthorImg>
								</div>
								<div className="ml-4">
									<h3
										className="text-lg font-medium leading-6 text-gray-700"
										onClick={() => {
											// setAssignedTrainers(clientInfo?.assigned_trainers);
											// setClientId(courseReports[0]?.clientid);
											// setCompany(clientInfo);
											// toggleAssignTrainerSlide(true);
										}}
									>
										{clientInfo?.client}
									</h3>
									<p className="text-sm text-gray-500 mt-1 break-all">
										{courseId}
									</p>
								</div>
							</div>
						)}
					</div>
					<div className="flex flex-shrink-0 items-center">
						{isHistory || (!isHistory && isSubmitted) ? (
							<button
								type="button"
								onClick={handle4Export}
								className="relative inline-flex items-center rounded-r-md rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium font-poppins-500  text-gray-700 hover:bg-gray-50 focus:z-10 border-lavender-500 focus:outline-none box-shadow-lavender-focus"
							>
								<span>
									<svg
										className="-ml-1 mr-2 h-5 w-5 text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											d="M14.4918 4.89453L10.3316 0.734375C9.85895 0.263359 9.22613 0 8.56207 0H2.72223C1.34137 0 0.222229 1.11914 0.222229 2.5L0.222483 17.5C0.222483 18.8809 1.34162 20 2.72248 20H12.7222C14.1031 20 15.2222 18.8809 15.2222 17.5V6.66016C15.2222 5.99609 14.9605 5.36328 14.4918 4.89453ZM8.97223 1.33125C9.14711 1.39242 9.31074 1.48148 9.44567 1.61637L13.6058 5.77652C13.7418 5.91016 13.8316 6.07422 13.8902 6.25H9.59723C9.25348 6.25 8.97223 5.96875 8.97223 5.625V1.33125ZM13.9722 17.5C13.9722 18.1891 13.4113 18.75 12.7222 18.75H2.72223C2.03317 18.75 1.47223 18.1891 1.47223 17.5V2.5C1.47223 1.81094 2.03317 1.25 2.72223 1.25H7.72223V5.625C7.72223 6.66016 8.56207 7.5 9.59723 7.5H13.9722V17.5ZM8.34723 9.375C8.34723 9.03125 8.06598 8.75 7.72223 8.75C7.37848 8.75 7.09723 9.03125 7.09723 9.375V14.1172L5.03863 12.0586C4.91754 11.9375 4.75738 11.875 4.59723 11.875C4.43707 11.875 4.27692 11.9375 4.15582 12.0586C3.91168 12.3027 3.91168 12.6984 4.15582 12.9422L7.28082 16.0672C7.52496 16.3113 7.92067 16.3113 8.16442 16.0672L11.2894 12.9422C11.5336 12.698 11.5336 12.3023 11.2894 12.0586C11.0453 11.8148 10.6496 11.8145 10.4058 12.0586L8.34723 14.1172V9.375Z"
											fill=""
										/>
									</svg>
								</span>
								<span>Export report</span>
							</button>
						) : (
							<button
								onClick={() => toggleSubmitReportModel(true)}
								type="button"
								className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-lavender px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
							>
								<span className="inline-block h-3">
									<svg
										className="-ml-1 mr-2 h-5 w-5 text-white"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											d="M17.8102 0.194487C18.0633 0.44358 18.0633 0.853379 17.8102 1.10247L6.88221 12.0304C6.63311 12.2835 6.22331 12.2835 5.97422 12.0304L0.188266 6.24504C-0.0627554 5.99595 -0.0627554 5.58615 0.188266 5.33706C0.439127 5.08395 0.846113 5.08395 1.09722 5.33706L6.42821 10.6685L16.9022 0.194487C17.1513 -0.0570166 17.5611 -0.0570166 17.8102 0.194487Z"
											fill=""
										/>
									</svg>
								</span>
								<span>Submit reports</span>
							</button>
						)}
					</div>
				</div>

				<div className="mb-4 bg-white shadow-base rounded-lg individual-course-table">
					<div className="border-b px-4 py-5 sm:px-6">
						<div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
							<div className="ml-4 mt-4">
								<div className="flex items-center">
									{isHistory || (!isHistory && isSubmitted) ? (
										<div className="ml-0">
											<h3 className="text-lg font-normal leading-6 text-gray-900">
												Report submitted
											</h3>
										</div>
									) : (
										<div className="ml-0">
											<h3 className="text-lg font-normal leading-6 text-gray-900">
												Reports
											</h3>
											<p className="text-sm text-gray-500 mt-1">
												{completedReports} out of {totalCountOfReports} reports
												completed.
											</p>
										</div>
									)}
								</div>
							</div>

							<div className="ml-4 mt-4 flex flex-shrink-0 -mr-4">
								{isHistory || (!isHistory && isSubmitted) ? (
									<></>
								) : (
									<Menu as="div" className="hidden md:block relative ml-3">
										<div>
											<Menu.Button className="relative sm:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
												<span className="sr-only">Open options</span>
												<svg
													width="18"
													height="16"
													viewBox="0 0 18 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														id="Vector"
														d="M0 1.57145C0 1.21627 0.287839 0.928589 0.642857 0.928589H17.3571C17.7107 0.928589 18 1.21627 18 1.57145C18 1.92662 17.7107 2.2143 17.3571 2.2143H0.642857C0.287839 2.2143 0 1.92662 0 1.57145ZM0 8.00002C0 7.64645 0.287839 7.35716 0.642857 7.35716H12.2143C12.5679 7.35716 12.8571 7.64645 12.8571 8.00002C12.8571 8.35359 12.5679 8.64287 12.2143 8.64287H0.642857C0.287839 8.64287 0 8.35359 0 8.00002ZM7.07143 15.0714H0.642857C0.287839 15.0714 0 14.7822 0 14.4286C0 14.075 0.287839 13.7857 0.642857 13.7857H7.07143C7.425 13.7857 7.71429 14.075 7.71429 14.4286C7.71429 14.7822 7.425 15.0714 7.07143 15.0714Z"
														fill="#9CA3AF"
													/>
												</svg>
											</Menu.Button>
										</div>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute right-0 z-20 mt-2 w-52 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
												<div className="py-1">
													<Menu.Item>
														{({ active }) => (
															<a
																className="hover:bg-gray-100 text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
																href="#"
																onClick={(e) => {
																	e.preventDefault()
																	setSortType('sort desc')
																}}
															>
																Update required first
															</a>
														)}
													</Menu.Item>
												</div>
												<div className="py-1">
													<Menu.Item>
														{({ active }) => (
															<a
																className="hover:bg-gray-100 text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
																href="#"
																onClick={(e) => {
																	e.preventDefault()
																	setSortType('firstName asc')
																}}
															>
																Alphabetic A - Z
															</a>
														)}
													</Menu.Item>
												</div>
												<div className="py-1">
													<Menu.Item>
														{({ active }) => (
															<a
																className="hover:bg-gray-100 text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
																href="#"
																onClick={(e) => {
																	e.preventDefault()
																	setSortType('firstName desc')
																}}
															>
																Alphabetic Z - A
															</a>
														)}
													</Menu.Item>
												</div>
											</Menu.Items>
										</Transition>
									</Menu>
								)}
								<div className="flex  items-center">
									<div className="hidden  sm:flex sm:mt-0 sm:ml-3">
										<label htmlFor="search" className="sr-only">
											Search
										</label>
										<div className="relative  rounded-md shadow-sm layout-search">
											<input
												type="text"
												name="search"
												id="search"
												className="block w-72 sm:w-80 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
												placeholder="Search"
												onChange={(e) => searchOptions(e.target.value ?? '')}
											/>
											<div
												className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
												aria-hidden="true"
											>
												<MagnifyingGlassIcon
													className="h-4 w-4 text-gray-400"
													aria-hidden="true"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{loading && (
						<div
							className={`${
								loading ? 'h-24 flex items-center justify-center' : ''
							}`}
						>
							<ScreenLoader />
						</div>
					)}
					{!loading && courseReports?.length > 0 ? (
						<div className="ring-black ring-opacity-5">
							<table className="min-w-full divide-y">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6"
										>
											Name
										</th>

										<th
											scope="col"
											className="hidden sm:table-cell whitespace-nowrap px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12  "
										>
											Course Status
										</th>
										<th
											scope="col"
											className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
										>
											RAG
										</th>
										<th
											scope="col"
											className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
										>
											%
										</th>
										<th
											scope="col"
											className="hidden sm:table-cell relative py-3.5 pl-3 pr-4 sm:pr-6 w-2/12  "
										>
											<span className="sr-only">Actions</span>
										</th>
									</tr>
								</thead>

								<tbody className="divide-y divide-gray-200 bg-white">
									{courseReports?.map((report) => (
										<tr
											className={`cursor-pointer relative z-10 hover:font-medium transition-all duration-300 ease-in ${
												!isHistory && report.submitStatus === 'Submitted'
													? 'opacity-50'
													: ''
											}`}
											onClick={(e) => {
												e.stopPropagation()
												e.preventDefault()
												// let obj: any = { ...report };
												// obj.CompletionDate = obj.CompletionDate
												//   ? new Date(obj.CompletionDate)
												//   : null;
												// obj.BILEndDate = obj.BILReturnDate
												//   ? new Date(obj.BILReturnDate)
												//   : null;
												// obj.EPAReadyDate = obj.EPAreadyDate
												//   ? new Date(obj.EPAreadyDate)
												//   : null;
												// obj.LeaverDate = obj.LeaverDate
												//   ? new Date(obj.LeaverDate)
												//   : null;
												// obj.BILStartDate = obj.BILStartDate
												//   ? new Date(obj.BILStartDate)
												//   : null;
												// obj.NextVisit = obj.NextDate
												//   ? isBefore(new Date(), obj.NextDate)
												//     ? new Date(obj.NextDate)
												//     : null
												//   : null;
												// getFiles(obj?.files);
												getSelectedReport(report).then((obj: any) => {
													setSelectedReport(obj)
													isHistory || report.submitStatus === 'Submitted'
														? toggleIndividualViewReport(true)
														: toggleIndividualReport(true)
												})
												// setTimeout(() => {
												//   report.submitStatus === "Submitted"
												//     ? toggleIndividualViewReport(true)
												//     : toggleIndividualReport(true);
												// }, 100);
											}}
										>
											{/* Learner name */}
											<td
												className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6"
												onClick={(e) => {
													e.stopPropagation()
													toggleLearnersProfile(true, report)
													setLearnerId(report?.learnerId)
													// settrainerId(report?.trainer_info?.tp_sfid);
												}}
											>
												<span className="cursor-pointer block mb-3 sm:mb-0">
													{`${report.firstName} ${report.lastName}`}
													<span className="ml-2 text-[#6B7280]">
														{report?.uln ?? '-'}
													</span>
												</span>
											</td>

											{/* Course Progress */}
											<td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
												{report.courseProgress
													? report.courseProgress
													: 'Not Started'}
											</td>

											{/* RAG */}
											<td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
												{report.ragRating
													? ragRatingRender(report.ragRating)
													: '-'}
											</td>

											{/* Percentage */}
											<td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
												{report.percentage ? `${report.percentage} % ` : '-'}
											</td>

											{/* Conditional links */}
											{!isHistory && report.submitStatus === 'due' && (
												<td
													onClick={(e) => {
														e.stopPropagation()
														e.preventDefault()
														// setTimeout(() => {
														//   toggleIndividualReport(true);
														// }, 100);
														// let obj: any = { ...report };
														// obj.CompletionDate = obj.CompletionDate
														//   ? new Date(obj.CompletionDate)
														//   : null;
														// obj.BILEndDate = obj.BILReturnDate
														//   ? new Date(obj.BILReturnDate)
														//   : null;
														// obj.EPAReadyDate = obj.EPAreadyDate
														//   ? new Date(obj.EPAreadyDate)
														//   : null;
														// obj.LeaverDate = obj.LeaverDate
														//   ? new Date(obj.LeaverDate)
														//   : null;
														// obj.BILStartDate = obj.BILStartDate
														//   ? new Date(obj.BILStartDate)
														//   : null;
														// obj.NextVisit = obj.NextDate
														//   ? isBefore(new Date(), obj.NextDate)
														//     ? new Date(obj.NextDate)
														//     : null
														//   : null;
														// getFiles(obj?.files);
														getSelectedReport(report).then((obj: any) => {
															setSelectedReport(obj)
															toggleIndividualReport(true)
														})
													}}
													className="hidden sm:table-cell whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end z-40 relative"
												>
													<div className="flex justify-end cursor-pointer">
														Update required
														<svg
															width="18"
															className="ml-2 w-4 h-5 text-lavender"
															height="15"
															viewBox="0 0 18 15"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M14.4759 0.649961C15.1763 -0.0602517 16.3238 -0.0637674 17.0297 0.642086L17.3644 0.977337C18.0675 1.68018 18.0675 2.82093 17.3644 3.52406L11.9503 8.93813C11.7028 9.18563 11.3878 9.35719 11.0475 9.4275L8.64281 9.93375C8.46844 9.96469 8.33906 9.91688 8.23219 9.81C8.12531 9.67781 8.0775 9.54844 8.10844 9.39938L8.61469 6.99187C8.685 6.65156 8.85375 6.34219 9.09562 6.09469L14.4759 0.649961ZM16.3913 1.27856C16.0397 0.925587 15.4659 0.927274 15.1144 1.28249L14.3466 2.0354L15.975 3.63937L16.7288 2.88562C17.0803 2.5349 17.0775 1.96509 16.7288 1.61381L16.3913 1.27856ZM9.495 7.17469L9.13219 8.91L10.8619 8.54719C11.0334 8.51062 11.1909 8.42625 11.3147 8.3025L15.3394 4.275L13.7391 2.67581L9.73688 6.7275C9.61594 6.85125 9.53156 7.00594 9.495 7.17469ZM8.55 3.6C8.7975 3.6 9 3.8025 9 4.05C9 4.2975 8.7975 4.5 8.55 4.5H1.8C1.30303 4.5 0.9 4.90218 0.9 5.4V12.6C0.9 13.0978 1.30303 13.5 1.8 13.5H14.4C14.8978 13.5 15.3 13.0978 15.3 12.6V7.65C15.3 7.4025 15.5025 7.2 15.75 7.2C15.9975 7.2 16.2 7.4025 16.2 7.65V12.6C16.2 13.5928 15.3928 14.4 14.4 14.4H1.8C0.805781 14.4 0 13.5928 0 12.6V5.4C0 4.40718 0.805781 3.6 1.8 3.6H8.55ZM2.925 9C2.925 8.62594 3.22594 8.325 3.6 8.325C3.97406 8.325 4.275 8.62594 4.275 9C4.275 9.37406 3.97406 9.675 3.6 9.675C3.22594 9.675 2.925 9.37406 2.925 9ZM6.975 9C6.975 9.37406 6.67406 9.675 6.3 9.675C5.92594 9.675 5.625 9.37406 5.625 9C5.625 8.62594 5.92594 8.325 6.3 8.325C6.67406 8.325 6.975 8.62594 6.975 9Z"
																fill="#7A69C3"
															/>
														</svg>
													</div>
												</td>
											)}
											{!isHistory && report.submitStatus === 'Updated' && (
												<td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
													<div
														onClick={(e) => {
															e.stopPropagation()
															e.preventDefault()
															// let obj: any = { ...report };
															// obj.CompletionDate = obj.CompletionDate
															//   ? new Date(obj.CompletionDate)
															//   : null;
															// obj.BILEndDate = obj.BILReturnDate
															//   ? new Date(obj.BILReturnDate)
															//   : null;
															// obj.EPAReadyDate = obj.EPAreadyDate
															//   ? new Date(obj.EPAreadyDate)
															//   : null;
															// obj.LeaverDate = obj.LeaverDate
															//   ? new Date(obj.LeaverDate)
															//   : null;
															// obj.BILStartDate = obj.BILStartDate
															//   ? new Date(obj.BILStartDate)
															//   : null;
															// obj.NextVisit = obj.NextDate
															//   ? isBefore(new Date(), obj.NextDate)
															//     ? new Date(obj.NextDate)
															//     : null
															// : null;
															getSelectedReport(report).then((obj: any) => {
																setSelectedReport(obj)
																toggleIndividualReport(true)
															})
															// getFiles(obj?.files);

															// setTimeout(() => {
															//   toggleIndividualReport(true);
															// }, 250);
														}}
														className="flex justify-end cursor-pointer"
													>
														Edit report
														<svg
															className="ml-2 w-4 h-5 text-lavender"
															viewBox="0 0 18 15"
															fill="currentColor"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M17.4024 2.07528C18.1723 2.84415 18.1723 4.08973 17.4024 4.85965L6.40899 15.8565C6.00821 16.2538 5.51602 16.5456 4.97462 16.7073L0.721412 17.9589C0.523833 18.0151 0.310329 17.9624 0.164783 17.8147C0.0192215 17.6706 -0.0352215 17.4561 0.0228778 17.2593L1.27372 13.0054C1.43297 12.4639 1.72583 11.9718 2.12485 11.571L13.1203 0.576571C13.8902 -0.19219 15.1348 -0.19219 15.9047 0.576571L17.4024 2.07528ZM11.9883 3.29872L14.6813 5.96004L16.6078 4.06512C16.9383 3.73465 16.9383 3.20028 16.6078 2.87087L15.1102 1.37216C14.7797 1.04274 14.2453 1.04274 13.9149 1.37216L11.9883 3.29872ZM11.1938 4.09325L2.92044 12.369C2.6543 12.6327 2.45919 12.9632 2.35301 13.3253L1.39325 16.5878L4.65469 15.628C5.0168 15.5225 5.34727 15.3257 5.61095 15.0585L13.8551 6.78621L11.1938 4.09325Z"
																fill=""
															/>
														</svg>
													</div>
												</td>
											)}
											{(isHistory || report.submitStatus === 'Submitted') && (
												<td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
													<div
														onClick={(e) => {
															if (isHistory) return
															e.stopPropagation()
															e.preventDefault()
															// let obj: any = { ...report };
															// obj.CompletionDate = obj.CompletionDate
															//   ? new Date(obj.CompletionDate)
															//   : null;
															// obj.BILEndDate = obj.BILReturnDate
															//   ? new Date(obj.BILReturnDate)
															//   : null;
															// obj.EPAReadyDate = obj.EPAreadyDate
															//   ? new Date(obj.EPAreadyDate)
															//   : null;
															// obj.LeaverDate = obj.LeaverDate
															//   ? new Date(obj.LeaverDate)
															//   : null;
															// obj.BILStartDate = obj.BILStartDate
															//   ? new Date(obj.BILStartDate)
															//   : null;
															// obj.NextVisit = obj.NextDate
															//   ? isBefore(new Date(), obj.NextDate)
															//     ? new Date(obj.NextDate)
															//     : null
															//   : null;
															getSelectedReport(report).then((obj: any) => {
																setSelectedReport(obj)
																toggleIndividualViewReport(true)
															})
															// setTimeout(() => {
															//   toggleIndividualViewReport(true);
															// }, 250);
														}}
														className="flex justify-end cursor-pointer"
													>
														View report
														<svg
															className="ml-2 w-4 h-5 text-lavender"
															width="16"
															height="15"
															viewBox="0 0 16 15"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<g id="Frame" clip-path="url(#clip0_5598_13034)">
																<path
																	id="Vector"
																	d="M11.5556 7.11114C11.5556 9.07503 9.96389 10.6667 8 10.6667C6.03611 10.6667 4.44445 9.07503 4.44445 7.11114C4.44445 5.14725 6.03611 3.55558 8 3.55558C9.96389 3.55558 11.5556 5.14725 11.5556 7.11114ZM8 4.44447C6.50278 4.44447 5.33334 5.61392 5.33334 7.11114C5.33334 8.58336 6.50278 9.7778 8 9.7778C9.47222 9.7778 10.6667 8.58336 10.6667 7.11114C10.6667 5.61392 9.47222 4.44447 8 4.44447ZM13.35 3.1278C14.65 4.33336 15.5194 5.7528 15.9306 6.76947C16.0222 6.98892 16.0222 7.23336 15.9306 7.4528C15.5194 8.44447 14.65 9.86392 13.35 11.0945C12.0417 12.3111 10.2444 13.3334 8 13.3334C5.75556 13.3334 3.95834 12.3111 2.65056 11.0945C1.35056 9.86392 0.48167 8.44447 0.0683646 7.4528C-0.0227882 7.23336 -0.0227882 6.98892 0.0683646 6.76947C0.48167 5.7528 1.35056 4.33336 2.65056 3.1278C3.95834 1.91225 5.75556 0.888916 8 0.888916C10.2444 0.888916 12.0417 1.91225 13.35 3.1278ZM0.888892 7.11114C1.25917 8.00003 2.05917 9.33336 3.25556 10.4445C4.45278 11.5556 6.04445 12.4445 8 12.4445C9.95556 12.4445 11.5472 11.5556 12.7444 10.4445C13.9417 9.33336 14.7417 8.00003 15.1111 7.11114C14.7417 6.19725 13.9417 4.86392 12.7444 3.7778C11.5472 2.66642 9.95556 1.7778 8 1.7778C6.04445 1.7778 4.45278 2.66642 3.25556 3.7778C2.05917 4.86392 1.25917 6.19725 0.888892 7.11114Z"
																	fill="#7A69C3"
																/>
															</g>
															<defs>
																<clipPath id="clip0_5598_13034">
																	<rect
																		width="16"
																		height="14.2222"
																		fill="white"
																	/>
																</clipPath>
															</defs>
														</svg>
													</div>
												</td>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						!loading && (
							<div className="h-54 my-2">
								<NoData />
							</div>
						)
					)}
					{totalCountOfReports > 10 && (
						<div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
							<div className="flex flex-1 justify-between sm:hidden ">
								<button
									onClick={(e) => changePage(e, currentPage - 1)}
									className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
								>
									Previous
								</button>
								<button
									onClick={(e) => changePage(e, currentPage + 1)}
									className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
								>
									Next
								</button>
							</div>

							<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
								<div>
									<p className="text-sm text-gray-700 font-inter-400">
										Showing
										<span className=" inline-flex pl-1 pr-1">
											{courseReports?.length ? 10 * currentPage - 9 : 0}
										</span>
										to
										<span className=" inline-flex pl-1 pr-1">
											{10 * currentPage > totalCountOfReports
												? totalCountOfReports
												: 10 * currentPage}
										</span>
										of
										<span className=" inline-flex pl-1 pr-1">
											{totalCountOfReports}
										</span>
										results
									</p>
								</div>

								<div>
									<nav
										className="isolate inline-flex -space-x-px rounded-md shadow-sm"
										aria-label="Pagination"
									>
										<a
											href="#"
											onClick={(e) => changePage(e, currentPage - 1)}
											className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
										>
											<span className="sr-only">Previous</span>
											{/* <!-- Heroicon name: mini/chevron-left --> */}
											<svg
												className="h-5 w-5"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												aria-hidden="true"
											>
												<path
													fill-rule="evenodd"
													d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
													clip-rule="evenodd"
												/>
											</svg>
										</a>
										{pages.map((page) => (
											<a
												key={page}
												href="#"
												onClick={(e) => changePage(e, page)}
												className={
													currentPage === page
														? 'relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20'
														: 'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20'
												}
											>
												{page}
											</a>
										))}
										<a
											href="#"
											onClick={(e) => changePage(e, currentPage + 1)}
											className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
										>
											<span className="sr-only">Next</span>
											<svg
												className="h-5 w-5"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												aria-hidden="true"
											>
												<path
													fill-rule="evenodd"
													d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
													clip-rule="evenodd"
												/>
											</svg>
										</a>
									</nav>
								</div>
							</div>
						</div>
					)}

					<div></div>
				</div>
			</div>
		</>
	)
}

export default IndividualCourse
