import React, { useEffect, useState } from "react";
import conveyaLogo from "../../../assets/images/conveya-logo.svg";
import viewProfile from "../../../assets/Icons/viewProfile.svg";
import userImage from "../../../assets/images/userImage.jpg";
import conveyaIcon from "../../../assets/images/conveya-c-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import apiUrl from "../../../utils/apiUrl";
import axios from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";

export default function WelcomeTrainer() {
  let navigate = useNavigate();
  const { state }: { state: any } = useLocation();
  const { login, user, setUser } = useAuth();
  const [loading, SetLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const emailRegisterd = state.email;
  const password = state.password;
  useEffect(() => {
    getProfileData();
  }, []);
  const getProfileData = async () => {
    SetLoading(true);
    let res = await axios.post("/api/profile/user/detail");
    if (res.status === 200) {
      setFirstName(res.data?.data?.firstName);
      setUser(res.data?.data);
      setTimeout(() => {
        SetLoading(false);
        setTimeout(() => {
          // login(emailRegisterd, password).then((res) => {
            navigate("/a/reports");
          // });
          // navigate("/a/reports")
        }, 3000);
      }, 3000);
    }
  };

  return (
    <>
      {/* Preparing Account start*/}
      {!loading && (
        <div className="flex min-h-full flex-col justify-center  bg-gray-50">
          <div className="px-4 sm:px-0 sm:w-full sm:max-w-lg mx-auto text-center pt-[20%] md:pt-[15%]">
            <img src={conveyaLogo} className="h-12 m-auto" alt="Conveya" />
            <h2 className="mt-6 text-3xl font-poppins-700 text-gray-700 mb-6">
              {/* Welcome aboard [First name]! */}
              Welcome aboard {firstName}!
            </h2>

            <p className="text-sm mt-2 text-gray-600">
              Your account is ready. Get started now.
            </p>

            <span className="mt-10 inline-block">
              <svg
                className="w-8"
                viewBox="0 0 19 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5602 0.193511C18.8133 0.442604 18.8133 0.852403 18.5602 1.1015L7.63221 12.0295C7.38311 12.2826 6.97331 12.2826 6.72422 12.0295L0.938266 6.24407C0.687245 5.99497 0.687245 5.58517 0.938266 5.33608C1.18913 5.08297 1.59611 5.08297 1.84722 5.33608L7.17821 10.6675L17.6522 0.193511C17.9013 -0.0579931 18.3111 -0.0579931 18.5602 0.193511Z"
                  fill="#7A69C3"
                />
              </svg>
            </span>
          </div>

          <footer className="mt-auto py-4">
            <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
              <p className="text-base font-poppins-500 text-gray-400 text-center">
                <img
                  src={conveyaIcon}
                  className="inline-block h-5 w-5 -mt-px"
                />{" "}
                Powered by <span className="text-lavender">Conveya</span> -
                Formally known as Scopesuite
              </p>
            </div>
          </footer>
        </div>
      )}

      {/* Preparing Account */}

      {/* Welcome Trainer start */}

      {loading && (
        <div className="flex min-h-full flex-col justify-center bg-gray-50">
          <div className="px-4 sm:px-0 sm:w-full sm:max-w-lg mx-auto text-center  pt-[20%] md:pt-[15%]">
            <img src={conveyaLogo} className="h-12 m-auto" alt="Conveya" />
            <h2 className="mt-6 text-3xl font-poppins-700 text-gray-700 mb-6">
              Preparing your account...
            </h2>

            <p className="text-sm mt-2 text-gray-600">
              Give us a moment while we are getting your account ready...
            </p>

            <div className="tosterRing_medium w-full flex justify-center mt-12">
              <div className="half-ring"></div>
            </div>
          </div>

          <footer className="mt-auto py-4">
            <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
              <p className="text-base font-poppins-500 text-gray-400 text-center">
                <img
                  src={conveyaIcon}
                  className="inline-block h-5 w-5 -mt-px"
                />{" "}
                Powered by <span className="text-lavender">Conveya</span> -
                Formally known as Scopesuite
              </p>
            </div>
          </footer>
        </div>
      )}
      {/* Welcome Trainer */}
    </>
  );
}
