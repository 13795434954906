import React from 'react';
import backarrow from './../../assets/Icons/backarrow.svg';
import sendIcon from './../../assets/Icons/send.svg';
import '../styles/Login.scss';
import { useLocation, useParams } from 'react-router-dom';
function ClaimAccount2() {
  const { state }: { state: any } = useLocation();
  const params = useParams();
    return (
        <>
            <div className="flex min-h-full flex-col  py-12 sm:px-6 lg:px-8 auth-pages">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="circledIcon bg-azure text-center mx-auto mt-auto display-flex p-2.5"><img
              className="mx-auto w-auto "
              src={sendIcon}
              alt="Send Email"
            /> </div>
        
            <h2 className="mt-6 text-center text-3xl font-inter-800 text-gray-900">Check your Email</h2>
            <p className="mt-2 text-center text-sm text-gray-600 font-inter-400">
            Please check your inbox and follow the instructions to {params.type==='reset' ? "reset your password. " : "complete your account registration. "}
            </p>
          </div>
  
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10 space-y-6">
            <div>
            <p className="mt-2 text-center text-sm text-gray-600 font-poppins-500">
            {params.type==='reset' ? "We sent the  password reset link to" : "We sent the link with instructions to"} 
            <br/>
            {state?.email}
            </p>
            </div>
              
                <div className="border-solid border-gray-300 border-b pt-1"></div>
                <div className="flex items-center justify-center pt-2">
                  <div className="text-sm flex items-center">
                    <a href="/login" className="font-poppins-500 text-lavender">
                    <img src={backarrow} alt="Back to log in"  className="inline-block mr-1 align-baseline"/>  Back to log in
                    </a>
                  </div>
                </div>
     
            </div>
          </div>
        <footer className="mt-auto">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md space-x-6">
            <p className="text-base font-inter-400 text-gray-400 text-center">Didn’t receive the email? <a href="mailto:info@conveya.co" className="text-lavender">Click to resend</a></p>
          </div>
          </footer>
        </div>
        </>
    );
}

export default ClaimAccount2;