import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Outlet,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";

import LogoIconBlack from "../../../assets/Icons/LogoIconBlack.svg";
import WarningIcon from "../../../assets/Icons/warning.svg";

import PairLearnersFilter from "../../../components/PairLearnersFilter";
import PairLearnersProfile from "../../../components/PairLearnersProfileSlide";

import SidebarSlide from "../../../components/SidebarSlide";
import FullScrollSlideOver from "../../../components/FullScrollSlideOver";
import axios from "../../../utils/axios";
import apiUrl from "../../../utils/apiUrl";
import ScreenLoader from "../../../utils/screenLoader";
import React from "react";
import NoData from "../../../utils/NoData";
import LearnersProfile from "../../../components/LearnersProfileSlide";
import { searchContext } from "../Layout";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function PairLearnersTrainer(props: any) {
  let firstTimeLoad = true;
  let layoutSearch = useContext(searchContext);
  const { trainerId, clientId } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [trainerSlideId, setTrainerSlideId] = useState<any>("")
  const [learnerId, setLearnerId] = useState<any>("")

  const [isLearnersProfile, setIsLearnersProfile] = useState(false);
  const [learnerSearch, setLearnerSearch] = useState<any>("");
  const [pages, setPages] = useState<number[]>([]);
  const [filterConfig, setFilterConfig] = useState<any>([]);
  const [totalCountOfReports, setTotalCountOfReports] = useState(100);
  const [learner, setLearner] = useState<any>([]);
  const [company, setCompany] = useState<any>([]);
  const [isPairLearnersFilter, setIsPairLearnersFilter] = useState(false);
  const [trainer, setTrainer] = useState<any>([]);
  const [trainerList, setTrainerList] = useState<any>([]);
  const [trainerLoading, setTrainerLoading] = useState(false);
  const [pairSlid, setPairSlid] = useState<any>([]);
  const [course, setCourse] = useState<any>([]);
  const [client, setClient] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalUnPaired, setTotalUnPaired] = useState<any>("");
  const [sortedColumns, setSortedColumns] = useState<any>([
    {
      key: "",
      type: "",
    },
  ]);
  const getTrainers = async () => {
    setTrainerLoading(true);
    let response = await axios.post(apiUrl.viewTrainerProfile, {
      trainer_id: trainerId,
    });
    setTrainerList(response.data.trainer_name);
    // setClientReports(response.data.client_reports)
    setTrainerLoading(false);
  };
  useEffect(() => {
    if (layoutSearch?.searchIn === "trainerPair") {
      setLearnerSearch(layoutSearch?.search)

    }
  }, [layoutSearch?.search])

  const sortCols = async (id: string) => {
    let type;

    if (!sortedColumns[0].key) {
      type = "ASC";
    } else {
      if (sortedColumns[0].key === id) {
        type = sortedColumns[0].type === "ASC" ? "DESC" : "ASC";
      } else {
        type = "ASC";
      }
    }

    setSortedColumns([
      {
        key: id,
        type: type,
      },
    ]);
  };

  const togglePairLearnersFilter = (v: boolean) => {
    if (v && isPairLearnersFilter) {
      setIsPairLearnersFilter(false);
      setTimeout(() => {
        setIsPairLearnersFilter(true);
      }, 100);
    } else {
      setIsPairLearnersFilter(v);
    }
  };

  const [isPairLearnersProfile, setIsPairLearnersProfile] = useState(false);
  const togglePairLearnersProfile = (v: boolean) => {
    if (!v) {
      getLearners();
    }
    if (v && isPairLearnersProfile) {
      setIsPairLearnersProfile(false);
      setTimeout(() => {
        setIsPairLearnersProfile(true);
      }, 100);
    } else {
      setIsPairLearnersProfile(v);
    }
  };
  // const createPaginationPages = () => {
  //   let pagesArray = [];
  //   if (totalCountOfReports === 50) {
  //     setPages([1, 2, 3, 4, 5]);
  //   } else {
  //     for (let i = 1; i <= Math.ceil(totalCountOfReports / 20); i++) {
  //       pagesArray.push(i);
  //     }
  //     setPages(pagesArray);
  //   }
  // };
  const createPaginationPages = () => {
    let pagesArray = [];
    if (totalCountOfReports > 5 * 10) {
      setPages([1, 2, 3, 4, 5]);
    } else {
      for (let i = 1; i <= Math.ceil(totalCountOfReports / 10); i++) {
        pagesArray.push(i);
      }
      setPages(pagesArray);
    }
  };

  useEffect(() => {
    // if (firstTimeLoad) {
    //   return;
    // }
    getLearners();
  }, [currentPage]);

  useEffect(() => {
    createPaginationPages();
  }, [totalCountOfReports]);
  const changePage = (e: any, page: number | string) => {
    e.preventDefault();
    if (typeof page === "number") {
      setCurrentPage(page);
    } else {
      if (page === "next") {
        if (currentPage === Math.ceil(totalCountOfReports / 10)) {
          return;
        }
        if (currentPage % 5 == 0) {
          let skip = currentPage / 5;
          let diff = totalCountOfReports - 5 * 10 * skip;
          let pagesArray = [];
          let updatedPages = [];
          if (diff > 50) {
            updatedPages = pages.map((page) => page + 5);
            setPages(updatedPages);
          } else {
            for (let i = 1; i <= Math.ceil(diff / 10); i++) {
              updatedPages.push(i + currentPage);
            }
            setPages(updatedPages);
          }
        }
        setCurrentPage(currentPage + 1);
      } else {
        if (currentPage === 1) {
          return;
        }
        if ((currentPage - 1) % 5 === 0) {
          let updatedPages = [];
          for (let i = currentPage - 5; i < currentPage; i++) {
            updatedPages.push(i);
          }
          setPages(updatedPages);
        }
        setCurrentPage(currentPage - 1);
      }
    }
  };
  // const [isShowReport, setIsShowReport] = useState(false);
  // const toggleTestReport = (v:boolean) => {
  //   if(v && isShowReport) {
  //     setIsShowReport(false);
  //     setTimeout(() => {
  //       setIsShowReport(true);
  //     }, 100);
  //   } else {
  //     setIsShowReport(v);
  //   }
  // };

  // const [isFullScrollSlideOver, setIsFullScrollSlideOver] = useState(false);
  // const toggleFullScrollSlideOver = (v:boolean) => {
  //   if(v && isFullScrollSlideOver) {
  //     setIsFullScrollSlideOver(false);
  //     setTimeout(() => {
  //       setIsFullScrollSlideOver(true);
  //     }, 100);
  //   } else {
  //     setIsFullScrollSlideOver(v);
  //   }
  // };
  const getLearners = async () => {
    setLoading(true);
    firstTimeLoad = false;

    const payload = {
      client_id: Number(clientId),
      course_ids: course,
      // trainer_ids: filterConfig?.trainer,
      trainer_ids: trainer,
      search_key: learnerSearch ? learnerSearch : "",
      sort_key: sortedColumns[0].key.length
        ? sortedColumns[0].key
        : "learner_name",
      sort_type: sortedColumns[0].type.length ? sortedColumns[0].type : "ASC",
    };
    const response = await axios.post(apiUrl.getClientLearners, payload,
      {
        params: {
          skip: learnerSearch
            ? currentPage > 1
              ? 0
              : currentPage - 1
            : currentPage
              ? currentPage - 1
              : 0,
          limit: 10,
        },
      });

    setTotalUnPaired(response.data.total_unpaired);
    setCurrentPage(response.data.page);
    setLearner(response.data.records);
    setCompany(response?.data);
    setTotalCountOfReports(response.data.total);
    setLoading(false);
  };

  useEffect(() => {
    getLearners();
    getTrainers();
  }, []);
  useEffect(() => {
    getLearners();
  }, [trainer, course]);
  useEffect(() => {
    setCurrentPage(1);
    if (learnerSearch?.trim().length > 0) {
      getLearners();
    } else {
      getLearners();
      // setSearchTotal(0);
    }
  }, [learnerSearch]);
  useEffect(() => {
    // if (firstTimeLoad) {
    //     return;
    // }

    if (currentPage === 1) {
      getLearners();
    } else {
      createPaginationPages();
      setCurrentPage(1);
    }
  }, [sortedColumns]);
  const toggleLearnersProfile = (v: boolean) => {
    if (!v) {
      // window.location.reload();
      // AllLearnerReport();
    }
    if (v && isLearnersProfile) {
      setIsLearnersProfile(false);
      setTimeout(() => {
        setIsLearnersProfile(true);
      }, 100);
    } else {
      setIsLearnersProfile(v);
    }
  };

  return (
    <>
      <PairLearnersFilter
        show={isPairLearnersFilter}
        toggle={togglePairLearnersFilter}
        setFilterConfig={setFilterConfig}
        trainer={trainer}
        course={course}
        setTrainer={setTrainer}
        setCourse={setCourse}
      />
      <LearnersProfile
        show={isLearnersProfile}
        toggle={toggleLearnersProfile}
        trainerId={trainerSlideId}
        learnerId={learnerId}
      />
      <PairLearnersProfile
        show={isPairLearnersProfile}
        toggle={togglePairLearnersProfile}
        pairSlid={pairSlid}
        company={company}
      />

      {/* <SidebarSlide show={isShowReport} toggle={toggleTestReport}  />          
    <FullScrollSlideOver show={isFullScrollSlideOver} toggle={toggleFullScrollSlideOver}  /> */}

      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex items-center">
          <Link to="/a/trainers" className="flex items-center">
            <ChevronLeftIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <span className="pl-3 text-gray-500 font-poppins-500">Trainer</span>
          </Link>
          <Link
            to={`/a/view-trainer/${trainerId}`}
            className="flex items-center"
          >
            <ChevronLeftIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <span className="pl-3 text-gray-500 font-poppins-500">
              {/* [full name] */}
              {trainerList}
            </span>
          </Link>
          <Link
            to={`/a/view-trainer/${trainerId}`}
            className="flex items-center"
          >
            <ChevronLeftIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <span className="pl-3 text-gray-500 font-poppins-500">Back</span>
          </Link>
        </div>

        <div className="mb-6">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="h-8 w-8" src={LogoIconBlack} alt="" />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-medium leading-6 text-gray-700">
                {/* [campany name] */}
                {company?.company_name}
              </h3>
            </div>
          </div>
        </div>

        <div className="mb-4 bg-white shadow-base rounded-lg">
          <div className="border-b  px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-col md:flex-row items-left md:items-center justify-between">
              <div className="ml-4 mt-4">
                <div className="flex items-center">
                  <div className="ml-0">
                    <h3 className="text-lg font-normal leading-6 text-gray-900">
                      Pair trainer with learner
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                      {totalCountOfReports} learners displayed ({totalUnPaired}{" "}
                      unpaired).
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4 flex flex-shrink-0">
                <div className="flex  items-center">
                  {/* <span onClick={() => toggleFullScrollSlideOver(true)}  className="text-gray-400 hover:text-gray-500 cursor-pointer flex items-center border-2 sm:border-0 sm:mt-0 px-3 py-1.5 rounded-md mt-2">
                  Full Scroll                   
                  </span> */}

                  <span
                    onClick={() => togglePairLearnersFilter(true)}
                    className="text-gray-400 hover:text-gray-500 cursor-pointer flex items-center border-2 sm:border-0 sm:mt-0 px-3 py-1.5 rounded-md mt-2"
                  >
                    <svg
                      width="18"
                      height="16"
                      className="h-5 w-5 flex-shrink-0"
                      viewBox="0 0 18 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 1.51473C0 0.747266 0.622266 0.125 1.38973 0.125H16.6113C17.3777 0.125 18 0.747266 18 1.51473C18 1.83816 17.8875 2.15141 17.6801 2.40066L11.25 10.1727V14.6832C11.25 15.3406 10.7156 15.875 10.0582 15.875C9.79453 15.875 9.53789 15.7871 9.32695 15.6254L7.18594 13.9555C6.91172 13.7445 6.75 13.4176 6.75 13.0695V10.1727L0.319078 2.40066C0.112852 2.15141 0 1.83816 0 1.51473ZM1.38973 1.25C1.24348 1.25 1.125 1.36848 1.125 1.51473C1.125 1.57625 1.14645 1.63602 1.18582 1.68348L7.74492 9.61016C7.8293 9.71211 7.875 9.83867 7.875 9.96875V13.0695L9.98789 14.7359C10.0301 14.7465 10.0441 14.75 10.0582 14.75C10.0969 14.75 10.125 14.7219 10.125 14.6832V9.96875C10.125 9.83867 10.1707 9.71211 10.2551 9.61016L16.8152 1.68348C16.8539 1.63602 16.875 1.57625 16.875 1.51473C16.875 1.36848 16.7555 1.25 16.6113 1.25H1.38973Z" />
                    </svg>
                    <button
                      type="button"
                      className="sm:hidden  text-sm focus:outline-none font-poppins-500 text-gray-700 ml-2"
                    >
                      Filter
                    </button>
                  </span>

                  <div className="hidden  md:flex sm:mt-0 sm:ml-2">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative  rounded-md shadow-sm layout-search">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        autoComplete="off"
                        value={learnerSearch}
                        onChange={(e) => setLearnerSearch(e.target.value)}
                        className="block w-72 sm:w-80 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search"
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <div className="ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6"
                    >
                      <div className="flex">
                        Name
                        <span
                          className="bg-gray-300 w-4 h-4 ml-2 text-center items-center justify-center flex rounded-sm"
                          onClick={(e) => {
                            e.preventDefault();
                            sortCols("learner_name");
                          }}
                        >
                          <svg
                            className="text-gray-700"
                            width="8"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            transform={
                              sortedColumns[0]?.key === "learner_name"
                                ? sortedColumns[0].type === "ASC"
                                  ? "rotate(180 0 0)"
                                  : "rotate(0 0 0)"
                                : "rotate(0 0 0)"
                            }
                          >
                            <path d="M9.84909 1.57032L5.32936 5.66109C5.15536 5.81646 4.89443 5.81646 4.72029 5.66109L0.150272 1.57032C-0.0360856 1.37715 -0.0511419 1.11579 0.116466 0.928298C0.294869 0.732282 0.582359 0.735123 0.759342 0.894209L4.99982 4.71226L9.24116 0.894209C9.42848 0.727254 9.71529 0.741912 9.88375 0.927957C10.0508 1.11579 10.0366 1.37715 9.84909 1.57032Z" />
                          </svg>
                        </span>
                      </div>
                    </th>

                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-medium text-gray-500"
                    >
                      <div className="flex">
                        Trainer
                        <span
                          className="bg-gray-300 w-4 h-4 ml-2 text-center items-center justify-center flex rounded-sm"
                          onClick={(e) => {
                            e.preventDefault();
                            sortCols("company_name");
                          }}
                        >
                          <svg
                            className="text-gray-700"
                            width="8"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            transform={
                              sortedColumns[0]?.key === "company_name"
                                ? sortedColumns[0].type === "ASC"
                                  ? "rotate(180 0 0)"
                                  : "rotate(0 0 0)"
                                : "rotate(0 0 0)"
                            }
                          >
                            <path d="M9.84909 1.57032L5.32936 5.66109C5.15536 5.81646 4.89443 5.81646 4.72029 5.66109L0.150272 1.57032C-0.0360856 1.37715 -0.0511419 1.11579 0.116466 0.928298C0.294869 0.732282 0.582359 0.735123 0.759342 0.894209L4.99982 4.71226L9.24116 0.894209C9.42848 0.727254 9.71529 0.741912 9.88375 0.927957C10.0508 1.11579 10.0366 1.37715 9.84909 1.57032Z" />
                          </svg>
                        </span>
                      </div>
                    </th> */}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-medium text-gray-500"
                    >
                      <span className="sr-only">Warning</span>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs font-medium text-gray-500"
                    >
                      <div className="flex">
                        Course
                        <span
                          className="bg-gray-300 w-4 h-4 ml-2 text-center items-center justify-center flex rounded-sm"
                          onClick={(e) => {
                            e.preventDefault();
                            sortCols("coursename");
                          }}
                        >
                          <svg
                            className="text-gray-700"
                            width="8"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            transform={
                              sortedColumns[0]?.key === "coursename"
                                ? sortedColumns[0].type === "ASC"
                                  ? "rotate(180 0 0)"
                                  : "rotate(0 0 0)"
                                : "rotate(0 0 0)"
                            }
                          >
                            <path d="M9.84909 1.57032L5.32936 5.66109C5.15536 5.81646 4.89443 5.81646 4.72029 5.66109L0.150272 1.57032C-0.0360856 1.37715 -0.0511419 1.11579 0.116466 0.928298C0.294869 0.732282 0.582359 0.735123 0.759342 0.894209L4.99982 4.71226L9.24116 0.894209C9.42848 0.727254 9.71529 0.741912 9.88375 0.927957C10.0508 1.11579 10.0366 1.37715 9.84909 1.57032Z" />
                          </svg>
                        </span>
                      </div>
                    </th>
                    {/* <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 "
                    >
                      <span className="sr-only">Pair</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {loading && <ScreenLoader />}
                  {!loading && learner.length === 0 && <NoData />}
                  {!loading &&
                    learner?.map((item: any, index: any) => {
                      return (
                        <tr key={index} onClick={() => {


                          setLearnerId(item?.learner_id);
                          setTrainerSlideId(item?.trainer_id);
                          // toggleLearnersProfile(true);
                          togglePairLearnersProfile(true);
                          setPairSlid(item);
                        }}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6"

                          >
                            {/* [Full name] */}
                            {item.learner_name}
                            <span className="ml-2">{item.uln}</span>

                          </td>
                          {/* <td className="whitespace-nowrap flex items-center px-3 py-4 text-sm text-gray-900">
                           
                            {item.trainer_name}
                            
                          </td> */}
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 text-right">

                            {""}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {/* <span className="text-gray-500"> */}

                            {/* [Course name]</span> ,
                      [Course name]{" "} */}
                            {item?.courses?.map((each: any, ind: any) => {
                              return (
                                <div className={ind === 0 ? "inline-block" : "ml-2 inline-block"}>
                                  <>
                                    {!item?.whether_paired ?
                                      <svg
                                        className="mr-2 inline-block"
                                        width="13" height="11" viewBox="0 0 16 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.33387 9.82394C6.96126 9.82394 6.68426 10.126 6.68426 10.4989C6.68426 10.8715 6.98657 11.1738 7.33387 11.1738C7.70648 11.1738 7.98348 10.8715 7.98348 10.4989C8.00879 10.1248 7.70789 9.82394 7.33387 9.82394ZM7.33387 8.69908C7.58255 8.69908 7.75851 8.49785 7.75851 8.24914V4.19965C7.75851 3.95094 7.5573 3.7497 7.33387 3.7497C7.11045 3.7497 6.88393 3.95218 6.88393 4.19965V8.24914C6.88393 8.49661 7.0864 8.69908 7.33387 8.69908ZM14.3164 10.8729L8.69776 1.37006C8.41374 0.888905 7.90193 0.600941 7.33387 0.600098C6.76582 0.600098 6.25682 0.887499 5.96998 1.3695L0.348496 10.8746C0.0658757 11.3518 0.0616574 11.9246 0.336635 12.4072C0.620994 12.9032 1.13449 13.1985 1.71239 13.1985H12.9582C13.5349 13.1985 14.0479 12.9024 14.3308 12.4063C14.6061 11.9246 14.6005 11.3509 14.3164 10.8729ZM13.5234 11.9359C13.4278 12.1721 13.2056 12.2986 12.9329 12.2986H1.71239C1.46194 12.2986 1.24051 12.1725 1.11959 11.9612C1.00491 11.7599 1.00668 11.5309 1.12398 11.3324L6.74613 1.82788C6.86705 1.62259 7.0864 1.49998 7.33387 1.49998C7.33387 1.49998 7.33343 1.49998 7.33387 1.49998C7.58036 1.50042 7.79956 1.62257 7.92049 1.82732L13.542 11.3324C13.6359 11.5309 13.664 11.7587 13.5234 11.9359Z"
                                          fill="#9CA3AF"
                                        />
                                      </svg>
                                      : null}
                                    {each?.coursename}
                                    {
                                      item?.courses.length > 1 && ind < item?.courses.length - 1 ? " ," : ""
                                    }
                                  </>
                                </div>
                              );
                            })}
                            {/* </span> */}
                          </td>

                          {/* <td className="flex whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-normal sm:pr-6 justify-end">
                            <span
                              className="flex cursor-pointer"
                              onClick={() => {
                                togglePairLearnersProfile(true);
                                setPairSlid(item);
                              }}
                            >
                              {!item.whether_paired && (
                                <svg
                                  className="mr-2"
                                  width="15"
                                  height="14"
                                  viewBox="0 0 15 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.33387 9.82394C6.96126 9.82394 6.68426 10.126 6.68426 10.4989C6.68426 10.8715 6.98657 11.1738 7.33387 11.1738C7.70648 11.1738 7.98348 10.8715 7.98348 10.4989C8.00879 10.1248 7.70789 9.82394 7.33387 9.82394ZM7.33387 8.69908C7.58255 8.69908 7.75851 8.49785 7.75851 8.24914V4.19965C7.75851 3.95094 7.5573 3.7497 7.33387 3.7497C7.11045 3.7497 6.88393 3.95218 6.88393 4.19965V8.24914C6.88393 8.49661 7.0864 8.69908 7.33387 8.69908ZM14.3164 10.8729L8.69776 1.37006C8.41374 0.888905 7.90193 0.600941 7.33387 0.600098C6.76582 0.600098 6.25682 0.887499 5.96998 1.3695L0.348496 10.8746C0.0658757 11.3518 0.0616574 11.9246 0.336635 12.4072C0.620994 12.9032 1.13449 13.1985 1.71239 13.1985H12.9582C13.5349 13.1985 14.0479 12.9024 14.3308 12.4063C14.6061 11.9246 14.6005 11.3509 14.3164 10.8729ZM13.5234 11.9359C13.4278 12.1721 13.2056 12.2986 12.9329 12.2986H1.71239C1.46194 12.2986 1.24051 12.1725 1.11959 11.9612C1.00491 11.7599 1.00668 11.5309 1.12398 11.3324L6.74613 1.82788C6.86705 1.62259 7.0864 1.49998 7.33387 1.49998C7.33387 1.49998 7.33343 1.49998 7.33387 1.49998C7.58036 1.50042 7.79956 1.62257 7.92049 1.82732L13.542 11.3324C13.6359 11.5309 13.664 11.7587 13.5234 11.9359Z"
                                    fill="#F87171"
                                  />
                                </svg>
                              )}
                              {item.whether_paired ? "Pair" : "Unpair"}
                              {item.whether_paired && (
                                <svg
                                  className="ml-2"
                                  width="18"
                                  height="15"
                                  viewBox="0 0 18 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.72594 8.55H4.87406C2.18278 8.55 0 10.7325 0 13.4241C0 13.9641 0.4365 14.4 0.974812 14.4H11.6257C12.1641 14.4 12.6 13.9641 12.6 13.4241C12.6 10.7325 10.4175 8.55 7.72594 8.55ZM11.6241 13.5H0.974812C0.93375 13.5 0.9 13.4662 0.9 13.4241C0.9 11.2331 2.68313 9.45 4.87406 9.45H7.72594C9.91687 9.45 11.7 11.2331 11.7 13.4241C11.7 13.4662 11.6662 13.5 11.6241 13.5ZM6.3 7.2C8.28844 7.2 9.9 5.58816 9.9 3.6C9.9 1.61184 8.28844 0 6.3 0C4.31156 0 2.7 1.61184 2.7 3.6C2.7 5.58816 4.31156 7.2 6.3 7.2ZM6.3 0.9C7.78894 0.9 9 2.11106 9 3.6C9 5.08866 7.78894 6.3 6.3 6.3C4.81106 6.3 3.6 5.08781 3.6 3.6C3.6 2.11106 4.81219 0.9 6.3 0.9ZM17.55 5.85H15.75V4.05C15.75 3.8025 15.5475 3.6 15.3 3.6C15.0525 3.6 14.85 3.80126 14.85 4.05V5.85H13.05C12.8025 5.85 12.6 6.0525 12.6 6.3C12.6 6.5475 12.8013 6.75 13.05 6.75H14.85V8.55C14.85 8.79874 15.0513 9 15.3 9C15.5487 9 15.75 8.79874 15.75 8.55V6.75H17.55C17.7975 6.75 18 6.5475 18 6.3C18 6.0525 17.7975 5.85 17.55 5.85Z"
                                    fill="#7A69C3"
                                  />
                                </svg>
                              )}
                            </span>
                          </td> */}
                        </tr>
                      );
                    })}

                  {/* <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                      [Full name]
                    </td>
                    <td className="whitespace-nowrap flex items-center px-3 py-4 text-sm text-gray-900">
                      <svg
                        className="mr-2"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.33387 9.82394C6.96126 9.82394 6.68426 10.126 6.68426 10.4989C6.68426 10.8715 6.98657 11.1738 7.33387 11.1738C7.70648 11.1738 7.98348 10.8715 7.98348 10.4989C8.00879 10.1248 7.70789 9.82394 7.33387 9.82394ZM7.33387 8.69908C7.58255 8.69908 7.75851 8.49785 7.75851 8.24914V4.19965C7.75851 3.95094 7.5573 3.7497 7.33387 3.7497C7.11045 3.7497 6.88393 3.95218 6.88393 4.19965V8.24914C6.88393 8.49661 7.0864 8.69908 7.33387 8.69908ZM14.3164 10.8729L8.69776 1.37006C8.41374 0.888905 7.90193 0.600941 7.33387 0.600098C6.76582 0.600098 6.25682 0.887499 5.96998 1.3695L0.348496 10.8746C0.0658757 11.3518 0.0616574 11.9246 0.336635 12.4072C0.620994 12.9032 1.13449 13.1985 1.71239 13.1985H12.9582C13.5349 13.1985 14.0479 12.9024 14.3308 12.4063C14.6061 11.9246 14.6005 11.3509 14.3164 10.8729ZM13.5234 11.9359C13.4278 12.1721 13.2056 12.2986 12.9329 12.2986H1.71239C1.46194 12.2986 1.24051 12.1725 1.11959 11.9612C1.00491 11.7599 1.00668 11.5309 1.12398 11.3324L6.74613 1.82788C6.86705 1.62259 7.0864 1.49998 7.33387 1.49998C7.33387 1.49998 7.33343 1.49998 7.33387 1.49998C7.58036 1.50042 7.79956 1.62257 7.92049 1.82732L13.542 11.3324C13.6359 11.5309 13.664 11.7587 13.5234 11.9359Z"
                          fill="#F87171"
                        />
                      </svg>
                      , [Full name]
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 text-right">
                      <svg
                        className="inline"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.33387 9.82394C6.96126 9.82394 6.68426 10.126 6.68426 10.4989C6.68426 10.8715 6.98657 11.1738 7.33387 11.1738C7.70648 11.1738 7.98348 10.8715 7.98348 10.4989C8.00879 10.1248 7.70789 9.82394 7.33387 9.82394ZM7.33387 8.69908C7.58255 8.69908 7.75851 8.49785 7.75851 8.24914V4.19965C7.75851 3.95094 7.5573 3.7497 7.33387 3.7497C7.11045 3.7497 6.88393 3.95218 6.88393 4.19965V8.24914C6.88393 8.49661 7.0864 8.69908 7.33387 8.69908ZM14.3164 10.8729L8.69776 1.37006C8.41374 0.888905 7.90193 0.600941 7.33387 0.600098C6.76582 0.600098 6.25682 0.887499 5.96998 1.3695L0.348496 10.8746C0.0658757 11.3518 0.0616574 11.9246 0.336635 12.4072C0.620994 12.9032 1.13449 13.1985 1.71239 13.1985H12.9582C13.5349 13.1985 14.0479 12.9024 14.3308 12.4063C14.6061 11.9246 14.6005 11.3509 14.3164 10.8729ZM13.5234 11.9359C13.4278 12.1721 13.2056 12.2986 12.9329 12.2986H1.71239C1.46194 12.2986 1.24051 12.1725 1.11959 11.9612C1.00491 11.7599 1.00668 11.5309 1.12398 11.3324L6.74613 1.82788C6.86705 1.62259 7.0864 1.49998 7.33387 1.49998C7.33387 1.49998 7.33343 1.49998 7.33387 1.49998C7.58036 1.50042 7.79956 1.62257 7.92049 1.82732L13.542 11.3324C13.6359 11.5309 13.664 11.7587 13.5234 11.9359Z"
                          fill="#F87171"
                        />
                      </svg>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span className="text-red-800">[Course name]</span> ,
                      [Course name]{" "}
                    </td>

                    <td className="flex whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-normal sm:pr-6 justify-end">
                      <span
                        className="flex cursor-pointer"
                        onClick={() => togglePairLearnersProfile(true)}
                      >
                        Pair
                        <svg
                          className="ml-2"
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.72594 8.55H4.87406C2.18278 8.55 0 10.7325 0 13.4241C0 13.9641 0.4365 14.4 0.974812 14.4H11.6257C12.1641 14.4 12.6 13.9641 12.6 13.4241C12.6 10.7325 10.4175 8.55 7.72594 8.55ZM11.6241 13.5H0.974812C0.93375 13.5 0.9 13.4662 0.9 13.4241C0.9 11.2331 2.68313 9.45 4.87406 9.45H7.72594C9.91687 9.45 11.7 11.2331 11.7 13.4241C11.7 13.4662 11.6662 13.5 11.6241 13.5ZM6.3 7.2C8.28844 7.2 9.9 5.58816 9.9 3.6C9.9 1.61184 8.28844 0 6.3 0C4.31156 0 2.7 1.61184 2.7 3.6C2.7 5.58816 4.31156 7.2 6.3 7.2ZM6.3 0.9C7.78894 0.9 9 2.11106 9 3.6C9 5.08866 7.78894 6.3 6.3 6.3C4.81106 6.3 3.6 5.08781 3.6 3.6C3.6 2.11106 4.81219 0.9 6.3 0.9ZM17.55 5.85H15.75V4.05C15.75 3.8025 15.5475 3.6 15.3 3.6C15.0525 3.6 14.85 3.80126 14.85 4.05V5.85H13.05C12.8025 5.85 12.6 6.0525 12.6 6.3C12.6 6.5475 12.8013 6.75 13.05 6.75H14.85V8.55C14.85 8.79874 15.0513 9 15.3 9C15.5487 9 15.75 8.79874 15.75 8.55V6.75H17.55C17.7975 6.75 18 6.5475 18 6.3C18 6.0525 17.7975 5.85 17.55 5.85Z"
                            fill="#7A69C3"
                          />
                        </svg>
                      </span>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <a
                href="#"
                onClick={(e) => changePage(e, "previous")}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </a>
              <a
                href="#"
                onClick={(e) => changePage(e, "next")}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </a>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700 font-inter-400">
                  Showing
                  <span className=" inline-flex pl-1 pr-1">
                    {10 * currentPage - 9}
                  </span>
                  to
                  <span className=" inline-flex pl-1 pr-1">
                    {10 * currentPage > totalCountOfReports
                      ? totalCountOfReports
                      : 10 * currentPage}
                  </span>
                  of
                  <span className=" inline-flex pl-1 pr-1">
                    {totalCountOfReports}
                  </span>
                  results
                </p>
              </div>
              {totalCountOfReports > 10 && (
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <a
                      href="#"
                      onClick={(e) => changePage(e, "previous")}
                      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Previous</span>
                      {/* <!-- Heroicon name: mini/chevron-left --> */}
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                    {pages.map((page) => (
                      <a
                        key={page}
                        href="#"
                        onClick={(e) => changePage(e, page)}
                        className={
                          currentPage === page
                            ? "relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20"
                            : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                        }
                      >
                        {page}
                      </a>
                    ))}
                    <a
                      href="#"
                      onClick={(e) => changePage(e, "next")}
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Next</span>
                      {/* <!-- Heroicon name: mini/chevron-right --> */}
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </nav>
                </div>
              )}
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </>
  );
}
