import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

import IndividualReport from "../../../components/IndividualReportSlide";
import IndividualViewReport from "../../../components/IndividualViewReportSlide";

import SubmitReportModel from "../../../components/SubmitReportModel";
import FileUploadModel from "../../../components/FileUploadModel";
import axios from "../../../utils/axios";
import apiUrl from "../../../utils/apiUrl";
import ScreenLoader from "../../../utils/screenLoader";
import NoData from "../../../utils/NoData";
import SuccsessToaster from "../../../components/tosters/SuccsessToaster";
import ErrorToaster from "../../../../src/components/tosters/ErrorToasters";
import { FileObj } from "../../../utils/common/FileDrop";
import LearnersProfile from "../../../components/LearnersProfileSlide";
import ReportTemplate from "../../../../src/assets/FileDrop.xlsx";
import { Menu, Transition } from "@headlessui/react";
import AssignTrainerSlide from "../../../components/AssignTrainerSlide";
import AuthorImg from "../../../components/AuthorImg";

export interface IReport {
  trainer_info: any;
  LearnerName: string;
  CourseProgress: string;
  Rag: string;
  Percentage: string;
  accountSfid: string;
  MathFS: string;
  EnglishFS: string;
  courseSfid: string;
  learnerSfid: string;
  trainingProviderSfid: string;
  trainingproviderid: string;
  LastModifiedDate: Date;
  TrainingProviderName: string;
  NextVisit: any;
  Comment: string;
  FunctionalSkills: string;
  Flag: string;
  CompanyName: string;
  CourseName: string;
  ReportDate: string;
  CompanyLogo: string;
  clientid: string;
  courseid: string;
  learnerid: string;
  CompletionDate: Date;
  CompletionStatus: string;
  BILStartDate: Date;
  BILEndDate: Date;
  EPAreadyDate: Date;
  LeaverDate: Date;
  AdditionalTag: string;
  isupdate: boolean;
  BILReturnDate: Date;
  EPAReadyDate: Date;
  files?: any[];
  NextDate: Date;
  additional: any;
  uln: Number;
}

function SubmittedIndividualCourse() {
  let { accountId, courseId } = useParams();
  const navigate = useNavigate();
  const [isIndividualReport, setIsIndividualReport] = useState(false);
  const [isLearnersProfile, setIsLearnersProfile] = useState(false);

  const [learnerId, setLearnerId] = useState<any>();
  const [trainerId, settrainerId] = useState<any>();
  const [message, setMessage] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [fileArray, setFileArray] = useState<FileObj[]>([]);
  const [toastMessage, setToastMessage] = useState("");
  const [sortType, setSortType] = useState<any>("createdate desc");
  const toggleIndividualReport = (v: boolean, isToaster?: boolean) => {
    if (v && isIndividualReport) {
      setIsIndividualReport(false);
      setTimeout(() => {
        setIsIndividualReport(true);
      }, 100);
    } else {
      setIsIndividualReport(v);
      if (!v) {
        getAllReports();
        setFileArray([]);
      }
    }
    if (isToaster) {
      setShow(true);
      setToastMessage("Data updated successfully");
      setTimeout(() => {
        setShow(false);
        setToastMessage("");
      }, 2000);
    }
  };

  const ragRatingRender = (rag: any) => {
    if (rag === "Green") {
      return (
        <span className="inline-flex rounded-full bg-green-100 py-1 px-2 text-xs font-poppins-400  text-green-800">
          Green
        </span>
      );
    } else if (rag === "Red") {
      return (
        <span className="inline-flex rounded-full bg-red-100 py-1 px-2 text-xs font-poppins-400  text-red-800">
          Red
        </span>
      );
    } else {
      return (
        <span className="inline-flex rounded-full bg-yellow-100 py-1 px-2 text-xs font-poppins-400  text-yellow-800">
          {rag}
        </span>
      );
    }
  };

  const getFiles = (files?: FileObj[]) => {
    files?.forEach((file: FileObj) => {
      file.size = file.FileSize ?? file.size;
      var request = new XMLHttpRequest();
      request.open("GET", file.FileUrl, true);
      request.responseType = "blob";
      request.onload = function () {
        var newFile = new File(
          [request.response],
          file.FileTitle ?? file.name,
          { lastModified: file.lastModified, type: file.FileType ?? file.type }
        ) as any;
        newFile.FileSize = file.FileSize ?? file.size;
        newFile.FileTitle = newFile.name;
        newFile.FileType = file.FileType ?? file.type;
        Object.defineProperty(newFile, "size", { value: newFile.FileSize });
        fileArray.push(file.FileUrl ? newFile : file);
        setFileArray([...fileArray]);
      };
      request.send();
    });
    return files;
  };

  const isBefore = (date1: Date, date2: Date) => {
    return date1 && date2
      ? moment(date1).isBefore(
          moment(new Date(date2).setDate(new Date(date2).getDate() + 1))
        )
      : true;
  };
  const [isIndividualViewReport, setIsIndividualViewReport] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [showToaster, setShowToaster] = useState<boolean>(false);
  const [showErrorToaster, setShowErrorToaster] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [courseReports, setCourseReports] = useState<any[]>([]);

  const [isAssignTrainerSlide, setIsAssignTrainerSlide] = useState(false);
  const [assignedTrainers, setAssignedTrainers] = useState<any>([]);
  const [company, setCompany] = useState<any>({});
  const [clientId, setClientId] = useState<any>();

  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const searchOptions = (val: string) => {
    setCurrentPage(1);
    setSearchText(val ?? "");
    if (!val) {
      getAllReports();
    } else {
      getAllReports(val);
    }
  };
  const toggleIndividualViewReport = (v: boolean) => {
    if (v && isIndividualViewReport) {
      setIsIndividualViewReport(false);
      setTimeout(() => {
        setIsIndividualViewReport(true);
      }, 100);
    } else {
      setIsIndividualViewReport(v);
    }
  };

  const [showSubmitReportModel, setSubmitReportModel] = useState(false);
  const toggleSubmitReportModel = (value: boolean) => {
    setSubmitReportModel(value);
  };

  const [showFileUploadModel, setFileUploadModel] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number[]>([]);
  const [completedReports, setCompletedReports] = useState<number>(0);
  const [totalCountOfReports, setTotalCountOfReports] = useState(0);
  const [isNotValidSrc, setIsNotValidSrc] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<IReport>();
  const [clientInfo, setClientInfo] = useState<any>();
  const [modifiedDate, setModifiedDate] = useState<any>(null);
  const toggleFileUploadModel = (value: boolean) => {
    setFileUploadModel(value);
  };

  // const toggleFileUploadToaster = (value: boolean) => {
  //   setFileUploadModel(value);
  // };

  const createPaginationPages = () => {
    let pagesArray = [];
    if (totalCountOfReports > 50) {
      setPages([1, 2, 3, 4, 5]);
    } else {
      for (let i = 1; i <= Math.ceil(totalCountOfReports / 10); i++) {
        pagesArray.push(i);
      }
      setPages(pagesArray);
    }
  };

  useEffect(() => {
    getAllReports();
    getClientInfo();
  }, []);

  useEffect(() => {
    createPaginationPages();
  }, [totalCountOfReports]);

  useEffect(() => {
    getAllReports(searchText);
  }, [currentPage]);

  useEffect(() => {
    getAllReports();
  }, [sortType]);

  const getClientInfo = () => {
    axios
      .post("/api/report/clientInfo", {
        clientId: accountId,
      })
      .then(({ data }) => {
        console.log(data);
        setClientInfo(data.data);
      });
  };

  const changePage = (e: any, page: number | string) => {
    e.preventDefault();
    if (typeof page === "number") {
      setCurrentPage(page);
    } else {
      if (page === "next") {
        if (currentPage === Math.ceil(totalCountOfReports / 10)) {
          return;
        }
        if (currentPage % 10 === 0) {
          let skip = currentPage / 5;
          let diff = totalCountOfReports - 10 * 10 * skip;
          let updatedPages = [];
          if (diff > 50) {
            updatedPages = pages.map((page) => page + 5);
            setPages(updatedPages);
          } else {
            for (let i = 1; i <= Math.ceil(diff / 10); i++) {
              updatedPages.push(i + currentPage);
            }
            setPages(updatedPages);
          }
        }
        setCurrentPage(currentPage + 1);
      } else {
        if (currentPage === 1) {
          return;
        }
        if ((currentPage - 1) % 10 === 0) {
          let updatedPages = [];
          for (let i = currentPage - 10; i < currentPage; i++) {
            updatedPages.push(i);
          }
          setPages(updatedPages);
        }
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const getAllReports = async (search?: string) => {
    setLoading(true);
    axios
      .post(
        `/api/report/courselearners?pageNo=${currentPage}&pageSize=10&sort=${sortType}`,
        {
          keyword: search,
          course: courseId,
        }
      )
      .then(({ data }) => {
        const result = data.data;
        setTotalCountOfReports(result.total);
        setCourseReports(result.list);
      })
      .finally(() => setLoading(false));
  };

  const submitReports = async () => {
    // await axios
    //   .post(
    //     apiUrl.getIndividualReports,
    //     {
    //       clientid: Number(accountId) ?? "",
    //       courseid: courseId,
    //       search_learner: "",
    //       submit_reports: "True",
    //       sort_type: "is_update",
    //     },
    //     {
    //       params: { skip: 0, limit: 5 },
    //     }
    //   )
    //   .then((e) => {
    //     if (e.status === 200) {
    //       if (courseReports.length > 0) {
    //         const editedCourseList: any = [];
    //         courseReports.forEach((coureseList: any) => {
    //           if (coureseList.isupdate === false) {
    //             editedCourseList.push(coureseList);
    //           }
    //         });
    //         if (editedCourseList.length > 1) {
    //           setName("");
    //         }
    //       }
    //       setSubmitReportModel(false);
    //       setShowToaster(true);
    //       setMessage("Report submitted successfully");
    //       setTimeout(() => {
    //         navigate(`/a/submitted-individual-course/${accountId}/${courseId}`);
    //       }, 2000);
    //     }
    //   })
    //   .catch((err) => {
    //     setSubmitReportModel(false);
    //     setShowError(true);
    //     setShow(false);
    //     setLoading(false);
    //     setErrorMsg(err.response.data.detail);
    //     setTimeout(() => {
    //       setShowError(false);
    //     }, 3000);
    //   });
    axios
      .post("/api/report/submit", {
        clientId,
        course: courseId,
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          setSubmitReportModel(false);
          setShowToaster(true);
          setMessage("Report submitted successfully");
          setTimeout(() => {
            navigate(`/a/submitted-individual-course/${accountId}/${courseId}`);
          }, 2000);
        } else {
          setSubmitReportModel(false);
          setShowError(true);
          setShow(false);
          setLoading(false);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitReportModel(false);
        setShowError(true);
        setShow(false);
        setLoading(false);
        setErrorMsg(err.response.data.message);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
      });
  };

  const getSelectedReport = (report: any) => {
    return axios
      .post(
        `/api/report/detail?enrollmentId=${report.enrollmentId}&refId=${report.refId}`
      )
      .then(({ data }) => {
        return data.data;
      });
  };

  const toggleFileToaster = (v: any) => {
    setShowToaster(true);
    setTimeout(() => {
      setShowToaster(false);
    }, 2000);
  };

  const toggleFileErrorToaster = (v: any) => {
    setShowErrorToaster(true);
    setTimeout(() => {
      setShowErrorToaster(false);
    }, 3000);
  };

  const toggleLearnersProfile = (v: boolean, item: any) => {
    if (!v) {
      // window.location.reload();
      // AllLearnerReport();
    }
    if (v && isLearnersProfile) {
      setIsLearnersProfile(false);
      setTimeout(() => {
        setIsLearnersProfile(true);
      }, 100);
    } else {
      setIsLearnersProfile(v);
    }
  };

  const toggleAssignTrainerSlide = (v: boolean) => {
    if (v && isAssignTrainerSlide) {
      setIsAssignTrainerSlide(false);
      setTimeout(() => {
        setIsAssignTrainerSlide(true);
      }, 100);
    } else {
      setIsAssignTrainerSlide(v);
    }
  };

  return (
    <>
      {showError && (
        <ErrorToaster
          show={showError}
          msg={errorMsg}
          toggle={(val: boolean) => {
            setShowError(val);
          }}
        />
      )}
      <LearnersProfile
        show={isLearnersProfile}
        toggle={toggleLearnersProfile}
        trainerId={trainerId}
        learnerId={learnerId}
      />

      <SuccsessToaster
        show={show}
        toggle={(val: boolean) => {
          setShow(val);
        }}
        message={message}
        name={name}
      />
      <SuccsessToaster
        show={showToaster}
        toggle={(val: boolean) => {
          setShowToaster(val);
        }}
        message={message}
        name={name}
      />
      {/* <AssignTrainerSlide
        show={isAssignTrainerSlide}
        toggle={toggleAssignTrainerSlide}
        assignedTrainers={assignedTrainers}
        company={company}
        clientId={clientId}
      /> */}
      {isIndividualReport && (
        <IndividualReport
          show={isIndividualReport}
          toggle={toggleIndividualReport}
          selectedReport={selectedReport}
          fileArray={fileArray}
          setFileArray={setFileArray}
          setMessage={setMessage}
          setName={setName}
          setShowErrorToaster={setShowErrorToaster}
        />
      )}

      <IndividualViewReport
        show={isIndividualViewReport}
        toggle={toggleIndividualViewReport}
        selectedReport={selectedReport}
        source={"reports"}
      />
      <SubmitReportModel
        show={showSubmitReportModel}
        submitReports={submitReports}
        toggle={toggleSubmitReportModel}
      />
      <FileUploadModel
        show={showFileUploadModel}
        toggle={toggleFileUploadModel}
        toaster={toggleFileToaster}
        errorToggle={toggleFileErrorToaster}
        message={setMessage}
        clientid={clientId}
      />

      <ErrorToaster
        show={showErrorToaster}
        toggle={(val: boolean) => {
          setShowErrorToaster(val);
        }}
        msg={message}
      />
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex items-center">
          <Link to="/a/reports" className="flex items-center">
            <ChevronLeftIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <span className="pl-3 text-gray-500 font-poppins-500">Back</span>
          </Link>

          <div className="justify-end text-right font-poppins-300 text-gray-500 text-xs ml-auto">
            {modifiedDate
              ? "Last saved " + moment.utc(modifiedDate).local().fromNow()
              : ""}
          </div>
        </div>

        <div className="mb-4 px-4 py-5 flex flex-col sm:flex-row flex-wrap sm:items-center sm:justify-between sm:flex-nowrap  bg-white shadow-base rounded-lg">
          <div className="mb-2 sm:mb-0">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <AuthorImg
                  url={clientInfo?.clientLogo}
                  name={clientInfo?.client}
                ></AuthorImg>
              </div>
              <div className="ml-4">
                <h3
                  className="text-lg font-medium leading-6 text-gray-700"
                  onClick={() => {
                    // setAssignedTrainers(clientInfo?.assigned_trainers);
                    // setClientId(courseReports[0]?.clientid);
                    // setCompany(clientInfo);
                    // toggleAssignTrainerSlide(true);
                  }}
                >
                  {clientInfo?.client}
                </h3>
                <p className="text-sm text-gray-500 mt-1">{courseId}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-shrink-0 items-center">
            {/* <Menu as="div" className="hidden md:block relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm ring-lavender-500 focus:outline-none focus:ring-offset-2 text-gray-400 hover:text-gray-600">
                  <span className="sr-only">Open options</span>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                  </svg>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className="hover:bg-gray-100 text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                          href={ReportTemplate}
                          download="FileDropTemplate"
                        >
                          <svg
                            className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                            width="14"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              id="Vector"
                              d="M12.8426 4.40508L9.09844 0.660937C8.67305 0.237023 8.10352 0 7.50586 0H2.25C1.00723 0 0 1.00723 0 2.25L0.000228516 15.75C0.000228516 16.9928 1.00746 18 2.25023 18H11.25C12.4928 18 13.5 16.9928 13.5 15.75V5.99414C13.5 5.39648 13.2645 4.82695 12.8426 4.40508ZM7.875 1.19813C8.03239 1.25318 8.17966 1.33334 8.30109 1.45473L12.0452 5.19887C12.1676 5.31914 12.2484 5.4668 12.3012 5.625H8.4375C8.12812 5.625 7.875 5.37188 7.875 5.0625V1.19813ZM12.375 15.75C12.375 16.3702 11.8702 16.875 11.25 16.875H2.25C1.62984 16.875 1.125 16.3702 1.125 15.75V2.25C1.125 1.62984 1.62984 1.125 2.25 1.125H6.75V5.0625C6.75 5.99414 7.50586 6.75 8.4375 6.75H12.375V15.75ZM7.3125 8.4375C7.3125 8.12812 7.05938 7.875 6.75 7.875C6.44062 7.875 6.1875 8.12812 6.1875 8.4375V12.7055L4.33477 10.8527C4.22578 10.7438 4.08164 10.6875 3.9375 10.6875C3.79336 10.6875 3.64922 10.7438 3.54023 10.8527C3.32051 11.0725 3.32051 11.4286 3.54023 11.648L6.35273 14.4605C6.57246 14.6802 6.92859 14.6802 7.14797 14.4605L9.96047 11.648C10.1802 11.4282 10.1802 11.0721 9.96047 10.8527C9.74074 10.6334 9.38461 10.633 9.16523 10.8527L7.3125 12.7055V8.4375Z"
                              fill="#9CA3AF"
                            />
                          </svg>
                          Download template
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu> */}
            {/* <button
              onClick={() => {
                toggleFileUploadModel(true);
                setClientId(courseReports[0]?.clientid);
              }}
              type="button"
              className="relative sm:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M2.22222 16.7778H10.2847C10.5938 17.1875 11.0174 17.5521 11.4479 17.8646C11.3403 17.8784 11.2257 17.8889 11.1111 17.8889H2.22222C0.994792 17.8889 0 16.8923 0 15.6666V2.33331C0 1.10588 0.994792 0.111084 2.22222 0.111084H7.64236C8.08333 0.111084 8.51042 0.286674 8.82292 0.599278L12.8438 4.6215C13.1562 4.934 13.3333 5.36108 13.3333 5.80206V7.00692C12.9444 7.11803 12.5729 7.26386 12.2222 7.44442V6.77775H8.33333C7.41319 6.77775 6.66667 6.03122 6.66667 5.11108V1.2222H2.22222C1.60868 1.2222 1.11111 1.71976 1.11111 2.33331V15.6666C1.11111 16.2812 1.60868 16.7778 2.22222 16.7778ZM12.059 5.4097L8.03472 1.38504C7.96528 1.31317 7.875 1.26317 7.77778 1.23886V5.11108C7.77778 5.41664 8.02778 5.66664 8.33333 5.66664H12.2049C12.1806 5.56942 12.1319 5.47914 12.059 5.4097ZM15.5556 12.3021H17.2222C17.5278 12.3021 17.7778 12.5833 17.7778 12.8576C17.7778 13.1944 17.5278 13.4132 17.2222 13.4132H15.5556V15.0798C15.5556 15.4166 15.3056 15.6354 15 15.6354C14.6944 15.6354 14.4444 15.4166 14.4444 15.0798V13.4132H12.7778C12.4722 13.4132 12.2222 13.1944 12.2222 12.8576C12.2222 12.5833 12.4722 12.3021 12.7778 12.3021H14.4444V10.6354C14.4444 10.3611 14.6944 10.0798 15 10.0798C15.3056 10.0798 15.5556 10.3611 15.5556 10.6354V12.3021ZM20 12.8889C20 15.6493 17.7604 17.8889 15 17.8889C12.2396 17.8889 10 15.6493 10 12.8889C10 10.1284 12.2396 7.88886 15 7.88886C17.7604 7.88886 20 10.1284 20 12.8889ZM15 8.99997C12.8507 8.99997 11.1111 10.7396 11.1111 12.8889C11.1111 15.0382 12.8507 16.7778 15 16.7778C17.1493 16.7778 18.8889 15.0382 18.8889 12.8889C18.8889 10.7396 17.1493 8.99997 15 8.99997Z" />
              </svg>
              <span>File drop</span>
            </button> */}

            {/* <button
              onClick={() => toggleSubmitReportModel(true)}
              type="button"
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-lavender px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="inline-block h-3">
                <svg
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M17.8102 0.194487C18.0633 0.44358 18.0633 0.853379 17.8102 1.10247L6.88221 12.0304C6.63311 12.2835 6.22331 12.2835 5.97422 12.0304L0.188266 6.24504C-0.0627554 5.99595 -0.0627554 5.58615 0.188266 5.33706C0.439127 5.08395 0.846113 5.08395 1.09722 5.33706L6.42821 10.6685L16.9022 0.194487C17.1513 -0.0570166 17.5611 -0.0570166 17.8102 0.194487Z"
                    fill=""
                  />
                </svg>
              </span>
              <span>Submit reports</span>
            </button> */}
          </div>
        </div>

        <div className="mb-4 bg-white shadow-base rounded-lg individual-course-table">
          <div className="border-b px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <div className="flex items-center">
                  <div className="ml-0">
                    <h3 className="text-lg font-normal leading-6 text-gray-900">
                      Reports
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                      {completedReports} out of {totalCountOfReports} reports
                      completed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4 flex flex-shrink-0 -mr-4">
                <Menu as="div" className="hidden md:block relative ml-3">
                  <div>
                    <Menu.Button className="relative sm:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open options</span>
                      <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id="Vector"
                          d="M0 1.57145C0 1.21627 0.287839 0.928589 0.642857 0.928589H17.3571C17.7107 0.928589 18 1.21627 18 1.57145C18 1.92662 17.7107 2.2143 17.3571 2.2143H0.642857C0.287839 2.2143 0 1.92662 0 1.57145ZM0 8.00002C0 7.64645 0.287839 7.35716 0.642857 7.35716H12.2143C12.5679 7.35716 12.8571 7.64645 12.8571 8.00002C12.8571 8.35359 12.5679 8.64287 12.2143 8.64287H0.642857C0.287839 8.64287 0 8.35359 0 8.00002ZM7.07143 15.0714H0.642857C0.287839 15.0714 0 14.7822 0 14.4286C0 14.075 0.287839 13.7857 0.642857 13.7857H7.07143C7.425 13.7857 7.71429 14.075 7.71429 14.4286C7.71429 14.7822 7.425 15.0714 7.07143 15.0714Z"
                          fill="#9CA3AF"
                        />
                      </svg>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-20 mt-2 w-52 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setSortType("createdate desc");
                              }}
                            >
                              Update required first
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setSortType("firstName asc");
                              }}
                            >
                              Alphabetic A - Z
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setSortType("firstName desc");
                              }}
                            >
                              Alphabetic Z - A
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <div className="flex  items-center">
                  <div className="hidden  sm:flex sm:mt-0 sm:ml-3">
                    <label htmlFor="search" className="sr-only">
                      Search learners
                    </label>
                    <div className="relative  rounded-md shadow-sm layout-search">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="block w-72 sm:w-80 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search learners"
                        onChange={(e) => searchOptions(e.target.value ?? "")}
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading && (
            <div
              className={`${
                loading ? "h-24 flex items-center justify-center" : ""
              }`}
            >
              <ScreenLoader />
            </div>
          )}
          {!loading && courseReports?.length > 0 ? (
            <div className="ring-black ring-opacity-5">
              <table className="min-w-full divide-y">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="hidden sm:table-cell whitespace-nowrap px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12  "
                    >
                      Course Status
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
                    >
                      RAG
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
                    >
                      %
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell relative py-3.5 pl-3 pr-4 sm:pr-6 w-2/12  "
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {courseReports?.map((report) => (
                    <tr
                      className={`cursor-pointer relative z-10 hover:font-medium transition-all duration-300 ease-in ${
                        report.submitStatus === "submitted" ? "opacity-50" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        // let obj: any = { ...report };
                        // obj.CompletionDate = obj.CompletionDate
                        //   ? new Date(obj.CompletionDate)
                        //   : null;
                        // obj.BILEndDate = obj.BILReturnDate
                        //   ? new Date(obj.BILReturnDate)
                        //   : null;
                        // obj.EPAReadyDate = obj.EPAreadyDate
                        //   ? new Date(obj.EPAreadyDate)
                        //   : null;
                        // obj.LeaverDate = obj.LeaverDate
                        //   ? new Date(obj.LeaverDate)
                        //   : null;
                        // obj.BILStartDate = obj.BILStartDate
                        //   ? new Date(obj.BILStartDate)
                        //   : null;
                        // obj.NextVisit = obj.NextDate
                        //   ? isBefore(new Date(), obj.NextDate)
                        //     ? new Date(obj.NextDate)
                        //     : null
                        //   : null;
                        // getFiles(obj?.files);
                        getSelectedReport(report).then((obj: any) => {
                          setSelectedReport(obj);
                        });
                        setTimeout(() => {
                          toggleIndividualReport(true);
                        }, 100);
                      }}
                    >
                      {/* Learner name */}
                      <td
                        className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleLearnersProfile(true, report);
                          setLearnerId(report?.learnerId);
                          // settrainerId(report?.trainer_info?.tp_sfid);
                        }}
                      >
                        <span className="cursor-pointer block mb-3 sm:mb-0">
                          {`${report.firstName} ${report.lastName}`}
                          <span className="ml-2">{report?.uln ?? "-"}</span>
                        </span>
                      </td>

                      {/* Course Progress */}
                      <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                        {report.courseProgress ? report.courseProgress : "-"}
                      </td>

                      {/* RAG */}
                      <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                        {report.ragRating
                          ? ragRatingRender(report.ragRating)
                          : "-"}
                      </td>

                      {/* Percentage */}
                      <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                        {report.percentage ? `${report.percentage} % ` : "-"}
                      </td>

                      <td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            // let obj: any = { ...report };
                            // obj.CompletionDate = obj.CompletionDate
                            //   ? new Date(obj.CompletionDate)
                            //   : null;
                            // obj.BILEndDate = obj.BILReturnDate
                            //   ? new Date(obj.BILReturnDate)
                            //   : null;
                            // obj.EPAReadyDate = obj.EPAreadyDate
                            //   ? new Date(obj.EPAreadyDate)
                            //   : null;
                            // obj.LeaverDate = obj.LeaverDate
                            //   ? new Date(obj.LeaverDate)
                            //   : null;
                            // obj.BILStartDate = obj.BILStartDate
                            //   ? new Date(obj.BILStartDate)
                            //   : null;
                            // obj.NextVisit = obj.NextDate
                            //   ? isBefore(new Date(), obj.NextDate)
                            //     ? new Date(obj.NextDate)
                            //     : null
                            //   : null;
                            getSelectedReport(report).then((obj: any) => {
                              setSelectedReport(obj);
                            });
                            setTimeout(() => {
                              toggleIndividualViewReport(true);
                            }, 250);
                          }}
                          className="flex justify-end cursor-pointer"
                        >
                          View report
                          <svg
                            className="ml-2 w-4 h-5 text-lavender"
                            width="16"
                            height="15"
                            viewBox="0 0 16 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Frame" clip-path="url(#clip0_5598_13034)">
                              <path
                                id="Vector"
                                d="M11.5556 7.11114C11.5556 9.07503 9.96389 10.6667 8 10.6667C6.03611 10.6667 4.44445 9.07503 4.44445 7.11114C4.44445 5.14725 6.03611 3.55558 8 3.55558C9.96389 3.55558 11.5556 5.14725 11.5556 7.11114ZM8 4.44447C6.50278 4.44447 5.33334 5.61392 5.33334 7.11114C5.33334 8.58336 6.50278 9.7778 8 9.7778C9.47222 9.7778 10.6667 8.58336 10.6667 7.11114C10.6667 5.61392 9.47222 4.44447 8 4.44447ZM13.35 3.1278C14.65 4.33336 15.5194 5.7528 15.9306 6.76947C16.0222 6.98892 16.0222 7.23336 15.9306 7.4528C15.5194 8.44447 14.65 9.86392 13.35 11.0945C12.0417 12.3111 10.2444 13.3334 8 13.3334C5.75556 13.3334 3.95834 12.3111 2.65056 11.0945C1.35056 9.86392 0.48167 8.44447 0.0683646 7.4528C-0.0227882 7.23336 -0.0227882 6.98892 0.0683646 6.76947C0.48167 5.7528 1.35056 4.33336 2.65056 3.1278C3.95834 1.91225 5.75556 0.888916 8 0.888916C10.2444 0.888916 12.0417 1.91225 13.35 3.1278ZM0.888892 7.11114C1.25917 8.00003 2.05917 9.33336 3.25556 10.4445C4.45278 11.5556 6.04445 12.4445 8 12.4445C9.95556 12.4445 11.5472 11.5556 12.7444 10.4445C13.9417 9.33336 14.7417 8.00003 15.1111 7.11114C14.7417 6.19725 13.9417 4.86392 12.7444 3.7778C11.5472 2.66642 9.95556 1.7778 8 1.7778C6.04445 1.7778 4.45278 2.66642 3.25556 3.7778C2.05917 4.86392 1.25917 6.19725 0.888892 7.11114Z"
                                fill="#7A69C3"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_5598_13034">
                                <rect
                                  width="16"
                                  height="14.2222"
                                  fill="white"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            !loading && (
              <div className="h-54 my-2">
                <NoData />
              </div>
            )
          )}
          {totalCountOfReports > 10 && (
            <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
              <div className="flex flex-1 justify-between sm:hidden ">
                <button
                  onClick={(e) => changePage(e, "previous")}
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
                >
                  Previous
                </button>
                <button
                  onClick={(e) => changePage(e, "next")}
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
                >
                  Next
                </button>
              </div>

              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700 font-inter-400">
                    Showing
                    <span className=" inline-flex pl-1 pr-1">
                      {courseReports?.length ? 10 * currentPage - 9 : 0}
                    </span>
                    to
                    <span className=" inline-flex pl-1 pr-1">
                      {10 * currentPage > totalCountOfReports
                        ? totalCountOfReports
                        : 10 * currentPage}
                    </span>
                    of
                    <span className=" inline-flex pl-1 pr-1">
                      {totalCountOfReports}
                    </span>
                    results
                  </p>
                </div>

                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <a
                      href="#"
                      onClick={(e) => changePage(e, "previous")}
                      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Previous</span>
                      {/* <!-- Heroicon name: mini/chevron-left --> */}
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                    {pages.map((page) => (
                      <a
                        key={page}
                        href="#"
                        onClick={(e) => changePage(e, page)}
                        className={
                          currentPage === page
                            ? "relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20"
                            : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                        }
                      >
                        {page}
                      </a>
                    ))}
                    <a
                      href="#"
                      onClick={(e) => changePage(e, "next")}
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          )}

          <div></div>
        </div>
      </div>
    </>
  );
}

export default SubmittedIndividualCourse;
