import { useEffect, useRef, useState } from 'react'
import { getFileUrl } from '../utils/fileAction'

export default function TextMore(props: { text: string }) {
	const multiRow = useRef(null)
	const [showDescription, setShowDescription] = useState(false)
	const [needMore, setNeedMore] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			// @ts-ignore
			const height = parseInt(getComputedStyle(multiRow.current).height)
			// @ts-ignore
			const lineHeight = parseInt(getComputedStyle(multiRow.current).lineHeight)
			if (height > lineHeight * 5) {
				setShowDescription(true)
				setNeedMore(true)
			} else {
				setShowDescription(false)
				setNeedMore(false)
			}
		}, 500)
	}, [])

	return (
		<>
			<div
				className={
					showDescription
						? 'overflow-hidden text-ellipsis line-clamp-5'
						: 'break-all'
				}
				ref={multiRow}
				dangerouslySetInnerHTML={{
					__html: props.text?.replace(/(\r\n|\n|\r)/g, '<br/>')
				}}
			></div>
			{needMore ? (
				<span
					className="text-right text-primary cursor-pointer flex justify-end"
					onClick={() => {
						setShowDescription(!showDescription)
					}}
				>
					{showDescription ? 'Show more' : 'Show less'}
				</span>
			) : null}
		</>
	)
}
