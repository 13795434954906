import { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import apiUrl from "../utils/apiUrl";
import axios from "../utils/axios";

export const NestedPagination = (props: any) => {
  const [isHistoryFilter, setIsHistoryFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCountOfCourses, setTotalCountOfCourses] = useState(
    props.total || 5
  );
  const [Cols, setCols] = useState<any>([]);
  useEffect(() => {
    createPaginationPages();
  }, [totalCountOfCourses]);
  useEffect(() => {
    getCoursesByClientWise();
  }, [currentPage]);
  const createPaginationPages = () => {
    let pagesArray = [];
    if (totalCountOfCourses > 90) {
      setPages([1, 2, 3, 4, 5, 6, 7, 8, 9]);
    } else {
      for (let i = 1; i <= Math.ceil(totalCountOfCourses / 5); i++) {
        pagesArray.push(i);
      }
      setPages(pagesArray);
    }
  };

  const getCoursesByClientWise = async () => {
    props.changePage(currentPage);
  };
  const changePage = (e: any, page: number) => {
    e.preventDefault();
    const totalPage = Math.ceil(totalCountOfCourses / 5)
    if(page < 0 || page > totalPage) {
      return
    } else {
      if(page > 4 && page < (totalPage - 3)) {
        setPages([page - 4, page - 3, page - 2, page - 1, page, page + 1, page + 2, page + 3, page + 4])
      }
      setCurrentPage(page);
    }
  };
  const toggleHistoryFilter = (v: boolean) => {
    if (v && isHistoryFilter) {
      setIsHistoryFilter(false);
      setTimeout(() => {
        setIsHistoryFilter(true);
      }, 100);
    } else {
      setIsHistoryFilter(v);
    }
  };

  return (
    <>
      <div className="w-full px-4 sm:px-6 lg:px-6">
        <div>
          <div className="flex items-center justify-between border-gray-200 px-0 py-3 sm:px-0">
            {totalCountOfCourses > 5 && (
              <div className="flex flex-1 justify-between sm:hidden">
                <a
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  onClick={(e) => changePage(e, currentPage -1)}
                >
                  Previous
                </a>
                <a
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  onClick={(e) => changePage(e, currentPage + 1)}
                >
                  Next
                </a>
              </div>
            )}
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700 font-inter-400">
                  Showing
                  <span className=" inline-flex pl-1 pr-1">
                    {5 * currentPage - 4}
                  </span>
                  to
                  <span className=" inline-flex pl-1 pr-1">
                    {5 * currentPage > totalCountOfCourses
                      ? totalCountOfCourses
                      : 5 * currentPage}
                  </span>
                  of
                  <span className=" inline-flex pl-1 pr-1">
                    {totalCountOfCourses}
                  </span>
                  results
                </p>
              </div>
              {totalCountOfCourses > 5 && (
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm -mr-2"
                    aria-label="Pagination"
                  >
                    <a
                      href="#"
                      onClick={(e) => changePage(e, currentPage - 1)}
                      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Previous</span>
                      {/* <!-- Heroicon name: mini/chevron-left --> */}
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                    {pages.map((page) => (
                      <a
                        key={page}
                        href="#"
                        onClick={(e) => changePage(e, page)}
                        className={
                          currentPage === page
                            ? "relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20"
                            : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                        }
                      >
                        {page}
                      </a>
                    ))}
                    <a
                      href="#"
                      onClick={(e) => changePage(e, currentPage + 1)}
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Next</span>
                      {/* <!-- Heroicon name: mini/chevron-right --> */}
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
