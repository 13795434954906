import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import conveyaLogo from "./../../assets/images/conveya-c-logo.png";
import "../../pages/styles/Login.scss";
import eyeclosedIcon from "../../assets/Icons/eyeclosed.svg";
import eyeopenIcon from "../../assets/Icons/eyeopen.svg";
import conveyaLongLogo from "./../../assets/images/conveyaLongLogo.png";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import eyeIcon from "../../assets/Icons/eyeIcon.svg";
import visibleEyeRed from "../../assets/Icons/visibleEyeRed.svg";
import { setSession } from "../../utils/jwt";
export default function Login() {
  const { login, isAuthenticated, isInitialized } = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  const onSubmit = (data: any) => {
    setLoading(true);
    login(data.email, data.password)
      .then(() => {
        setLoading(false);
        // navigate('/a/dashboard');
      })
      .catch((error) => {
        if (error?.data?.code === 201) {
          setError("email", {
            message: "",
          });
          setError("password", {
            type: "wrong_password",
            message:
              "Oops! Incorrect email or password. Double-check your details. Stuck?",
          });
        } else if (error?.data?.code === 202) {
          setError("email", {
            message: "",
          });
          setError("password", {
            type: "lock_password",
            message:
              "Oops! It seems you've hit the login attempt limit. Please retry in 15 minutes.",
          });
        } else {
          setError("email", {
            type: "login_error",
            message: error.response.data.message,
          });
          setError("password", {
            type: "login_error",
            message: error.response.data.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
      <div className="flex min-h-full flex-col  py-12 sm:px-6 lg:px-8 auth-pages">
        <div className=" px-4  sm:px-0 sm:mx-auto sm:w-full sm:max-w-md">
          <img
            src={conveyaLongLogo}
            className="mx-auto h-8 w-auto"
            alt="Conveya"
          />
          <h2 className="mt-6 text-center text-3xl font-inter-800  text-gray-900">
            Sign in
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <label
                  htmlFor="email"
                  className={
                    errors.email
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    maxLength={80}
                    {...register("email", {
                      required: true,
                      pattern: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i,
                    })}
                    name="email"
                    autoComplete="email"
                    className={
                      errors.email
                        ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                        : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                    }
                  />
                </div>
                <p className="mt-2 text-sm text-red-700" id="email-error">
                  <>
                    {errors.email?.type == "required" ? "Required field" : ""}
                    {errors.email?.type == "pattern"
                      ? "Sorry, we couldn't locate a registered account with this email address."
                      : ""}
                    {errors.email?.type == "no_email_present"
                      ? errors.email.message
                      : ""}
                    {errors.email?.type == "verify_email"
                      ? errors.email.message
                      : ""}
                    {}
                  </>
                </p>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className={
                    errors.password
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  Password
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    maxLength={20}
                    {...register("password", { required: true })}
                    autoComplete="current-password"
                    className={
                      errors.password
                        ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                        : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                    }
                    placeholder=""
                  />
                  <div
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <img
                      src={
                        passwordVisible
                          ? errors.password
                            ? visibleEyeRed
                            : eyeopenIcon
                          : errors.password
                          ? eyeIcon
                          : eyeclosedIcon
                      }
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                      alt=""
                    />
                  </div>
                </div>
                <p className="mt-2 text-sm text-red-700" id="email-error">
                  <>
                    {errors.password?.type == "required"
                      ? "Required field"
                      : ""}
                    {errors.password?.type == "wrong_password"
                      ? errors.password.message
                      : ""}
                    {errors.password?.type == "lock_password"
                      ? errors.password.message
                      : ""}
                    {errors.password?.type == "wrong_password" && (
                      <Link
                        to="/reset-password"
                        // target=""
                        className="font-poppins-500 text-lavender"
                      >
                        Try resetting your password.
                      </Link>
                    )}
                    {errors.password?.type == "login_error"
                      ? errors.password.message
                      : ""}
                  </>
                </p>
              </div>

              <div className="flex items-center justify-center">
                <div className="text-sm flex items-center">
                  <a
                    href="reset-password"
                    className="font-poppins-500 text-lavender"
                  >
                    Don’t know your password?
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center btn-lavender"
                  style={{ opacity: loading ? 0.4 : 1 }}
                  disabled={loading ? true : false}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
        <footer className="mt-auto">
          <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
            <p className="text-base font-poppins-500 text-gray-400 text-center">
              <img src={conveyaLogo} className="inline-block h-5 w-5 -mt-px" />
              Powered by
              <span className="text-lavender">
                <a
                  href="https://www.conveya.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Conveya.co
                </a>
              </span>{" "}
              - Formally known as Scopesuite
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
