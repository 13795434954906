import React, { useState } from "react";
import backarrow from "./../../assets/Icons/backarrow.svg";
import conveyaLogo from "./../../assets/images/conveya-c-logo.png";
import "../styles/Login.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import apiUrl from "../../utils/apiUrl";
import conveyaLongLogo from "./../../assets/images/conveyaLongLogo.png";
import useAuth from "../../hooks/useAuth";
function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();

  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  const onSubmit = (data: any) => {
    setLoading(true);
    axios
      .post(apiUrl.resetPassword, {
        email: data.email,
      })
      .then(
        (res) => {
          // navigate("/reset-code", { state: { email: data.email } });
          if (res.data.code === 200) {
            // setUser(res.data);
            navigate("/reset-code", { state: { email: data.email } });
          } else if (res.data.code === 202) {
            setError("email", {
              type: "no_email_present",
              message:
                "Oops! It seems you've hit the login attempt limit. Please retry in 15 minutes.",
            });
          } else {
            setError("email", {
              type: "no_email_present",
              message: "Sorry, you cannot reset password for this email. ",
            });
          }
        },
        (error) => {
          // setLoading(false);
          // if (error.response.status === 403) {
          // setError("email", {
          //   type: "no_email_present",
          //   message:
          //     "Sorry, we couldn't locate a registered account with this email address. ",
          // });
          // } else if (error.response.status === 409) {
          //   setError("email", {
          //     type: "existing_email",
          //     message: "This email appears to be already claimed. ",
          //   });
          // } else {
          setError("email", {
            type: "generic",
            message: "Sorry, you cannot reset password for this email.",
          });
          // }
        }
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="flex min-h-full flex-col  py-12 sm:px-6 lg:px-8 auth-pages">
        <div className="px-4 sm:px-0 sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-8 w-auto"
            src={conveyaLongLogo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-inter-800 text-gray-900">
            Don’t know your password?
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600 font-inter-400">
            Don't worry! It happens. Please enter your email address associated
            with your account.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <label
                  htmlFor="email"
                  className={
                    errors.email
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    maxLength={80}
                    autoComplete="email"
                    {...register("email", {
                      required: true,
                      pattern: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i,
                    })}
                    // className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                    className={
                      errors.email
                        ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                        : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                    }
                  />
                </div>
                <p className="mt-2 text-sm text-red-700" id="email-error">
                  <>
                    {errors.email?.type == "required" ? "Required field" : ""}
                    {errors.email?.type == "pattern"
                      ? "Sorry, we couldn't locate a registered account with this email address."
                      : ""}
                    {errors.email?.type == "no_email_present"
                      ? errors.email.message
                      : ""}
                    {errors.email?.type == "existing_email"
                      ? errors.email.message
                      : ""}
                    {errors.email?.type == "generic"
                      ? errors.email.message
                      : ""}
                  </>
                </p>
              </div>
              <div className="flex items-center justify-center pt-2">
                <div className="text-sm flex items-center">
                  <a href="login" className="font-poppins-500 text-lavender">
                    <img
                      src={backarrow}
                      alt="Back to log in"
                      className="inline-block mr-1 align-baseline"
                    />{" "}
                    Back to log in
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center btn-lavender "
                  style={{ opacity: loading ? 0.4 : 1 }}
                  disabled={loading ? true : false}
                >
                  Send reset code
                </button>
              </div>

              {/* <div className="border-solid border-gray-300 border-b pt-1"></div> */}
            </form>
          </div>
        </div>
        <footer className="mt-auto">
          <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
            <p className="text-base font-poppins-500 text-gray-400 text-center">
              <img
                src={conveyaLogo}
                className="inline-block h-5 w-5 -mt-px"
                alt=""
              />{" "}
              Powered by <span className="text-lavender">Conveya</span> -
              Formally known as Scopesuite
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default ResetPassword;
