import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import warningIcon from '../assets/Icons/warningIcon.svg';

import timerIcon from '../assets/Icons/timeoutIcon.svg';


let countTimer: any = null;
export default function TimeoutModal(props: any) {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(10);

  const cancelButtonRef = useRef(null)

  useEffect(() => {
    setOpen(props.show);
    if(props.show) {
      countTimer = setInterval(() => {
        setCount(prevCount => prevCount - 1);
      }, 1000);
    }
  }, [props.show])

  useEffect(() => {
    if(count === 0) {
      clearInterval(countTimer);
      props.staySignedIn(false);
      setCount(10);
    }
  }, [count]);

  return (

    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.toggle}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                    {/* <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> */}
                    <img src={timerIcon} alt="Timer" className=""/>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium text-gray-900 font-poppins-500">
                    The session is about to expire... 
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-base text-gray-500 font-poppins-400">
                      You will be automatically signed out in 
                      <br className="hidden sm:block"/>
                       <span className="font-poppins-500 text-gray-700"> {count} sec</span> 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-6">
              
                <button
                    type="button"
                    className="inline-flex w-full justify-center  btn-lavender focus:outline-none  col-start-1 sm:col-start-2 sm:mt-0 sm:text-sm"
                    onClick={() => {
                      props.staySignedIn(true);
                      clearInterval(countTimer);
                      setTimeout(() => {
                        setCount(10);
                      }, 1000)
                      
                    }}
                    // onClick={() => props.toggle(false)}
                    // ref={cancelButtonRef}
                  >
                    Stay signed in
                  </button>  
                  <button
                    type="button"
                    className="inline-flex w-full justify-center text-sm font-poppins-500 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm  btn-white-padding  hover:bg-gray-50 focus:outline-none focus:bg-gray-50 ring-lavender-500 col-start-2 mt-3 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => props.staySignedIn(false)}
                    // onClick={() => {
                    //   props.toggle(false);
                    //   props.deactivateAccount();
                    // }}
                  >
                    Sign out now
                  </button>
                
    
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
   
  )
}
