import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  ChevronUpIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import fileUploadImage from "../assets/images/fileUploadImage.jpg";
// import axios from "../utils/axios";
import axios from "../utils/axios"
import apiUrl from "../utils/apiUrl";

function RemoveTrainerModel(props: any) {
  const id = props.id;


  const removeTrainer = async () => {


    const response = await axios
      .delete(apiUrl.deletTrainer, {
        data: {
          trainer_id: id,
          // company_id:"",

        },
      })

    if (response.data.isdeleted === true) {
      // isdeleted

      // props.togle(true);
      props.toggle(false);
      if (props.successfulRemoval) {
        props.successfulRemoval();
      }
    }


    // const response = await axios.delete(apiUrl.deletTrainer,
    //   {
    //     trainer_id: id,
    //   })

  }

  return (

    <Transition.Root show={props.show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => props.toggle(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <svg
                      className="w-6 h-6"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 7V9M10 13H10.01M3.07183 17H16.9282C18.4678 17 19.4301 15.3333 18.6603 14L11.7321 2C10.9623 0.666667 9.03778 0.666667 8.26798 2L1.33978 14C0.56998 15.3333 1.53223 17 3.07183 17Z"
                        stroke="#DC2626"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 font-poppins-500"
                    >
                      Are you sure you want to remove the trainer?
                    </Dialog.Title>
                  </div>
                </div>

                <div className="text-gray-400 text-center py-2 text-sm">
                  {/* [trainers full name] */}
                  {props.name}
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    onClick={removeTrainer}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-poppins-500 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-3 sm:col-start-2 sm:text-sm"
                  >
                    Remove
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-poppins-500 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => props.toggle(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default RemoveTrainerModel;
