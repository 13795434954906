import React, { useEffect, useState } from "react";
import AppSlideOver from "./AppSlideOver";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Dialog } from "@headlessui/react";
import axios from "../utils/axios";
import apiUrl from "../utils/apiUrl";

export default function PairLearnersFilter(props: any) {
  const [showTrainer, setShowTrainer] = useState(false);
  const [showCourse, setShowCourse] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [trainer, setTrainer] = useState<any>([]);
  const [course, setCourse] = useState<any>([]);
  const [client, setClient] = useState<any>([]);

  const getFilter = async () => {
    const response = await axios.get(apiUrl.trainerFilter);
    const courseResponse = await axios.post(apiUrl.trainerFilter, {client_id: props.clientId ?? ""});
    //  setTrainer()
    setCourse(filterArrayCourse(courseResponse.data.courses));
    setClient(filterArrayClient(response.data.clients));
    setTrainer(filterArrayTrainer(response.data.trainers));
  };
  const filterArrayTrainer = (data: any) => {
    let arrayTemp: any = [];

    data.map((each: any) => {
      if (each.tcr_sfid !== null) {
        let temp = {
          label: each.trainer_name,
          value: each.trainer_name,
          trainerid: each.tcr_sfid,
          selected: false,
          removeFromSearch: false,
        };
        arrayTemp.push(temp);
      }
    });
    return arrayTemp;
  };
  const filterArrayClient = (data: any) => {
    let arrayTemp: any = [];

    data.map((each: any) => {
      let temp = {
        account_sfid: each.account_sfid,
        company_name: each.company_name,
        selected: false,
        removeFromSearch: false,
      };
      arrayTemp.push(temp);
    });
    return arrayTemp;
  };
  const filterArrayCourse = (data: any) => {
    let arrayTemp: any = [];

    data.map((each: any) => {
      let temp = {
        course_sfid: each.course_sfid,
        coursename: each.coursename,
        selected: false,
        removeFromSearch: false,
      };
      arrayTemp.push(temp);
    });
    return arrayTemp;
  };

  useEffect(() => {
    setTrainer(props?.trainer);
    setCourse(props?.course);
    // setClient(props?.companyName);
    // // setStartDate(props?.filterConfig[0]?.daterange?.from_date ?? new Date())
    // // setEndDate(props?.filterConfig[0]?.daterange?.to_date ?? "")
    // setShowClient(false);
    setShowCourse(false);
    setShowTrainer(false);
    getFilter();
  }, []);

  const filteroptions = (arr: any) => {
    let arraytemp: any = [];
    arr.map((each: any) => {
      if (each.selected === true) {
        if (each.trainerid) {
          arraytemp.push(each.trainerid);
        } else if (each.account_sfid) {
          arraytemp.push(each.account_sfid);
        } else if (each.course_sfid) {
          arraytemp.push(each.course_sfid);
        }
      }
    });
    return arraytemp;
  };

  const saveFilter = () => {
    props.toggle(false);

    let filterArr: any = [
      {
        trainer: filteroptions(trainer),
        clients: filteroptions(client),
        courses: filteroptions(course),
      },
    ];

    props.setFilterConfig(filterArr);
    // props.setClient([...filteroptions(client)]);
    props.setCourse([...filteroptions(course)]);
    props.setTrainer([...filteroptions(trainer)]);
    // setTrainer([]);
    // setClient([]);
    // setCourse([]);
  };

  const searchOptions = (e: any, item: any) => {
    let options = item === course ? [...course] : [...client];
    options = options.map((option) => {
      if (!option.coursename?.toLowerCase().includes(e.target.value?.toLowerCase())) {
        option.removeFromSearch = true;
        return option;
      } else {
        option.removeFromSearch = false;
        return option;
      }
    });

    item === course ? setCourse(options) : setClient(options);
  };

  const clearFilter = () => {
    props.toggle(false);

    let filterArr: any = [
      {
        trainer: [],
        clients: [],
        courses: [],
      },
    ];

    props.setFilterConfig(filterArr);

    props.setCourse([]);
    props.setTrainer([]);
    window.location.reload();
  };

  return (
    <AppSlideOver toggle={props.toggle} show={props.show}>
      <div className="flex min-h-0 flex-1 flex-col py-6">
        <div className=" px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <div className="text-lg font-inter-500 leading-7 text-gray-900">
              Filter
            </div>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => props.toggle(false)}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <div className=" relative overflow-y-auto mt-6 pb-6 flex-1 px-4 sm:px-6">
          {/* Replace with your content */}
          <form>
            <div>
              <div
                className="relative flex  items-center py-2"
                onClick={() => setShowTrainer(!showTrainer)}
              >
                <div className="min-w-0 flex-1 text-sm items-center">
                  <label
                    htmlFor=""
                    className="font-poppins-500 text-sm text-gray-700"
                  >
                    Trainer
                  </label>
                </div>
                <div className="flex h-5 items-center pr-1">
                  <span className="text-gray-400 hover:text-gray-500">
                    {showTrainer ? (
                      <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </div>
              </div>

              {showTrainer &&
                trainer.map((item: any, index: any) => {
                  return (
                    <div className="pt-2 pb-4 space-y-4 max-h-40 overflow-y-auto">
                      {/* <div className="flex  items-center">
                                <input
                                    id=""
                                    aria-describedby=""
                                    name=""
                                    type="checkbox"
                                    className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                                />
                                <label htmlFor="" className="font-poppins-400 text-sm text-gray-600">
                                    Unpaired
                                </label>
                            </div> */}

                      {/* // <div className="pt-2 pb-4 space-y-4 max-h-40 overflow-y-auto" key={index}> */}
                      <div className="flex  items-center">
                        <input
                          id={item.label}
                          aria-describedby=""
                          name={item.label}
                          type="checkbox"
                          checked={item.selected}
                          onChange={() => {
                            let clickedCopy = [...trainer];
                            clickedCopy[index].selected =
                              !clickedCopy[index].selected;
                            setTrainer([...clickedCopy]);
                          }}
                          className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                        />
                        <label
                          htmlFor=""
                          className="font-poppins-400 text-sm text-gray-600"
                        >
                          {/* [Trainers name] */}
                          {item.label}
                        </label>
                      </div>
                    </div>
                  );
                })}

              {/* </div> */}
            </div>

            <div>
              <div
                className="relative flex  items-center py-2"
                onClick={() => setShowCourse(!showCourse)}
              >
                <div className="min-w-0 flex-1 text-sm items-center">
                  <label
                    htmlFor=""
                    className="font-poppins-500 text-sm text-gray-700"
                  >
                    Course
                  </label>
                </div>
                <div className="flex h-5 items-center pr-1">
                  <span className="text-gray-400 hover:text-gray-500">
                    {showCourse ? (
                      <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </div>
              </div>
              {showCourse && (
                <div>
                  <div className="flex">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full rounded-md shadow-sm layout-search my-4">
                      <input
                        type="text"
                        name="search"
                        autoComplete="off"
                        id="search"
                        onChange={(e: any) => searchOptions(e, course)}
                        className="block w-full rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search"
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pt-2 pb-4 space-y-4 max-h-52 overflow-y-auto iosScroll">

                    {course.map((item: any, index: any) => {
                      return (
                        !course[index].removeFromSearch && (
                          <div className="flex  items-center" key={index}>
                            <input
                              id={item.coursename}
                              aria-describedby=""
                              name={item.coursename}
                              type="checkbox"
                              checked={item.selected}
                              onChange={() => {
                                let clickedCopy = [...course];
                                clickedCopy[index].selected =
                                  !clickedCopy[index].selected;
                                setCourse([...clickedCopy]);
                              }}
                              className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                            />
                            <label
                              htmlFor=""
                              className="font-poppins-400 text-sm text-gray-600"
                            >
                              {item.coursename}
                            </label>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {/* client */}

          </form>
        </div>

        <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
          <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
            onClick={clearFilter}
          >
            Clear all
          </button>
          <button
            onClick={saveFilter}
            className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
          >
            Filter
          </button>
        </div>
      </div>
    </AppSlideOver>
  );
}
