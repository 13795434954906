import { Fragment, useEffect, useState } from "react";
import AppSlideOver from './AppSlideOver'
import {
    ChevronDownIcon,
    ChevronUpIcon,
    MagnifyingGlassIcon,
    XMarkIcon
} from "@heroicons/react/20/solid";
import { Dialog } from '@headlessui/react';
import apiUrl from "../utils/apiUrl";
import axios from "../utils/axios"
import calenderIcon from "../assets/Icons/calenderIcon.svg";



export default function EditCompany(props: any) {

    const [campanyName, setCompanyName] = useState("")

    useEffect(() => {
        if (props?.companyDetails) {
            setCompanyName(props.companyDetails?.alias)
        }
    }, [props])

    const saveFilter = (() => {
        // let req = {
        //     tp_name: campanyName,
        // };
        // axios.post(apiUrl.updateCompanyInfo, req).then(
        //     (res) => {
        //         if (res.status === 200) {
        //             props.toggle(false);
        //             props.companyProfile();
        //         }
        //     }, (error) => {
        //     })
        axios.post("/api/profile/account/save", {
            ...props?.companyDetails,
            alias: campanyName
        }).then(
            (res) => {
                if (res.status === 200) {
                    props.toggle(false);
                    props.companyProfile();
                }
            }, (error) => {
            })
    })

    return (


        <AppSlideOver toggle={props.toggle} show={props.show} >


            <div className="flex min-h-0 flex-1 flex-col py-6 slide-over-input">
                <div className=" px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                        <div className="text-lg font-inter-500 leading-7 text-gray-900">
                            Company
                        </div>

                        <div className="ml-3 flex h-7 items-center">
                            <button
                                type="button"
                                className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                                onClick={() => props.toggle(false)}
                            >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                </div>

                <div className=" relative overflow-y-auto mt-6 pb-6 flex-1">
                    {/* Replace with your content */}
                    <form >



                        <div className="flex-1">

                        <div className="py-4 border-t border-gray-200 px-4 sm:px-6">
                                <label
                                    htmlFor="fileName"
                                    className="block text-sm font-poppins-400 text-gray-700"
                                >
                                    Training provider name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        value={props.companyDetails?.trainingProviderName}
                                        disabled
                                        className="block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-500 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                        placeholder=""
                                    />
                                </div>
                            </div>

                            <div className="py-4 border-t border-gray-200 px-4 sm:px-6">
                                <label
                                    htmlFor="fileName"
                                    className="block text-sm font-poppins-400 text-gray-700"
                                >
                                    Company name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="fileName"
                                        value={campanyName}
                                        onChange={(e) => setCompanyName(e.target.value ?? "")}
                                        id="fileName"
                                        className="block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-500 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                        placeholder=""
                                    />
                                </div>
                            </div>


                        </div>





                    </form>
                </div>


                <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
                    <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
                        // onClick={clearFilter}
                        onClick={() => {
                            props.toggle(false)
                            window.location.reload();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={saveFilter}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
                    >
                        Update
                    </button>
                </div>


            </div>
        </AppSlideOver>



    )
}


