import React,{ Fragment, useContext, useEffect, useState } from "react";
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import ReportsUploadedSlide from '../../../components/ReportsUploadedSlide';

import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,

  XMarkIcon,
} from "@heroicons/react/20/solid";
function CourseValidation() {
 
  const [showReportsUploadedSlide, setShowReportsUploadedSlide] = useState(false);
  const toggleReportsUploadedSlide = (value: boolean) => {
    setShowReportsUploadedSlide(value);
  };
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
     <ReportsUploadedSlide
        show={showReportsUploadedSlide}
        toggle={toggleReportsUploadedSlide}
    />
       
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex flex-wrap items-center justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Almost there!
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You have almost uploaded the report
            </p>
          </div>
          <div>

            <button

              type="button"
              className="relative w-full sm:w-auto mt-2  mr-3 sm:mt-0 sm:ml-3 sm:mr-0  items-center rounded-md border border-gray-300 bg-lavender px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span> Submit report </span>
            </button>

          </div>
        </div>
        <div className="mb-6 bg-white shadow-base rounded-lg">
          <div className="px-6 py-5">
            <div className="mb-10">
              <span className="inline-flex items-center rounded-full bg-yellow-100 mb-3 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                Unmatched course names
              </span>
              <div className="text-gray-600 text-sm font-medium">We stumbled across some course names that we can’t recognised. Match them so we can recognise this name in the future.</div>
            </div>
            <div>
              <ul>
                <li className="mb-2">
                  <div className=" bg-gray-50">
                    <div className="flex items-center px-2 py-2">

                      <div className="flex items-center justify-between w-full flex-row gap-4">
                        <div className="text-gray-600 text-sm basis-2/3">
                          <div className="flex justify-between">
                            <div> “ Engineering lvl 2”</div>
                            <div >=</div>
                          </div>
                        </div>

                        <div className="basis-1/3">
                          <div className="flex relative items-center gap-2 w-full">

                            <svg className=" absolute left-3" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                              <g clip-path="url(#clip0_6149_2814)">
                                <path d="M17.6113 14.375V1.875C17.6113 0.839453 16.7715 0 15.7363 0H3.23633C1.51055 0 0.111328 1.39922 0.111328 3.125V17.5C0.111328 18.8809 1.23047 20 2.61133 20H16.9863C17.3318 20 17.6113 19.7205 17.6113 19.375C17.6113 19.0295 17.3301 18.75 16.9863 18.75H16.3613V16.1348C17.0879 15.875 17.6113 15.1875 17.6113 14.375ZM15.1113 18.75H2.61133C1.92227 18.75 1.36133 18.1891 1.36133 17.5C1.36133 16.8109 1.92227 16.25 2.61133 16.25H15.1113V18.75ZM15.7363 15H2.61133C2.15391 15 1.73047 15.1324 1.36133 15.3477V3.125C1.36133 2.08945 2.20078 1.25 3.23633 1.25H15.7363C16.0801 1.25 16.3613 1.52969 16.3613 1.875V14.375C16.3613 14.7188 16.0801 15 15.7363 15ZM9.41992 4.09375C9.2075 3.67031 8.51406 3.67031 8.30195 4.09375L4.55195 11.5938C4.39648 11.9062 4.52148 12.2812 4.83008 12.4336C5.13891 12.5862 5.51445 12.4629 5.66875 12.1541L6.74805 10H10.9746L12.052 12.1547C12.1621 12.375 12.3809 12.5 12.6113 12.5C12.7053 12.5 12.8005 12.4786 12.8903 12.4341C13.1991 12.2796 13.3243 11.9044 13.1698 11.5954L9.41992 4.09375ZM7.37305 8.75L8.86133 5.77344L10.3496 8.75H7.37305Z" fill="#9CA3AF"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_6149_2814">
                                  <rect width="17.5" height="20" fill="white" transform="translate(0.111328)"/>
                                </clipPath>
                              </defs>
                            </svg>

                            <select
                              id="location"
                              name="location"
                              className=" block w-full rounded-md border-0 py-1.5 pl-10 pr-10 text-gray-500 text-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              defaultValue="Did you mean...?"
                            >
                              <option>Did you mean...?</option>
                              <option>[Course name]</option>
                              <option>[Course name]</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="mb-2">
                  <div className=" bg-gray-50">
                    <div className="flex items-center px-2 py-2">

                      <div className="flex items-center justify-between w-full flex-row gap-4">
                        <div className="text-gray-600 text-sm basis-2/3">
                          <div className="flex justify-between">
                            <div> “ Engineering lvl 2”</div>
                            <div >=</div>
                          </div>
                        </div>

                        <div className="basis-1/3">
                          <select
                            id="location"
                            name="location"
                            className=" block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-500 text-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue="Did you mean...?"
                          >
                            <option>Did you mean...?</option>
                            <option>[Course name]</option>
                            <option>[Course name]</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className=" bg-gray-50">
                    <div className="flex items-center px-2 py-2">

                      <div className="flex items-center justify-between w-full flex-row gap-4">
                        <div className="text-gray-600 text-sm basis-2/3">
                          <div className="flex justify-between">
                            <div> “ Engineering lvl 2”</div>
                            <div >=</div>
                          </div>
                        </div>

                        <div className="basis-1/3">
                          <select
                            id="location"
                            name="location"
                            className=" block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-500 text-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue="Did you mean...?"
                          >
                            <option>Did you mean...?</option>
                            <option>[Course name]</option>
                            <option>[Course name]</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-6 bg-white shadow-base rounded-lg">
          <div className="py-3 px-6">
            <div className="flex justify-between">
              <div className="text-gray-600 font-medium text-sm">
                <span className="inline-flex">

                  <svg width="20" height="20" viewBox="0 0 20 20" className="bg-orange-50 rounded px-0.5 py-0.5 mr-2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="triangle-exclamation">
                      <path id="Vector" d="M10.0006 13.5298C9.5348 13.5298 9.18855 13.9073 9.18855 14.3735C9.18855 14.8392 9.56644 15.2171 10.0006 15.2171C10.4663 15.2171 10.8126 14.8392 10.8126 14.3735C10.8442 13.9059 10.4681 13.5298 10.0006 13.5298ZM10.0006 12.1237C10.3114 12.1237 10.5314 11.8722 10.5314 11.5613V6.49944C10.5314 6.18855 10.2798 5.93701 10.0006 5.93701C9.72128 5.93701 9.43813 6.1901 9.43813 6.49944V11.5613C9.43813 11.8706 9.69123 12.1237 10.0006 12.1237ZM18.7288 14.841L11.7054 2.96246C11.3504 2.36101 10.7106 2.00105 10.0006 2C9.29049 2 8.65424 2.35925 8.2957 2.96175L1.26884 14.8431C0.915566 15.4396 0.910293 16.1556 1.25402 16.7589C1.60946 17.3789 2.25134 17.748 2.97371 17.748H17.0309C17.7519 17.748 18.3931 17.3779 18.7467 16.7578C19.0908 16.1556 19.0838 15.4386 18.7288 14.841ZM17.7375 16.1697C17.618 16.465 17.3403 16.6232 16.9993 16.6232H2.97371C2.66064 16.6232 2.38386 16.4655 2.23271 16.2013C2.08936 15.9498 2.09157 15.6635 2.2382 15.4154L9.26589 3.53473C9.41704 3.27812 9.69123 3.12486 10.0006 3.12486C10 3.12486 10.0006 3.12486 10.0006 3.12486C10.3087 3.12541 10.5827 3.27809 10.7338 3.53403L17.7607 15.4154C17.8781 15.6635 17.9132 15.9483 17.7375 16.1697Z" fill="#FBBF24" />
                    </g>
                  </svg>

                  We could not find [x] people. Please make sure their unique learner number is correct.<span className="ml-1">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 0C4.02891 0 0 4.02891 0 9C0 13.9711 4.02891 18 9 18C13.9711 18 18 13.9711 18 9C18 4.02891 13.9711 0 9 0ZM9 16.875C4.6582 16.875 1.125 13.3418 1.125 9C1.125 4.6582 4.6582 1.125 9 1.125C13.3418 1.125 16.875 4.6582 16.875 9C16.875 13.3418 13.3418 16.875 9 16.875ZM9 6.46875C9.46582 6.46875 9.84375 6.09117 9.84375 5.625C9.84375 5.15918 9.46582 4.78125 9 4.78125C8.53418 4.78125 8.15625 5.15742 8.15625 5.625C8.15625 6.09258 8.53242 6.46875 9 6.46875ZM10.6875 12.375H9.5625V8.4375C9.5625 8.12813 9.30937 7.875 9 7.875H7.875C7.56563 7.875 7.3125 8.12813 7.3125 8.4375C7.3125 8.74687 7.56563 9 7.875 9H8.4375V12.375H7.3125C7.00313 12.375 6.75 12.6281 6.75 12.9375C6.75 13.2469 7.00313 13.5 7.3125 13.5H10.6875C10.9981 13.5 11.25 13.2481 11.25 12.9375C11.25 12.6281 10.9969 12.375 10.6875 12.375Z" fill="#9CA3AF" />
                    </svg>
                  </span></span></div>
              <div className="text-lavender text-sm underline"> Show me </div>
            </div>
          </div>
        </div>
        <div className="mb-4 bg-white shadow-base rounded-lg individual-course-table">
          <div className="border-b px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <div className="flex items-center">
                  <div className="ml-0">
                    <h3 className="text-lg font-normal leading-6 text-gray-900">
                      Reports uploaded
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                      [x] people reports has been uploaded
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4 flex flex-shrink-0 -mr-4">
                <div className="flex items-center">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1.51473C0 0.747266 0.622266 0.125 1.38973 0.125H16.6113C17.3777 0.125 18 0.747266 18 1.51473C18 1.83816 17.8875 2.15141 17.6801 2.40066L11.25 10.1727V14.6832C11.25 15.3406 10.7156 15.875 10.0582 15.875C9.79453 15.875 9.53789 15.7871 9.32695 15.6254L7.18594 13.9555C6.91172 13.7445 6.75 13.4176 6.75 13.0695V10.1727L0.319078 2.40066C0.112852 2.15141 0 1.83816 0 1.51473ZM1.38973 1.25C1.24348 1.25 1.125 1.36848 1.125 1.51473C1.125 1.57625 1.14645 1.63602 1.18582 1.68348L7.74492 9.61016C7.8293 9.71211 7.875 9.83867 7.875 9.96875V13.0695L9.98789 14.7359C10.0301 14.7465 10.0441 14.75 10.0582 14.75C10.0969 14.75 10.125 14.7219 10.125 14.6832V9.96875C10.125 9.83867 10.1707 9.71211 10.2551 9.61016L16.8152 1.68348C16.8539 1.63602 16.875 1.57625 16.875 1.51473C16.875 1.36848 16.7555 1.25 16.6113 1.25H1.38973Z" fill="#9CA3AF" />
                  </svg>

                </div>
                <div className="flex  items-center">
                  <div className="hidden  sm:flex sm:mt-0 sm:ml-3">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative  rounded-md shadow-sm layout-search">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="block w-72 sm:w-80 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search "

                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ring-black ring-opacity-5">
            <table className="min-w-full divide-y">
              <thead className="bg-gray-50">
                <tr className="">
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-xs sm:px-6 font-medium text-gray-500 "
                  >
                    <a href="#" className="group inline-flex">
                      Name
                      <span
                        className="ml-2 flex-none rounded-sm bg-gray-300 text-gray-700 group-hover:bg-gray-300"
                      //   onClick={(e) => {
                      //     e.preventDefault();
                      //     sortCols("company_name");
                      //   }}
                      >
                        <svg
                          className="h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        // transform={
                        //   sortedColumns[0]?.sort_key === "company_name"
                        //     ? sortedColumns[0].sort_type === "ASC"
                        //       ? "rotate(180 0 0)"
                        //       : "rotate(0 0 0)"
                        //     : "rotate(0 0 0)"
                        // }
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </a>
                  </th>

                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-3 text-left text-xs font-medium text-gray-500 "
                  >
                    <a href="#" className="group inline-flex">
                      Course name
                      <span
                        className="ml-2 flex-none rounded-sm bg-gray-300 text-gray-700 group-hover:bg-gray-300"
                      //   onClick={(e) => {
                      //     e.preventDefault();
                      //     sortCols("coursename");
                      //   }}
                      >
                        <svg
                          className="h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        // transform={
                        //   sortedColumns[0]?.sort_key === "coursename"
                        //     ? sortedColumns[0].sort_type === "ASC"
                        //       ? "rotate(180 0 0)"
                        //       : "rotate(0 0 0)"
                        //     : "rotate(0 0 0)"
                        // }
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </a>
                  </th>

                  <th
                    scope="col"
                    className="px-3 whitespace-nowrap py-3.5 text-left text-xs font-medium text-gray-500 "
                  >
                    <a href="#" className="group inline-flex">
                      (Unique learner number)
                      <span
                        className="ml-2 flex-none rounded-sm bg-gray-300 text-gray-700 group-hover:bg-gray-300"
                      //   onClick={(e) => {
                      //     e.preventDefault();
                      //     sortCols("reportdate");
                      //   }}
                      >
                        <svg
                          className="h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        // transform={
                        //   sortedColumns[0]?.sort_key === "reportdate"
                        //     ? sortedColumns[0].sort_type === "ASC"
                        //       ? "rotate(180 0 0)"
                        //       : "rotate(0 0 0)"
                        //     : "rotate(0 0 0)"
                        // }
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </a>
                  </th>
                  <th
                    scope="col"
                    className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 w-3"
                  >
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                <tr    onClick={() => toggleReportsUploadedSlide(true)}>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                   Matthew Smith
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm text-gray-600 ">
                      [Course name]
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-gray-600">
                    [ULN]
                  </td>
                  <td className="whitespace-nowrap w-3 py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="pen-to-square" clip-path="url(#clip0_5697_119389)">
                        <path id="Vector" d="M13.5949 0.795522C14.4738 -0.0832079 15.9012 -0.0832079 16.7801 0.795522L17.2055 1.22126C18.0844 2.10017 18.0844 3.52611 17.2055 4.40501L9.45703 12.1499C9.16172 12.4488 8.78555 12.6245 8.37422 12.7511L5.18906 13.4859C4.99922 13.5281 4.80234 13.4718 4.66523 13.3347C4.52812 13.1976 4.47187 13.0007 4.51406 12.8109L5.24883 9.62571C5.34375 9.21438 5.55117 8.83821 5.85 8.51126L13.5949 0.795522ZM15.982 1.59111C15.5426 1.15165 14.8324 1.15165 14.393 1.59111L13.4508 2.53119L15.4688 4.54915L16.4074 3.60697C16.8469 3.16857 16.8469 2.4563 16.4074 2.01685L15.982 1.59111ZM6.3457 9.87884L5.81133 12.1886L8.08945 11.6542C8.325 11.6085 8.51484 11.5031 8.6625 11.3238L14.6742 5.34368L12.6562 3.32677L6.64453 9.33743C6.49687 9.48509 6.39141 9.67493 6.3457 9.87884ZM7.3125 2.24994C7.62187 2.24994 7.875 2.50165 7.875 2.81244C7.875 3.12322 7.62187 3.37493 7.3125 3.37493H2.8125C1.88051 3.37493 1.125 4.13079 1.125 5.06243V15.1874C1.125 16.1191 1.88051 16.8749 2.8125 16.8749H12.9375C13.8691 16.8749 14.625 16.1191 14.625 15.1874V10.6874C14.625 10.3781 14.8781 10.1249 15.1875 10.1249C15.4969 10.1249 15.75 10.3781 15.75 10.6874V15.1874C15.75 16.7413 14.4914 17.9999 12.9375 17.9999H2.8125C1.2593 17.9999 0 16.7413 0 15.1874V5.06243C0 3.50923 1.2593 2.24994 2.8125 2.24994H7.3125Z" fill="#B91C1C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5697_119389">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>


                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                    Amy Simpson
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm text-red-700 ">
                    Required*
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-gray-600">
                    [ULN]
                  </td>
                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="pen-to-square" clip-path="url(#clip0_5697_119389)">
                        <path id="Vector" d="M13.5949 0.795522C14.4738 -0.0832079 15.9012 -0.0832079 16.7801 0.795522L17.2055 1.22126C18.0844 2.10017 18.0844 3.52611 17.2055 4.40501L9.45703 12.1499C9.16172 12.4488 8.78555 12.6245 8.37422 12.7511L5.18906 13.4859C4.99922 13.5281 4.80234 13.4718 4.66523 13.3347C4.52812 13.1976 4.47187 13.0007 4.51406 12.8109L5.24883 9.62571C5.34375 9.21438 5.55117 8.83821 5.85 8.51126L13.5949 0.795522ZM15.982 1.59111C15.5426 1.15165 14.8324 1.15165 14.393 1.59111L13.4508 2.53119L15.4688 4.54915L16.4074 3.60697C16.8469 3.16857 16.8469 2.4563 16.4074 2.01685L15.982 1.59111ZM6.3457 9.87884L5.81133 12.1886L8.08945 11.6542C8.325 11.6085 8.51484 11.5031 8.6625 11.3238L14.6742 5.34368L12.6562 3.32677L6.64453 9.33743C6.49687 9.48509 6.39141 9.67493 6.3457 9.87884ZM7.3125 2.24994C7.62187 2.24994 7.875 2.50165 7.875 2.81244C7.875 3.12322 7.62187 3.37493 7.3125 3.37493H2.8125C1.88051 3.37493 1.125 4.13079 1.125 5.06243V15.1874C1.125 16.1191 1.88051 16.8749 2.8125 16.8749H12.9375C13.8691 16.8749 14.625 16.1191 14.625 15.1874V10.6874C14.625 10.3781 14.8781 10.1249 15.1875 10.1249C15.4969 10.1249 15.75 10.3781 15.75 10.6874V15.1874C15.75 16.7413 14.4914 17.9999 12.9375 17.9999H2.8125C1.2593 17.9999 0 16.7413 0 15.1874V5.06243C0 3.50923 1.2593 2.24994 2.8125 2.24994H7.3125Z" fill="#B91C1C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5697_119389">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                  Matthew Smith
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm  text-gray-600">
                  [Course name]
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-red-700">
                  Duplicate
                  </td>
                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="pen-to-square" clip-path="url(#clip0_5697_119389)">
                        <path id="Vector" d="M13.5949 0.795522C14.4738 -0.0832079 15.9012 -0.0832079 16.7801 0.795522L17.2055 1.22126C18.0844 2.10017 18.0844 3.52611 17.2055 4.40501L9.45703 12.1499C9.16172 12.4488 8.78555 12.6245 8.37422 12.7511L5.18906 13.4859C4.99922 13.5281 4.80234 13.4718 4.66523 13.3347C4.52812 13.1976 4.47187 13.0007 4.51406 12.8109L5.24883 9.62571C5.34375 9.21438 5.55117 8.83821 5.85 8.51126L13.5949 0.795522ZM15.982 1.59111C15.5426 1.15165 14.8324 1.15165 14.393 1.59111L13.4508 2.53119L15.4688 4.54915L16.4074 3.60697C16.8469 3.16857 16.8469 2.4563 16.4074 2.01685L15.982 1.59111ZM6.3457 9.87884L5.81133 12.1886L8.08945 11.6542C8.325 11.6085 8.51484 11.5031 8.6625 11.3238L14.6742 5.34368L12.6562 3.32677L6.64453 9.33743C6.49687 9.48509 6.39141 9.67493 6.3457 9.87884ZM7.3125 2.24994C7.62187 2.24994 7.875 2.50165 7.875 2.81244C7.875 3.12322 7.62187 3.37493 7.3125 3.37493H2.8125C1.88051 3.37493 1.125 4.13079 1.125 5.06243V15.1874C1.125 16.1191 1.88051 16.8749 2.8125 16.8749H12.9375C13.8691 16.8749 14.625 16.1191 14.625 15.1874V10.6874C14.625 10.3781 14.8781 10.1249 15.1875 10.1249C15.4969 10.1249 15.75 10.3781 15.75 10.6874V15.1874C15.75 16.7413 14.4914 17.9999 12.9375 17.9999H2.8125C1.2593 17.9999 0 16.7413 0 15.1874V5.06243C0 3.50923 1.2593 2.24994 2.8125 2.24994H7.3125Z" fill="#B91C1C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5697_119389">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                  Ester Howards
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm  text-gray-600">
                  Not enrolled
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-red-700">
                  [ULN]
                  </td>
                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="pen-to-square" clip-path="url(#clip0_5697_119389)">
                        <path id="Vector" d="M13.5949 0.795522C14.4738 -0.0832079 15.9012 -0.0832079 16.7801 0.795522L17.2055 1.22126C18.0844 2.10017 18.0844 3.52611 17.2055 4.40501L9.45703 12.1499C9.16172 12.4488 8.78555 12.6245 8.37422 12.7511L5.18906 13.4859C4.99922 13.5281 4.80234 13.4718 4.66523 13.3347C4.52812 13.1976 4.47187 13.0007 4.51406 12.8109L5.24883 9.62571C5.34375 9.21438 5.55117 8.83821 5.85 8.51126L13.5949 0.795522ZM15.982 1.59111C15.5426 1.15165 14.8324 1.15165 14.393 1.59111L13.4508 2.53119L15.4688 4.54915L16.4074 3.60697C16.8469 3.16857 16.8469 2.4563 16.4074 2.01685L15.982 1.59111ZM6.3457 9.87884L5.81133 12.1886L8.08945 11.6542C8.325 11.6085 8.51484 11.5031 8.6625 11.3238L14.6742 5.34368L12.6562 3.32677L6.64453 9.33743C6.49687 9.48509 6.39141 9.67493 6.3457 9.87884ZM7.3125 2.24994C7.62187 2.24994 7.875 2.50165 7.875 2.81244C7.875 3.12322 7.62187 3.37493 7.3125 3.37493H2.8125C1.88051 3.37493 1.125 4.13079 1.125 5.06243V15.1874C1.125 16.1191 1.88051 16.8749 2.8125 16.8749H12.9375C13.8691 16.8749 14.625 16.1191 14.625 15.1874V10.6874C14.625 10.3781 14.8781 10.1249 15.1875 10.1249C15.4969 10.1249 15.75 10.3781 15.75 10.6874V15.1874C15.75 16.7413 14.4914 17.9999 12.9375 17.9999H2.8125C1.2593 17.9999 0 16.7413 0 15.1874V5.06243C0 3.50923 1.2593 2.24994 2.8125 2.24994H7.3125Z" fill="#B91C1C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5697_119389">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                  Reese Lindone
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm  text-gray-600">
                  [Course name]
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-red-700">
                  [ULN]
                  </td>
                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                  Matthew Smith
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm  text-gray-600">
                  [Course name]
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-red-700">
                  Duplicate
                  </td>
                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="pen-to-square" clip-path="url(#clip0_5697_119389)">
                        <path id="Vector" d="M13.5949 0.795522C14.4738 -0.0832079 15.9012 -0.0832079 16.7801 0.795522L17.2055 1.22126C18.0844 2.10017 18.0844 3.52611 17.2055 4.40501L9.45703 12.1499C9.16172 12.4488 8.78555 12.6245 8.37422 12.7511L5.18906 13.4859C4.99922 13.5281 4.80234 13.4718 4.66523 13.3347C4.52812 13.1976 4.47187 13.0007 4.51406 12.8109L5.24883 9.62571C5.34375 9.21438 5.55117 8.83821 5.85 8.51126L13.5949 0.795522ZM15.982 1.59111C15.5426 1.15165 14.8324 1.15165 14.393 1.59111L13.4508 2.53119L15.4688 4.54915L16.4074 3.60697C16.8469 3.16857 16.8469 2.4563 16.4074 2.01685L15.982 1.59111ZM6.3457 9.87884L5.81133 12.1886L8.08945 11.6542C8.325 11.6085 8.51484 11.5031 8.6625 11.3238L14.6742 5.34368L12.6562 3.32677L6.64453 9.33743C6.49687 9.48509 6.39141 9.67493 6.3457 9.87884ZM7.3125 2.24994C7.62187 2.24994 7.875 2.50165 7.875 2.81244C7.875 3.12322 7.62187 3.37493 7.3125 3.37493H2.8125C1.88051 3.37493 1.125 4.13079 1.125 5.06243V15.1874C1.125 16.1191 1.88051 16.8749 2.8125 16.8749H12.9375C13.8691 16.8749 14.625 16.1191 14.625 15.1874V10.6874C14.625 10.3781 14.8781 10.1249 15.1875 10.1249C15.4969 10.1249 15.75 10.3781 15.75 10.6874V15.1874C15.75 16.7413 14.4914 17.9999 12.9375 17.9999H2.8125C1.2593 17.9999 0 16.7413 0 15.1874V5.06243C0 3.50923 1.2593 2.24994 2.8125 2.24994H7.3125Z" fill="#B91C1C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5697_119389">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                  Matthew Smith
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm  text-gray-600">
                  [Course name]
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-red-700">
                
                  </td>
                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="pen-to-square" clip-path="url(#clip0_5697_119389)">
                        <path id="Vector" d="M13.5949 0.795522C14.4738 -0.0832079 15.9012 -0.0832079 16.7801 0.795522L17.2055 1.22126C18.0844 2.10017 18.0844 3.52611 17.2055 4.40501L9.45703 12.1499C9.16172 12.4488 8.78555 12.6245 8.37422 12.7511L5.18906 13.4859C4.99922 13.5281 4.80234 13.4718 4.66523 13.3347C4.52812 13.1976 4.47187 13.0007 4.51406 12.8109L5.24883 9.62571C5.34375 9.21438 5.55117 8.83821 5.85 8.51126L13.5949 0.795522ZM15.982 1.59111C15.5426 1.15165 14.8324 1.15165 14.393 1.59111L13.4508 2.53119L15.4688 4.54915L16.4074 3.60697C16.8469 3.16857 16.8469 2.4563 16.4074 2.01685L15.982 1.59111ZM6.3457 9.87884L5.81133 12.1886L8.08945 11.6542C8.325 11.6085 8.51484 11.5031 8.6625 11.3238L14.6742 5.34368L12.6562 3.32677L6.64453 9.33743C6.49687 9.48509 6.39141 9.67493 6.3457 9.87884ZM7.3125 2.24994C7.62187 2.24994 7.875 2.50165 7.875 2.81244C7.875 3.12322 7.62187 3.37493 7.3125 3.37493H2.8125C1.88051 3.37493 1.125 4.13079 1.125 5.06243V15.1874C1.125 16.1191 1.88051 16.8749 2.8125 16.8749H12.9375C13.8691 16.8749 14.625 16.1191 14.625 15.1874V10.6874C14.625 10.3781 14.8781 10.1249 15.1875 10.1249C15.4969 10.1249 15.75 10.3781 15.75 10.6874V15.1874C15.75 16.7413 14.4914 17.9999 12.9375 17.9999H2.8125C1.2593 17.9999 0 16.7413 0 15.1874V5.06243C0 3.50923 1.2593 2.24994 2.8125 2.24994H7.3125Z" fill="#B91C1C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5697_119389">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                   Matthew Smith
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm text-gray-600 ">
                      [Course name]
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-gray-600">
                  
                  </td>
                  <td className="whitespace-nowrap w-3 py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="pen-to-square" clip-path="url(#clip0_5697_119389)">
                        <path id="Vector" d="M13.5949 0.795522C14.4738 -0.0832079 15.9012 -0.0832079 16.7801 0.795522L17.2055 1.22126C18.0844 2.10017 18.0844 3.52611 17.2055 4.40501L9.45703 12.1499C9.16172 12.4488 8.78555 12.6245 8.37422 12.7511L5.18906 13.4859C4.99922 13.5281 4.80234 13.4718 4.66523 13.3347C4.52812 13.1976 4.47187 13.0007 4.51406 12.8109L5.24883 9.62571C5.34375 9.21438 5.55117 8.83821 5.85 8.51126L13.5949 0.795522ZM15.982 1.59111C15.5426 1.15165 14.8324 1.15165 14.393 1.59111L13.4508 2.53119L15.4688 4.54915L16.4074 3.60697C16.8469 3.16857 16.8469 2.4563 16.4074 2.01685L15.982 1.59111ZM6.3457 9.87884L5.81133 12.1886L8.08945 11.6542C8.325 11.6085 8.51484 11.5031 8.6625 11.3238L14.6742 5.34368L12.6562 3.32677L6.64453 9.33743C6.49687 9.48509 6.39141 9.67493 6.3457 9.87884ZM7.3125 2.24994C7.62187 2.24994 7.875 2.50165 7.875 2.81244C7.875 3.12322 7.62187 3.37493 7.3125 3.37493H2.8125C1.88051 3.37493 1.125 4.13079 1.125 5.06243V15.1874C1.125 16.1191 1.88051 16.8749 2.8125 16.8749H12.9375C13.8691 16.8749 14.625 16.1191 14.625 15.1874V10.6874C14.625 10.3781 14.8781 10.1249 15.1875 10.1249C15.4969 10.1249 15.75 10.3781 15.75 10.6874V15.1874C15.75 16.7413 14.4914 17.9999 12.9375 17.9999H2.8125C1.2593 17.9999 0 16.7413 0 15.1874V5.06243C0 3.50923 1.2593 2.24994 2.8125 2.24994H7.3125Z" fill="#B91C1C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5697_119389">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>


                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                   Matthew Smith
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm text-gray-600 ">
                      [Course name]
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-gray-600">
                    [ULN]
                  </td>
                  <td className="whitespace-nowrap w-3 py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="pen-to-square" clip-path="url(#clip0_5697_119389)">
                        <path id="Vector" d="M13.5949 0.795522C14.4738 -0.0832079 15.9012 -0.0832079 16.7801 0.795522L17.2055 1.22126C18.0844 2.10017 18.0844 3.52611 17.2055 4.40501L9.45703 12.1499C9.16172 12.4488 8.78555 12.6245 8.37422 12.7511L5.18906 13.4859C4.99922 13.5281 4.80234 13.4718 4.66523 13.3347C4.52812 13.1976 4.47187 13.0007 4.51406 12.8109L5.24883 9.62571C5.34375 9.21438 5.55117 8.83821 5.85 8.51126L13.5949 0.795522ZM15.982 1.59111C15.5426 1.15165 14.8324 1.15165 14.393 1.59111L13.4508 2.53119L15.4688 4.54915L16.4074 3.60697C16.8469 3.16857 16.8469 2.4563 16.4074 2.01685L15.982 1.59111ZM6.3457 9.87884L5.81133 12.1886L8.08945 11.6542C8.325 11.6085 8.51484 11.5031 8.6625 11.3238L14.6742 5.34368L12.6562 3.32677L6.64453 9.33743C6.49687 9.48509 6.39141 9.67493 6.3457 9.87884ZM7.3125 2.24994C7.62187 2.24994 7.875 2.50165 7.875 2.81244C7.875 3.12322 7.62187 3.37493 7.3125 3.37493H2.8125C1.88051 3.37493 1.125 4.13079 1.125 5.06243V15.1874C1.125 16.1191 1.88051 16.8749 2.8125 16.8749H12.9375C13.8691 16.8749 14.625 16.1191 14.625 15.1874V10.6874C14.625 10.3781 14.8781 10.1249 15.1875 10.1249C15.4969 10.1249 15.75 10.3781 15.75 10.6874V15.1874C15.75 16.7413 14.4914 17.9999 12.9375 17.9999H2.8125C1.2593 17.9999 0 16.7413 0 15.1874V5.06243C0 3.50923 1.2593 2.24994 2.8125 2.24994H7.3125Z" fill="#B91C1C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5697_119389">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>


                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm sm:pl-6 text-gray-900">
                   Matthew Smith
                  </td>
                  <td className="whitespace-nowrap text-left  py-4 px-3 text-sm text-gray-600 ">
                      [Course name]
                  </td>
                  <td className="whitespace-nowrap text-left  px-3 py-4 text-sm text-gray-600">
                    [ULN]
                  </td>
                  <td className="whitespace-nowrap w-3 py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="pen-to-square" clip-path="url(#clip0_5697_119389)">
                        <path id="Vector" d="M13.5949 0.795522C14.4738 -0.0832079 15.9012 -0.0832079 16.7801 0.795522L17.2055 1.22126C18.0844 2.10017 18.0844 3.52611 17.2055 4.40501L9.45703 12.1499C9.16172 12.4488 8.78555 12.6245 8.37422 12.7511L5.18906 13.4859C4.99922 13.5281 4.80234 13.4718 4.66523 13.3347C4.52812 13.1976 4.47187 13.0007 4.51406 12.8109L5.24883 9.62571C5.34375 9.21438 5.55117 8.83821 5.85 8.51126L13.5949 0.795522ZM15.982 1.59111C15.5426 1.15165 14.8324 1.15165 14.393 1.59111L13.4508 2.53119L15.4688 4.54915L16.4074 3.60697C16.8469 3.16857 16.8469 2.4563 16.4074 2.01685L15.982 1.59111ZM6.3457 9.87884L5.81133 12.1886L8.08945 11.6542C8.325 11.6085 8.51484 11.5031 8.6625 11.3238L14.6742 5.34368L12.6562 3.32677L6.64453 9.33743C6.49687 9.48509 6.39141 9.67493 6.3457 9.87884ZM7.3125 2.24994C7.62187 2.24994 7.875 2.50165 7.875 2.81244C7.875 3.12322 7.62187 3.37493 7.3125 3.37493H2.8125C1.88051 3.37493 1.125 4.13079 1.125 5.06243V15.1874C1.125 16.1191 1.88051 16.8749 2.8125 16.8749H12.9375C13.8691 16.8749 14.625 16.1191 14.625 15.1874V10.6874C14.625 10.3781 14.8781 10.1249 15.1875 10.1249C15.4969 10.1249 15.75 10.3781 15.75 10.6874V15.1874C15.75 16.7413 14.4914 17.9999 12.9375 17.9999H2.8125C1.2593 17.9999 0 16.7413 0 15.1874V5.06243C0 3.50923 1.2593 2.24994 2.8125 2.24994H7.3125Z" fill="#B91C1C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5697_119389">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>


                  </td>
                </tr>
              </tbody>
            </table>
          </div>


          <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden ">
              <button
                //   onClick={(e) => changePage(e, "previous")}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
              >
                Previous
              </button>
              <button
                //   onClick={(e) => changePage(e, "next")}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
              >
                Next
              </button>
            </div>

            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700 font-inter-400">
                  Showing
                  <span className=" inline-flex pl-1 pr-1">
                    1
                  </span>
                  to
                  <span className=" inline-flex pl-1 pr-1">
                    10
                  </span>
                  of
                  <span className=" inline-flex pl-1 pr-1">
                    19
                  </span>
                  results
                </p>
              </div>

              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <a
                    href="#"

                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    {/* <!-- Heroicon name: mini/chevron-left --> */}
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>

                  <a
                    // key={page}
                    href="#"
                    // onClick={(e) => changePage(e, page)}
                    className=
                    "relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20"


                  >
                    1
                  </a>

                  <a
                    href="#"

                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </nav>
              </div>
            </div>
          </div>


      
        </div>
      </div>
    </>
  )
}
export default CourseValidation;