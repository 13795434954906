import React, { useEffect, useState } from "react";
import conveyaLogo from "../../../assets/images/conveya-logo.svg";
import viewProfile from "../../../assets/Icons/viewProfile.svg";
import userImage from "../../../assets/images/userImage.jpg";
import conveyaIcon from "../../../assets/images/conveya-c-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import apiUrl from "../../../utils/apiUrl";
import axios from "../../../utils/axios";
import { useForm } from "react-hook-form";
import { FilePicker } from "react-file-picker";
import useAuth from "../../../hooks/useAuth";
import { getFileUrl, uploadFile } from "../../../utils/fileAction";
import { setSession } from "../../../utils/jwt";

export default function SetupAccount() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  let navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem('user') || '')
  const {setUser} = useAuth()
  const { state }: { state: any } = useLocation();
  const [personalDetails, setPersonalDetails] = useState();
  const [accessToken, setAccessToken] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [photoLoading, setPhotoLoading] = useState<boolean>(false);
  const emailRegisterd = user.email;
  const accountId = user.accountId;
  const userId = user.id;
  const [trainingProviderName, setTrainingProviderName] = useState(
    user.trainingProviderName
  );
  const [photoUrl, setPhotoUrl] = useState(viewProfile);
  const [photoKey, setPhotoKey] = useState("");
  console.log(accountId, trainingProviderName);
  const [alias, setAlias] = useState("");
  // console.log("setup account", emailRegisterd, password)

  // const login =async()=>{
  //   const resp=await axios.post(apiUrl.login,{
  //     email:emailRegisterd,
  //     password:password,
  //   })

  //   if(resp.status===200){
  //     setAccessToken(resp.data.access_token);
  //   }

  // }

  const photoSelected = async (file: any) => {
    // login();
    // login(emailRegisterd, password)
    setPhotoLoading(true);
    // console.log("photp selected", file);
    let img = file.target.files[0];
    // let payload: any = {
    //   image_type: "upload_image",
    // };

    // var data = new FormData();
    // data.append("payload", JSON.stringify(payload));
    // data.append("image", img);
    // const resp = await axios.post(
    //   apiUrl.updateProfileImage,
    //   data
    //   // { headers: {"Authorization" : `Bearer ${accessToken}`}}
    // );
    // if (resp.status === 200) {
    //   getProfileData();
    // } else {
    //   setPhotoLoading(false);
    // }
    uploadFile(img)
      .then((key: any) => {
        setPhotoKey(key);
        getFileUrl(key)
          .then((url) => setPhotoUrl(url))
          .finally(() => setPhotoLoading(false));
      })
      .catch(() => setPhotoLoading(false));
  };

  const onSubmit = async (data: any) => {
    // login();
    const resp = await axios.post("/api/register/setup", {
      trainingProviderName,
      alias,
      email: emailRegisterd,
      firstName,
      lastName,
      accountId,
      userId,
      photoUrl: photoKey,
    });

    if (resp.status === 200) {
      const accessToken = resp.data.data.access_token;
      setSession(accessToken);
      const user = resp.data.data.user;
      sessionStorage.setItem("user", JSON.stringify(user));
      setUser(user)
      sessionStorage.setItem('isSetup', `1`)
      navigate("/welcome-trainer", {
        state: { email: emailRegisterd },
      });
    }
  };
  // const getProfileData = async () => {
  //   let res = await axios.get(
  //     apiUrl.viewPersonalProfile
  //     // { headers: {"Authorization" : `Bearer ${accessToken}`}}
  //   );
  //   if (res.status === 200) {
  //     // setPersonalDetails({ ...res.data.Personal });
  //     // setPhoto(res.data?.Personal?.PhotoUrl);
  //     // setFirstName(res.data?.Personal?.FirstName)
  //     // setLastName(res.data?.Personal?.LastName)
  //     setPhotoLoading(false);
  //   }
  // };

  return (
    <>
      <div className="flex min-h-full flex-col registerTrainer justify-center">
        <div className="px-4 sm:px-0 sm:w-full sm:max-w-xs lg:ml-20  xl:ml-60 mx-auto pt-16 md:pt-20">
          <img src={conveyaLogo} className="h-8" alt="Conveya" />
          <h2 className="mt-6 text-3xl font-poppins-700  text-gray-700">
            Set up account...
          </h2>

          <p className="text-sm mt-2 text-gray-600">
            Enter your publicly displayed information to finish the set up.
          </p>

          <form
            className="space-y-6 mt-10"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <label className="block text-sm font-poppins-400 text-gray-700">
                Your profile photo
              </label>

              <div className="relative mt-2 flex items-center gap-3">
                {photoLoading ? (
                  <div className="rounded-full inline-block h-12 w-12 overflow-hidden bg-gray-100 object-cover">
                    <div
                      className="half-ring"
                      style={{
                        marginLeft: 20,
                        marginTop: 20,
                      }}
                    ></div>
                  </div>
                ) : (
                  <img
                    className="rounded-full inline-block h-12 w-12 overflow-hidden bg-gray-100 object-cover"
                    src={photoUrl}
                    alt="user image"
                  />
                )}
                {/* <img src={ photo} className="w-10 h-10 mr-6 rounded-full"/> */}
                {/* <FilePicker
                          extensions={["jpg", "jpeg", "png"]}
                          onChange={photoSelected}
                          onError={(errMsg: any) =>
                            console.log("errMsg", errMsg)
                          }
                        > */}
                {/* <img src={userImage} className="w-10 h-10 mr-6 rounded-full"/> */}
                <label htmlFor="file-upload" style={{ position: "relative" }}>
                  <button
                    type="button"
                    className="text-sm font-poppins-500 text-gray-700 bg-white border border-gray-300 rounded-md  mr-4 btn-white-padding hover:bg-gray-50 focus:outline-none focus:bg-gray-50 ring-lavender-500"
                  >
                    Change
                  </button>
                  <input
                    onChange={(file: any) => photoSelected(file)}
                    accept=".jpg,.png,.jpeg,.heic,.svg"
                    style={{
                      opacity: 0,
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                    }}
                    name="file-upload"
                    id="file-upload"
                    type="file"
                  />
                </label>
              </div>
            </div>
            <div>
              <label
                className={
                  errors.firstName
                    ? "block text-sm font-poppins-400 text-red-700"
                    : "block text-sm font-poppins-400 text-gray-700"
                }
              >
                Training provider name*
              </label>
              {user.trainingProviderName ? (
                <div className="block text-sm">
                  {user.trainingProviderName}
                </div>
              ) : (
                <>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      {...register("trainingProviderName", {
                        required: true,
                      })}
                      type="text"
                      className={
                        errors.trainingProviderName
                          ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-500 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                          : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                      }
                      value={trainingProviderName}
                      autoComplete="off"
                      onChange={(e) =>
                        setTrainingProviderName(e.target.value ?? "")
                      }
                      id="trainingProviderName"
                      placeholder=" Enter your training provider name.."
                    />
                  </div>
                  <p className="mt-2 text-sm text-red-500" id="email-error">
                    <>
                      {errors.trainingProviderName
                        ? "Please provide your training provider name."
                        : ""}
                    </>
                  </p>
                </>
              )}
              {/* </div> */}
            </div>
            <div>
              <label
                className={
                  errors.firstName
                    ? "block text-sm font-poppins-400 text-red-700"
                    : "block text-sm font-poppins-400 text-gray-700"
                }
              >
                Alias *
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  {...register("alias", {
                    required: true,
                  })}
                  type="text"
                  className={
                    errors.alias
                      ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-500 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                      : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                  }
                  value={alias}
                  autoComplete="off"
                  onChange={(e) => setAlias(e.target.value ?? "")}
                  id="alias"
                  placeholder=" Enter your alias.."
                />
              </div>
              <p className="mt-2 text-sm text-red-500" id="email-error">
                <>{errors.alias ? "Please provide your alias." : ""}</>
              </p>
              {/* </div> */}
            </div>
            <div>
              <label
                className={
                  errors.firstName
                    ? "block text-sm font-poppins-400 text-red-700"
                    : "block text-sm font-poppins-400 text-gray-700"
                }
              >
                First name *
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  {...register("firstName", {
                    required: true,
                  })}
                  type="text"
                  className={
                    errors.firstName
                      ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-500 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                      : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                  }
                  value={firstName}
                  autoComplete="off"
                  onChange={(e) => setFirstName(e.target.value ?? "")}
                  id="firstName"
                  placeholder=" Enter your first name.."
                />
              </div>
              <p className="mt-2 text-sm text-red-500" id="email-error">
                <>{errors.firstName ? "Please provide your first name." : ""}</>
              </p>
              {/* </div> */}
            </div>

            <div className="relative mt-1 rounded-md shadow-sm">
              <label
                className={
                  errors.lastName
                    ? "block text-sm font-poppins-400 text-red-700"
                    : "block text-sm font-poppins-400 text-gray-700"
                }
              >
                Last name *
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  {...register("lastName", {
                    required: true,
                  })}
                  type="text"
                  name="lastName"
                  autoComplete="off"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value ?? "")}
                  id="lastName"
                  className={
                    errors.lastName
                      ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-500 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                      : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                  }
                  placeholder="Enter your last name..."
                />
              </div>
              <p className="mt-2 text-sm text-red-500" id="email-error">
                <>{errors.lastName ? "Please provide your last name." : ""}</>
              </p>
              {/* </div> */}
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center btn-lavender"
              >
                Finish set up
              </button>
            </div>
          </form>
        </div>

        <footer className="mt-auto py-4 xl:hidden">
          <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
            <p className="text-base font-poppins-500 text-gray-400 text-center">
              <img src={conveyaIcon} className="inline-block h-5 w-5 -mt-px" />{" "}
              Powered by <span className="text-lavender">Conveya</span> -
              Formally known as Scopesuite
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
