import { ReactNode } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
// import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function LoginGuard({ children }: GuestGuardProps) {
  const {user, isAuthenticated } = useAuth();
  let navigate = useNavigate();
  if (isAuthenticated) {
    console.log(sessionStorage.getItem('isSetup'))
    if(!sessionStorage.getItem('isSetup')){
      
      navigate("/setup-account", {
        state: { email: user?.email },
          });
    }else{
    
    return  <Navigate to={"/a/reports"} />;
  }
}

  return <>{children}</>;
}
