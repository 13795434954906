import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ChevronDownIcon, MagnifyingGlassIcon, ChevronUpIcon, CheckIcon } from "@heroicons/react/24/outline";
import fileUploadImage from "../assets/images/fileUploadImage.jpg"


function SubmitReportModel(props: any) {

    return (


        <Transition.Root show={props.show} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={() => props.toggle(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <svg className="w-6 h-6" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5113 0.197895C17.3214 0.0664805 17.0999 0 16.8749 0C16.6829 0 16.4903 0.0491836 16.3167 0.148465L0.566664 9.11682C0.194395 9.36211 -0.0242244 9.76641 0.00214282 10.1637C0.02851 10.5609 0.296576 10.9673 0.691908 11.1322L4.32003 12.6443L6.49444 17.7947C6.54604 17.9508 6.65151 18 6.7183 18C6.79069 18 6.86142 17.9714 6.9136 17.9212L9.92824 15.0244L14.1611 16.7878C14.312 16.8508 14.4599 16.8747 14.5931 16.8747C14.8257 16.8747 15.0189 16.8011 15.1444 16.7307C15.4465 16.5609 15.6531 16.2628 15.7058 15.9203L17.9558 1.32697C18.0527 0.869062 17.8699 0.442266 17.5113 0.197895ZM1.12495 10.125L14.4878 2.48906L4.3558 11.4715L1.12495 10.125ZM7.0558 16.2527L5.31205 12.1254L13.3804 4.97461L7.42143 13.1695C7.31926 13.3102 7.28794 13.4892 7.33572 13.6561C7.38402 13.8231 7.50601 13.9582 7.66752 14.0221L8.87689 14.5058L7.0558 16.2527ZM14.6495 15.5848L8.75385 13.2448L16.6886 2.33578L14.6495 15.5848Z" fill="#9CA3AF"/>
</svg>
                    
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 font-poppins-500">
                    Submit reports
                    </Dialog.Title>
                  </div>
                </div>

                <div className="text-gray-400 text-center py-2 text-sm">
                Once submitted, you won't be able to make any changes. <br></br>
Are you sure you want to submit this report?
                </div>



                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                   onClick={() => props.submitReports(false)}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-lavender px-4 py-2 text-sm font-poppins-500 text-white shadow-sm hover:bg-lavender focus:outline-none focus:ring-2 sm:col-start-2 sm:text-sm"
                   
                  >
                    Submit reports
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-poppins-500 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => props.toggle(false)}
                   
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>

                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    );
}

export default SubmitReportModel;
