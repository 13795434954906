import nodataimage from '../assets/images/nodataimage.png'
const NoData = (props: any) => {
	return (
		<div className="flex flex-col items-center justify-center py-8 w-full">
			<img src={nodataimage} alt="No Data" />
			<div className="font-poppins-300 text-gray-600 text-xs mt-3">
				{props.data || 'No data'}
			</div>
		</div>
	)
}

export default NoData
