import React from 'react';

function LoadingScreen() {
    return (
        <div>
            Loading...
        </div>
    );
}

export default LoadingScreen;