import React, { useEffect } from 'react'
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { XMarkIcon, ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";


export default function AppSlideOver(props: any) {


    return (

        <>
            {

                <SlidingPane
                    className="some-custom-class"
                    overlayClassName="some-custom-overlay-class"
                    isOpen={props.show}
                    hideHeader={true}
                    title=""
                    subtitle=""
                    onRequestClose={() => {
                        // triggered on "<" on left top click or on outside click
                        props.toggle(false);
                    }}
                >

                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white ">


                        {props.children}



                    </div>
                </SlidingPane>

            }

        </>



    )
}


