import React, { useEffect, useState } from "react";
import conveyaLogo from "../../../assets/images/conveya-logo.svg";
import eyeclosedIcon from "../../../assets/Icons/eyeclosed.svg";
import eyeIcon from "../../../assets/Icons/eyeIcon.svg";
import visibleEyeRed from "../../../assets/Icons/visibleEyeRed.svg";
import eyeopenIcon from "../../../assets/Icons/eyeopen.svg";

import mailIcon from "../../../assets/Icons/mailIcon.svg";
import conveyaIcon from "../../../assets/images/conveya-c-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import correctIconGray from "../../../assets/Icons/correctIconGray.svg";
import correctIconGreen from "../../../assets/Icons/correctIconGreen.svg";
import crossIconRed from "../../../assets/Icons/crossIconRed.svg";
import apiUrl from "../../../utils/apiUrl";
import axios from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { setSession } from "../../../utils/jwt";

export default function RegisterTrainer(props: any) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  let password = watch("new_password");

  const { login, logout, isAuthenticated, isInitialized } = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { state }: { state: any } = useLocation();
  const emailRegisterd = state.email;
  const invitedBy = state.invited_by;
  const token = state.token;
  let navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onSubmit = async (data: any) => {
    if (isAuthenticated) {
      await logout();
      sessionStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
      setSession(null);
      //  navigate

      //  return
    }

    if (
      password.length < 8 ||
      password?.search(/^(?=.*\d)/) === -1 ||
      password?.search(/^(?=.*[a-zA-Z])/) === -1
    ) {
      setError("new_password", {
        type: "min_length_required",
      });
    } else {
      axios
        .post(apiUrl.registerTrainer, {
          token: token,
          email: emailRegisterd,
          password: password,
          type: "email",
        })
        .then((response) => {
          if (response.data.code === 200) {
            login(emailRegisterd, password).then((res) => {
              // setLoading(false);
              // navigate('/a/dashboard');
              navigate("/setup-account");
            });
          }
        });
      // registerTrainer
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col registerTrainer justify-center">
        <div className="px-4 sm:px-0 sm:w-full sm:max-w-xs lg:ml-20  xl:ml-60 mx-auto pt-16 md:pt-20">
          <img src={conveyaLogo} className="h-8" alt="Conveya" />
          <h2 className="mt-6 text-3xl font-poppins-700  text-gray-700">
            Create account...
          </h2>

          <p className="text-sm mt-2 text-gray-600">
            <b>{invitedBy}</b> invited you to create account on Conveya.{" "}
          </p>

          <form
            className="space-y-6 mt-10"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <label className="block text-sm font-poppins-400 text-gray-700">
                Email
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  className="block w-full appearance-none rounded-md border border-gray-300 pl-10 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                  defaultValue={emailRegisterd}
                  disabled
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-index">
                  <img src={mailIcon}></img>
                </div>
              </div>
            </div>

            <div className="relative mt-1 rounded-md shadow-sm">
              <label
                className={
                  errors.new_password
                    ? "block text-sm font-poppins-400 text-red-700"
                    : "block text-sm font-poppins-400 text-gray-700"
                }
              >
                Password
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="new_password"
                  {...register("new_password", { required: true })}
                  autoComplete="off"
                  //  maxLength={20}
                  //  minLength={8}
                  className={
                    errors.new_password
                      ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                      : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                  }
                  placeholder=""
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={togglePasswordVisibility}
                >
                  <img
                    src={
                      passwordVisible
                        ? errors.new_password
                          ? visibleEyeRed
                          : eyeopenIcon
                        : errors.new_password
                        ? eyeIcon
                        : eyeclosedIcon
                    }
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-evenly mb-12">
              {/* <div className="flex justify-center text-gray-600">
                <span className="inline-flex items-center	justify-center mr-1.5">
                  <svg
                    width="10"
                    height="13"
                    viewBox="0 0 18 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.8102 0.194487C18.0633 0.44358 18.0633 0.853379 17.8102 1.10247L6.88221 12.0304C6.63311 12.2835 6.22331 12.2835 5.97422 12.0304L0.188266 6.24504C-0.0627554 5.99595 -0.0627554 5.58615 0.188266 5.33706C0.439127 5.08395 0.846113 5.08395 1.09722 5.33706L6.42821 10.6685L16.9022 0.194487C17.1513 -0.0570166 17.5611 -0.0570166 17.8102 0.194487Z"
                      fill="#9CA3AF"
                    />
                  </svg>
                </span>
                <span className="text-xs">Min 8 characters</span>
              </div>

              <div className="flex justify-center text-gray-600">
                <span className="inline-flex items-center	justify-center mr-1.5">
                  <svg
                    width="10"
                    height="13"
                    viewBox="0 0 18 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.8102 0.194487C18.0633 0.44358 18.0633 0.853379 17.8102 1.10247L6.88221 12.0304C6.63311 12.2835 6.22331 12.2835 5.97422 12.0304L0.188266 6.24504C-0.0627554 5.99595 -0.0627554 5.58615 0.188266 5.33706C0.439127 5.08395 0.846113 5.08395 1.09722 5.33706L6.42821 10.6685L16.9022 0.194487C17.1513 -0.0570166 17.5611 -0.0570166 17.8102 0.194487Z"
                      fill="#9CA3AF"
                    />
                  </svg>
                </span>
                <span className="text-xs">Number(s)</span>
              </div>

              <div className="flex justify-center text-gray-600">
                <span className="inline-flex items-center	justify-center mr-1.5">
                  <svg
                    width="10"
                    height="13"
                    viewBox="0 0 18 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.8102 0.194487C18.0633 0.44358 18.0633 0.853379 17.8102 1.10247L6.88221 12.0304C6.63311 12.2835 6.22331 12.2835 5.97422 12.0304L0.188266 6.24504C-0.0627554 5.99595 -0.0627554 5.58615 0.188266 5.33706C0.439127 5.08395 0.846113 5.08395 1.09722 5.33706L6.42821 10.6685L16.9022 0.194487C17.1513 -0.0570166 17.5611 -0.0570166 17.8102 0.194487Z"
                      fill="#9CA3AF"
                    />
                  </svg>
                </span>
                <span className="text-xs">Letter(s)</span>
              </div> */}
              <div className="flex justify-evenly">
                <div>
                  <img
                    src={
                      password?.length > 7
                        ? correctIconGreen
                        : errors.new_password
                        ? crossIconRed
                        : correctIconGray
                    }
                    className="inline-block mr-1"
                    alt=""
                  />

                  <span
                    className={
                      errors.new_password && password?.length < 8
                        ? "inline-block text-xs font-poppins-400 text-red-600"
                        : "inline-block text-xs font-poppins-400 text-gray-600"
                    }
                  >
                    Min 8 characters
                  </span>
                </div>
                <div>
                  <img
                    src={
                      password?.search(/^(?=.*\d)/) === 0
                        ? correctIconGreen
                        : errors.new_password
                        ? crossIconRed
                        : correctIconGray
                    }
                    className="inline-block mr-1"
                    alt=""
                  />
                  <span
                    className={
                      errors.new_password && password.search(/^(?=.*\d)/) === -1
                        ? "inline-block text-xs font-poppins-400 text-red-600"
                        : "inline-block text-xs font-poppins-400 text-gray-600"
                    }
                  >
                    Number(s)
                  </span>
                </div>
                <div>
                  <img
                    src={
                      password?.search(/^(?=.*[a-zA-Z])/) === 0
                        ? correctIconGreen
                        : errors.new_password
                        ? crossIconRed
                        : correctIconGray
                    }
                    className="inline-block mr-1"
                    alt=""
                  />
                  <span
                    className={
                      errors.new_password &&
                      password.search(/^(?=.*[a-zA-Z])/) === -1
                        ? "inline-block text-xs font-poppins-400 text-red-600"
                        : "inline-block text-xs font-poppins-400 text-gray-600"
                    }
                  >
                    Letter(s)
                  </span>
                </div>
              </div>
            </div>

            <div className="text-center text-xs text-gray-600 pt-7">
              By clicking the "Create password" button, you agree to the Conveya{" "}
              <a
                href="reset-password"
                className="font-poppins-500 text-lavender"
              >
                privacy policy.
              </a>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center btn-lavender"
              >
                Create account
              </button>
            </div>
          </form>
        </div>

        <footer className="mt-auto py-4 xl:hidden">
          <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
            <p className="text-base font-poppins-500 text-gray-400 text-center">
              <img src={conveyaIcon} className="inline-block h-5 w-5 -mt-px" />{" "}
              Powered by <span className="text-lavender">Conveya</span> -
              Formally known as Scopesuite
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
