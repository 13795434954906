import { Fragment, useEffect, useState } from "react";
import AppSlideOver from "./AppSlideOver";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Dialog } from "@headlessui/react";

import calenderIcon from "../assets/Icons/calenderIcon.svg";
import DatePicker from "react-datepicker";
import { each } from "lodash";
import moment from "moment";
import axios from "../utils/axios";

export default function HistoryFilter(props: any) {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [showDate, setShowDate] = useState(false);
  // const [showTrainer, setShowTrainer] = useState(false);
  const [showCourse, setShowCourse] = useState(false);
  const [showClient, setShowClient] = useState(false);
  // const [trainer, setTrainer] = useState<any>([]);
  const [course, setCourse] = useState<any>([]);
  const [client, setClient] = useState<any>([]);
  let removeFromSearch = false;

  useEffect(() => {
    // setTrainer(props?.trainerName);
    setCourse(props?.courseName);
    setClient(props?.clientName);
    setShowClient(false);
    setShowCourse(false);
    setShowDate(false);
  }, [props]);

  const filteroptions = (arr: any, type: any) => {
    let arraytemp: any = [];
    arr.map((each: any) => {
      if (each.selected === true) {
        // if (type === "trainer") {
        //   arraytemp.push(each.id);
        // } else {
        arraytemp.push(each.value);
        // }
      }
    });
    return arraytemp;
  };

  const clearfilteroptions = (arr: any) => {
    let arraytemp: any = [];
    arr.map((each: any) => {
      each.selected = false;
    });
    return [];
  };

  const saveFilter = () => {
    props.toggle(false);
    var fromDate: any = {};
    if (startDate) {
      fromDate = { from_date: moment(startDate).format("YYYY-MM-DD") };
    }
    var toDate: any = {};
    if (endDate) {
      toDate = { to_date: moment(endDate).format("YYYY-MM-DD") };
    }

    let filterArr: any = [
      {
        ...fromDate,
        ...toDate,
        // trainer: filteroptions(trainer, "trainer"),
        clients: filteroptions(client, "client"),
        courses: filteroptions(course, "course"),
      },
    ];
    props.setFilterConfig(filterArr);
  };

  const clearFilter = () => {
    // setTrainer(clearfilteroptions(trainer));
    setCourse(clearfilteroptions(course));
    setClient(clearfilteroptions(client));
    setStartDate("");
    setEndDate("");
    setShowClient(false);
    setShowCourse(false);
    // setShowTrainer(false);
    setShowDate(false);
    props.setFilterConfig([]);
    props.toggle(false);
  };

  const searchOptions = (e: any, item: any) => {
    let options = item === course ? [...course] : [...client];
    options = options.map((option) => {
      if (!option.label.toLowerCase().includes(e.target.value.toLowerCase())) {
        option.removeFromSearch = true;
        return option;
      } else {
        option.removeFromSearch = false;
        return option;
      }
    });

    item === course ? setCourse(options) : setClient(options);
  };

  return (
    <AppSlideOver toggle={props.toggle} show={props.show}>
      <div className="flex min-h-0 flex-1 flex-col py-6 slide-over-input">
        <div className=" px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <div className="text-lg font-inter-500 leading-7 text-gray-900">
              Filter
            </div>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => props.toggle(false)}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <div className=" relative overflow-y-auto mt-6 pb-6 flex-1 px-4 sm:px-6">
          {/* Replace with your content */}
          <form>
            <div>
              <div className="relative flex  items-center py-2">
                <div
                  className="min-w-0 flex-1 text-sm items-center"
                  onClick={() => setShowDate(!showDate)}
                >
                  <label
                    htmlFor=""
                    className="font-poppins-500 text-sm text-gray-700"
                  >
                    Dates
                  </label>
                </div>
                <div
                  className="flex h-5 items-center pr-1"
                  onClick={() => setShowDate(!showDate)}
                >
                  <span className="text-gray-400 hover:text-gray-500">
                    {showDate ? (
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </div>
              </div>

              {showDate && (
                <div className="flex">
                  <div>
                    {/* <label
                      htmlFor="fileName"
                      className="block text-sm font-poppins-400 text-gray-600"
                    >
                      BIL start date *
                    </label> */}

                    <div className="relative mt-1">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-index">
                        <img src={calenderIcon}></img>
                      </div>
                      {/* <input
                                                type="text"
                                                name=""
                                                id=""
                                                value=""
                                                className="block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                                placeholder="DD/MM/YYYY"
                                            /> */}
                      <DatePicker
                        className="block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        calendarStartDay={1}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                  <div className="px-3 pt-8 text-gray-500">-</div>
                  <div>
                    {/* <label
                      htmlFor="fileName"
                      className="block text-sm font-poppins-400 text-gray-600"
                    >
                      BIL return date
                    </label> */}

                    <div className="relative mt-1">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-index">
                        <img src={calenderIcon}></img>
                      </div>
                      {/* <input
                                                type="text"
                                                name=""
                                                id=""
                                                value=""
                                                className="block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                                placeholder="DD/MM/YYYY"
                                            /> */}
                      <DatePicker
                        className="block  pl-10 w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                        selected={endDate}
                        onChange={(date: Date) => setEndDate(date)}
                        calendarStartDay={1}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <div className="relative flex  items-center py-2">
                <div
                  className="min-w-0 flex-1 text-sm items-center"
                  onClick={() => setShowCourse(!showCourse)}
                >
                  <label
                    htmlFor=""
                    className="font-poppins-500 text-sm text-gray-700"
                  >
                    Course
                  </label>
                </div>
                <div
                  className="flex h-5 items-center pr-1"
                  onClick={() => setShowCourse(!showCourse)}
                >
                  <span className="text-gray-400 hover:text-gray-500">
                    {showCourse ? (
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </div>
              </div>
              {showCourse && (
                <div>
                  <div className="flex">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full rounded-md shadow-sm layout-search my-4">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        autoComplete="off"
                        onChange={(e: any) => searchOptions(e, course)}
                        className="block w-full rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search"
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pt-2 pb-4 space-y-4 max-h-40 overflow-y-auto">
                    {course.map((item: any, index: any) => {
                      return (
                        !course[index].removeFromSearch && (
                          <div className="flex  items-center" key={index}>
                            <input
                              id={item.label}
                              aria-describedby=""
                              name={item.label}
                              type="checkbox"
                              checked={item.selected}
                              onChange={() => {
                                let clickedCopy = [...course];
                                clickedCopy[index].selected =
                                  !clickedCopy[index].selected;
                                setCourse([...clickedCopy]);
                              }}
                              className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                            />
                            <label
                              htmlFor=""
                              className="font-poppins-400 text-sm text-gray-600"
                            >
                              {item.label}
                            </label>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div>
              <div className="relative flex  items-center py-2">
                <div
                  className="min-w-0 flex-1 text-sm items-center"
                  onClick={() => setShowClient(!showClient)}
                >
                  <label
                    htmlFor=""
                    className="font-poppins-500 text-sm text-gray-700"
                  >
                    Client
                  </label>
                </div>
                <div
                  className="flex h-5 items-center pr-1"
                  onClick={() => setShowClient(!showClient)}
                >
                  <span className="text-gray-400 hover:text-gray-500">
                    {showClient ? (
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </div>
              </div>
              {showClient && (
                <div>
                  <div className="flex">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full rounded-md shadow-sm layout-search my-4">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        autoComplete="off"
                        onChange={(e: any) => searchOptions(e, client)}
                        className="block w-full rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search"
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pt-2 pb-4 space-y-4 max-h-40 overflow-y-auto">
                    {client.map((item: any, index: any) => {
                      return (
                        !client[index].removeFromSearch && (
                          <div className="flex  items-center" key={index}>
                            <input
                              id={item.label}
                              aria-describedby=""
                              name={item.label}
                              type="checkbox"
                              checked={item.selected}
                              onChange={() => {
                                let clickedCopy = [...client];
                                clickedCopy[index].selected =
                                  !clickedCopy[index].selected;
                                setClient([...clickedCopy]);
                              }}
                              className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                            />
                            <label
                              htmlFor=""
                              className="font-poppins-400 text-sm text-gray-600"
                            >
                              {item.label}
                            </label>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>

        <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
          <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
            onClick={clearFilter}
            // onClick={() => props.toggle(false)}
          >
            Clear all
          </button>
          <button
            onClick={saveFilter}
            className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
          >
            Filter
          </button>
        </div>
      </div>
    </AppSlideOver>
  );
}
