import { Fragment, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import correctIconGray from '../../assets/Icons/correctIconGray.svg'

export default function ApplicantSuccessToast(props: any) {
	const [show, setShow] = useState(true)

	useEffect(() => {
		setShow(props.show)
		setTimeout(() => {
			props.toggle(false)
		}, 3000)
	}, [props.show])

	return props.show ? (
		<>
			{/* Global notification live region, render this permanently at the end of the document */}
			<div
				aria-live="assertive"
				className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
			>
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition
						show={show}
						as={Fragment}
						enter="transform ease-out duration-300 transition"
						enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
						enterTo="translate-y-0 opacity-100 sm:translate-x-0"
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
							<div className="p-4">
								<div className="flex items-start">
									<div className="flex-shrink-0">
										<svg
											width="24"
											height="17"
											viewBox="0 0 24 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M23.7469 0.257363C24.0844 0.589487 24.0844 1.13589 23.7469 1.46801L9.17627 16.0386C8.84415 16.3761 8.29775 16.3761 7.96563 16.0386L0.251022 8.32477C-0.0836739 7.99265 -0.0836739 7.44625 0.251022 7.11412C0.585503 6.77664 1.12815 6.77664 1.46295 7.11412L8.57095 14.2227L22.5362 0.257363C22.8684 -0.0779752 23.4148 -0.0779752 23.7469 0.257363Z"
												fill="#9CA3AF"
											/>
										</svg>
									</div>
									<div className="ml-3 w-0 flex-1 pt-0.5">
										<p className="text-sm font-poppins-500 text-gray-900">
											Applicant status updated.
										</p>
										<p className="text-xs font-poppins-500 text-gray-500">
											{/* {props.name ?? "name"} */}
											{props.message}
										</p>
									</div>
									<div className="ml-4 flex flex-shrink-0">
										<button
											type="button"
											className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={() => {
												props.toggle(false)
											}}
										>
											<span className="sr-only">Close</span>
											<XMarkIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</>
	) : null
}
