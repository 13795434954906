import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  ChevronUpIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import fileUploadImage from "../assets/images/fileUploadImage.jpg";
import OneSchemaImporter from "@oneschema/react";
import apiUrl from "../utils/apiUrl";
import axios from "../utils/axios";
import { error } from "console";
// import axios1 from "axios";

// import useAuth from "../hooks/useAuth";

function FileUploadModel(props: any) {
  // const {user} = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    setIsOpen(props.show);
  }, [props.show]);




  const handleSuccess = async (data: any) => {
    const arrRecords: any = [];
    data?.records.map((each: any) => {
      arrRecords.push(each)
    })


    const Jsondata = {
      columns: data?.columns,
      records: arrRecords

    }
    const str = JSON.stringify(Jsondata)

    // const response = await 
    axios.post(apiUrl.filedropTCR, {
      // client_id: "dfad382d-534a-4990-584d-8970a5bd33108",
      client_id: props?.clientid,
      data: str

    }).then((response)=>{
      
      // props.toggle(false);
      props.toaster(true);
      props.message("File imported successfully");
    }).catch((error)=>{
      
      props.errorToggle(true);
      // props.toggle(true);
      props.message(error.response.data?.detail)
    })
    
  
    // if(response.status===200){      
      
      // props.toggle(false);
      // props.toaster(true);
    // }else  if(response!.status === 401){
    //   console.log("file upload file", response)
    //   props.toggle(false);
    //   props.toaster(true);
    //   // props.message()
    // }
  };


  
  // return (<Transition.Root show={props.show} as={Fragment}>
  //         <Dialog
  //             as="div"
  //             className="relative z-50"
  //             onClose={() => props.toggle(false)}
  //         >
  //             <Transition.Child
  //                 as={Fragment}
  //                 enter="ease-out duration-300"
  //                 enterFrom="opacity-0"
  //                 enterTo="opacity-100"
  //                 leave="ease-in duration-200"
  //                 leaveFrom="opacity-100"
  //                 leaveTo="opacity-0"
  //             >
  //                 <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  //             </Transition.Child>

  //             <div className="fixed inset-0 z-10 overflow-y-auto">
  //                 <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
  //                     <Transition.Child
  //                         as={Fragment}
  //                         enter="ease-out duration-300"
  //                         enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //                         enterTo="opacity-100 translate-y-0 sm:scale-100"
  //                         leave="ease-in duration-200"
  //                         leaveFrom="opacity-100 translate-y-0 sm:scale-100"
  //                         leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //                     >
  //                         <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-5xl m-6">

  //                             <div>
  //                                 <div className="border-b px-5 py-3">
  //                                     <h1 className="font-poppins-600 text-gray-700">
  //                                         <svg className="mr-2 h-5 w-5 inline-block text-gray-700" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  //                                             <path d="M4.78906 6.05885L9.375 2.01713V14.3751C9.375 14.7206 9.65453 15.0001 10 15.0001C10.3455 15.0001 10.625 14.7206 10.625 14.3751V2.01713L15.2109 6.09127C15.3281 6.19947 15.4766 6.25026 15.625 6.25026C15.7971 6.25026 15.9692 6.17948 16.0926 6.0403C16.3221 5.78272 16.2977 5.38719 16.0401 5.15828L10.4151 0.158376C10.1783 -0.052792 9.82172 -0.052792 9.585 0.158376L3.96 5.15828C3.70234 5.38699 3.67812 5.78151 3.90625 6.03932C4.13438 6.29713 4.53516 6.32057 4.78906 6.05885ZM17.5 12.5001H13.125C12.7798 12.5001 12.5 12.7799 12.5 13.1251C12.5 13.4703 12.7798 13.7501 13.125 13.7501H17.5C18.1902 13.7501 18.75 14.3099 18.75 15.0001V17.5C18.75 18.1903 18.1902 18.75 17.5 18.75H2.5C1.80977 18.75 1.25 18.1903 1.25 17.5V15.0001C1.25 14.3099 1.80977 13.7501 2.5 13.7501H6.875C7.21875 13.7501 7.5 13.4689 7.5 13.1251C7.5 12.7814 7.21875 12.5001 6.875 12.5001H2.5C1.11914 12.5001 0 13.6193 0 15.0001V17.5C0 18.8809 1.11914 20 2.5 20H17.5C18.8809 20 20 18.8809 20 17.5V15.0001C20 13.6212 18.8789 12.5001 17.5 12.5001ZM17.1875 16.2501C17.1875 15.7325 16.7676 15.3126 16.25 15.3126C15.7324 15.3126 15.3125 15.7325 15.3125 16.2501C15.3125 16.7676 15.7324 17.1876 16.25 17.1876C16.7676 17.1876 17.1875 16.7696 17.1875 16.2501Z" />
  //                                         </svg>
  //                                         Upload a file</h1>
  //                                 </div>
  //                                 <div className="m-5 flex items-center justify-between text-center p-5 h-80 min-h-full bg-gray-100 border-dashed border-gray-200 border-2">
  //                                     <div className="w-full" onClick={()=>{setIsOpen(true)}}>

  //                                         <img className="mx-auto w-40" src={fileUploadImage} />

  //                                         <h3 className="mt-2 text-sm font-medium text-gray-900">What data do you want to upload?</h3>
  //                                         <p className="mt-1 text-sm text-gray-500">Upload a SCV or Excel file to begin the import process</p>
  //                                     </div>
  //                                 </div>
  //                                 <div className="border-t py-2 text-right">
  //                                     <div className="font-poppins-500 invisible">
  //                                         <button className="btn-white-padding border rounded-md text-gray-700 focus:outine-none mx-2">Previous</button>
  //                                         <button className="btn-lavender focus:outine-none mx-2">Next</button>
  //                                     </div>
  //                                 </div>
  //                             </div>

  //                         </Dialog.Panel>
  //                     </Transition.Child>
  //                 </div>
  //             </div>

  //         </Dialog>
  //     </Transition.Root>
  //)

  return (
    <OneSchemaImporter
      /* managing state from your application */
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      /* required config values */
      clientId={"d1e62117-30bd-4e44-bfb6-bdced1883118"}
      userJwt="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJkMWU2MjExNy0zMGJkLTRlNDQtYmZiNi1iZGNlZDE4ODMxMTgiLCJ1c2VyX2lkIjoiaWFuIn0._R3sjBQN7eFVYqJ5mGI6sveBqKjGrYMMNYConQqEqSw"
      // templateKey={"bt_filedrop_template"}
      templateKey={"Training_Portal_File_Drop"}
      /* optional config values */
      importConfig={{ type: "local", metadataOnly: false }}
      // devMode={process.env.NODE_ENV !== "production"}
      devMode={false}
      className="oneschema-importer"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 99999,
        width: "100vw",
        height: "100vh",
      }}
      inline={true}
      /* handling results */
      onSuccess={(data) => {
        props.toggle(false);
        handleSuccess(data);
      }}

      onCancel={() => {
        props.toggle(false);
        // props.togle(false)
        
      }}
      onError={(message) => {
        
        props.toggle(false);
        }}
    />
  );
}

export default FileUploadModel;
