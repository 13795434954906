import { Fragment, useEffect, useState } from "react";
import AppSlideOver from './AppSlideOver'
import {
    ChevronDownIcon,
    ChevronUpIcon,
    MagnifyingGlassIcon,
    XMarkIcon
} from "@heroicons/react/20/solid";
import { Dialog } from '@headlessui/react';

import calenderIcon from "../assets/Icons/calenderIcon.svg";

import LogoIcon from "../assets/images/logoIcon.svg";
import axios from "../utils/axios";
import apiUrl from "../utils/apiUrl";

export default function AssignCourse(props: any) {

    // const courseName=   ["Course name", "Course name", "Course name"]
    // const clientsName=   ["Client’s name", "Client’s name", "Client’s name"]
    const companyName = ["company name", "company name", "company name"]

    const [courseName, setCourseName] = useState<any>([]);
    const [clientsName, setClientsName] = useState<any>([]);
    const [showCourse, setShowCourse] = useState(false);
    const [showClient, setShowClient] = useState(false);

    const filterArrayClient = (data: any) => {
        let arrayTemp: any = [];

        data.map((each: any) => {
            let temp = {
                account_sfid: each.account_sfid,
                company_name: each.company_name,
                selected: true,
                // //   removeFromSearch:false,
            }
            arrayTemp.push(temp)
        })

        return arrayTemp
    }
    const filterArrayCourse = (data: any) => {
        let arrayTemp: any = [];

        data.map((each: any) => {
            let temp = {
                // course_sfid: each.course_sfid,
                coursename: each.coursename,
                selected: false,
                //   removeFromSearch:false,
            }
            arrayTemp.push(temp)
        })
        return arrayTemp
    }

    const getFilter = async () => {

        const response = await axios.get(apiUrl.trainerFilter)
        setCourseName(filterArrayCourse(response.data.courses))
        setClientsName(filterArrayClient(response.data.clients))


    }

    useEffect(() => {
        getFilter();
    }, [])


    return (


        <AppSlideOver toggle={props.toggle} show={props.show} >


            <div className="flex min-h-0 flex-1 flex-col py-6 slide-over-input">
                <div className=" px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                        <div className="text-lg font-inter-500 leading-7 text-gray-900">
                            Assign course
                            <p className="text-sm text-gray-500 font-inter-400">Assign all learners with the selected criteria.</p>
                        </div>

                        <div className="ml-3 flex h-7 items-center">
                            <button
                                type="button"
                                className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                                onClick={() => props.toggle(false)}
                            >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                </div>

                <div className=" relative overflow-y-auto mt-6 pb-6 flex-1">
                    {/* Replace with your content */}
                    <form >



                        <div className="flex-1">

                            <div className="py-4 border-b border-t border-gray-200 px-4 sm:px-6">
                                <label
                                    htmlFor="fileName"
                                    className="block text-sm font-poppins-400 text-gray-700"
                                >
                                    Course name
                                </label>
                                <div className="mt-1">
                                    <select
                                        id=""
                                        name=""
                                        className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
                                    >
                                        {
                                            courseName?.map((e: any) => {
                                                return (
                                                    <option value="Completed">{e.coursename}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="Active">[Options]</option>
                                        <option value="Completed">[Options]</option>
                                        <option value="Exempt">[Options]</option> */}
                                    </select>
                                </div>
                            </div>

                            <div className="py-4 border-b border-gray-200 px-4 sm:px-6 relative">
                                <label
                                    htmlFor="fileName"
                                    className="block text-sm font-poppins-400 text-gray-700"
                                >
                                    Client
                                </label>
                                <div className="mt-1">
                                    <select
                                        id=""
                                        name=""
                                        // multiple
                                        // multiple="multiple"
                                        className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
                                    >
                                        {
                                            clientsName?.map((e: any) => {
                                                return (<option value="Completed">{e.company_name}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="Active">[Client’s name]</option>
                                        <option value="Completed">[Client’s name]</option>
                                        <option value="Exempt">[Client’s name]</option> */}
                                    </select>
                                    {/* {clientsName.map((item: any, index: any) => {
                      return (     
                                <div className="flex  items-center">
                                <input
                            id={item.company_name}
                            autoComplete="off"
                            aria-describedby=""
                            name={item.company_name}
                            type="checkbox"
                            checked={item.selected}
                            onChange={() => {
                              let clickedCopy = [...clientsName];
                              clickedCopy[index].selected =
                                !clickedCopy[index].selected;
                              setClientsName([...clickedCopy]);
                            }}
                                        className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                                    />
                                    <label htmlFor="" className="font-poppins-400 text-sm text-gray-600">
                                        [Client]
                                        {item.company_name}
                                    </label>
                                </div>)})} */}
                                </div>

                                {/* <div className="w-full relative">
                                <div className="space-y-4 max-h-50 rounded-md overflow-y-auto border p-4 mt-2 pt-2 bg-white absolute w-full">

                                    <p className="font-poppins-500 text-lavender text-sm mt-2 cursor-pointer">Choose all</p>

                                    <div className="flex items-center">
                                        <input
                                            id=""
                                            aria-describedby=""
                                            name=""
                                            type="checkbox"
                                            className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                                        />
                                        <img src={LogoIcon} className="w-5 h-5 mr-2" />
                                        <label htmlFor="" className="font-poppins-400 text-sm text-gray-600">
                                        [company name]
                                        </label>
                                    </div>

                                    <div className="flex items-center">
                                        <input
                                            id=""
                                            aria-describedby=""
                                            name=""
                                            type="checkbox"
                                            className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                                        />
                                        <img src={LogoIcon} className="w-5 h-5 mr-2" />
                                        <label htmlFor="" className="font-poppins-400 text-sm text-gray-600">
                                        [company name]
                                        </label>
                                    </div>

                                    <div className="flex items-center">
                                        <input
                                            id=""
                                            aria-describedby=""
                                            name=""
                                            type="checkbox"
                                            className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                                        />
                                        <img src={LogoIcon} className="w-5 h-5 mr-2" />
                                        <label htmlFor="" className="font-poppins-400 text-sm text-gray-600">
                                        [company name]
                                        </label>
                                    </div>

                                    <div className="flex items-center">
                                        <input
                                            id=""
                                            aria-describedby=""
                                            name=""
                                            type="checkbox"
                                            className="h-4 w-4 mr-2 rounded  border-gray-300 bg-white text-lavender  focus:outline-none"
                                        />
                                        <img src={LogoIcon} className="w-5 h-5 mr-2" />
                                        <label htmlFor="" className="font-poppins-400 text-sm text-gray-600">
                                        [company name]
                                        </label>
                                    </div>
                                    
                                    </div>
                                </div> */}

                            </div>

                        </div>





                    </form>
                </div>


                <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
                    <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
                        // onClick={clearFilter}
                        onClick={() => {
                            props.toggle(false)
                            window.location.reload();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        // onClick={saveFilter}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
                    >
                        Assign course
                    </button>
                </div>


            </div>
        </AppSlideOver>



    )
}


