import { Fragment, useEffect, useState, createContext } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Bars3CenterLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'

import ConveyaLogo from '../../assets/images/conveya-logo.svg'
import ProfileIcon from '../../assets/images/userprofilepicnotification.png'
import MobileIcon from '../../assets/icons/mobilemenu.svg'
import conveyaLogo from './../../assets/images/conveya-c-logo.png'

import '../../pages//styles/DatePicker.scss'

import {
	ChevronUpDownIcon,
	MagnifyingGlassIcon
} from '@heroicons/react/20/solid'

import { Outlet, useLocation, Link, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import axios from '../../utils/axios'
import apiUrl from '../../utils/apiUrl'
import TimeoutModal from '../../components/TimeoutModal'
import DisabledAccount from '../auth/DisabledAccount'
import { getFileUrl } from '../../utils/fileAction'

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export interface lookUpParam {
	courseprogress: any
	functionalskills: any
	flags: any
	completionstatus: any
	withdrawn: any
	additional_tag: any
}
export interface serach {
	searchIn: string
	search: string
}

export const LookUpContext = createContext<lookUpParam | undefined>({
	courseprogress: {},
	functionalskills: {},
	withdrawn: {},
	flags: {},
	completionstatus: {},
	additional_tag: {}
})

export const searchContext = createContext<serach | undefined>({
	searchIn: '',
	search: ''
})
export default function Layout() {
	const { logout, user } = useAuth()
	let navigate = useNavigate()
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [personalDetails, setPersonalDetails] = useState<any>({})
	const [lookUps, setLookUps] = useState<lookUpParam>()
	const location = useLocation()
	const [showTimeout, setShowTimeout] = useState(false)
	const [searchText, setSearchText] = useState<string>('')
	const [layoutSearch, setLayoutSearch] = useState<serach>()
	const [profileImg, setProfileImg] = useState('')
	// const [searchIn, setSearchIn]=useState<serach>()
	// useEffect(() => {
	//   if (user?.first_time) {
	//     navigate("/setup-account", {
	//       state: { email: user?.email },
	//     });
	//   }
	// }, [user?.first_time]);

	useEffect(() => {
		if (!sessionStorage.getItem('isSetup')) {
			navigate('/setup-account')
		}
		getProfileData()
	}, [])

	const toggleTimeout = (value: boolean) => {
		setShowTimeout(value)
	}
	const onLogOut = () => {
		logout()
	}

	useEffect(() => {
		setTimeout(() => {
			toggleTimeout(true)
		}, 1800000)
	})

	const staySignedIn = (value: boolean) => {
		toggleTimeout(false)
		if (value) {
			setTimeout(() => {
				toggleTimeout(true)
			}, 1800000)
			// axios.get(apiUrl.viewPersonalProfile);
		} else {
			logout()
		}
	}

	const hideSlideOver = () => {
		// return;
		let slideoverContainer = document.getElementById('slideover_container')
		slideoverContainer?.classList.remove('translate-x-0')
		slideoverContainer?.classList.add('translate-x-full')
		let root = document.getElementsByTagName('html')[0]
		root.classList.remove('overflow-hidden')
		root.classList.remove('scrollWidth')
		let wrapper: any = document.getElementById('wrapper_for_slideover')
		wrapper.style.display = 'none'
	}

	const getProfileData = async () => {
		let res = await axios.post('/api/profile/user/detail')
		if (res.status === 200) {
			setPersonalDetails({ ...res.data.data })
			getFileUrl(res.data.data.photourl).then((url) => setProfileImg(url))
		}
	}

	const searchOptions = (e: any) => {
		// setLayoutSearch(e)
		if (location.pathname.includes('/a/reports')) {
			setLayoutSearch({ searchIn: 'report', search: e })
		} else if (location.pathname.includes('/a/trainers')) {
			setLayoutSearch({ searchIn: 'trainers', search: e })
		} else if (location.pathname.includes('/a/history')) {
			setLayoutSearch({ searchIn: 'history', search: e })
		} else if (location.pathname.includes('/a/assign-learners')) {
			setLayoutSearch({ searchIn: 'assign', search: e })
		} else if (location.pathname.includes('/a/trainer-pair-learners')) {
			setLayoutSearch({ searchIn: 'trainerPair', search: e })
		} else if (
			location.pathname.includes('/a/history-submitted-individual-course')
		) {
			setLayoutSearch({ searchIn: 'historySubmitted', search: e })
		}
	}

	return (
		<>
			<LookUpContext.Provider value={lookUps}>
				<searchContext.Provider value={layoutSearch}>
					<TimeoutModal
						show={showTimeout}
						toggle={toggleTimeout}
						staySignedIn={staySignedIn}
					/>
					{/* <div id="wrapper_for_slideover" onClick={() => hideSlideOver()} className='absolute inset-0 bg-white opacity-0 w-full h-full z-50'></div> */}
					<div className="flex flex-col h-full">
						<Transition.Root show={sidebarOpen} as={Fragment}>
							<Dialog
								as="div"
								className="relative z-40 lg:hidden"
								onClose={setSidebarOpen}
							>
								<Transition.Child
									as={Fragment}
									enter="transition-opacity ease-linear duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="transition-opacity ease-linear duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
								</Transition.Child>

								<div className="fixed inset-0 z-40 flex">
									<Transition.Child
										as={Fragment}
										enter="transition ease-in-out duration-300 transform"
										enterFrom="-translate-x-full"
										enterTo="translate-x-0"
										leave="transition ease-in-out duration-300 transform"
										leaveFrom="translate-x-0"
										leaveTo="-translate-x-full"
									>
										<Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
											<Transition.Child
												as={Fragment}
												enter="ease-in-out duration-300"
												enterFrom="opacity-0"
												enterTo="opacity-100"
												leave="ease-in-out duration-300"
												leaveFrom="opacity-100"
												leaveTo="opacity-0"
											>
												<div className="absolute top-0 right-0 -mr-12 pt-2">
													<button
														type="button"
														className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none ring-2 ring-inset ring-white border-lavender border-2"
														onClick={() => setSidebarOpen(false)}
													>
														<span className="sr-only">Close sidebar</span>
														<XMarkIcon
															className="h-6 w-6 text-white"
															aria-hidden="true"
														/>
													</button>
												</div>
											</Transition.Child>
											<div className="flex flex-shrink-0 items-center px-4 space-x-3">
												<img
													className="h-8 w-auto"
													src={conveyaLogo}
													alt="Conveya"
													onClick={() => navigate('/a/reports')}
												/>
												<span className="flex items-center justify-start  font-poppins-500 text-xl text-lavender">
													Conveya
												</span>
											</div>
											<div className="mt-5 h-0 flex-1 overflow-y-auto">
												<nav className="px-2">
													<div className="space-y-1">
														<Link
															onClick={() => setSidebarOpen(false)}
															to="/a/reports"
															className="text-gray-600 hover:text-gray-700 hover:bg-gray-50 group flex  items-center px-3 py-2 text-base leading-5  font-poppins-400 rounded-md"
														>
															Reports
														</Link>
														<Link
															onClick={() => setSidebarOpen(false)}
															to="/a/applications"
															className="text-gray-600 hover:text-gray-700 hover:bg-gray-50 group flex  items-center px-3 py-2 text-base leading-5  font-poppins-400 rounded-md"
														>
															Applications(Beta)
														</Link>
														<Link
															onClick={() => setSidebarOpen(false)}
															to="/a/history"
															className="text-gray-600 hover:text-gray-700 hover:bg-gray-50 group flex  items-center px-3 py-2 text-base leading-5  font-poppins-400 rounded-md"
														>
															History
														</Link>

														{/* <Link
                              onClick={() => setSidebarOpen(false)}
                              to="/a/trainers"
                              className="text-gray-600 hover:text-gray-700 hover:bg-gray-50 group flex  items-center px-3 py-2 text-base leading-5  font-poppins-400 rounded-md"
                            >
                              Trainers
                            </Link> */}
													</div>
												</nav>
											</div>
										</Dialog.Panel>
									</Transition.Child>
									<div className="w-14 flex-shrink-0" aria-hidden="true">
										{/* Dummy element to force sidebar to shrink to fit close icon */}
									</div>
								</div>
							</Dialog>
						</Transition.Root>

						{user && !user?.isdeleted && (
							<div className="sticky top-0 z-30">
								<nav className="bg-white shadow">
									<div className="w-full pl-0 sm:pl-0 pr-4 sm:pr-6 lg:px-8">
										{/* <button onClick={() => setInScroll(html.scrollHeight === html.clientHeight)}  >Check Scroll</button> */}
										<div className="relative flex h-16 justify-between">
											{/* <div className="absolute inset-y-0 left-0 flex items-center lg:hidden border-r border-gray-200 "> */}
											<button
												type="button"
												onClick={() => setSidebarOpen(true)}
												className="border-r border-gray-200 px-4 text-gray-500 border-lavender-500 focus:border  lg:hidden"
												aria-controls="mobile-menu"
												aria-expanded="false"
											>
												<span className="sr-only">Open main menu</span>
												<Bars3CenterLeftIcon
													className="h-6 w-6"
													aria-hidden="true"
												/>
											</button>
											{/* </div> */}

											<div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
												<div className="flex flex-1 layout-search lg:hidden">
													<form
														className="flex w-full md:ml-0"
														action="#"
														method="GET"
													>
														<label htmlFor="search-field" className="sr-only">
															Search
														</label>
														<div className="relative w-full text-gray-400 focus-within:text-gray-600">
															<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-6">
																<MagnifyingGlassIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															</div>
															<input
																id="search-field"
																name="search-field"
																autoComplete="off"
																className="block h-full w-full border-transparent py-2 pl-12 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
																placeholder="Search"
																type="text"
																onChange={(e) =>
																	searchOptions(e.target.value ?? '')
																}
															/>
														</div>
													</form>
												</div>

												<div className="flex flex-shrink-0 items-center">
													{/* <img className="block h-8 w-auto lg:hidden" src={ConveyaLogo} alt="" /> */}
													<img
														className="hidden h-8 w-auto lg:block cursor-pointer"
														src={ConveyaLogo}
														alt=""
														onClick={() => navigate('/a/reports')}
													/>
												</div>
												<div className="hidden sm:ml-6 lg:flex sm:space-x-8">
													<Link
														to="/a/reports"
														className={`inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium  ${
															location.pathname.includes('/a/reports') ||
															location.pathname.includes(
																'a/individual-course'
															) ||
															location.pathname.includes('a/pair-learners') ||
															location.pathname.includes(
																'a/submitted-individual-course'
															)
																? 'border-lavender text-gray-900'
																: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
														}`}
													>
														Reports
													</Link>
													<Link
														to="/a/applications"
														className={`inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium  ${
															location.pathname.includes('/a/applications')
																? 'border-lavender text-gray-900'
																: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
														}`}
													>
														Applications(Beta)
													</Link>
													<Link
														to="/a/history"
														className={`inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium  ${
															location.pathname.includes('/a/history') ||
															location.pathname.includes(
																'a/history-submitted-individual-course'
															)
																? 'border-lavender text-gray-900'
																: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
														}`}
													>
														History
													</Link>
													{user?.user_type !== 'TP' ? null : (
														<Link
															to="/a/trainers"
															className={`inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium  ${
																location.pathname.includes('/a/trainers') ||
																location.pathname.includes('a/view-trainer') ||
																location.pathname.includes(
																	'a/trainer-pair-learners'
																) ||
																location.pathname.includes(
																	'a/assign-learners'
																) ||
																location.pathname.includes(
																	'a/trainer-individual-course'
																) ||
																location.pathname.includes(
																	'a/trainer-submitted-individual-course'
																)
																	? 'border-lavender text-gray-900'
																	: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
															}`}
														>
															Trainers
														</Link>
													)}
												</div>
											</div>
											<div className="flex items-center">
												<Menu as="div" className="relative ml-3">
													<div>
														<Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm ring-lavender-500 focus:outline-none  focus:ring-offset-2">
															<button
																type="button"
																className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
																id="user-menu-button"
																aria-expanded="false"
																aria-haspopup="true"
															>
																<span className="sr-only">Open user menu</span>
																{/* <img className="h-8 w-8 rounded-full" src={ProfileIcon} alt="" /> */}
																{/* <span className="h-8 w-8 flex-shrink-0 rounded-full bg-lavender text-white font-medium text-sm inline-flex items-center justify-center p-1">
                                HD
                              </span> */}
																{profileImg ? (
																	<img
																		className="h-8 w-8 rounded-full object-cover"
																		src={profileImg}
																		alt="user image"
																	/>
																) : (
																	<span className="h-8 w-8 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-sm inline-flex items-center justify-center p-1">
																		{personalDetails?.name?.trim()?.split(' ')
																			.length === 2
																			? personalDetails?.name
																					?.split(' ')[0][0]
																					.toUpperCase() +
																			  personalDetails?.name
																					?.split(' ')[1][0]
																					.toUpperCase()
																			: personalDetails?.name
																					?.split(' ')[0][0]
																					.toUpperCase()}
																	</span>
																)}
															</button>
														</Menu.Button>
													</div>
													<Transition
														as={Fragment}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95"
													>
														<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
															<div className="py-1">
																<Menu.Item>
																	{({ active }) => (
																		<Link
																			to="/a/profile"
																			className={classNames(
																				active
																					? 'bg-gray-100 text-gray-900'
																					: 'text-gray-700',
																				'block px-4 py-2 text-sm font-poppins-400'
																			)}
																		>
																			Profile
																		</Link>
																	)}
																</Menu.Item>

																<Menu.Item>
																	{({ active }) => (
																		<Link
																			to=""
																			onClick={(e) => {
																				e.preventDefault()
																				window.open(
																					'https://conveyatraining.helpscoutdocs.com/',
																					'_blank'
																				)
																			}}
																			target="_blank"
																			className={classNames(
																				active
																					? 'bg-gray-100 text-gray-900'
																					: 'text-gray-700',
																				'block px-4 py-2 text-sm font-poppins-400'
																			)}
																		>
																			Help
																		</Link>
																	)}
																</Menu.Item>
															</div>

															<div className="py-1">
																<Menu.Item>
																	{({ active }) => (
																		<Link
																			to="#"
																			className={classNames(
																				active
																					? 'bg-gray-100 text-gray-900'
																					: 'text-gray-700',
																				'block px-4 py-2 text-sm font-poppins-400'
																			)}
																			onClick={onLogOut}
																		>
																			Logout
																		</Link>
																	)}
																</Menu.Item>
															</div>
														</Menu.Items>
													</Transition>
												</Menu>
											</div>
										</div>
									</div>

									<div className="hidden lg:hidden" id="mobile-menu">
										<div className="space-y-1 pt-0 pb-4">
											<Link
												to="/a/reports"
												className="block border-l-4 border-lavender bg-indigo-50 py-2 pl-3 pr-4 text-sm font-medium text-lavender"
											>
												Reports
											</Link>
											<Link
												onClick={() => setSidebarOpen(false)}
												to="/a/applications"
												className="text-gray-600 hover:text-gray-700 hover:bg-gray-50 group flex  items-center px-3 py-2 text-base leading-5  font-poppins-400 rounded-md"
											>
												Applications(Beta)
											</Link>
											<Link
												to="/a/history"
												className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
											>
												History
											</Link>
											{user?.user_type !== 'TP' ? null : (
												<Link
													to="/trainers"
													className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
												>
													Trainers
												</Link>
											)}
										</div>
									</div>
								</nav>
							</div>
						)}

						<>
							{((user === null || user?.isdeleted) && <DisabledAccount />) ||
								(user && !user?.isdeleted && <Outlet />)}
						</>

						{/* Footer on all logged in pages */}
						<footer className="mt-auto">
							<div className="py-6 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
								<p className="text-base font-poppins-500 text-gray-400 text-center">
									<img
										src={conveyaLogo}
										className="inline-block h-5 w-5 -mt-px"
										onClick={() => navigate('/a/reports')}
									/>{' '}
									Powered by{' '}
									<span className="text-lavender">
										<a
											href="https://www.conveya.co/"
											target="_blank"
											rel="noreferrer"
										>
											Conveya.co
										</a>
									</span>{' '}
									- Formally known as Scopesuite
								</p>
							</div>
						</footer>
					</div>
				</searchContext.Provider>
			</LookUpContext.Provider>
		</>
	)
}
