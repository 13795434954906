import axios from "../../utils/axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../../utils/apiUrl";

function VerifyToken(props: any) {
  const [text, setText] = useState("");
  const params = useParams();
  let navigate = useNavigate();
  let url =
    props.type === "email" ? apiUrl.verifyemail : apiUrl.verifynewpassword;
  axios.get(url + "/" + params.token).then(
    (data) => {
      if (data.status === 200) {
        navigate("/create-new-password", {
          state: { email: data.data.email, token: params.token },
        });
      } else {
        setText(
          "Something went wrong or you do not have permission for this work."
        );
        navigate("/link-expired");
      }
    },
    (error) => {
      setText(
        error?.response?.data?.detail
          ? error.response.data.detail
          : "Something went wrong."
      );
      navigate("/link-expired");
    }
  );

  return <div>{text.length ? text : "Loading..."}</div>;
}

export default VerifyToken;
