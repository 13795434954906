import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

import IndividualViewReport from "../../../components/IndividualViewReportSlide";

import axios from "../../../utils/axios";
import apiUrl from "../../../utils/apiUrl";
import ScreenLoader from "../../../utils/screenLoader";
import NoData from "../../../utils/NoData";
import { IReport } from "../reports/IndividualCourse";
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function SubmittedIndividualCourseTrainer() {
  let { accountId, courseId, trainerId } = useParams();

  const [isIndividualReport, setIsIndividualReport] = useState(false);
  const toggleIndividualReport = (v: boolean) => {
    if (v && isIndividualReport) {
      setIsIndividualReport(false);
      setTimeout(() => {
        setIsIndividualReport(true);
      }, 100);
    } else {
      setIsIndividualReport(v);
      if (!v) getAllReports();
    }
  };
  const ragRatingRender = (rag: any) => {
    if (rag === "Green") {
      return (
        <span className="inline-flex rounded-full bg-green-100 px-4 text-xs font-poppins-400  text-green-800">
          Green
        </span>
      );
    } else if (rag === "Red") {
      return (
        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-poppins-400  text-red-800">
          Red
        </span>
      );
    } else {
      return (
        <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-poppins-400  text-yellow-800">
          {rag}
        </span>
      );
    }
  };
  const [show, setShow] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [courseReports, setCourseReports] = useState<IReport[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<any>();
  const searchOptions = (val: string) => {
    setSearchText(val ?? "");
    setCurrentPage(1);
    if (!val) {
      getAllReports();
    } else {
      getAllReports(val);
    }
  };
  const [isIndividualViewReport, setIsIndividualViewReport] = useState(false);
  const [showSubmitReportModel, setSubmitReportModel] = useState(false);
  const toggleSubmitReportModel = (value: boolean) => {
    setSubmitReportModel(value);
  };

  const [showFileUploadModel, setFileUploadModel] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number[]>([]);
  const [completedReports, setCompletedReports] = useState<number>(0);
  const [totalCountOfReports, setTotalCountOfReports] = useState(0);
  const [isNotValidSrc, setIsNotValidSrc] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<IReport>();
  const toggleFileUploadModel = (value: boolean) => {
    setFileUploadModel(value);
  };

  useEffect(() => {
    getAllReports();
  }, []);
  useEffect(() => {
    getAllReports(searchText);
  }, [currentPage]);
  useEffect(() => {
    createPaginationPages();
  }, [totalCountOfReports]);
  const getAllReports = async (search?: string) => {
    setLoading(true);
    let res = await axios.post(
      apiUrl.getIndividualReports,
      {
        clientid: Number(accountId) ?? "",
        courseid: courseId,
        search_learner: search ?? "",
        submit_reports: "True",
        sort_type: "is_update"
      },
      {
        params: {
          skip: search
            ? currentPage > 1
              ? 0
              : currentPage - 1
            : currentPage
            ? currentPage - 1
            : 0,
          limit: 10,
        },
      }
    );
    if (res.status === 200) {
      setClientInfo(res.data[0].client_info);
      setLoading(false);
      setTotalCountOfReports(res.data[0].total);
      setCourseReports(res.data?.[0].items);
      setCompletedReports(res.data?.[0].total_completed);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    createPaginationPages();
  }, [totalCountOfReports]);
  const createPaginationPages = () => {
    let pagesArray = [];
    if (totalCountOfReports > 50) {
      setPages([1, 2, 3, 4, 5]);
    } else {
      for (let i = 1; i <= Math.ceil(totalCountOfReports / 10); i++) {
        pagesArray.push(i);
      }
      setPages(pagesArray);
    }
  };
  const changePage = (e: any, page: number | string) => {
    e.preventDefault();
    if (typeof page === "number") {
      setCurrentPage(page);
    } else {
      if (page === "next") {
        if (currentPage === Math.ceil(totalCountOfReports / 10)) {
          return;
        }
        if (currentPage % 5 == 0) {
          let skip = currentPage / 5;
          let diff = totalCountOfReports - 5 * 10 * skip;
          let pagesArray = [];
          let updatedPages = [];
          if (diff > 50) {
            updatedPages = pages.map((page) => page + 5);
            setPages(updatedPages);
          } else {
            for (let i = 1; i <= Math.ceil(diff / 10); i++) {
              updatedPages.push(i + currentPage);
            }
            setPages(updatedPages);
          }
        }
        setCurrentPage(currentPage + 1);
      } else {
        if (currentPage === 1) {
          return;
        }
        if ((currentPage - 1) % 5 === 0) {
          let updatedPages = [];
          for (let i = currentPage - 5; i < currentPage; i++) {
            updatedPages.push(i);
          }
          setPages(updatedPages);
        }
        setCurrentPage(currentPage - 1);
      }
    }
  };
  const toggleIndividualViewReport = (v: boolean) => {
    if (v && isIndividualViewReport) {
      setIsIndividualViewReport(false);
      setTimeout(() => {
        setIsIndividualViewReport(true);
      }, 100);
    } else {
      setIsIndividualViewReport(v);
    }
  };

  const exportCSV = async () => {
    let response = await axios.post(apiUrl.tcrExportCSV, {
      clientid: Number(accountId) ?? "",
      courseid: Number(courseId),
    });

    if (response.status === 200) {
      // return;

      var universalBOM = "\uFEFF";
      var a = window.document.createElement("a");
      a.setAttribute(
        "href",
        "data:text/csv; charset=utf-8," +
          encodeURIComponent(universalBOM + response.data)
      );
      let name = "content.csv";
      a.setAttribute("download", name);
      window.document.body.appendChild(a);
      a.click();
    }
  };

  return (
    <>
      <IndividualViewReport
        show={isIndividualViewReport}
        toggle={toggleIndividualViewReport}
        selectedReport={selectedReport}
      />

      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex items-center">
          <Link to="/a/trainers" className="flex items-center">
            {/* <ChevronLeftIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            /> */}
            <span className="pl-3 text-gray-500 font-poppins-500">Trainer</span>
          </Link>
          <Link
            //  to="/a/trainers"
            to={`/a/view-trainer/${trainerId}`}
            className="flex items-center"
          >
            <ChevronLeftIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <span className="pl-3 text-gray-500 font-poppins-500">Back</span>
          </Link>
        </div>

        <div className="mb-4 px-4 py-5 flex flex-col sm:flex-row flex-wrap sm:items-center justify-between sm:flex-nowrap  bg-white shadow-base rounded-lg">
          {/* <div>
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img className="h-8 w-8" src={LogoIconBlack} alt="" />
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-medium leading-6 text-gray-700">
                  [campany name]
                </h3>
                <p className="text-sm text-gray-500 mt-1">[course name]</p>
              </div>
            </div>
          </div> */}
          <div>
            <div className="flex items-center">
              <div className="flex-shrink-0">
                {!isNotValidSrc && (
                  <img
                    onError={() => {
                      setIsNotValidSrc(true);
                    }}
                    className="h-8 w-8"
                    src={
                      courseReports[0]?.CompanyLogo || clientInfo?.CompanyLogo
                    }
                    alt=""
                  />
                )}
                {isNotValidSrc && (
                  <span className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-sm inline-flex items-center justify-center p-1">
                    {courseReports[0]?.CompanyName
                      ? courseReports[0]?.CompanyName?.trim()?.split(" ")
                          ?.length > 1
                        ? courseReports[0]?.CompanyName?.split(
                            " "
                          )[0][0].toUpperCase() +
                          courseReports[0]?.CompanyName?.split(" ")[
                            courseReports[0]?.CompanyName?.split(" ").length - 1
                          ][0].toUpperCase()
                        : courseReports[0]?.CompanyName?.split(
                            " "
                          )[0][0].toUpperCase()
                      : clientInfo?.CompanyName?.trim()?.split(" ")?.length > 1
                      ? clientInfo?.CompanyName?.split(
                          " "
                        )[0][0].toUpperCase() +
                        clientInfo?.CompanyName?.split(" ")[
                          clientInfo?.CompanyName?.split(" ").length - 1
                        ][0].toUpperCase()
                      : clientInfo?.CompanyName?.split(" ")[0][0].toUpperCase()}
                  </span>
                )}
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-medium leading-6 text-gray-700">
                  {courseReports[0]?.CompanyName || clientInfo?.CompanyName}
                </h3>
                <p className="text-sm text-gray-500 mt-1">
                  {courseReports[0]?.CourseName || clientInfo?.CourseName}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-shrink-0">
            <button
              type="button"
              className="flex btn-white-padding relative mt-4 sm:mt-0 sm:ml-3 sm:inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={exportCSV}
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M14.4918 4.89453L10.3316 0.734375C9.85895 0.263359 9.22613 0 8.56207 0H2.72223C1.34137 0 0.222229 1.11914 0.222229 2.5L0.222483 17.5C0.222483 18.8809 1.34162 20 2.72248 20H12.7222C14.1031 20 15.2222 18.8809 15.2222 17.5V6.66016C15.2222 5.99609 14.9605 5.36328 14.4918 4.89453ZM8.97223 1.33125C9.14711 1.39242 9.31074 1.48148 9.44567 1.61637L13.6058 5.77652C13.7418 5.91016 13.8316 6.07422 13.8902 6.25H9.59723C9.25348 6.25 8.97223 5.96875 8.97223 5.625V1.33125ZM13.9722 17.5C13.9722 18.1891 13.4113 18.75 12.7222 18.75H2.72223C2.03317 18.75 1.47223 18.1891 1.47223 17.5V2.5C1.47223 1.81094 2.03317 1.25 2.72223 1.25H7.72223V5.625C7.72223 6.66016 8.56207 7.5 9.59723 7.5H13.9722V17.5ZM8.34723 9.375C8.34723 9.03125 8.06598 8.75 7.72223 8.75C7.37848 8.75 7.09723 9.03125 7.09723 9.375V14.1172L5.03863 12.0586C4.91754 11.9375 4.75738 11.875 4.59723 11.875C4.43707 11.875 4.27692 11.9375 4.15582 12.0586C3.91168 12.3027 3.91168 12.6984 4.15582 12.9422L7.28082 16.0672C7.52496 16.3113 7.92067 16.3113 8.16442 16.0672L11.2894 12.9422C11.5336 12.698 11.5336 12.3023 11.2894 12.0586C11.0453 11.8148 10.6496 11.8145 10.4058 12.0586L8.34723 14.1172V9.375Z"
                  fill=""
                />
              </svg>
              <span>CSV export</span>
            </button>
          </div>
        </div>
        <div className="mb-4 bg-white shadow-base rounded-lg">
          <div className="border-b  px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <div className="flex items-center">
                  <div className="ml-0">
                    <h3 className="text-lg font-normal leading-6 text-gray-900">
                      Reports
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                      {completedReports} out of {totalCountOfReports} reports
                      completed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4 flex flex-shrink-0 -mr-4">
                <div className="flex  items-center">
                  <div className="hidden  sm:flex sm:mt-0 sm:ml-2">
                    <label htmlFor="search" className="sr-only">
                      Search learners
                    </label>
                    <div className="relative  rounded-md shadow-sm layout-search">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="block w-72 sm:w-80 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search learners"
                        onChange={(e) => searchOptions(e.target.value ?? "")}
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading && (
            <div
              className={`${
                loading ? "h-24 flex items-center justify-center" : ""
              }`}
            >
              <ScreenLoader />
            </div>
          )}
          {!loading && courseReports?.length > 0 ? (
            <div className="ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="hidden sm:table-cell w-2 py-3.5 pl-3 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6"
                    ></th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="hidden sm:table-cell whitespace-nowrap px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12	"
                    >
                      Course progress
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
                    >
                      RAG
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
                    >
                      %
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell relative py-3.5 pl-3 pr-4 sm:pr-6 w-2/12	"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {courseReports?.map((report) => (
                    <tr
                      className="cursor-pointer relative z-10"
                      onClick={(e) => {
                        e.preventDefault();

                        let obj: any = { ...report };
                        obj.CompletionDate = obj.CompletionDate
                          ? new Date(obj.CompletionDate)
                          : null;
                        obj.BILEndDate = obj.BILReturnDate
                          ? new Date(obj.BILReturnDate)
                          : null;
                        obj.EPAReadyDate = obj.EPAreadyDate
                          ? new Date(obj.EPAreadyDate)
                          : null;
                        obj.LeaverDate = obj.LeaverDate
                          ? new Date(obj.LeaverDate)
                          : null;
                        obj.BILStartDate = obj.BILStartDate
                          ? new Date(obj.BILStartDate)
                          : null;
                        obj.NextVisit = obj.NextVisit
                          ? new Date(obj.NextVisit)
                          : null;
                        setSelectedReport(obj);
                        setTimeout(() => {
                          toggleIndividualViewReport(true);
                        }, 50);
                      }}
                    >
                      <td className=" hidden sm:table-cell whitespace-nowrap py-4 pl-3 pr-3 text-sm text-gray-900 sm:pl-6">
                        {report.isupdate && (
                          <svg
                            className="text-green-400"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.35313 10.3531C7.15938 10.55 6.84062 10.55 6.64687 10.3531L4.64687 8.35312C4.45 8.15937 4.45 7.84062 4.64687 7.64687C4.84062 7.45 5.15938 7.45 5.35313 7.64687L7 9.29375L10.6469 5.64687C10.8406 5.45 11.1594 5.45 11.3531 5.64687C11.55 5.84062 11.55 6.15938 11.3531 6.35313L7.35313 10.3531ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1Z" />
                          </svg>
                        )}
                      </td>

                      <td className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                        <span className="cursor-pointer block font-poppins-500 mb-3 sm:mb-0">
                          {report.LearnerName}
                        </span>

                        <dl className="font-normal sm:hidden">
                          <dd className="mt-1 truncate">
                            <div className="flex items-center">
                              <span className="border-r pr-2">
                                {report.CourseProgress ?? "-"}
                              </span>

                              <span className="mx-2">
                                {report.Rag ? ragRatingRender(report.Rag) : "-"}
                              </span>

                              <span className="border-l pl-2">
                                {report.Percentage
                                  ? `${report.Percentage} % `
                                  : "-"}
                              </span>
                            </div>
                          </dd>
                          <dd className="mt-1 truncate text-lavender font-medium">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleIndividualViewReport(true);
                                let obj: any = { ...report };
                                obj.CompletionDate = obj.CompletionDate
                                  ? new Date(obj.CompletionDate)
                                  : null;
                                obj.BILEndDate = obj.BILReturnDate
                                  ? new Date(obj.BILReturnDate)
                                  : null;
                                obj.EPAReadyDate = obj.EPAreadyDate
                                  ? new Date(obj.EPAreadyDate)
                                  : null;
                                obj.LeaverDate = obj.LeaverDate
                                  ? new Date(obj.LeaverDate)
                                  : null;
                                obj.BILStartDate = obj.BILStartDate
                                  ? new Date(obj.BILStartDate)
                                  : null;
                                obj.NextVisit = obj.NextVisit
                                  ? new Date(obj.NextVisit)
                                  : null;
                                setSelectedReport(obj);
                              }}
                              className="whitespace-nowrap pt-2 text-left text-sm text-lavender font-medium sm:pr-6 justify-end z-40 relative"
                            >
                              View
                            </div>
                          </dd>
                        </dl>
                      </td>

                      <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                        {report.CourseProgress ?? "-"}
                      </td>
                      <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                        {report.Rag ? ragRatingRender(report.Rag) : "-"}
                      </td>

                      <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                        {report.Percentage ? `${report.Percentage} % ` : "-"}
                      </td>

                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleIndividualViewReport(true);
                          let obj: any = { ...report };
                          obj.CompletionDate = obj.CompletionDate
                            ? new Date(obj.CompletionDate)
                            : null;
                          obj.BILEndDate = obj.BILReturnDate
                            ? new Date(obj.BILReturnDate)
                            : null;
                          obj.EPAReadyDate = obj.EPAreadyDate
                            ? new Date(obj.EPAreadyDate)
                            : null;
                          obj.LeaverDate = obj.LeaverDate
                            ? new Date(obj.LeaverDate)
                            : null;
                          obj.BILStartDate = obj.BILStartDate
                            ? new Date(obj.BILStartDate)
                            : null;
                          obj.NextVisit = obj.NextVisit
                            ? new Date(obj.NextVisit)
                            : null;
                          setSelectedReport(obj);
                        }}
                        className="hidden sm:table-cell whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end z-40 relative"
                      >
                        View
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            !loading && (
              <div className="h-54 my-2">
                <NoData />
              </div>
            )
          )}

          <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden ">
              <button
                onClick={(e) => changePage(e, "previous")}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
              >
                Previous
              </button>
              <button
                onClick={(e) => changePage(e, "next")}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700 font-inter-400">
                  Showing
                  <span className=" inline-flex pl-1 pr-1">
                    {10 * currentPage - 9}
                  </span>
                  to
                  <span className=" inline-flex pl-1 pr-1">
                    {10 * currentPage > totalCountOfReports
                      ? totalCountOfReports
                      : 10 * currentPage}
                  </span>
                  of
                  <span className=" inline-flex pl-1 pr-1">
                    {totalCountOfReports}
                  </span>
                  results
                </p>
              </div>
              {totalCountOfReports > 10 && (
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <a
                      href="#"
                      onClick={(e) => changePage(e, "previous")}
                      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Previous</span>
                      {/* <!-- Heroicon name: mini/chevron-left --> */}
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                    {pages.map((page) => (
                      <a
                        key={page}
                        href="#"
                        onClick={(e) => changePage(e, page)}
                        className={
                          currentPage === page
                            ? "relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20"
                            : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                        }
                      >
                        {page}
                      </a>
                    ))}
                    <a
                      href="#"
                      onClick={(e) => changePage(e, "next")}
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Next</span>
                      {/* <!-- Heroicon name: mini/chevron-right --> */}
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </nav>
                </div>
              )}
            </div>
          </div>

          <div></div>
        </div>
        {/* <div className="mb-4 bg-white shadow-base rounded-lg">
          <div className="border-b  px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <div className="flex items-center">
                  <div className="ml-0">
                    <h3 className="text-lg font-normal leading-6 text-gray-900">
                      Reports
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                      [x] out of [y] reports completed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4 flex flex-shrink-0 -mr-4">
                <div className="flex  items-center">
                  <div className="hidden  sm:flex sm:mt-0 sm:ml-2">
                    <label htmlFor="search" className="sr-only">
                      Search learners
                    </label>
                    <div className="relative  rounded-md shadow-sm layout-search">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="block w-72 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search learners"
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ring-1 ring-black ring-opacity-5">
            <table className="min-w-full divide-y">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="hidden sm:table-cell whitespace-nowrap px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12	"
                  >
                    Course progress
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
                  >
                    RAG
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
                  >
                    %
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell relative py-3.5 pl-3 pr-4 sm:pr-6 w-2/12	"
                  >
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white">
                <tr
                  className="cursor-pointer"
                  onClick={() => toggleIndividualViewReport(true)}
                >
                  <td className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                    [Full name]
                    <dl className="font-normal sm:hidden">
                      <dd className="mt-1 truncate">
                        <div className="flex items-center">
                          <span className="border-r pr-2">Ongoing </span>
                          <span className="text-red-800 bg-red-100 px-3 py-0.5 rounded-full mx-2">
                            Red
                          </span>
                          <span className="border-l pl-2">[%]% </span>
                        </div>
                      </dd>
                      <dd className="mt-1 truncate text-lavender font-medium">
                        View
                      </dd>
                    </dl>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    Ongoing
                  </td>
                  <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                    <span className="text-red-800 bg-red-100 px-3 py-0.5 rounded-full">
                      Red
                    </span>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    100%
                  </td>
                  <td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    View
                  </td>
                </tr>

                <tr
                  className="cursor-pointer"
                  onClick={() => toggleIndividualViewReport(true)}
                >
                  <td className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                    [Full name]
                    <dl className="font-normal sm:hidden">
                      <dd className="mt-1 truncate">
                        <div className="flex items-center">
                          <span className="border-r pr-2">Ongoing </span>
                          <span className="text-yellow-800 bg-yellow-100 px-3 py-0.5 rounded-full mx-2">
                            Amber
                          </span>
                          <span className="border-l pl-2">[%]% </span>
                        </div>
                      </dd>
                      <dd className="mt-1 truncate text-lavender font-medium">
                        View
                      </dd>
                    </dl>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    Ongoing
                  </td>
                  <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                    <span className="text-yellow-800 bg-yellow-100 px-3 py-0.5 rounded-full">
                      Amber
                    </span>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    100%
                  </td>
                  <td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    View
                  </td>
                </tr>

                <tr
                  className="cursor-pointer"
                  onClick={() => toggleIndividualViewReport(true)}
                >
                  <td className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                    [Full name]
                    <dl className="font-normal sm:hidden">
                      <dd className="mt-1 truncate">
                        <div className="flex items-center">
                          <span className="border-r pr-2">Ongoing </span>
                          <span className="text-yellow-800 bg-yellow-100 px-3 py-0.5 rounded-full mx-2">
                            Amber
                          </span>
                          <span className="border-l pl-2">[%]% </span>
                        </div>
                      </dd>
                      <dd className="mt-1 truncate text-lavender font-medium">
                        View
                      </dd>
                    </dl>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    Ongoing
                  </td>
                  <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                    <span className="text-yellow-800 bg-yellow-100 px-3 py-0.5 rounded-full">
                      Amber
                    </span>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    100%
                  </td>
                  <td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    View
                  </td>
                </tr>

                <tr
                  className="cursor-pointer"
                  onClick={() => toggleIndividualViewReport(true)}
                >
                  <td className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                    [Full name]
                    <dl className="font-normal sm:hidden">
                      <dd className="mt-1 truncate">
                        <div className="flex items-center">
                          <span className="border-r pr-2">Ongoing </span>
                          <span className="text-yellow-800 bg-yellow-100 px-3 py-0.5 rounded-full mx-2">
                            Amber
                          </span>
                          <span className="border-l pl-2">[%]% </span>
                        </div>
                      </dd>
                      <dd className="mt-1 truncate text-lavender font-medium">
                        View
                      </dd>
                    </dl>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    Ongoing
                  </td>
                  <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                    <span className="text-yellow-800 bg-yellow-100 px-3 py-0.5 rounded-full">
                      Amber
                    </span>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    100%
                  </td>
                  <td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    View
                  </td>
                </tr>

                <tr
                  className="cursor-pointer"
                  onClick={() => toggleIndividualViewReport(true)}
                >
                  <td className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                    [Full name]
                    <dl className="font-normal sm:hidden">
                      <dd className="mt-1 truncate">
                        <div className="flex items-center">
                          <span className="border-r pr-2">Ongoing </span>
                          <span className="text-yellow-800 bg-yellow-100 px-3 py-0.5 rounded-full mx-2">
                            Amber
                          </span>
                          <span className="border-l pl-2">[%]% </span>
                        </div>
                      </dd>
                      <dd className="mt-1 truncate text-lavender font-medium">
                        View
                      </dd>
                    </dl>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    Ongoing
                  </td>
                  <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                    <span className="text-yellow-800 bg-yellow-100 px-3 py-0.5 rounded-full">
                      Amber
                    </span>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    100%
                  </td>
                  <td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    View
                  </td>
                </tr>

                <tr
                  className="cursor-pointer"
                  onClick={() => toggleIndividualViewReport(true)}
                >
                  <td className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                    [Full name]
                    <dl className="font-normal sm:hidden">
                      <dd className="mt-1 truncate">
                        <div className="flex items-center">
                          <span className="border-r pr-2">Ongoing </span>
                          <span className="text-green-800 bg-green-100 px-3 py-0.5 rounded-full mx-2">
                            Green
                          </span>
                          <span className="border-l pl-2">[%]% </span>
                        </div>
                      </dd>
                      <dd className="mt-1 truncate text-lavender font-medium">
                        View
                      </dd>
                    </dl>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    Ongoing
                  </td>
                  <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                    <span className="text-green-800 bg-green-100 px-3 py-0.5 rounded-full">
                      Green
                    </span>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    100%
                  </td>
                  <td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    View
                  </td>
                </tr>

                <tr
                  className="cursor-pointer"
                  onClick={() => toggleIndividualViewReport(true)}
                >
                  <td className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                    [Full name]
                    <dl className="font-normal sm:hidden">
                      <dd className="mt-1 truncate">
                        <div className="flex items-center">
                          <span className="border-r pr-2">Ongoing </span>
                          <span className="text-red-800 bg-red-100 px-3 py-0.5 rounded-full mx-2">
                            Red
                          </span>
                          <span className="border-l pl-2">[%]% </span>
                        </div>
                      </dd>
                      <dd className="mt-1 truncate text-lavender font-medium">
                        View
                      </dd>
                    </dl>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    Ongoing
                  </td>
                  <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                    <span className="text-red-800 bg-red-100 px-3 py-0.5 rounded-full">
                      Red
                    </span>
                  </td>

                  <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                    100%
                  </td>
                  <td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                    View
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <a
                href="#"
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </a>
              <a
                href="#"
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </a>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700 font-inter-400">
                  Showing
                  <span className=" inline-flex pl-1 pr-1">
                    {10 * currentPage - 9}
                  </span>
                  to
                  <span className=" inline-flex pl-1 pr-1">
                    {10 * currentPage > totalCountOfReports
                      ? totalCountOfReports
                      : 10 * currentPage}
                  </span>
                  of
                  <span className=" inline-flex pl-1 pr-1">
                    {totalCountOfReports}
                  </span>
                  results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <a
                    href="#"
                    onClick={(e) => changePage(e, "previous")}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                  {pages.map((page) => (
                    <a
                      key={page}
                      href="#"
                      onClick={(e) => changePage(e, page)}
                      className={
                        currentPage === page
                          ? "relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20"
                          : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                      }
                    >
                      {page}
                    </a>
                  ))}
                  <a
                    href="#"
                    onClick={(e) => changePage(e, "next")}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </nav>
              </div>
            </div>
          </div>
          <div></div>
        </div> */}
      </div>
    </>
  );
}

export default SubmittedIndividualCourseTrainer;
