import { Fragment, useEffect, useState } from "react";
import AppSlideOver from './AppSlideOver'
import {
    ChevronDownIcon,
    ChevronUpIcon,
    MagnifyingGlassIcon,
    XMarkIcon
} from "@heroicons/react/20/solid";
import { Dialog } from '@headlessui/react';

import calenderIcon from "../assets/Icons/calenderIcon.svg";
import apiUrl from "../utils/apiUrl";
import axios from "../utils/axios";
import { useForm } from "react-hook-form";



export default function ResetPassword(props: any) {
const [oldPassword, setOldPassword]=useState<string>("")
const [newPassword, setNewPassword]=useState<string>("")
const [confirmNewPassword, setConfirmNewPassword]=useState<string>("")
const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const resetPassword = () => {
    props.toggle(false);
    setTimeout(() => {
      props.resetPassword();
    }, 500);
  };

  const onSubmit = (data: any) => {
    if (newPassword !== confirmNewPassword) {
      setError("retypePassword", {
        type: "password_not_matched",
        message: "The password does not match. Try again",
      });
    } else {
      let req = {
        oldPassword:oldPassword,
        password: newPassword,
        confirmedPassword: confirmNewPassword,
      };
      axios.post('/api/reset/changepwd', req).then(
        (res) => {
          if (res.status === 200) {
            props.toggle(false);
          }
        },
        (err) => {
          if (err.response.status === 403) {
            setError("oldPassword", {
              type: "wrong_password",
              message: "The old password is incorrect.",
            });
          }
        }
      );
    }
  };

    return (


        <AppSlideOver toggle={props.toggle} show={props.show} >


            <div className="flex min-h-0 flex-1 flex-col py-6 slide-over-input">
                <div className=" px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                        <div className="text-lg font-inter-500 leading-7 text-gray-900">
                        Reset Password
                        </div>

                        <div className="ml-3 flex h-7 items-center">
                            <button
                                type="button"
                                className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                                onClick={() => props.toggle(false)}
                            >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                </div>

                <div className=" relative overflow-y-auto mt-6 pb-6 flex-1">
                    {/* Replace with your content */}
                    <form 
                    id="reset_password"
                    onSubmit={handleSubmit(onSubmit)}
                    >



                        <div className="flex-1">

                            <div className="py-4 border-b border-t border-gray-200 px-4 sm:px-6">
                                <label
                                    htmlFor="fileName"
                                    className={
                                        errors.oldPassword
                                          ? "block text-sm font-poppins-400 text-red-700"
                                          : "block text-sm font-poppins-400 text-gray-700"
                                      }
                                    // "block text-sm font-poppins-400 text-gray-700"
                                >
                                  Old password
                                </label>
                                <div className="mt-1">
                                    <input
                                        // type="text"
                                        // name="fileName"
                                        type="password"
                                        {...register("oldPassword", {
                                          required: true,
                                          minLength: 8,
                                        })}
                                        value={oldPassword}
                                        onChange={(e)=>{setOldPassword(e.target.value)}}
                                        id="fileName"
                                        className={
                                            errors.oldPassword
                                              ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-700 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-red-700 focus:shadow-sm sm:text-sm"
                                              : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                          }
                                        // "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-500 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                        placeholder=""
                                    />
                                    
                                </div>
                                <p
                              className="mt-2 text-sm text-red-700"
                              id="email-error"
                            >
                              <>
                                {errors.oldPassword?.type == "required"
                                  ? "Required field"
                                  : ""}
                                {errors.oldPassword?.type == "wrong_password"
                                  ? errors.oldPassword.message
                                  : ""}
                              </>
                            </p>
                            </div>
                            

                            <div className="py-4 border-b border-gray-200 px-4 sm:px-6">
                                <label
                                    htmlFor="fileName"
                                    className={
                                        errors.newPassword
                                          ? "block text-sm font-poppins-400 text-red-700"
                                          : "block text-sm font-poppins-400 text-gray-700"
                                      }
                                    // className="block text-sm font-poppins-400 text-gray-700"
                                >
                                    New password
                                </label>
                                <div className="mt-1">
                                    <input
                                        // type="text"
                                        // name="fileName"
                                        type="password"
                                        {...register("newPassword", {
                                          required: true,
                                          minLength: 8,
                                        })}
                                        id="newPassword"
                                        value={newPassword}
                                        onChange={(e)=>{setNewPassword(e.target.value)}}
                                        // id="fileName"
                                        // className="block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-500 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                        className={
                                            errors.newPassword
                                              ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-700 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-red-700 focus:shadow-sm sm:text-sm"
                                              : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                          }
                                        placeholder=""
                                    />
                                </div>
                                <p
                              className="mt-2 text-sm text-red-700"
                              id="email-error"
                            >
                              <>
                                {errors.newPassword?.type == "required"
                                  ? "Required field"
                                  : ""}
                                {/* {errors.password?.type == "wrong_password" ? errors.password.message : ""} */}
                              </>
                            </p>
                            </div>

                            <div className="py-4 border-b border-gray-200 px-4 sm:px-6">
                                <label
                                    htmlFor="fileName"
                                    className={
                                        errors.retypePassword
                                          ? "block text-sm font-poppins-400 text-red-700"
                                          : "block text-sm font-poppins-400 text-gray-700"
                                      }
                                >
                                    Confirm new password
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="password"
                                        {...register("retypePassword", {
                                          required: true,
                                          minLength: 8,
                                        })}
                                        id="retypePassword"
                                         value={confirmNewPassword}
                                        onChange={(e)=>{setConfirmNewPassword(e.target.value)}}
                                        // id="fileName"
                                        // className="block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-500 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                        className={
                                            errors.retypePassword
                                              ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-700 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-red-700 focus:shadow-sm sm:text-sm"
                                              : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                                          }
                                        placeholder=""
                                    />
                                     <p
                              className="mt-2 text-sm text-red-700"
                              id="email-error"
                            >
                              <>
                                {errors.retypePassword?.type == "required"
                                  ? "Required field"
                                  : ""}
                                {errors.retypePassword?.type ==
                                "password_not_matched"
                                  ? errors.retypePassword.message
                                  : ""}
                              </>
                            </p>
                                </div>

                               
                            </div>

                        </div>





                    </form>
                </div>


                <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
                    <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
                    // onClick={clearFilter}
                    onClick={() => {
                        props.toggle(false);
                        setTimeout(() => {
                          reset();
                        }, 500);
                      }} >
                        Cancel
                    </button>
                    <button
                        // onClick={saveFilter}
                        type="submit"
                        form="reset_password"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
                    >
                       Reset password
                    </button>
                </div>


            </div>
        </AppSlideOver>



    )
}


