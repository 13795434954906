import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../utils/axios";

export default function VerifyEmailByToken(props: any) {
  const {token} = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    axios.get('/api/register/verifyemail/'+token).then((response) => {
      if (response.data.code === 200) {
        navigate("/login");
      } else {
        navigate('/link-expired')
      }
    })
    .catch(({ response }) => {
      if(response.data.code === 403) {
        navigate("/login");
      } else {
        navigate('/link-expired')
      }
    });
  }, [])

  return (
    <>
      <div className="flex min-h-full justify-center">
        <div className="flex-1 flex items-center justify-center">
          <div className="half-ring"></div>
        </div>
      </div>
    </>
  );
}
