import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  XMarkIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  ChevronUpIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import fileUploadImage from "../assets/images/fileUploadImage.jpg";
function RejectModal(props: any) {
  useEffect(() => {
    setReason("");
    setError(false);
  }, [props.show]);
  const [error, setError] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => props.toggle(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="48" height="48" rx="24" fill="#FEE2E2" />
                      <path
                        d="M29.8228 29.8241C29.5884 30.0586 29.209 30.0586 28.9743 29.8241L23.9999 24.848L19.0243 29.8241C18.7899 30.0586 18.4106 30.0586 18.1758 29.8241C17.9414 29.5897 17.9414 29.2103 18.1758 28.9755L23.1525 24.0006L18.1766 19.0245C17.9421 18.79 17.9421 18.4106 18.1766 18.1759C18.411 17.9414 18.7904 17.9414 19.0251 18.1759L23.9999 23.1531L28.9755 18.177C29.2099 17.9425 29.5892 17.9425 29.824 18.177C30.0584 18.4115 30.0584 18.7908 29.824 19.0256L24.8473 24.0006L29.8228 28.9767C30.0591 29.2092 30.0591 29.5916 29.8228 29.8241Z"
                        fill="#EF4444"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 font-poppins-500"
                    >
                      Reject applicant(s)
                    </Dialog.Title>
                  </div>
                </div>

                <div className="text-gray-400 text-center py-2 text-sm">
                  Please select reason for rejection. Once you proceed, this
                  action is final.
                </div>
                <Menu as="div" className="hidden md:block relative">
                  <Menu.Button
                    className={
                      error
                        ? "bg-white relative w-full overflow-hidden text-ellipsis whitespace-nowrap items-center flex border px-2 py-2   shadow-base rounded-lg  focus:outline-none border-red-700"
                        : "bg-white relative w-full  overflow-hidden text-ellipsis whitespace-nowrap items-center flex border px-2 py-2   shadow-base rounded-lg  focus:outline-none"
                    }
                  >
                    <span
                      className={
                        reason ? "" : error ? "text-red-700" : "text-gray-400"
                      }
                    >
                      {reason ? reason : "Select rejection reason..."}
                    </span>

                    <div className="flex-1 justify-end flex">
                      <svg
                        width="16"
                        height="10"
                        className="justify-end flex items-end text-right"
                        viewBox="0 0 16 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.7585 2.10823L8.52697 9.03863C8.24857 9.30184 7.83109 9.30184 7.55246 9.03863L0.240435 2.10823C-0.057737 1.78096 -0.0818271 1.33818 0.186346 1.02054C0.47179 0.688456 0.931775 0.693269 1.21495 0.962785L7.99972 7.43116L14.7858 0.962785C15.0856 0.679938 15.5445 0.704771 15.814 1.01996C16.0813 1.33818 16.0585 1.78096 15.7585 2.10823Z"
                          fill={error ? "rgb(185 28 28)" : "#9CA3AF"}
                        />
                      </svg>
                    </div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-20 mt-2 w-full origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 items-center text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                setReason("Does not meet eligibility criteria");
                                setError(false);
                                // setSortType('sort desc')
                              }}
                            >
                              Does not meet eligibility criteria
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 items-center text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                setReason("Job role mismatch requirements");
                                setError(false);
                                // setSortType('sort desc')
                              }}
                            >
                              Job role mismatch requirements
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 items-center text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                setReason(
                                  "Incomplete or inaccurate application"
                                );
                                setError(false);
                                // setSortType('sort desc')
                              }}
                            >
                              Incomplete or inaccurate application
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 items-center text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                setReason("Apprenticeship capacity reached");
                                setError(false);
                                // setSortType('sort desc')
                              }}
                            >
                              Apprenticeship capacity reached
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 items-center text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                setReason(
                                  "Failure to pass initial assessment tests"
                                );
                                setError(false);
                                // setSortType('sort desc')
                              }}
                            >
                              Failure to pass initial assessment tests
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 items-center text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                setReason("Lack of relevant job experience");
                                setError(false);
                                // setSortType('sort desc')
                              }}
                            >
                              Lack of relevant job experience
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 items-center text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                setReason(
                                  "Ineligibility for external funding or subsidies"
                                );
                                setError(false);
                                // setSortType('sort desc')
                              }}
                            >
                              Ineligibility for external funding or subsidies
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 items-center text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                setReason(
                                  "Insufficient English, maths, or ICT skills"
                                );
                                setError(false);
                                // setSortType('sort desc')
                              }}
                            >
                              Insufficient English, maths, or ICT skills
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className="hover:bg-gray-100 items-center text-gray-900 flex px-4 py-2 text-sm font-poppins-400"
                              href="#"
                              onClick={(e) => {
                                setReason("No contact / unresponsive");
                                setError(false);
                                // setSortType('sort desc')
                              }}
                            >
                              No contact / unresponsive
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    onClick={() => {
                      if (!reason) {
                        setError(true);
                        return;
                      }
                      props.submitReports(reason);
                    }}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-lavender px-4 py-2 text-sm font-poppins-500 text-white shadow-sm hover:bg-lavender focus:outline-none focus:ring-2 sm:col-start-2 sm:text-sm"
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-poppins-500 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => props.toggle(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default RejectModal;
