import { Fragment, useEffect, useState } from "react";

import {
  Outlet,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  MagnifyingGlassIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import editIconGray from "../../../assets/Icons/editIconGray.svg";
import uploadIcon from "../../../assets/Icons/uploadIcon.svg";
import apiUrl from "../../../utils/apiUrl";
import axios from "../../../utils/axios";
import TrainerProfileEditslide from "../../../components/TrainerProfileEditslide";
import ErrorToaster from "../../../components/tosters/ErrorToasters";
import PasswordUpdatedToaster from "../../../components/tosters/PasswordUpdatedToster";

// import PersonalProfileEdit from "../../../components/PersonalProfileEditSlide";
// import userImage from "../../../assets/images/userImage.jpg";
// import LogoIconBlack from "../../../assets/Icons/LogoIconBlack.svg";
// import LogoIcon from "../../../assets/images/logoIcon.svg";
// import profileImage from "../../../assets/images/Person-Image.png";
// import { FilePicker } from "react-file-picker";
// import { mapKeys, camelCase } from "lodash";
// import ResetPassword from "../../../components/ResetPasswordSlide";

// interface ITrainer {
//   tp_sfid: string;
//   name: string;
//   email: string;
//   status: string;
//   photo: string;
//   photoUrl: string;
//   firstName: string;
//   lastName: string;
//   trainingProviderName: string;
//   isNotValidSrc: boolean;
// }

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

let toastMessage = "";

function EditProfile() {
  const [isPersonalProfileEdit, setIsPersonalProfileEdit] = useState(false);
  const [editToaster, setEditToaster] = useState(false);
  const [trainer, setTrainer] = useState<any>([]);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [photoLoading, setPhotoLoading] = useState<boolean>(false);
  const { trainerId } = useParams();
  
  const togglePersonalProfileEdit = (v: boolean) => {
    if (v && isPersonalProfileEdit) {
      setIsPersonalProfileEdit(false);
      setTimeout(() => {
        setIsPersonalProfileEdit(true);
      }, 100);
    } else {
      setIsPersonalProfileEdit(v);
    }
  };

  const toggleErrorToast = (v: boolean) => {
    if (v && showErrorToast) {
      setShowErrorToast(false);
      setTimeout(() => {
        setShowErrorToast(true);
      }, 3000);
    } else {
      setShowErrorToast(v);
    }
  };

  const resetPassword = (msg: string) => {
    toastMessage = msg;
    setShowToaster(true);

    setTimeout(() => {
      setShowToaster(false);
      getProfileData();
      window.location.reload();
    }, 3000);
  };

  const getProfileData = () => {
    axios
      .post(apiUrl.viewTrainerProfile, {
        trainer_id: trainerId,
      })
      .then(
        (data) => {
          // data.data.isNotValidSrc = false;
          // setTrainer(
          //   // mapKeys(data.data, (v, k) => camelCase(k)) as unknown as ITrainer
          // );
          setTrainer(data.data)
        },
        (error) => { }
      );
  };
  useEffect(() => {
    getProfileData();
  }, []);
  const photoSelected = (file: any, trainer: any) => {
    setPhotoLoading(true);
    let img = file.target.files[0];
    let payload: any = {
      trainer_id: trainer.id,
    };
    var data = new FormData();
    data.append("payload", JSON.stringify(payload));
    data.append("image", img);

    axios.post(apiUrl.updateTrainerProfileImage, data).then(
      (data) => {
        getProfileData();
        resetPassword("The image is updated successfully");
      },
      (error) => {
        setPhotoLoading(false);
        toggleErrorToast(true);
        setTimeout(() => {
          toggleErrorToast(false);
        }, 3500);
      }
    );
  };
  // const photoDeleted = (trainer: ITrainer) => {
  const photoDeleted = (trainer: any) => {
    axios
      .delete(apiUrl.deleteTrainerProfileImage, {
        data: {
          trainer_id: trainer.id,
        },
      })
      .then(
        (data) => {
          getProfileData();
        },
        (error) => { }
      );
  };

  const trainerProfile = () => {
    setEditToaster(true);
    getProfileData();
    setTimeout(() => {
      setEditToaster(false);
    }, 2000);
  };
  return (
    <>
      <TrainerProfileEditslide
        show={isPersonalProfileEdit}
        personalDetails={
          trainer
          //   mapKeys(trainer, (v, k) => {
          //     return k.charAt(0).toUpperCase() + k.slice(1);
          //   }) as unknown as ITrainer
        }
        toggle={togglePersonalProfileEdit}
        personalProfile={trainerProfile}
        url={apiUrl.updateTrainerProfile}
      />
      <PasswordUpdatedToaster show={showToaster} message={toastMessage} />
      <ErrorToaster
        headerMsg="Incompatible file"
        msg="This extension is not supported"
        show={showErrorToast}
        toggle={toggleErrorToast}
      />

      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="hidden py-6 lg:flex items-center font-poppins-500 text-gray-500">
          <Link to="/a/trainers" className="flex items-center">
            <span className="pr-3">Trainer</span>
            <ChevronRightIcon
              className="h-5 w-5 flex-shrink-0"
              aria-hidden="true"
            />
            <span className="pr-3">{trainer?.trainer_name}</span>
          </Link>

          <ChevronRightIcon
            className="h-5 w-5 flex-shrink-0"
            aria-hidden="true"
          />
          <span className="pr-3">Profile</span>
        </div>

        <div className="lg:hidden py-6 flex items-center text-gray-500">
          <Link to="/a/trainers/" className="flex items-center">
            <ChevronLeftIcon
              className="h-5 w-5 flex-shrink-0"
              aria-hidden="true"
            />
            <span className="pl-3 font-poppins-500">Back</span>
          </Link>
        </div>

        <div className="mb-4 px-4 py-5 sm:flex  items-center justify-between  bg-white shadow-base rounded-lg">
          <div>
            <div className="flex items-top sm:items-center">
              <div className="flex-shrink-0">
                {trainer.profile_info?.photo_url && (
                  <img
                    className="h-12 w-12 rounded-full object-cover"
                    src={trainer.profile_info?.photo_url}
                    alt=""
                  />
                )}
                {!trainer.profile_info?.photo_url && trainer.trainer_name && (
                  <span className="h-16 w-16 flex-shrink-0 rounded-full bg-lavender text-white font-medium text-2xl inline-flex items-center justify-center p-1">
                    {/* HD */}
                    {trainer.trainer_name?.trim()?.split(" ")?.length > 1
                      ? trainer.trainer_name?.split(" ")[0][0].toUpperCase() +
                        trainer.trainer_name
                          ?.split(" ")
                          [
                            trainer.trainer_name?.split(" ").length - 1
                          ][0].toUpperCase()
                      : trainer.trainer_name?.split(" ")[0][0].toUpperCase()}
                  </span>
                )}
                {!trainer.trainer_name && (
                  <div className="flex-shrink-0">
                    <svg
                      className="w-10 h-10 text-lavender"
                      viewBox="0 0 18 18"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.00002 0.899902C4.52604 0.899902 0.900024 4.52592 0.900024 8.9999C0.900024 13.4739 4.52604 17.0999 9.00002 17.0999C13.474 17.0999 17.1 13.4739 17.1 8.9999C17.1 4.52592 13.474 0.899902 9.00002 0.899902ZM9.00002 16.0874C7.50532 16.0874 6.11946 15.6198 4.97534 14.8268C5.10506 13.2682 6.39917 12.0374 7.98752 12.0374H10.0125C11.6025 12.0374 12.8963 13.2695 13.025 14.8268C11.8793 15.6191 10.4935 16.0874 9.00002 16.0874ZM13.9265 14.0846C13.4803 12.3317 11.9015 11.0249 10.0125 11.0249H7.98752C6.09763 11.0249 4.51971 12.3317 4.07358 14.0852C2.74372 12.7683 1.91252 10.9933 1.91252 8.9999C1.91252 5.09229 5.09241 1.9124 9.00002 1.9124C12.9076 1.9124 16.0875 5.09229 16.0875 8.9999C16.0875 10.9933 15.2554 12.7683 13.9265 14.0846ZM9.00002 4.9499C7.60151 4.9499 6.46877 6.08264 6.46877 7.48115C6.46877 8.87967 7.60151 10.0124 9.00002 10.0124C10.3985 10.0124 11.5313 8.87904 11.5313 7.48115C11.5313 6.08264 10.3985 4.9499 9.00002 4.9499ZM9.00002 8.9999C8.16155 8.9999 7.48127 8.31963 7.48127 7.48115C7.48127 6.64268 8.16155 5.9624 9.00002 5.9624C9.8385 5.9624 10.5188 6.64363 10.5188 7.48115C10.5188 8.31868 9.8385 8.9999 9.00002 8.9999Z" />
                    </svg>
                  </div>
                )}
              </div>

              <div className="ml-5">
                <div>
                  <h1 className="text-2xl  leading-7 font-poppins-600 text-gray-900 block sm:inline-flex sm:truncate sm:leading-9 sm:text-3xl">
                    {trainer?.trainer_name}
                  </h1>
                  <h1 className="inline-flex mt-2.5 sm:mt-0 sm:ml-2 self-end rounded-full font-poppins-400 bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 ">
                    {trainer?.active === true ? "Registered" : "Invited"}
                  </h1>
                </div>

                <div className="hidden mt-2 sm:flex flex-col sm:mt-1 sm:flex-row sm:flex-wrap  sm:items-center">
                  <div className="mt-2 flex items-center text-sm text-gray-500 font-poppins-400">
                    Trainer
                  </div>

                  <div
                    aria-hidden="true"
                    className="hidden font-poppins-500 mt-2 sm:flex sm:items-center h-3 w-0.5 bg-gray-500 sm:ml-6 sm:mr-6"
                  ></div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 font-poppins-400">
                    <svg
                      width="19"
                      height="14"
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                      viewBox="0 0 19 14"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16.25 0H2.75C1.50723 0 0.5 1.00723 0.5 2.25V11.25C0.5 12.4928 1.50723 13.5 2.75 13.5H16.25C17.4928 13.5 18.5 12.4928 18.5 11.25V2.25C18.5 1.00723 17.491 0 16.25 0ZM2.75 1.125H16.25C16.8702 1.125 17.375 1.62984 17.375 2.25V3.51598L10.5125 8.66285C9.91484 9.11004 9.0841 9.11004 8.48645 8.66285L1.625 3.51562V2.25C1.625 1.63125 2.12984 1.125 2.75 1.125ZM17.375 11.25C17.375 11.8702 16.8702 12.375 16.25 12.375H2.75C2.12984 12.375 1.625 11.8702 1.625 11.25V4.89023L7.8125 9.5625C8.30891 9.93551 8.90445 10.1257 9.5 10.1257C10.0955 10.1257 10.6918 9.93516 11.1875 9.5625L17.375 4.89023V11.25Z" />
                    </svg>
                    {trainer?.email}
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:hidden mt-4 sm:mt-2 flex items-center text-sm text-gray-500 font-poppins-400">
              <svg
                width="19"
                height="14"
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                viewBox="0 0 19 14"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16.25 0H2.75C1.50723 0 0.5 1.00723 0.5 2.25V11.25C0.5 12.4928 1.50723 13.5 2.75 13.5H16.25C17.4928 13.5 18.5 12.4928 18.5 11.25V2.25C18.5 1.00723 17.491 0 16.25 0ZM2.75 1.125H16.25C16.8702 1.125 17.375 1.62984 17.375 2.25V3.51598L10.5125 8.66285C9.91484 9.11004 9.0841 9.11004 8.48645 8.66285L1.625 3.51562V2.25C1.625 1.63125 2.12984 1.125 2.75 1.125ZM17.375 11.25C17.375 11.8702 16.8702 12.375 16.25 12.375H2.75C2.12984 12.375 1.625 11.8702 1.625 11.25V4.89023L7.8125 9.5625C8.30891 9.93551 8.90445 10.1257 9.5 10.1257C10.0955 10.1257 10.6918 9.93516 11.1875 9.5625L17.375 4.89023V11.25Z" />
              </svg>
              {trainer?.email}
            </div>
          </div>
        </div>

        {trainer && (
          <div className=" pb-4 ">
            <div className=" bg-white shadow-base rounded-lg">
              <div className="border-b border-gray-200 px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-lg  font-inter-500 text-gray-900 leading-6 ">
                      Personal
                    </h3>
                    <p className=" text-sm font-poppins-400 text-gray-500 ">
                      Publicly displayed information.
                    </p>
                  </div>

                  <div
                    onClick={() => togglePersonalProfileEdit(true)}
                    className="flex-shrink-0 cursor-pointer"
                  >
                    <img src={editIconGray} alt="Edit" />
                  </div>
                </div>
              </div>
              <div className="px-4 py-4 sm:px-6">
                <div>
                  <label className="block text-sm font-poppins-400 text-gray-700">
                    Photo
                  </label>
                  <div className="mt-1 flex items-center space-x-5">
                    {trainer.profile_info?.photo_url ? (
                      photoLoading ? (
                        <div className="rounded-full inline-block h-12 w-12 overflow-hidden bg-gray-100 object-cover">
                          <div
                            className="half-ring"
                            style={{
                              marginLeft: 20,
                              marginTop: 20,
                            }}
                          ></div>
                        </div>
                      ) : (
                        <img
                          className="rounded-full inline-block h-12 w-12 overflow-hidden bg-gray-100 object-cover"
                          src={trainer.profile_info?.photo_url}
                          alt="user image"
                        />
                      )
                    ) : (
                      <span className="h-12 w-12 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-sm inline-flex items-center justify-center p-1">
                        {trainer.trainer_name?.trim().split(" ")?.length > 1
                          ? trainer.trainer_name
                              ?.trim()
                              .split(" ")[0][0]
                              .toUpperCase() +
                            trainer.trainer_name
                              ?.trim()
                              .split(" ")
                              [
                                trainer.trainer_name?.trim().split(" ").length -
                                  1
                              ][0].toUpperCase()
                          : trainer.trainer_name
                              ?.trim()
                              .split(" ")[0][0]
                              .toUpperCase()}
                      </span>
                    )}
                    <button
                      className="text-sm font-poppins-500 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm  mr-4 btn-white-padding hover:bg-gray-50 focus:outline-none focus:bg-gray-50 ring-lavender-500"
                      onClick={() => photoDeleted(trainer)}
                    >
                      Delete
                    </button>
                    <label htmlFor="file-upload">
                      <button className="text-sm font-poppins-500 text-white   rounded-md shadow-sm  btn-lavender pointer-events-none">
                        <img
                          src={uploadIcon}
                          alt="upload"
                          className="inline-block mr-2"
                        />
                        Update
                      </button>
                      <input
                        onChange={(file: any) => photoSelected(file, trainer)}
                        accept=".jpg,.png,.jpeg,.heic,.svg"
                        style={{ display: "none" }}
                        name="file-upload"
                        id="file-upload"
                        type="file"
                      />
                    </label>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="basis-full sm:basis-1/2 pt-4">
                    <p className="text-sm font-poppins-400 text-gray-500 font-normal">
                      Name
                    </p>
                    <h3 className="text-base font-poppins-500 text-gray-700 font-medium pt-1">
                      {trainer?.trainer_name ?? "-"}
                    </h3>
                  </div>
                  <div className="basis-full sm:basis-1/2 pt-4">
                    <p className="text-sm font-poppins-400 text-gray-500 font-normal">
                      Email
                    </p>
                    <h3 className="text-base font-poppins-500 text-gray-700 font-medium pt-1">
                      {trainer?.email}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default EditProfile;
