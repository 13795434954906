import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../../utils/apiUrl";
import axios from "../../utils/axios";

function VerifyTrainer(props: any) {
  const [text, setText] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [invitedBy, setInvitedBy] = useState("");

  const params = useParams();
  let navigate = useNavigate();
  let url = apiUrl.verifyTrainer;
  axios
    .post(url, {
      token: params.token,
    })
    .then(
      ({ data }) => {
        console.log(data);
        setRegisterEmail(data?.data?.email);
        setInvitedBy(data?.data?.inviteBy);
        if (data.data?.verified === true) {
          if (props.type === "email") {
            navigate("/register-trainer", {
              state: {
                email: data.data.email,
                token: params.token,
                invited_by: data.data.inviteBy,
                accountId: data.data.accountId,
                trainingProviderName: data.data.trainingProviderName,
              },
            });
          } else {
            navigate("/register-by-link", {
              state: {
                email: data.data.email,
                token: params.token,
                invited_by: data.data.inviteBy,
                accountId: data.data.accountId,
                trainingProviderName: data.data.trainingProviderName,
              },
            });
          }
        } else {
          setText("The link you tried to open has expired");
          navigate("/link-expired");
        }
        //   axios1.post('https://dev-api-conveyatcr.herokuapp.com'+ apiUrl.tcrVerifyEmail,
        //     {
        //       email:  data.data.email,
        //       type: "register_user",

        //     }).then(
        //       (data)=>{
        //         console.log("data verfiy",data)
        //         if(data.data.email_exists){
        //           navigate("/", {
        //                 state: { email: registerEmail, token: params.token,  },
        //               });

        //         }else {
        //           navigate("/register-trainer", {
        //             state: { email: registerEmail, token: params.token, invited_by :invitedBy },
        //               });

        //         }

        //       }
        //     )

        // }
        // // if (data.status === 200) {
        // //   navigate("/", {
        // //     state: { email: data.data.email, token: params.token },
        // //   });
        // // }
        // else {
        //   setText(
        //     "Something went wrong or you do not have permission for this work."
        //   );
        //   navigate("/link-expired");
        // }
      },
      (error) => {
        setText(
          error?.response?.data?.detail
            ? error.response.data.detail
            : "Something went wrong."
        );
        navigate("/link-expired");
      }
    );

  return <div>{text.length ? text : "Loading..."}</div>;
  // return null;
}

export default VerifyTrainer;
