import React, { useState } from "react";
import backarrow from "./../../assets/Icons/backarrow.svg";
import eyeclosedIcon from "../../assets/Icons/eyeclosed.svg";
import eyeopenIcon from "../../assets/Icons/eyeopen.svg";
import conveyaLogo from "./../../assets/images/conveya-c-logo.png";
import "../styles/Login.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import apiUrl from "../../utils/apiUrl";
import conveyaLongLogo from './../../assets/images/conveyaLongLogo.png';
import correctIconGray from "../../assets/Icons/correctIconGray.svg";
import useAuth from "../../hooks/useAuth";
import eyeIcon from "../../assets/Icons/eyeIcon.svg";
import visibleEyeRed from "../../assets/Icons/visibleEyeRed.svg";
function NewPassword() {
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  let navigate = useNavigate();
  const {user,setUser} = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  const onSubmit = (data: any) => {
    let obj={...data}
    obj.userid=user?.user_id;
    obj.email= user?.user_email;
    setLoading(true);
    axios.put(apiUrl.createNewPassword, obj).then(
      (res) => {
        setUser({})
        if (res.status === 200) {
          navigate("/login", { state: { email: data.email } });
        }
        setLoading(false);
      },
      (error) => {
        setUser(user)
        setLoading(false);
        if (error.response.status === 401) {
          setError("email", {
            type: "no_email_present",
            message:
              "Sorry, we couldn't locate a registered account with this email address. ",
          });
        } else if (error.response.status === 409) {
          setError("email", {
            type: "existing_email",
            message: "This email appears to be already claimed. ",
          });
        } else {
          setError("email", {
            type: "generic",
            message: "Sorry, you cannot reset password for this email.",
          });
        }
      }
    );
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
      <div className="flex min-h-full flex-col  py-12 sm:px-6 lg:px-8 auth-pages">
        <div className="px-4 sm:px-0 sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-8 w-auto"
            src={conveyaLongLogo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-inter-800 text-gray-900">
          New password
          </h2>
        
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <label
                  htmlFor="new_password"
                  className={
                    errors.new_password
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
             Password
                </label>
                {/* <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none  sm:text-sm"
                    />
                  </div> */}
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    maxLength={20}
                    id="new_password"
                  
                    {...register("new_password", { required: true })}
                    autoComplete="current-password"
                    className={
                      errors.new_password
                        ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                        : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                    }
                    placeholder=""
                  />
                  <div
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <img
                      src={passwordVisible ? (errors.new_password?visibleEyeRed: eyeopenIcon) : (errors.new_password?eyeIcon:eyeclosedIcon)}
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                      alt=""
                    />
                  </div>
                </div>
                <p className="mt-2 text-sm text-red-700" id="email-error">
                  <>
                    {errors.new_password?.type == "required"
                      ? "Required field"
                      : ""}
                    {errors.new_password?.type == "wrong_password"
                      ? errors.new_password.message
                      : ""}
                    {errors.new_password?.type == "wrong_password" && (
                      <a href="reset-password" className="text-lavender pr-1">
                        Reset password
                      </a>
                    )}
                  </>
                </p>
              </div>
            
               <div>
                <label
                  htmlFor="passwordConfirm"
                  className={
                    errors.passwordConfirm
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
             Confirm password
                </label>
                {/* <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none  sm:text-sm"
                    />
                  </div> */}
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    maxLength={20}
                    id="passwordConfirm"
                    {...register("passwordConfirm", { required: true })}
                    autoComplete="current-password"
                    className={
                      errors.passwordConfirm
                        ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                        : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                    }
                    placeholder=""
                  />
                  <div
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <img
                      src={passwordVisible ? (errors.passwordConfirm?visibleEyeRed: eyeopenIcon) : (errors.passwordConfirm?eyeIcon:eyeclosedIcon)}
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                      alt=""
                    />
                  </div>
                </div>
                <p className="mt-2 text-sm text-red-700" id="email-error">
                  <>
                    {errors.passwordConfirm?.type == "required"
                      ? "Required field"
                      : ""}
                    {errors.passwordConfirm?.type == "wrong_password"
                      ? errors.passwordConfirm.message
                      : ""}
                    {errors.passwordConfirm?.type == "wrong_password" && (
                      <a href="reset-password" className="text-lavender pr-1">
                        Reset password
                      </a>
                    )}
                  </>
                </p>
              </div>
              <div className="flex justify-evenly">
                <div>
                  <img src={correctIconGray} className="inline-block mr-1" alt=""/>
                  <span className="inline-block text-xs font-poppins-400 text-gray-600">
                    Min 8 characters
                  </span>
                </div>
                <div>
                  <img src={correctIconGray} className="inline-block mr-1"  alt=""/>
                  <span className="inline-block text-xs font-poppins-400 text-gray-600">
                    Number(s)
                  </span>
                </div>
                <div>
                  <img src={correctIconGray} className="inline-block mr-1" alt=""/>
                  <span className="inline-block text-xs font-poppins-400 text-gray-600">
                    Letter(s)
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-center pt-2">
                <div className="text-sm flex items-center">
                  <a href="login" className="font-poppins-500 text-lavender">
                    <img
                      src={backarrow}
                      alt="Back to log in"
                      className="inline-block mr-1 align-baseline"
                    />{" "}
                    Back to log in
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center btn-lavender "
                  style={{ opacity: loading ? 0.4 : 1 }}
                  disabled={loading ? true : false}
                >
           Set password
                </button>
              </div>

              {/* <div className="border-solid border-gray-300 border-b pt-1"></div> */}
              
            </form>
          </div>
        </div>
        <footer className="mt-auto">
          <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
            <p className="text-base font-poppins-500 text-gray-400 text-center">
            
            <img src={conveyaLogo} className="inline-block h-5 w-5 -mt-px" alt=""/>  Powered by <span className="text-lavender">Conveya</span> - Formally known as Scopesuite
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default NewPassword;
