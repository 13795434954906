import { Fragment, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import correctIconGray from '../../assets/Icons/correctIconGray.svg'

export default function SuccsessToaster(props: any) {
	const [show, setShow] = useState(true)

	useEffect(() => {
		setShow(props.show)
		setTimeout(() => {
			props.toggle(false)
		}, 3000)
	}, [props.show])

	return props.show ? (
		<>
			{/* Global notification live region, render this permanently at the end of the document */}
			<div
				aria-live="assertive"
				className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
			>
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition
						show={show}
						as={Fragment}
						enter="transform ease-out duration-300 transition"
						enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
						enterTo="translate-y-0 opacity-100 sm:translate-x-0"
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
							<div className="p-4">
								<div className="flex items-start">
									<div className="flex-shrink-0">
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g id="Tick">
												<path
													id="Vector"
													d="M11.0297 15.5297C10.7391 15.825 10.2609 15.825 9.97031 15.5297L6.97031 12.5297C6.675 12.2391 6.675 11.7609 6.97031 11.4703C7.26094 11.175 7.73906 11.175 8.02969 11.4703L10.5 13.9406L15.9703 8.47031C16.2609 8.175 16.7391 8.175 17.0297 8.47031C17.325 8.76094 17.325 9.23906 17.0297 9.52969L11.0297 15.5297ZM24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12ZM12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5Z"
													fill="#34D399"
												/>
											</g>
										</svg>
									</div>
									<div className="ml-3 w-0 flex-1 pt-0.5">
										<p className="text-sm font-poppins-500 text-gray-900">
											{props.message ?? 'Submitted reports successfully'}
										</p>
										<p className="text-xs font-poppins-500 text-gray-900">
											{/* {props.name ?? "name"} */}
										</p>
									</div>
									<div className="ml-4 flex flex-shrink-0">
										<button
											type="button"
											className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={() => {
												props.toggle(false)
											}}
										>
											<span className="sr-only">Close</span>
											<XMarkIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</>
	) : null
}
