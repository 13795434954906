import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import moment from "moment";
import {
  Outlet,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";

import LogoIconBlack from "../../../assets/Icons/LogoIconBlack.svg";
import WarningIcon from "../../../assets/Icons/warning.svg";

import IndividualReport from "../../../components/IndividualReportSlide";
import IndividualViewReport from "../../../components/IndividualViewReportSlide";

import SubmitReportModel from "../../../components/SubmitReportModel";
import FileUploadModel from "../../../components/FileUploadModel";
import axios from "../../../utils/axios";
import apiUrl from "../../../utils/apiUrl";
import ScreenLoader from "../../../utils/screenLoader";
import NoData from "../../../utils/NoData";
import SuccsessToaster from "../../../components/tosters/SuccsessToaster";
import ErrorToaster from "../../../../src/components/tosters/ErrorToasters";
import { FileObj } from "../../../utils/common/FileDrop";
import LearnersProfile from "../../../components/LearnersProfileSlide";
import AssignTrainerSlide from "../../../components/AssignTrainerSlide";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export interface IReport {
  LearnerName: string;
  CourseProgress: string;
  Rag: string;
  Percentage: string;
  accountSfid: string;
  courseSfid: string;
  learnerSfid: string;
  trainingProviderSfid: string;
  LastModifiedDate: Date;
  TrainingProviderName: string;
  NextVisit: any;
  Comment: string;
  FunctionalSkills: string;
  Flag: string;
  CompanyName: string;
  CourseName: string;
  ReportDate: string;
  CompanyLogo: string;
  clientid: string;
  courseid: string;
  learnerid: string;
  CompletionDate: Date;
  CompletionStatus: string;
  BILStartDate: Date;
  BILEndDate: Date;
  EPAreadyDate: Date;
  LeaverDate: Date;
  AdditionalTag: string;
  isupdate: boolean;
  BILReturnDate: Date;
  EPAReadyDate: Date;
  files?: any[];
  NextDate: Date;
  additional: any;
  uln: Number;
}

function IndividualCourseTrainer() {
  let { accountId, courseId, trainerId } = useParams();
  const navigate = useNavigate();
  const [isIndividualReport, setIsIndividualReport] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [fileArray, setFileArray] = useState<FileObj[]>([]);
  const [toastMessage, setToastMessage] = useState("");
  const [showToaster, setShowToaster] = useState<boolean>(false);
  const [showErrorToaster, setShowErrorToaster] = useState<boolean>(false);
  const [isLearnersProfile, setIsLearnersProfile] = useState(false);
  const [isAssignTrainerSlide, setIsAssignTrainerSlide] = useState(false);
  const [assignedTrainers, setAssignedTrainers] = useState<any>([]);
  const [company, setCompany] = useState<any>({});

  const [learnerId, setLearnerId] = useState<any>();
  // const [trainerId, settrainerId] = useState<any>();
  const toggleLearnersProfile = (v: boolean, item: any) => {
    if (!v) {
      // window.location.reload();
      // AllLearnerReport();
    }
    if (v && isLearnersProfile) {
      setIsLearnersProfile(false);
      setTimeout(() => {
        setIsLearnersProfile(true);
      }, 100);
    } else {
      setIsLearnersProfile(v);
    }
  };
  const toggleIndividualReport = (v: boolean, isToaster?: boolean) => {
    if (v && isIndividualReport) {
      setIsIndividualReport(false);
      setTimeout(() => {
        setIsIndividualReport(true);
      }, 100);
    } else {
      setIsIndividualReport(v);
      if (!v) {
        getAllReports();
        setFileArray([]);
      }
    }
    if (isToaster) {
      setShow(true);
      setToastMessage("Data updated successfully");
      setTimeout(() => {
        setShow(false);
        setToastMessage("");
      }, 2000);
    }
  };
  const ragRatingRender = (rag: any) => {
    if (rag === "Green") {
      return (
        <span className="inline-flex rounded-full bg-green-100 py-1 px-2 text-xs font-poppins-400  text-green-800">
          Green
        </span>
      );
    } else if (rag === "Red") {
      return (
        <span className="inline-flex rounded-full bg-red-100 py-1 px-2 text-xs font-poppins-400  text-red-800">
          Red
        </span>
      );
    } else {
      return (
        <span className="inline-flex rounded-full bg-yellow-100 py-1 px-2 text-xs font-poppins-400  text-yellow-800">
          {rag}
        </span>
      );
    }
  };
  const getFiles = (files?: FileObj[]) => {
    files?.forEach((file: FileObj) => {
      file.size = file.FileSize ?? file.size;
      var request = new XMLHttpRequest();
      request.open("GET", file.FileUrl, true);
      request.responseType = "blob";
      request.onload = function () {
        var newFile = new File(
          [request.response],
          file.FileTitle ?? file.name,
          { lastModified: file.lastModified, type: file.FileType ?? file.type }
        ) as any;
        newFile.FileSize = file.FileSize ?? file.size;
        newFile.FileTitle = newFile.name;
        newFile.FileType = file.FileType ?? file.type;
        Object.defineProperty(newFile, "size", { value: newFile.FileSize });
        fileArray.push(file.FileUrl ? newFile : file);
        setFileArray([...fileArray]);
      };
      request.send();
    });
    return files;
  };

  const isBefore = (date1: Date, date2: Date) => {
    return date1 && date2
      ? moment(date1).isBefore(
          moment(new Date(date2).setDate(new Date(date2).getDate() + 1))
        )
      : true;
  };
  const [isIndividualViewReport, setIsIndividualViewReport] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [courseReports, setCourseReports] = useState<IReport[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const searchOptions = (val: string) => {
    setCurrentPage(1);
    setSearchText(val ?? "");
    if (!val) {
      getAllReports();
    } else {
      getAllReports(val);
    }
  };
  const toggleIndividualViewReport = (v: boolean) => {
    if (v && isIndividualViewReport) {
      setIsIndividualViewReport(false);
      setTimeout(() => {
        setIsIndividualViewReport(true);
      }, 100);
    } else {
      setIsIndividualViewReport(v);
    }
  };

  const [showSubmitReportModel, setSubmitReportModel] = useState(false);
  const toggleSubmitReportModel = (value: boolean) => {
    setSubmitReportModel(value);
  };

  const [showFileUploadModel, setFileUploadModel] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number[]>([]);
  const [completedReports, setCompletedReports] = useState<number>(0);
  const [totalCountOfReports, setTotalCountOfReports] = useState(0);
  const [isNotValidSrc, setIsNotValidSrc] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<IReport>();
  const [clientInfo, setClientInfo] = useState<any>();
  const [clientId, setClientId] = useState<any>();
  const toggleFileUploadModel = (value: boolean) => {
    setFileUploadModel(value);
  };
  const createPaginationPages = () => {
    let pagesArray = [];
    if (totalCountOfReports > 50) {
      setPages([1, 2, 3, 4, 5]);
    } else {
      for (let i = 1; i <= Math.ceil(totalCountOfReports / 10); i++) {
        pagesArray.push(i);
      }
      setPages(pagesArray);
    }
  };

  const toggleAssignTrainerSlide = (v: boolean) => {
    if (v && isAssignTrainerSlide) {
      setIsAssignTrainerSlide(false);
      setTimeout(() => {
        setIsAssignTrainerSlide(true);
      }, 100);
    } else {
      setIsAssignTrainerSlide(v);
    }
  };
  useEffect(() => {
    getAllReports();
  }, []);
  useEffect(() => {
    createPaginationPages();
  }, [totalCountOfReports]);
  useEffect(() => {
    getAllReports(searchText);
  }, [currentPage]);
  const changePage = (e: any, page: number | string) => {
    e.preventDefault();
    if (typeof page === "number") {
      setCurrentPage(page);
    } else {
      if (page === "next") {
        if (currentPage === Math.ceil(totalCountOfReports / 10)) {
          return;
        }
        if (currentPage % 10 == 0) {
          let skip = currentPage / 5;
          let diff = totalCountOfReports - 10 * 10 * skip;
          let pagesArray = [];
          let updatedPages = [];
          if (diff > 50) {
            updatedPages = pages.map((page) => page + 5);
            setPages(updatedPages);
          } else {
            for (let i = 1; i <= Math.ceil(diff / 10); i++) {
              updatedPages.push(i + currentPage);
            }
            setPages(updatedPages);
          }
        }
        setCurrentPage(currentPage + 1);
      } else {
        if (currentPage === 1) {
          return;
        }
        if ((currentPage - 1) % 10 === 0) {
          let updatedPages = [];
          for (let i = currentPage - 10; i < currentPage; i++) {
            updatedPages.push(i);
          }
          setPages(updatedPages);
        }
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const getAllReports = async (search?: string) => {
    setLoading(true);
    let res = await axios.post(
      apiUrl.getIndividualReports,
      {
        clientid: Number(accountId) ?? "",
        courseid: courseId,
        search_learner: search ?? "",
        submit_reports: "",
        sort_type: "is_update"
      },
      {
        params: {
          skip: search
            ? currentPage > 1
              ? 0
              : currentPage - 1
            : currentPage
            ? currentPage - 1
            : 0,
          limit: 10,
        },
      }
    );
    if (res.status === 200) {
      setClientInfo(res.data[0].client_info); 
      setLoading(false);
      setTotalCountOfReports(res.data[0].total);
      setCourseReports(res.data?.[0].items);
      setCompletedReports(res.data?.[0].total_completed);
    } else {
      setLoading(false);
    }
  };
  const submitReports = async () => {
    await axios
      .post(
        apiUrl.getIndividualReports,
        {
          clientid: Number(accountId) ?? "",
          courseid: courseId,
          search_learner: "",
          submit_reports: "True",
          sort_type: "is_update"
        },
        {
          params: { skip: 0, limit: 5 },
        }
      )
      .then(() => {
        setShow(true);
        setSubmitReportModel(false);
        navigate("/a/reports");
      })
      .catch((err) => {
        setSubmitReportModel(false);
        setShowError(true);
        setShow(false);
        setLoading(false);
        setErrorMsg(err.response.data.detail);
      });
  };

  const toggleFileToaster = (v: any) => {
    setShowToaster(true);
    setTimeout(() => {
      setShowToaster(false);
    }, 2000);
  };
  const toggleFileErrorToaster = (v: any) => {
    setShowErrorToaster(true);
    setTimeout(() => {
      setShowErrorToaster(false);
    }, 2000);
  };
  return (
    <>
      {showError && (
        <ErrorToaster
          show={showError}
          msg={errorMsg}
          toggle={(val: boolean) => {
            setShowError(val);
          }}
        />
      )}
      <SuccsessToaster
        show={show}
        toggle={(val: boolean) => {
          setShow(val);
        }}
        message={message}
      />
      <LearnersProfile
        show={isLearnersProfile}
        toggle={toggleLearnersProfile}
        trainerId={trainerId}
        learnerId={learnerId}
      />
      {isIndividualReport && (
        <IndividualReport
          show={isIndividualReport}
          toggle={toggleIndividualReport}
          selectedReport={selectedReport}
          fileArray={fileArray}
          setFileArray={setFileArray}
        />
      )}
      <IndividualViewReport
        show={isIndividualViewReport}
        toggle={toggleIndividualViewReport}
        source={"trainers"}
      />
      <SubmitReportModel
        show={showSubmitReportModel}
        submitReports={submitReports}
        toggle={toggleSubmitReportModel}
      />
      <AssignTrainerSlide
        show={isAssignTrainerSlide}
        toggle={toggleAssignTrainerSlide}
        assignedTrainers={assignedTrainers}
        company={company}
        clientId={clientId}
      />
      <FileUploadModel
        show={showFileUploadModel}
        toggle={toggleFileUploadModel}
        clientid={clientId}
        toaster={toggleFileToaster}
        errorToggle={toggleFileErrorToaster}
        message={setMessage}
      />

      <SuccsessToaster
        show={showToaster}
        toggle={(val: boolean) => {
          setShowToaster(val);
        }}
        // message={"File imported successfully"}
        message={message}
      />
      <ErrorToaster
        show={showErrorToaster}
        toggle={(val: boolean) => {
          setShowErrorToaster(val);
        }}
        msg={message}
      />

      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex items-center">
          <Link to="/a/trainers" className="flex items-center">
            {/* <ChevronLeftIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            /> */}
            <span className="pl-3 text-gray-500 font-poppins-500">Trainer</span>
          </Link>
          <ChevronLeftIcon
            className="h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <Link
            // to="#"
            to={`/a/view-trainer/${trainerId}`}
            className="flex items-center"
          >
            <span className="pl-3 text-gray-500 font-poppins-500">
              {/* {trainer.trainer_name} */}
              Back
            </span>
          </Link>
        </div>

        <div className="mb-4 px-4 py-5 flex flex-col sm:flex-row flex-wrap sm:items-center sm:justify-between sm:flex-nowrap  bg-white shadow-base rounded-lg">
          <div className="mb-2 sm:mb-0">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                {!isNotValidSrc && (
                  <img
                    onError={() => {
                      setIsNotValidSrc(true);
                    }}
                    className="h-8 w-8"
                    src={
                      courseReports[0]?.CompanyLogo || clientInfo?.CompanyLogo
                    }
                    alt=""
                  />
                )}
                {isNotValidSrc && (
                  <span 
                    className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-sm inline-flex items-center justify-center p-1"
                    onClick={() => {
                      setAssignedTrainers(clientInfo?.assigned_trainers);
                      setClientId(courseReports[0]?.courseid);
                      setCompany(courseReports[0]);
                      toggleAssignTrainerSlide(true);
                    }}
                  >
                    {courseReports[0]?.CompanyName
                      ? courseReports[0]?.CompanyName?.trim()?.split(" ")
                          ?.length > 1
                        ? courseReports[0]?.CompanyName?.split(
                            " "
                          )[0][0].toUpperCase() +
                          courseReports[0]?.CompanyName?.split(" ")[
                            courseReports[0]?.CompanyName?.split(" ").length - 1
                          ][0].toUpperCase()
                        : courseReports[0]?.CompanyName?.split(
                            " "
                          )[0][0].toUpperCase()
                      : clientInfo?.CompanyName?.trim()?.split(" ")?.length > 1
                      ? clientInfo?.CompanyName?.split(
                          " "
                        )[0][0].toUpperCase() +
                        clientInfo?.CompanyName?.split(" ")[
                          clientInfo?.CompanyName?.split(" ").length - 1
                        ][0].toUpperCase()
                      : clientInfo?.CompanyName?.split(" ")[0][0].toUpperCase()}
                  </span>
                )}
              </div>
              <div 
                className="ml-4"
              >
                <h3 
                  className="text-lg font-medium leading-6 text-gray-700"
                  onClick={() => {
                    setAssignedTrainers(clientInfo?.assigned_trainers);
                    setClientId(courseReports[0]?.courseid);
                    setCompany(courseReports[0]);
                    toggleAssignTrainerSlide(true);
                  }}
                >
                  {courseReports[0]?.CompanyName || clientInfo?.CompanyName}
                </h3>
                <p className="text-sm text-gray-500 mt-1">
                  {courseReports[0]?.CourseName || clientInfo?.CourseName}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-shrink-0">
            {/* <button
              onClick={() => {
                toggleFileUploadModel(true);
                setClientId(courseReports[0]?.clientid);
              }}
              type="button"
              className="relative sm:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M2.22222 16.7778H10.2847C10.5938 17.1875 11.0174 17.5521 11.4479 17.8646C11.3403 17.8784 11.2257 17.8889 11.1111 17.8889H2.22222C0.994792 17.8889 0 16.8923 0 15.6666V2.33331C0 1.10588 0.994792 0.111084 2.22222 0.111084H7.64236C8.08333 0.111084 8.51042 0.286674 8.82292 0.599278L12.8438 4.6215C13.1562 4.934 13.3333 5.36108 13.3333 5.80206V7.00692C12.9444 7.11803 12.5729 7.26386 12.2222 7.44442V6.77775H8.33333C7.41319 6.77775 6.66667 6.03122 6.66667 5.11108V1.2222H2.22222C1.60868 1.2222 1.11111 1.71976 1.11111 2.33331V15.6666C1.11111 16.2812 1.60868 16.7778 2.22222 16.7778ZM12.059 5.4097L8.03472 1.38504C7.96528 1.31317 7.875 1.26317 7.77778 1.23886V5.11108C7.77778 5.41664 8.02778 5.66664 8.33333 5.66664H12.2049C12.1806 5.56942 12.1319 5.47914 12.059 5.4097ZM15.5556 12.3021H17.2222C17.5278 12.3021 17.7778 12.5833 17.7778 12.8576C17.7778 13.1944 17.5278 13.4132 17.2222 13.4132H15.5556V15.0798C15.5556 15.4166 15.3056 15.6354 15 15.6354C14.6944 15.6354 14.4444 15.4166 14.4444 15.0798V13.4132H12.7778C12.4722 13.4132 12.2222 13.1944 12.2222 12.8576C12.2222 12.5833 12.4722 12.3021 12.7778 12.3021H14.4444V10.6354C14.4444 10.3611 14.6944 10.0798 15 10.0798C15.3056 10.0798 15.5556 10.3611 15.5556 10.6354V12.3021ZM20 12.8889C20 15.6493 17.7604 17.8889 15 17.8889C12.2396 17.8889 10 15.6493 10 12.8889C10 10.1284 12.2396 7.88886 15 7.88886C17.7604 7.88886 20 10.1284 20 12.8889ZM15 8.99997C12.8507 8.99997 11.1111 10.7396 11.1111 12.8889C11.1111 15.0382 12.8507 16.7778 15 16.7778C17.1493 16.7778 18.8889 15.0382 18.8889 12.8889C18.8889 10.7396 17.1493 8.99997 15 8.99997Z" />
              </svg>
              <span>File drop</span>
            </button> */}

            <button
              onClick={() => toggleSubmitReportModel(true)}
              type="button"
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-lavender px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="inline-block h-3">
                <svg
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M17.8102 0.194487C18.0633 0.44358 18.0633 0.853379 17.8102 1.10247L6.88221 12.0304C6.63311 12.2835 6.22331 12.2835 5.97422 12.0304L0.188266 6.24504C-0.0627554 5.99595 -0.0627554 5.58615 0.188266 5.33706C0.439127 5.08395 0.846113 5.08395 1.09722 5.33706L6.42821 10.6685L16.9022 0.194487C17.1513 -0.0570166 17.5611 -0.0570166 17.8102 0.194487Z"
                    fill=""
                  />
                </svg>
              </span>
              <span>Submit reports</span>
            </button>
          </div>
        </div>

        <div className="mb-4 bg-white shadow-base rounded-lg">
          <div className="border-b  px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <div className="flex items-center">
                  <div className="ml-0">
                    <h3 className="text-lg font-normal leading-6 text-gray-900">
                      Reports
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                      {completedReports} out of {totalCountOfReports} reports
                      completed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4 flex flex-shrink-0 -mr-4">
                <div className="flex  items-center">
                  <div className="hidden  sm:flex sm:mt-0 sm:ml-2">
                    <label htmlFor="search" className="sr-only">
                      Search learners
                    </label>
                    <div className="relative  rounded-md shadow-sm layout-search">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="block w-72 sm:w-80 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                        placeholder="Search learners"
                        onChange={(e) => searchOptions(e.target.value ?? "")}
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        aria-hidden="true"
                      >
                        <MagnifyingGlassIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading && (
            <div
              className={`${
                loading ? "h-24 flex items-center justify-center" : ""
              }`}
            >
              <ScreenLoader />
            </div>
          )}
          {!loading && courseReports?.length > 0 ? (
            <div className="ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="hidden sm:table-cell w-2 py-3.5 pl-3 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6"
                    ></th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell py-3.5 pl-3 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="hidden sm:table-cell whitespace-nowrap px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12	"
                    >
                      Course Status
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
                    >
                      RAG
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-3 py-3.5 text-center text-xs font-medium text-gray-500 w-2/12"
                    >
                      %
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell relative py-3.5 pl-3 pr-4 sm:pr-6 w-2/12	"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {courseReports?.map((report) => (
                    <tr
                      className={`cursor-pointer relative z-10 ${
                        report.isupdate ? "bg-gray-50" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        let obj: any = { ...report };
                        obj.CompletionDate = obj.CompletionDate
                          ? new Date(obj.CompletionDate)
                          : null;
                        obj.BILEndDate = obj.BILReturnDate
                          ? new Date(obj.BILReturnDate)
                          : null;
                        obj.EPAReadyDate = obj.EPAreadyDate
                          ? new Date(obj.EPAreadyDate)
                          : null;
                        obj.LeaverDate = obj.LeaverDate
                          ? new Date(obj.LeaverDate)
                          : null;
                        obj.BILStartDate = obj.BILStartDate
                          ? new Date(obj.BILStartDate)
                          : null;
                        obj.NextVisit = obj.NextDate
                          ? isBefore(new Date(), obj.NextDate)
                            ? new Date(obj.NextDate)
                            : null
                          : null;
                        getFiles(obj?.files);
                        setSelectedReport(obj);
                        setTimeout(() => {
                          toggleIndividualReport(true);
                        }, 100);
                      }}
                    >
                      <td className=" hidden sm:table-cell whitespace-nowrap py-4 pl-3 pr-3 text-sm text-gray-900 sm:pl-6">
                        {report.isupdate && (
                          <svg
                            className="text-green-400"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.35313 10.3531C7.15938 10.55 6.84062 10.55 6.64687 10.3531L4.64687 8.35312C4.45 8.15937 4.45 7.84062 4.64687 7.64687C4.84062 7.45 5.15938 7.45 5.35313 7.64687L7 9.29375L10.6469 5.64687C10.8406 5.45 11.1594 5.45 11.3531 5.64687C11.55 5.84062 11.55 6.15938 11.3531 6.35313L7.35313 10.3531ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1Z" />
                          </svg>
                        )}
                      </td>

                      <td className="w-full relative max-w-0 sm:w-auto sm:max-w-none whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                        <span
                          className="cursor-pointer block font-poppins-500 mb-3 sm:mb-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleLearnersProfile(true, report);
                            setLearnerId(report?.learnerid);
                          }}
                        >
                          {report.LearnerName}
                          <span className="ml-2">
                            {report?.additional?.uln ?? "-"}
                          </span>
                        </span>
                        <span className="sm:hidden absolute right-3 top-3">
                          {report.isupdate && (
                            <svg
                              className="text-green-400"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7.35313 10.3531C7.15938 10.55 6.84062 10.55 6.64687 10.3531L4.64687 8.35312C4.45 8.15937 4.45 7.84062 4.64687 7.64687C4.84062 7.45 5.15938 7.45 5.35313 7.64687L7 9.29375L10.6469 5.64687C10.8406 5.45 11.1594 5.45 11.3531 5.64687C11.55 5.84062 11.55 6.15938 11.3531 6.35313L7.35313 10.3531ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1Z" />
                            </svg>
                          )}
                        </span>

                        <dl className="font-normal sm:hidden">
                          <dd className="mt-1 truncate">
                            <div className="flex items-center">
                              <span className="border-r pr-2">
                                {report.CourseProgress ?? "-"}{" "}
                              </span>

                              <span className="mx-2">
                                {report.Rag ? ragRatingRender(report.Rag) : "-"}
                              </span>

                              <span className="border-l pl-2">
                                {report.Percentage
                                  ? `${report.Percentage} % `
                                  : "-"}
                              </span>
                            </div>
                          </dd>
                          <dd className="mt-1 truncate text-lavender font-medium">
                            {!report.isupdate && (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setTimeout(() => {
                                    toggleIndividualReport(true);
                                  }, 100);
                                  let obj: any = { ...report };
                                  obj.CompletionDate = obj.CompletionDate
                                    ? new Date(obj.CompletionDate)
                                    : null;
                                  obj.BILEndDate = obj.BILReturnDate
                                    ? new Date(obj.BILReturnDate)
                                    : null;
                                  obj.EPAReadyDate = obj.EPAreadyDate
                                    ? new Date(obj.EPAreadyDate)
                                    : null;
                                  obj.LeaverDate = obj.LeaverDate
                                    ? new Date(obj.LeaverDate)
                                    : null;
                                  obj.BILStartDate = obj.BILStartDate
                                    ? new Date(obj.BILStartDate)
                                    : null;
                                  obj.NextVisit = obj.NextDate
                                    ? isBefore(new Date(), obj.NextDate)
                                      ? new Date(obj.NextDate)
                                      : null
                                    : null;
                                  getFiles(obj?.files);
                                  setSelectedReport(obj);
                                }}
                                className="whitespace-nowrap pt-2 text-left text-sm text-lavender font-medium sm:pr-6 z-40 relative"
                              >
                                Update
                              </div>
                            )}
                            {report.isupdate && (
                              <div className="whitespace-nowrap pt-2 text-left text-sm text-lavender font-medium sm:pr-6 ">
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    let obj: any = { ...report };
                                    obj.CompletionDate = obj.CompletionDate
                                      ? new Date(obj.CompletionDate)
                                      : null;
                                    obj.BILEndDate = obj.BILReturnDate
                                      ? new Date(obj.BILReturnDate)
                                      : null;
                                    obj.EPAReadyDate = obj.EPAreadyDate
                                      ? new Date(obj.EPAreadyDate)
                                      : null;
                                    obj.LeaverDate = obj.LeaverDate
                                      ? new Date(obj.LeaverDate)
                                      : null;
                                    obj.BILStartDate = obj.BILStartDate
                                      ? new Date(obj.BILStartDate)
                                      : null;
                                    obj.NextVisit = obj.NextDate
                                      ? isBefore(new Date(), obj.NextDate)
                                        ? new Date(obj.NextDate)
                                        : null
                                      : null;
                                    setSelectedReport(obj);
                                    getFiles(obj?.files);

                                    setTimeout(() => {
                                      toggleIndividualReport(true);
                                    }, 250);
                                  }}
                                  className="flex cursor-pointer"
                                >
                                  Edit
                                </div>
                              </div>
                            )}
                          </dd>
                        </dl>
                      </td>

                      <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                        {report.CourseProgress ?? "-"}
                      </td>
                      <td className="hidden sm:table-cell whitespace-nowrap text-center px-3 py-4 text-xs text-gray-500">
                        {report.Rag ? ragRatingRender(report.Rag) : "-"}
                      </td>

                      <td className="hidden sm:table-cell whitespace-nowrap text-center  px-3 py-4 text-sm text-gray-900">
                        {report.Percentage ? `${report.Percentage} % ` : "-"}
                      </td>

                      {!report.isupdate && (
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setTimeout(() => {
                              toggleIndividualReport(true);
                            }, 100);
                            let obj: any = { ...report };
                            obj.CompletionDate = obj.CompletionDate
                              ? new Date(obj.CompletionDate)
                              : null;
                            obj.BILEndDate = obj.BILReturnDate
                              ? new Date(obj.BILReturnDate)
                              : null;
                            obj.EPAReadyDate = obj.EPAreadyDate
                              ? new Date(obj.EPAreadyDate)
                              : null;
                            obj.LeaverDate = obj.LeaverDate
                              ? new Date(obj.LeaverDate)
                              : null;
                            obj.BILStartDate = obj.BILStartDate
                              ? new Date(obj.BILStartDate)
                              : null;
                            obj.NextVisit = obj.NextDate
                              ? isBefore(new Date(), obj.NextDate)
                                ? new Date(obj.NextDate)
                                : null
                              : null;
                            getFiles(obj?.files);
                            setSelectedReport(obj);
                          }}
                          className="hidden sm:table-cell whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end z-40 relative"
                        >
                          Update
                        </td>
                      )}
                      {report.isupdate && (
                        <td className="hidden sm:table-cell  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-lavender font-medium sm:pr-6 justify-end">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              let obj: any = { ...report };
                              obj.CompletionDate = obj.CompletionDate
                                ? new Date(obj.CompletionDate)
                                : null;
                              obj.BILEndDate = obj.BILReturnDate
                                ? new Date(obj.BILReturnDate)
                                : null;
                              obj.EPAReadyDate = obj.EPAreadyDate
                                ? new Date(obj.EPAreadyDate)
                                : null;
                              obj.LeaverDate = obj.LeaverDate
                                ? new Date(obj.LeaverDate)
                                : null;
                              obj.BILStartDate = obj.BILStartDate
                                ? new Date(obj.BILStartDate)
                                : null;
                              obj.NextVisit = obj.NextDate
                                ? isBefore(new Date(), obj.NextDate)
                                  ? new Date(obj.NextDate)
                                  : null
                                : null;
                              setSelectedReport(obj);
                              getFiles(obj?.files);

                              setTimeout(() => {
                                toggleIndividualReport(true);
                              }, 250);
                            }}
                            className="flex justify-end cursor-pointer"
                          >
                            Edit
                            <svg
                              className="ml-2 w-4 h-5 text-lavender"
                              viewBox="0 0 18 15"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.4024 2.07528C18.1723 2.84415 18.1723 4.08973 17.4024 4.85965L6.40899 15.8565C6.00821 16.2538 5.51602 16.5456 4.97462 16.7073L0.721412 17.9589C0.523833 18.0151 0.310329 17.9624 0.164783 17.8147C0.0192215 17.6706 -0.0352215 17.4561 0.0228778 17.2593L1.27372 13.0054C1.43297 12.4639 1.72583 11.9718 2.12485 11.571L13.1203 0.576571C13.8902 -0.19219 15.1348 -0.19219 15.9047 0.576571L17.4024 2.07528ZM11.9883 3.29872L14.6813 5.96004L16.6078 4.06512C16.9383 3.73465 16.9383 3.20028 16.6078 2.87087L15.1102 1.37216C14.7797 1.04274 14.2453 1.04274 13.9149 1.37216L11.9883 3.29872ZM11.1938 4.09325L2.92044 12.369C2.6543 12.6327 2.45919 12.9632 2.35301 13.3253L1.39325 16.5878L4.65469 15.628C5.0168 15.5225 5.34727 15.3257 5.61095 15.0585L13.8551 6.78621L11.1938 4.09325Z"
                                fill=""
                              />
                            </svg>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            !loading && (
              <div className="h-54 my-2">
                <NoData />
              </div>
            )
          )}

          <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden ">
              <button
                onClick={(e) => changePage(e, "previous")}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
              >
                Previous
              </button>
              <button
                onClick={(e) => changePage(e, "next")}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-poppins-500 text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700 font-inter-400">
                  Showing
                  <span className=" inline-flex pl-1 pr-1">
                    {courseReports?.length ? 10 * currentPage - 9 : 0}
                  </span>
                  to
                  <span className=" inline-flex pl-1 pr-1">
                    {10 * currentPage > totalCountOfReports
                      ? totalCountOfReports
                      : 10 * currentPage}
                  </span>
                  of
                  <span className=" inline-flex pl-1 pr-1">
                    {totalCountOfReports}
                  </span>
                  results
                </p>
              </div>
              {totalCountOfReports > 10 && (
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <a
                      href="#"
                      onClick={(e) => changePage(e, "previous")}
                      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Previous</span>
                      {/* <!-- Heroicon name: mini/chevron-left --> */}
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                    {pages.map((page) => (
                      <a
                        key={page}
                        href="#"
                        onClick={(e) => changePage(e, page)}
                        className={
                          currentPage === page
                            ? "relative z-10 inline-flex items-center border border-lavender bg-azure px-4 py-2 text-sm font-inter-500 text-lavender focus:z-20"
                            : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                        }
                      >
                        {page}
                      </a>
                    ))}
                    <a
                      href="#"
                      onClick={(e) => changePage(e, "next")}
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-inter-500 text-gray-500 hover:bg-gray-50 focus:z-20"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </nav>
                </div>
              )}
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </>
  );
}

export default IndividualCourseTrainer;
