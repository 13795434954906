import React from 'react';
import conveyaLongLogo from './../../assets/images/conveyaLongLogo.png';
import '../styles/Login.scss';
function LinkExpired() {
    return (
        <>
            <div className="flex min-h-full flex-col  py-12 pt-sm:px-6 lg:px-8 linkExpiredPage auth-pages" >
            
            {/* <div className="flex flex-shrink-0 items-center px-4 space-x-3 mx-auto justify-center">
                    <img
                      className="h-8 w-auto"
                      src={conveyaLongLogo}
                      alt="Conveya"
                    />
           
            </div> 
                 */}
                <div className="my-auto sm:mx-auto sm:w-full sm:max-w-md">
                <div className="mb-16 flex flex-shrink-0 items-center px-4 space-x-3 mx-auto justify-center">
                    <img
                      className="h-8 w-auto"
                      src={conveyaLongLogo}
                      alt="Conveya"
                    />
           
            </div> 
                    <h2 className="text-center text-5xl font-poppins-700 text-gray-900">Link expired.</h2>
                    <p className="mt-2 text-center text-base text-gray-500 font-inter-400">
                    Sorry, the link you tried to open has expired.
                    </p>
                    <div className=" flex items-center justify-center pt-5">
                            <a href="login" className=" text-lavender text-base font-inter-500">
                                  Go to home page →
                    </a>
                        </div>
                </div>
              
                <footer>
                    <div className="mt-20 sm:mx-auto sm:w-full sm:max-w-md space-x-6">
                        <p className="text-base font-inter-400 text-gray-400 text-center">Questions? Email us at <a href="mailto:info@conveya.co" className="text-lavender">info@conveya.co</a></p>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default LinkExpired;