import { createContext, ReactNode, useEffect, useReducer } from "react";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from "../@types/auth";
import apiUrl from "../utils/apiUrl";

// ----------------------------------------------------------------------

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
  VerifyCode = "VERIFYCODE",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.VerifyCode]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case "VERIFYCODE":
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      // todo 校验accesstoken
      try {
        const accessToken = window.sessionStorage.getItem("accessToken");
        const user = JSON.parse(window.sessionStorage.getItem("user") || "");
        if (accessToken) {
          setSession(accessToken);
          try {
            // const response = await axios.get('/api/account/my-account');
            // const response = await axios.get(apiUrl.viewPersonalProfile);
            // const user = response.data.user;
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          } catch {
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user: null as unknown as AuthUser,
              },
            });
          }
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    // try {
    //   const response = await axios.post(apiUrl.login, {
    //     email,
    //     password,
    //   });
    //   if (response?.data) {
    //     // const { accessToken, user } = response.data;
    //     const accessToken = response.data.data.access_token;
    //     // const user = {
    //     //   name: 'Abc',
    //     //   location: 'India'
    //     // }

    //     setSession(accessToken);
    //     // const userResp = await axios.get('/api/users/me');
    //     // console.log('userResp', userResp);
    //     // const user = userResp.data;
    //     const user = response.data.data.user;

    //     sessionStorage.setItem("user", JSON.stringify(user));
    //     sessionStorage.setItem('isSetup', response.data.data.isSetup || '')
    //     dispatch({
    //       type: Types.Login,
    //       payload: {
    //         user,
    //       },
    //     });
    //   }
    // } catch (e: any) {
    //   throw e;
    // }
    return axios
      .post("/api/auth/tcr/signin", {
        email,
        password,
      })
      .then((response) => {
        if (response?.data && response?.data.code === 200) {
          // const { accessToken, user } = response.data;
          const accessToken = response.data.data.access_token;
          // const user = {
          //   name: 'Abc',
          //   location: 'India'
          // }

          setSession(accessToken);
          // const userResp = await axios.get('/api/users/me');
          // console.log('userResp', userResp);
          // const user = userResp.data;
          const user = response.data.data.user;

          sessionStorage.setItem("user", JSON.stringify(user));
          sessionStorage.setItem("isSetup", response.data.data.isSetup || "");
          dispatch({
            type: Types.Login,
            payload: {
              user,
            },
          });
        } else {
          throw response;
        }
      });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.sessionStorage.setItem("accessToken", accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const setUser = async (user: AuthUser) => {
    dispatch({ type: Types.VerifyCode, payload: { user: user } });
  };

  const logout = async () => {
    axios.get("/api/tcr/auth/logout");
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
