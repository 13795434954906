import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
	XMarkIcon,
	ChevronDownIcon,
	MagnifyingGlassIcon,
	ChevronUpIcon,
	CheckIcon
} from '@heroicons/react/24/outline'
import fileUploadImage from '../assets/images/fileUploadImage.jpg'

function EnrollModel(props: any) {
	return (
		<Transition.Root show={props.show} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-50"
				onClose={() => props.toggle(false)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
								<div>
									<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect width="48" height="48" rx="24" fill="#D1FAE5" />
											<g clip-path="url(#clip0_9732_14377)">
												<path
													d="M22.3013 26.066H18.4988C14.9104 26.066 12 28.976 12 32.5648C12 33.2848 12.582 33.866 13.2998 33.866H27.4987C28.2188 33.866 28.8 33.2848 28.8 32.5648C28.8 28.976 25.89 26.066 22.3013 26.066ZM27.4987 32.666H13.2998C13.245 32.666 13.2 32.621 13.2 32.5648C13.2 29.6435 15.5775 27.266 18.4988 27.266H22.3013C25.2225 27.266 27.6 29.6435 27.6 32.5648C27.6 32.621 27.555 32.666 27.4987 32.666ZM20.4 24.266C23.0513 24.266 25.2 22.1169 25.2 19.466C25.2 16.8151 23.0513 14.666 20.4 14.666C17.7488 14.666 15.6 16.8151 15.6 19.466C15.6 22.1169 17.7488 24.266 20.4 24.266ZM20.4 15.866C22.3853 15.866 24 17.4808 24 19.466C24 21.4509 22.3853 23.066 20.4 23.066C18.4148 23.066 16.8 21.4498 16.8 19.466C16.8 17.4808 18.4163 15.866 20.4 15.866ZM35.8237 20.441C35.5894 20.2066 35.2095 20.2066 34.9755 20.441L31.4003 24.017L29.8241 22.4413C29.5898 22.2069 29.2099 22.2069 28.9759 22.4413C28.7419 22.6756 28.7415 23.0555 28.9759 23.2895L30.9761 25.2894C31.095 25.406 31.2488 25.466 31.3988 25.466C31.5487 25.466 31.7058 25.4074 31.8229 25.2902L35.8241 21.289C36.06 21.056 36.06 20.6773 35.8237 20.441Z"
													fill="#14B8A6"
												/>
											</g>
											<defs>
												<clipPath id="clip0_9732_14377">
													<rect
														width="24"
														height="19.2"
														fill="white"
														transform="translate(12 14.666)"
													/>
												</clipPath>
											</defs>
										</svg>
									</div>
									<div className="mt-3 text-center sm:mt-5">
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-gray-900 font-poppins-500"
										>
											Enrol User(s)
										</Dialog.Title>
									</div>
								</div>

								<div className="text-gray-400 text-center py-2 text-sm">
									Please confirm your selections. Once you proceed, this action
									is final.
								</div>

								<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
									<button
										onClick={() => props.submitReports(false)}
										type="button"
										className="inline-flex w-full justify-center rounded-md border border-transparent bg-lavender px-4 py-2 text-sm font-poppins-500 text-white shadow-sm hover:bg-lavender focus:outline-none focus:ring-2 sm:col-start-2 sm:text-sm"
									>
										Confirm
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-poppins-500 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:col-start-1 sm:mt-0 sm:text-sm"
										onClick={() => props.toggle(false)}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default EnrollModel
