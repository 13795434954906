import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl text-gray-300">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-xl font-semibold text-gray-600">
            Sorry, we couldn't find this page.
          </p>
          <a
            rel="noopener noreferrer"
            href="/a/reports"
            className="hidden  relative ml-3 sm:inline-flex items-center rounded-md border border-gray-300 bg-lavender px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 my-8"
          >
            Back to reports
          </a>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
