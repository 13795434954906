import './App.scss'
import 'react-datepicker/dist/react-datepicker.css'
import AppRoutes from './Routes'
import { useEffect } from 'react'

import { AuthContext } from './contexts/AuthenticationContext'

// console.log = () => {}
// console.error = () => {}
// console.warn = () => {}

let html = document.documentElement

function App() {
	return (
		<div className="App h-full">
			{/* <AuthContext.Provider value={false}> */}
			<AppRoutes />
			{/* </AuthContext.Provider> */}
		</div>
	)
}

export default App
