import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
	XMarkIcon,
	ChevronDownIcon,
	MagnifyingGlassIcon,
	ChevronUpIcon,
	CheckIcon
} from '@heroicons/react/24/outline'
import fileUploadImage from '../assets/images/fileUploadImage.jpg'

function EnrollModel(props: any) {
	return (
		<Transition.Root show={props.show} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-999"
				onClose={() => props.toggle(false)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 z-96 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-96 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
								<div>
									<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-200 text-red-600">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											viewBox="0 0 16 16"
										>
											<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
											<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
										</svg>
									</div>
									<div className="mt-3 text-center sm:mt-5">
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-gray-900 font-poppins-500"
										>
											Confirm Deletion
										</Dialog.Title>
									</div>
								</div>

								<div className="text-gray-400 text-center py-2 text-sm">
									Are you sure you want to delete this comment? There's no going
									back!
								</div>

								<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
									<button
										onClick={() => props.submit(false)}
										type="button"
										className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-800 px-4 py-2 text-sm font-poppins-500 text-white shadow-sm hover:bg-lavender focus:outline-none focus:ring-2 sm:col-start-2 sm:text-sm"
									>
										<svg
											width="19"
											height="20"
											className="mr-2"
											viewBox="0 0 19 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.23633 15.625C6.23633 15.9688 5.95508 16.25 5.61133 16.25C5.26758 16.25 4.98633 15.9688 4.98633 15.625V6.875C4.98633 6.53125 5.26758 6.25 5.61133 6.25C5.95508 6.25 6.23633 6.53125 6.23633 6.875V15.625ZM9.98633 15.625C9.98633 15.9688 9.70508 16.25 9.36133 16.25C9.01758 16.25 8.73633 15.9688 8.73633 15.625V6.875C8.73633 6.53125 9.01758 6.25 9.36133 6.25C9.70508 6.25 9.98633 6.53125 9.98633 6.875V15.625ZM13.7363 15.625C13.7363 15.9688 13.4551 16.25 13.1113 16.25C12.7676 16.25 12.4863 15.9688 12.4863 15.625V6.875C12.4863 6.53125 12.7676 6.25 13.1113 6.25C13.4551 6.25 13.7363 6.53125 13.7363 6.875V15.625ZM12.7246 0.88125L13.7715 2.5H17.4863C17.8301 2.5 18.1113 2.77969 18.1113 3.125C18.1113 3.47031 17.8301 3.75 17.4863 3.75H16.8613V16.875C16.8613 18.6016 15.4629 20 13.7363 20H4.98633C3.26055 20 1.86133 18.6016 1.86133 16.875V3.75H1.23633C0.891172 3.75 0.611328 3.47031 0.611328 3.125C0.611328 2.77969 0.891172 2.5 1.23633 2.5H4.95117L5.96289 0.88125C6.30664 0.333047 6.9082 0 7.55273 0H11.1699C11.8145 0 12.416 0.333047 12.7246 0.88125ZM6.42773 2.5H12.2949L11.6973 1.54375C11.584 1.36094 11.3848 1.25 11.1699 1.25H7.55273C7.33789 1.25 7.13867 1.36094 7.02539 1.54375L6.42773 2.5ZM3.11133 16.875C3.11133 17.9102 3.95078 18.75 4.98633 18.75H13.7363C14.7715 18.75 15.6113 17.9102 15.6113 16.875V3.75H3.11133V16.875Z"
												fill="white"
											/>
										</svg>
										Delete
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-poppins-500 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:col-start-1 sm:mt-0 sm:text-sm"
										onClick={() => props.toggle(false)}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default EnrollModel
