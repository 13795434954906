import React from 'react';
import '../../pages/styles/Login.scss';
import eyeclosedIcon from '../../assets/Icons/eyeclosed.svg';
import correctIcon from '../../assets/Icons/correctIcon.svg';
function PasswordUpdated() {
    return (
        <>
               <div className="flex min-h-full flex-col  py-12 sm:px-6 lg:px-8 auth-pages">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="circledIcon bg-azure text-center mx-auto mt-auto display-flex p-2.5"><img
              className="mx-auto w-auto "
              src={correctIcon}
              alt="Password Updated"
            /> </div>
          <h2 className="mt-6 text-center text-3xl font-inter-800  text-gray-900">Password updated</h2>
          <p className="mt-2 text-center text-sm text-gray-600 font-inter-400">
          Your password has been updated succsessfuly.  

          
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-poppins-400 text-gray-700">
                  Email address
                  </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-poppins-400 text-gray-700">
                  Password
                  </label>
                {/* <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none  sm:text-sm"
                    />
                  </div> */}
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-gray-300 pr-10 placeholder-gray-400 focus:outline-none sm:text-sm "
                    placeholder=""
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <img src={eyeclosedIcon} className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <div className="text-sm flex items-center">
                  <a href="reset-password" className="font-poppins-500 text-lavender">
                    Forgot your password?
                    </a>
                  {/* <Link></Link> */}
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center btn-lavender"
                >
                  Sign in
                  </button>

              </div>
            </form>
          </div>
        </div>
      </div>
        </>
    );
}

export default PasswordUpdated;