import React, { useEffect, useState } from 'react'
import AppSlideOver from './AppSlideOver'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon
} from "@heroicons/react/20/solid";
import { Dialog } from '@headlessui/react';
import emailIcon from "../assets/Icons/emailIcon.svg";
import logoIconBlack from "../assets/images/logoIconBlack.svg";
import WarningIcon from "../assets/Icons/warning.svg";
import axios from '../utils/axios';
import apiUrl from '../utils/apiUrl';
import { useForm } from 'react-hook-form';




export default function PairLearnersProfile(props: any) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    reset,
    trigger,
    control,
  } = useForm();
  const [trainer, setTrainer] = useState<any>([]);
  const [learnerPair, setLearnerPair] = useState<any>("Unpaired");
  const [trainerPair, setTrainerPair] = useState<any>({});
  const [selectedTrainer, setSelectedTrainer] = useState<any>([]);
  const [showUln, setShowUln] = useState(false);
  const [ulnError, setUlnError] = useState(false);
  const [uln, setUln] = useState<any>("");

  const trainerSelected = (valu: any, index: any) => {
    if (valu !== "Unpaired") {
      let temp: any = [];
      selectedTrainer?.map((item: any, ind: any) => {
        if (ind === index) {
          trainer?.map((each: any) => {
            if (each?.id === valu) {
              temp.push({ ind: ind, id: each?.id, text: each?.trainer_name.trim() });
            }
          })
        } else {
          temp.push(item);
        }
      });
      setSelectedTrainer(temp);
    }
  };

  const getFilter = async () => {
    const response = await axios.get(apiUrl.trainerFilter)
    setTrainer(response.data.trainers)
  }

  useEffect(() => {
    setUln(props.pairSlid?.uln)
  }, [props.pairSlid])

  useEffect(() => {
    getFilter();
    let tempSelect: any = []
    props.pairSlid.courses?.map((each: any, i: any) => {
      tempSelect.push({ id: i, text: "Unpaired" });
    });
    setSelectedTrainer([...tempSelect]);
  }, [props])

  const submitTrainer = async () => {
    if (uln) {
      const finalCourseList: any = [];
      const finalTrainerList: any = [];
      props.pairSlid.courses?.map((each: any, index: any) => {
        if(each.assigned_trainers){
          finalTrainerList.push(each.assigned_trainers.map((e:any) => e.id))
        }
        if (selectedTrainer[index]?.text !== "Unpaired") {
          finalTrainerList[index].push(selectedTrainer[index]?.id);
        }
        else {
          finalTrainerList.push("Unpaired");
        }
        finalCourseList.push({
          course_id: each.course_sfid ?? "",
          coursename: each.coursename,
          trainer_id: finalTrainerList[index]    
        });
      });
      try {
        const response = await axios.post(apiUrl.pairLearner, {
          learner_id: props.pairSlid.learner_sfid,
          courses: finalCourseList,
          uln: uln,
          company_id: props.pairSlid.account_sfid, 
        })
        const updateUln = await axios.post(apiUrl.updateLearnerUln, {
          learner_id: props.pairSlid.learner_sfid,
          uln: uln
        })

        if (response.status === 200 && updateUln.status === 200) {
          props.toggle(false)
          setUlnError(false)
          setShowUln(false)
        }
      } catch (error) {
        setUlnError(false);
        console.log(error, "errorrr")
        props.toggle(true)
      }
    } else {
      props.toggle(true)
      setUlnError(true);
      setShowUln(true)
    }

  }
  return (
    <AppSlideOver toggle={props.toggle} show={props.show} >
      <div className="flex min-h-0 flex-1 flex-col py-6">
        <div className=" px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <div className="text-lg font-inter-500 leading-7 text-gray-900">
              Profile
            </div>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => props.toggle(false)}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>

        <div className=" relative overflow-y-auto mt-6 pb-6 flex-1 px-0">
          {/* Replace with your content */}
          <form className="mb-12"
            id="personal_detail"
            onSubmit={handleSubmit(submitTrainer)}
          >
            <div className="mb-6">
              <div>
                <div className="h-24 w-full object-cover lg:h-24 bg-lavender"></div>
              </div>
              <div className="mx-auto px-4 sm:px-6 lg:px-6">
                <div className="-mt-10 sm:-mt-12 sm:flex sm:items-end sm:space-x-5">
                  <div className="flex h-24 w-24 rounded-lg bg-white items-center justify-center">
                    <span className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-sm inline-flex items-center justify-center p-1">
                      {props.company?.company_name?.trim()?.split(" ")
                        ?.length > 1
                        ? props.company?.company_name
                          ?.split(" ")[0][0]
                          .toUpperCase() +
                        props.company?.company_name
                          ?.split(" ")
                        [
                          props.company?.company_name?.split(" ").length -
                          1
                        ][0].toUpperCase()
                        : props.company?.company_name
                          ?.split(" ")[0][0]
                          .toUpperCase()}
                    </span>
                  </div>
                </div>
                <div className="mt-1 min-w-0 flex-1 block">
                  <h1 className="truncate text-xl font-poppins-600 text-gray-900 mb-1">
                    {/* [full name] */}
                    {props.pairSlid.learner_name}
                  </h1>
                  <div>
                    <span className="font-poppins-400 text-gray-500 mb-1">
                      {/* [company name] */}
                      {props.company?.company_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="px-4 pt-6 sm:px-6 lg:px-6 border-gray-200 ">
                {
                  <div className="py-4 border-y border-gray-200">
                    <div className="flex items-center justify-between">
                      <div className={errors.uln || ulnError
                        ? "flex items-center text-red-500 text-sm"
                        : "flex items-center text-gray-500 text-sm"}
                      >
                        ULN
                      </div>
                      <div className="flex-shrink-0">
                        <h3 className="flex text-sm font-poppins-400 text-gray-800 cursor-pointer">
                          {/* [uln]  */}
                          {props.pairSlid?.uln}
                          <svg
                            className="ml-1"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => setShowUln(true)}
                          >
                            <path
                              d="M17.4024 2.07528C18.1723 2.84415 18.1723 4.08973 17.4024 4.85965L6.40899 15.8565C6.00821 16.2538 5.51602 16.5456 4.97462 16.7073L0.721412 17.9589C0.523833 18.0151 0.310329 17.9624 0.164783 17.8147C0.0192215 17.6706 -0.0352215 17.4561 0.0228778 17.2593L1.27372 13.0054C1.43297 12.4639 1.72583 11.9718 2.12485 11.571L13.1203 0.576571C13.8902 -0.19219 15.1348 -0.19219 15.9047 0.576571L17.4024 2.07528ZM11.9883 3.29872L14.6813 5.96004L16.6078 4.06512C16.9383 3.73465 16.9383 3.20028 16.6078 2.87087L15.1102 1.37216C14.7797 1.04274 14.2453 1.04274 13.9149 1.37216L11.9883 3.29872ZM11.1938 4.09325L2.92044 12.369C2.6543 12.6327 2.45919 12.9632 2.35301 13.3253L1.39325 16.5878L4.65469 15.628C5.0168 15.5225 5.34727 15.3257 5.61095 15.0585L13.8551 6.78621L11.1938 4.09325Z"
                              fill="#9CA3AF"
                            />
                          </svg>
                        </h3>
                      </div>
                    </div>
                  </div>
                }

                {/* ULN label */}
                {showUln && (
                  <div className="py-4 border-y border-gray-200">
                    <label
                      htmlFor="Title"
                      className={
                        errors.uln || ulnError
                          ? "block text-sm font-poppins-400 text-red-500"
                          : "block text-sm font-poppins-400 text-gray-500"
                      }
                    >
                      ULN*
                    </label>
                    <div className="relative w-full rounded-md shadow-sm layout-search my-2">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        placeholder={
                          (ulnError && uln?.length === 0) || uln === null
                            ? "Enter learners ULN..."
                            : "22"
                        }
                        value={uln}
                        onChange={(e: any) => setUln(e.target.value)}
                        autoComplete="off"
                        className={
                          ulnError
                            ? "block w-full rounded-md  border border-red-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                            : "block w-full rounded-md  border border-gray-300 pr-10 border-lavender-500 shadow-sm sm:text-sm"
                        }
                      />
                      <p className="mt-2 text-sm text-red-500" id="email-error">
                        <>
                          {
                            ulnError ? (!uln ? 'Please provide a valid uln number.' : "Oops, it appears ULN is already taken.") : ''
                          }
                        </>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            {/* Course info */}
            <div className="mx-auto px-4 sm:px-6 lg:px-6">
              {props.pairSlid.courses?.map((each: any, index: any) => {
                return (
                  <div>
                    <div
                      className="pb-2 border-b border-gray-200 "
                      key={index}
                    >
                      <div className="font-poppins-500 text-gray-900 text-md">
                        {/* [course name] */}
                        {each.coursename}
                      </div>
                    </div>
                    { (props.user.user_type === "TP" && props.user.user_type !== "Trainer")  &&
                      <>
                        <div>
                          {each?.assigned_trainers?.map((item: any, ind: any) => {
                            return (
                              <div>
                                <div className="py-4 space-y-4 border-b  border-gray-200">
                                  <div className="flex items-center justify-between">
                                    <div className="flex px-4 py-2 sm:py-0 sm:px-0 items-center bg-gray-50 sm:bg-inherit">
                                      <div>
                                        <span className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-xs inline-flex items-center justify-center p-1 border-2 border-gray-500">
                                          {item.trainer_name?.trim()?.split(" ")
                                            ?.length > 1 ? (item.trainer_name?.trim()?.split(" ")[0][0].toUpperCase() +
                                            item.trainer_name?.trim()?.split(" ")[item.trainer_name?.trim()?.split(" ").length - 1][0].toUpperCase())
                                            : item.trainer_name?.trim()?.split(" ")[0][0].toUpperCase()
                                          }
                                        </span>
                                      </div>

                                      <div className="ml-3">
                                        <p className="text-sm font-inter-500 text-gray-700 ">
                                          {/* [Trainers full name] */}
                                          {item?.trainer_name}
                                        </p>
                                        <span className="text-xs gray-500">
                                          {/* [email] */}
                                          {item?.trainig_provider_email}
                                        </span>
                                      </div>
                                    </div>

                                    {/* { user?.user_type === "TP" && user?.user_type !== "Trainer" && <div
                                      className="flex-shrink-0"
                                      onClick={() =>
                                        removeTrainer(index, item, each.course_sfid)
                                      }
                                      >
                                        {" "}
                                        <h3 className="flex text-sm font-poppins-500 text-lavender cursor-pointer hover:underline">
                                          Remove
                                        </h3>
                                      </div>
                                    } */}
                                  </div>
                                </div>

                              </div>
                            );
                          })}
                        </div>
                        
                        <div className="py-4 pb-6 border-b border-gray-200">
                          <label
                            htmlFor="Title"
                            className="block text-sm font-poppins-400 text-gray-700"
                          >
                            Trainer
                          </label>
                          <div className="mt-1">

                            <select
                              id={index}
                              name=""
                              value={selectedTrainer[index]?.text}
                              onChange={(e: any) =>
                                trainerSelected(e.target.selectedOptions[0].id, index)
                              }
                              className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
                            >
                              <option value="Unpaired" key="Unpaired">
                                Unpaired
                              </option>
                              {trainer?.map(
                                (trainerList: any, index: any) => {
                                  return (
                                    <option id={trainerList.id}>
                                      {trainerList.trainer_name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                      </>
                    }
                  </div>)
              })}
            </div>
          </form>
        </div>


        {/* Cancel and Save button */}
        <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
          <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
            // onClick={clearFilter}
            onClick={() => {
              props.toggle(false)
              window.location.reload();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={() => submitTrainer()}
            className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
          >
            Save
          </button>
        </div>


      </div>
    </AppSlideOver>



  )
}


