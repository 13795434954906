import { Fragment, useEffect, useState } from "react";
import AppSlideOver from "./AppSlideOver";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Dialog } from "@headlessui/react";
import apiUrl from "../utils/apiUrl";
import axios from "../utils/axios";
import calenderIcon from "../assets/Icons/calenderIcon.svg";
import { useForm } from "react-hook-form";
import ScreenLoader from ".././utils/screenLoader";

export default function TrainerProfileEditslide(props: any) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    trigger,
    control,
  } = useForm();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [personalEmail, setPersonalEmail] = useState<string>("");
  const [personalEmailCheck, setPersonalEmailCheck] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  let emailtes = watch()
  useEffect(() => {
    console.log('props.', props);
    if (props) {
      reset();
    }
  }, [props]);
  useEffect(() => {
    if (props?.personalDetails) {
      console.log("hi")
      setFirstName(props.personalDetails?.profile_info?.first_name);
      setLastName(props.personalDetails?.profile_info?.last_name);
      setPersonalEmail(props.personalDetails.email);
    }
  }, [props]);

  const updateProfile = () => {
    setLoading(true);
    let req: any = {
      first_name: firstName,
      last_name: lastName,
      email: personalEmail,
    };
    if (props.personalDetails.id) {
      req.trainer_id = props.personalDetails.id;
    }
    axios.post(apiUrl.updatePersonalInfoTrainer, req).then(
      (res) => {
        if (res.status === 200) {
          props.toggle(false);
          props.personalProfile();
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
      })
  };


  const saveFilter = () => {
    if (props.personalDetails.email === personalEmail) {
      updateProfile();
    } else {
      // if(props?.personalDetails.Email !== personalEmail){
      axios.post(apiUrl.tcrVerifyEmail,
        {
          email: personalEmail
        }).then(
          (res) => {
            if (!res.data.email_exists) {
              updateProfile();
            } else {
              setPersonalEmailCheck(res.data.email_exists)

            }
          },

        )
      // }
    }
  }

  return (
    <AppSlideOver toggle={props.toggle} show={props.show}>
      <form
        className="mb-12"
        id="personal_detail"
        onSubmit={handleSubmit(saveFilter)}
      >
        <div className="flex min-h-0 flex-1 flex-col py-6 slide-over-input">
          <div className=" px-4 sm:px-6">
            <div className="flex items-start justify-between">
              <div className="text-lg font-inter-500 leading-7 text-gray-900">
                Personal
                <p className="text-sm text-gray-500 font-inter-400">

                  {
                    props.personalDetails?.profile_info?.first_name ? "Outline of " + props.personalDetails?.profile_info?.first_name + "'s personal details." : "Outline of personal details."
                  }
                </p>
              </div>

              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={() => props.toggle(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          <div className=" relative overflow-y-auto mt-6 pb-6 flex-1">
            {/* Replace with your content */}

            <div className="flex-1">
              <div className="py-4 border-b border-t border-gray-200 px-4 sm:px-6">
                <label
                  htmlFor="fileName"
                  className={
                    errors.fileName
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  First name *
                </label>
                <div className="mt-1">
                  <input
                    {...register("fileName", {
                      required: true,
                    })}
                    type="text"
                    className={
                      errors.fileName
                        ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-500 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                        : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                    }
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value ?? "")}
                    id="fileName"
                    placeholder=""
                  />
                </div>
                <p className="mt-2 text-sm text-red-500" id="email-error">
                  <>{errors.fileName ? "Please provide your first name." : ""}</>
                </p>
              </div>

              <div className="py-4 border-b border-gray-200 px-4 sm:px-6">
                <label
                  htmlFor="lastName"
                  className={
                    errors.lastName
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  Last name *
                </label>
                <div className="mt-1">
                  <input
                    {...register("lastName", {
                      required: true,
                    })}
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value ?? "")}
                    id="lastName"
                    className={
                      errors.lastName
                        ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-500 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                        : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                    }
                    placeholder=""
                  />
                </div>
                <p className="mt-2 text-sm text-red-500" id="email-error">
                  <>{errors.lastName ? "Please provide your last name." : ""}</>
                </p>
              </div>

              <div className="py-4 border-b border-gray-200 px-4 sm:px-6">
                <label
                  htmlFor="email"
                  className={
                    errors.email || personalEmailCheck
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  Email *
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="text"
                    className={
                      errors.email || personalEmailCheck
                        ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-500 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                        : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                    }
                    {...register("email", {
                      required: true,
                      pattern: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i,
                    })}
                    value={personalEmail}
                    onChange={(e) => setPersonalEmail(e.target.value ?? "")}
                    placeholder=""
                  />
                </div>
                <p className="mt-2 text-sm text-red-500" id="email-error">
                  <>
                    {personalEmailCheck ? "Oops it appears email is already taken." : errors.email?.type == "required"
                      ? "Required field. Please enter your email address."
                      : errors.email?.type == "pattern"
                        // ? "Sorry, we couldn't locate a registered account with this email address."
                        ? "Please enter valid email address."
                        : ""}
                  </>
                </p>
              </div>
            </div>
            {loading && (
              <div
                className={`${loading ? "h-12 flex items-center justify-center" : ""
                  }`}
              >
                <ScreenLoader />
              </div>
            )}
          </div>

          <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
              // onClick={clearFilter}
              onClick={() => {
                props.toggle(false)
                window.location.reload();
              }}
            >
              Cancel
            </button>
            <button
              // onClick={saveFilter}
              className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </AppSlideOver>
  );
}
