import { Fragment, useEffect, useState } from "react";
import AppSlideOver from "./AppSlideOver";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Dialog } from "@headlessui/react";

import calenderIcon from "../assets/Icons/calenderIcon.svg";
import { useForm } from "react-hook-form";
import apiUrl from "../utils/apiUrl";
import axios from "../utils/axios";
export default function InviteTrainer(props: any) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    trigger,
    control,
    setError,
    getValues
  } = useForm();

  // const [inviteEmail, setInviteEmail] = useState("")
  const [inviteEmailCheck, setInviteEmailCheck] = useState(false)
  const [inviteEmailArray, setInviteEmailArray] = useState<any>([])
  const trainerEmail = async () => {

    // const response = await axios.post(apiUrl.inviteTrainerTCR, {
    //   email:inviteEmailArray
    // })
    // props.toggle(false);
  };
  const saveFilter = async (data: any) => {
    for (let i = 0; i < inviteEmailArray.length; i++) {
      if (data.email === inviteEmailArray[i]) {
        setError("email", {
          type: "duplicate",
          message:
            "This email already exists in the list.",
        });
        return true;
      }
    }

    const res = await axios.post(apiUrl.tcrVerifyEmail,
      {
        email: data.email,
        type: "invite_user"
      })

    if (!res.data.email_exists) {
      setInviteEmailArray([...inviteEmailArray, data.email])
      reset();
      return true;
    } else {
      if (res?.data?.email_exists && !res?.data?.registered) {
        setError("email", {
          type: "invited",
          message:
            "Trainer already invited.",
        });
        setInviteEmailCheck(res.data.email_exists)
      } else {
        setError("email", {
          type: "registered",
          message:
            "Trainer already registered.",
        });
        setInviteEmailCheck(res.data.email_exists)
      }

      return true;

    }

  };

  const InviteAll = async () => {
    console.log('inviteEmailArray', inviteEmailArray, errors);
    let inviteEmail = getValues('email');

    if (!inviteEmailArray.length) {
      setError("email", {
        type: "addrequired",
        message:
          "You need to add email first before sending invite.",
      });
      return;
    }

    if (inviteEmail.length) {
      if (errors) {

      } else {
        let a = await saveFilter({
          email: inviteEmail
        });
      }
    }

    if (Object.keys(errors).length && errors?.email?.type !== 'required') {
      return;
    }
    let inviteEmailArrayCopy = [...inviteEmailArray];
    if (inviteEmail.length) {
      if (inviteEmailArrayCopy.indexOf(inviteEmail) === -1) {
        inviteEmailArrayCopy.push(inviteEmail);
      }
    }

    setInviteEmailArray([...inviteEmailArrayCopy]);
    const response = await axios.post(apiUrl.inviteTrainerTCR, {
      email: inviteEmailArrayCopy
    })
    if (response.status === 200) {
      props.toggle(false);
      props.setInvite([...inviteEmailArray]);
      setInviteEmailArray([]);
      props.toaster(true);
      props.getAllTrainers()
    }
  }

  return (
    <AppSlideOver toggle={props.toggle} show={props.show}>
      <div className="flex min-h-0 flex-1 flex-col py-6 slide-over-input">
        <div className=" px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <div className="text-lg font-inter-500 leading-7 text-gray-900">
              Invite Trainer
              <p className="text-sm text-gray-500 font-inter-400">
                Invite trainer to help you manage reports.
              </p>
            </div>

            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => props.toggle(false)}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <div className=" relative overflow-y-auto mt-6 pb-6 flex-1">
          {/* Replace with your content */}
          <form
            className="mb-12"
            id="personal_detail"
            onSubmit={handleSubmit(saveFilter)}

          >
            <div className="flex-1">
              <div className="py-4 border-b border-t border-gray-200 px-4 sm:px-6">
                <label
                  htmlFor="email"
                  className={errors.email ? "block text-sm font-poppins-400 text-red-700" : "block text-sm font-poppins-400 text-gray-700"}
                >
                  Invite by email
                </label>
                <div className="mt-1 flex">
                  {/* <input
                    type="text"
                    name="fileName"
                    value={inviteEmail}
                    id="fileName"
                    onChange={(e) => setInviteEmail(e.target.value)}
                    className="block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-500 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                    placeholder="Enter email address"
                  /> */}
                  <input
                    id="email"
                    type="text"
                    autoComplete="off"
                    className={
                      errors.email || inviteEmailCheck
                        ? "block w-full rounded-md border border-red-700 shadow-sm font-poppins-400 text-red-500 focus:outline-none active:border-red-700 focus:border-red-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                        : "block w-full rounded-md border border-gray-300 shadow-sm font-poppins-400 text-gray-700 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:text-gray-700 focus:shadow-sm sm:text-sm"
                    }
                    {...register("email", {
                      required: true,
                      pattern: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i,
                    })}
                    // value={inviteEmail}
                    // onChange={(e) => setInviteEmail(e.target.value ?? "")}
                    placeholder=""
                  />



                  <button
                    type="submit"
                    className="ml-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
                  // onClick={()=> trainerEmail}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 7.99964C15.5 8.31695 15.2404 8.57656 14.9231 8.57656H8.57692V14.9227C8.57692 15.2416 8.31889 15.5 8 15.5C7.68111 15.5 7.42308 15.24 7.42308 14.9227V8.57656H1.07692C0.758029 8.57656 0.5 8.31875 0.5 8C0.5 7.68233 0.758173 7.42272 1.07692 7.42272H7.42308V1.07656C7.42308 0.757668 7.68111 0.5 8 0.5C8.31889 0.5 8.57692 0.757813 8.57692 1.07656V7.42272H14.9231C15.2404 7.42272 15.5 7.68233 15.5 7.99964Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </button>


                </div>
                <p className="mt-2 text-sm text-red-500" id="email-error">
                  <>
                    {errors.email?.type === 'required' ? 'Please enter trainers email address to invite them.' : ''}
                    {errors.email?.type === 'pattern' ? 'Please enter valid email address.' : ''}
                    {errors.email?.type === 'registered' ? errors.email.message : ''}
                    {errors.email?.type === 'invited' ? errors.email.message : ''}
                    {errors.email?.type === 'duplicate' ? errors.email.message : ''}
                    {errors.email?.type === 'addrequired' ? errors.email.message : ''}
                  </>
                </p>
              </div>
              <div className="py-1 px-4 sm:px-6">
                {inviteEmailArray?.length > 0 &&
                  inviteEmailArray?.map((email: any, index: any) => {
                    return (


                      <span className="inline-flex items-center mt-2.5 mr-2 self-end rounded-full font-poppins-400 bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800 " key={index}>
                        {/* [email] */}
                        {email}
                        <button
                          type="button"
                          className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-400"
                          onClick={() => setInviteEmailArray(inviteEmailArray.filter((each: any, idx: any) => idx != index))}
                        >
                          <span className="sr-only">Remove filter for Option</span>
                          <svg
                            className="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-width="1.5"
                              d="M1 1l6 6m0-6L1 7"
                            />
                          </svg>
                        </button>
                      </span>


                    )
                  })}
              </div>

            </div>
          </form>
        </div>

        <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
          <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
            // onClick={clearFilter}
            onClick={() => {
              props.toggle(false)
              window.location.reload();
            }}
          >
            Cancel
          </button>
          <button
            onClick={InviteAll}
            className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M17.5113 0.197895C17.3214 0.0664805 17.0999 0 16.8749 0C16.6829 0 16.4903 0.0491836 16.3167 0.148465L0.566664 9.11682C0.194395 9.36211 -0.0242244 9.76641 0.00214282 10.1637C0.02851 10.5609 0.296576 10.9673 0.691908 11.1322L4.32003 12.6443L6.49444 17.7947C6.54604 17.9508 6.65151 18 6.7183 18C6.79069 18 6.86142 17.9714 6.9136 17.9212L9.92824 15.0244L14.1611 16.7878C14.312 16.8508 14.4599 16.8747 14.5931 16.8747C14.8257 16.8747 15.0189 16.8011 15.1444 16.7307C15.4465 16.5609 15.6531 16.2628 15.7058 15.9203L17.9558 1.32697C18.0527 0.869062 17.8699 0.442266 17.5113 0.197895ZM1.12495 10.125L14.4878 2.48906L4.3558 11.4715L1.12495 10.125ZM7.0558 16.2527L5.31205 12.1254L13.3804 4.97461L7.42143 13.1695C7.31926 13.3102 7.28794 13.4892 7.33572 13.6561C7.38402 13.8231 7.50601 13.9582 7.66752 14.0221L8.87689 14.5058L7.0558 16.2527ZM14.6495 15.5848L8.75385 13.2448L16.6886 2.33578L14.6495 15.5848Z"
                fill="#fff"
              />
            </svg>

            {inviteEmailArray?.length < 2 ? "Invite trainer" : "Invite " + inviteEmailArray.length + " trainers"}
          </button>
        </div>
      </div>
    </AppSlideOver>
  );
}
