import React, { useEffect, useState } from "react";
import AppSlideOver from "./AppSlideOver";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

export default function ReportsUploadedSlide(props: any) {

  return (
    <>
      <AppSlideOver toggle={props.toggle} show={props.show}>
        <div className="flex min-h-0 flex-1 flex-col py-6">
          <div className=" px-4 sm:px-6">
            <div className="flex items-start justify-between">
              <div className="text-lg font-inter-500 leading-7 text-gray-900">
                Profile
              </div>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={() => props.toggle(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          <div className=" relative overflow-y-auto mt-6 pb-6 flex-1 px-4 sm:px-6">
            <h1>Body area</h1>
          </div>

          {/* Cancel and Save button */}
          <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-2">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
              onClick={() => {
                props.toggle(false);
              }}
            >
              Cancel
            </button>
            <button type="button"
              className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
            >
              Save
            </button>
          </div>

          {/* </form> */}
        </div>
      </AppSlideOver>
    </>
  );
}
