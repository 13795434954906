/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import conveyaLogo from './../../assets/images/conveya-c-logo.png';
import backarrow from './../../assets/Icons/backarrow.svg';
import '../styles/Login.scss';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import axios from '../../utils/axios';
import apiUrl from '../../utils/apiUrl';
import { useNavigate } from "react-router-dom";

export default function ClaimAccount1() {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors }, setError } = useForm();
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await axios.post(apiUrl.claim, data);
      if (response.status === 200) {
        navigate('/claim-account-2/claim', {state: {email: data.email}});
      }
    } catch(e:any) {
      if(e.response.status === 401) {
        setError('email',{
          type: 'no_email_present',
          message: "Sorry, we couldn't locate an account with this email address. "
        })
      }
      else if(e.response.status === 409) {
        setError('email',{
          type: 'existing_email',
          message: "This email appears to be already claimed. "
        })
      }
      else if(e.response.status === 422) {
        setError('email',{
          type: 'invalid_email',
          message: "Invalid email address "
        })
      }
    }
    
    
  }
  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
      <div className="flex min-h-full flex-col  py-12 sm:px-6 lg:px-8 auth-pages">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={conveyaLogo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-inter-800 text-gray-900">Claim your account</h2>
          <p className="mt-2 text-center text-sm text-gray-600 font-inter-400">
            Enter your email and we'll send you a link to complete your account registration.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="email" className={errors.email ? "block text-sm font-poppins-400 text-red-700" : "block text-sm font-poppins-400 text-gray-700"}>
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                    name="email"
                    type="text"
                    autoComplete="email"
                    // className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                    className={errors.email ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm" : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"}
                />
                </div>
                <p className="mt-2 text-sm text-red-700" id="email-error">
                  <>
                    {errors.email?.type == "required" ? "Required field" : ""}
                    {errors.email?.type == "pattern" ? "Sorry, we couldn't locate a registered account with this email address." : ""}
                    {errors.email?.type == "no_email_present" ? errors.email.message : ""}
                    {errors.email?.type == "existing_email" ? errors.email.message  : ""}
                    {errors.email?.type == "invalid_email" ? errors.email.message : ""}
                    {errors.email?.type == "existing_email" && 
                    <a href="reset-password" className="text-lavender pr-1">
                       Reset your password.
                    </a>}
                  </>
                </p>
              </div>
              <div >
            



              <button
                  type="submit"
                  className="flex w-full justify-center btn-lavender "
                >
                  {/* <a href="claim-account-2">  Claim account</a> */}
                  Claim account
                </button>
              </div>

              <div className="border-solid border-gray-300 border-b pt-1"></div>
              <div className="flex items-center justify-center pt-2">
                <div className="text-sm flex items-center">
                  <a href="login" className="font-poppins-500 text-lavender">
                    <img src={backarrow} alt="Back to log in" className="inline-block mr-1 align-baseline" />  Back to log in
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="mt-auto">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md space-x-6">
            <p className="text-base font-inter-400 text-gray-400 text-center">Questions? Email us at <a href="mailto:info@conveya.co" className="text-lavender"> info@conveya.co</a></p>
          </div>
        </footer>
      </div>
    </>
  )
}
