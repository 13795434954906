import React, { useEffect, useState } from "react";
import conveyaLogo from "../../../assets/images/conveya-logo.svg";
import eyeclosedIcon from "../../../assets/Icons/eyeclosed.svg";
import eyeIcon from "../../../assets/Icons/eyeIcon.svg";
import visibleEyeRed from "../../../assets/Icons/visibleEyeRed.svg";
import eyeopenIcon from "../../../assets/Icons/eyeopen.svg";

import mailIcon from "../../../assets/Icons/mailIcon.svg";
import conveyaIcon from "../../../assets/images/conveya-c-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import correctIconGray from "../../../assets/Icons/correctIconGray.svg";
import correctIconGreen from "../../../assets/Icons/correctIconGreen.svg";
import crossIconRed from "../../../assets/Icons/crossIconRed.svg";
import apiUrl from "../../../utils/apiUrl";
import axios from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { setSession } from "../../../utils/jwt";

export default function RegisterByLinkVerifyEmail(props: any) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  const { login, logout, isAuthenticated, isInitialized } = useAuth();
  const { state }: { state: any } = useLocation();
  const emailRegisterd = state?.email;
  const accountId = state.accountId;
  const trainingProviderName = state.trainingProviderName;
  console.log(accountId, trainingProviderName);
  let navigate = useNavigate();

  const onSubmit = async (data: any) => {
    if (isAuthenticated) {
      await logout();
      sessionStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
      setSession(null);
      //  navigate

      //  return
    }
    axios
      .post("/api/register/verifyemail", {
        email: state?.email,
        verificationCode: data.verificationCode,
      })
      .then((response) => {
        if (response.data.code === 200) {
          navigate("/login");
        } else {
          setError("verificationCode", {
            message: "verification fail",
          });
        }
      })
      .catch(({ response }) => {
        setError("verificationCode", {
          message: response.data.message,
        });
      });
  };

  return (
    <>
      <div className="flex min-h-full flex-col registerTrainer justify-center">
        <div className="px-4 sm:px-0 sm:w-full sm:max-w-xs lg:ml-20  xl:ml-60 mx-auto pt-16 md:pt-20">
          <img src={conveyaLogo} className="h-8" alt="Conveya" />
          <h2 className="mt-6 text-3xl font-poppins-700  text-gray-700">
            Check your email
          </h2>

          <p className="text-sm mt-2 text-gray-600">
            Please check your inbox and follow the instructions to complete your
            account registration.
          </p>

          <form
            className="space-y-6 mt-10"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <label className="block text-sm font-poppins-400 text-gray-700">
                Email
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  className={
                    errors.email
                      ? "block w-full appearance-none rounded-md border border-red-700 pl-10 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                      : "block w-full appearance-none rounded-md border border-gray-300 pl-10 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                  }
                  defaultValue={emailRegisterd}
                  disabled
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-index">
                  <img src={mailIcon}></img>
                </div>
              </div>
              <p className="mt-2 text-sm text-red-700">
                <>
                  {errors.email?.type == "required" ? "Required field" : ""}
                  {errors.email?.type == "pattern"
                    ? "Sorry, we couldn't locate a registered account with this email address."
                    : ""}
                </>
              </p>
            </div>

            <div className="relative mt-1 rounded-md shadow-sm">
              <label
                className={
                  errors.verificationCode
                    ? "block text-sm font-poppins-400 text-red-700"
                    : "block text-sm font-poppins-400 text-gray-700"
                }
              >
                Verification code
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="text"
                  id="verificationCode"
                  {...register("verificationCode", { required: true })}
                  autoComplete="off"
                  //  maxLength={20}
                  //  minLength={8}
                  className={
                    errors.verificationCode
                      ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                      : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                  }
                  placeholder=""
                />
              </div>
              <p className="mt-2 text-sm text-red-700">
                <>
                  {errors.verificationCode?.type == "required"
                    ? "Required field"
                    : errors.verificationCode?.message}
                </>
              </p>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center btn-lavender"
              >
                Submit code
              </button>
            </div>
          </form>
        </div>

        <footer className="mt-auto py-4 xl:hidden">
          <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
            <p className="text-base font-poppins-500 text-gray-400 text-center">
              <img src={conveyaIcon} className="inline-block h-5 w-5 -mt-px" />{" "}
              Powered by <span className="text-lavender">Conveya</span> -
              Formally known as Scopesuite
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
