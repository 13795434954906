import { useState, ReactNode } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// pages
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );
  const navigate = useNavigate();


  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    navigate("/login");
    // return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    let path = requestedLocation;
    setRequestedLocation(null);
    return <Navigate to={path} />;
  }

  return <>{children}</>;
}
