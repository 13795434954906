import axios from '../utils/axios'
import apiUrl from '../utils/apiUrl'
import moment from 'moment'
import { FileObj } from '../utils/common/FileDrop'

export const submitReportForm = ({
	individualReport,
	startDate,
	lookUps,
	math,
	english,
	withdrawnOPtions,
	lstFiles,
	fileKeys
}: any) => {
	let report: any = {
		...individualReport
	}
	report.nextDate =
		startDate !== null && startDate
			? moment(startDate).format('YYYY-MM-DD')
			: ''
	report.flag = report.flag ? report.flag : ''
	report.comment = report.comment ? report.comment : ''
	report.bilEndDate = report.bilEndDate
		? moment(report.bilEndDate).format('YYYY-MM-DD')
		: ''
	report.bilStartDate = report.bilStartDate
		? moment(report.bilStartDate).format('YYYY-MM-DD')
		: ''
	report.completionDate = report.completionDate
		? moment(report.completionDate).format('YYYY-MM-DD')
		: ''
	report.epaStartDate = report.epaStartDate
		? moment(report.epaStartDate).format('YYYY-MM-DD')
		: ''
	// report.LeaverDate = report.LeaverDate
	//   ? moment(report.LeaverDate).format("YYYY-MM-DD")
	//   : "";
	// report.AdditionalTag = report.AdditionalTag ?? "";
	// report.rag = report.rag ?? "Amber";
	report.startDate = report.startDate
		? moment(report.startDate).format('YYYY-MM-DD')
		: ''
	report.plannedEndDate = report.plannedEndDate
		? moment(report.plannedEndDate).format('YYYY-MM-DD')
		: ''
	// report.enddate = report.endDate ? moment(report.endDate).format("YYYY-MM-DD") : ""
	if (report.courseProgress === 'Completed') {
		report.completionStatus = report.completionStatus ?? ''
	} else {
		report.completionStatus = ''
	}
	// report.FunctionalSkills = individualReport?.FunctionalSkills ?? "";
	// report.courseProgress = report.courseProgress ?? 'Ongoing'
	report.fsmathStatus = math
	report.fsenglishStatus = english
	report.detailFiles = lstFiles
	report.withdrawnReasons = withdrawnOPtions
	// var data = new FormData();
	// data.append("payload", JSON.stringify(report));
	// if (lstFiles?.length) {
	//   lstFiles.map((file: FileObj) => {
	//     return data.append("files", file.FileBlob ? file.FileBlob : file);
	//   });
	// }
	return axios.post('/api/report/save', report)
}
