import React, { useState } from "react";
import backarrow from "./../../assets/Icons/backarrow.svg";
import eyeclosedIcon from "../../assets/Icons/eyeclosed.svg";
import eyeopenIcon from "../../assets/Icons/eyeopen.svg";
import conveyaLogo from "./../../assets/images/conveya-c-logo.png";
import "../styles/Login.scss";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import apiUrl from "../../utils/apiUrl";
import conveyaLongLogo from "./../../assets/images/conveyaLongLogo.png";
import eyeIcon from "../../assets/Icons/eyeIcon.svg";
import visibleEyeRed from "../../assets/Icons/visibleEyeRed.svg";
function ResetCode() {
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { state }: { state: any } = useLocation();
  const [inheritedEmail, setInheritedEmail] = useState<any>(
    state?.email ? state.email : null
  );
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  const onSubmit = (data: any) => {
    setLoading(true);
    axios
      .post(apiUrl.verifyResetCode, {
        verificationCode: data.verification_code,
        email: inheritedEmail ? inheritedEmail : data.email,
      })
      .then((res) => {
        if (res.data.code === 200) {
          navigate("/create-new-password", {
            state: { email: inheritedEmail ? inheritedEmail : data.email },
          });
        } else if (res.data.code === 201 || res.data.code === 205) {
          setError("verification_code", {
            type: "",
            message: "The verification code appears to be incorrect.",
          });
        } else if (res.data.code === 202) {
          setError("verification_code", {
            type: "lock_email",
            message:
              "Oops! It seems you've hit the login attempt limit. Please retry in 15 minutes.",
          });
        } else if (res.data.code === 203) {
          setError("verification_code", {
            type: "",
            message: res.data.message,
          });
        } else if (res.data.code === 204) {
          setError("verification_code", {
            type: "code_expire",
            message: "Verification code expired. ",
          });
        } else {
          setError("email", {
            type: "generic",
            message: "Sorry, you cannot reset password for this email.",
          });
        }
        // setLoading(false);
      })
      .catch(() => {
        setError("email", {
          type: "generic",
          message: "Sorry, you cannot reset password for this email.",
        });
      })
      .finally(() => setLoading(false));
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
      <div className="flex min-h-full flex-col  py-12 sm:px-6 lg:px-8 auth-pages">
        <div className="px-4 sm:px-0 sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-8 w-auto"
            src={conveyaLongLogo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-inter-800 text-gray-900">
            Enter verification code
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600 font-inter-400">
            A 9-digit verification code has been sent to your email address.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <label
                  htmlFor="email"
                  className={
                    errors.email
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  Email address
                </label>
                <div className="mt-1">
                  {inheritedEmail ? (
                    <input
                      id="email1"
                      value={inheritedEmail}
                      disabled
                      // className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                      className={
                        "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                      }
                    />
                  ) : (
                    <input
                      id="email"
                      maxLength={80}
                      autoComplete="email"
                      {...register("email", {
                        required: true,
                        pattern: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i,
                      })}
                      // className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                      className={
                        errors.email
                          ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                          : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                      }
                    />
                  )}
                </div>
                <p className="mt-2 text-sm text-red-700" id="email-error">
                  <>
                    {errors.email?.type == "required" ? "Required field" : ""}
                    {errors.email?.type == "pattern"
                      ? "Sorry, we couldn't locate a registered account with this email address."
                      : ""}
                    {errors.email?.type == "no_email_present"
                      ? errors.email.message
                      : ""}
                    {errors.email?.type == "existing_email"
                      ? errors.email.message
                      : ""}
                    {errors.email?.type == "generic"
                      ? errors.email.message
                      : ""}
                  </>
                </p>
              </div>
              <div>
                <label
                  htmlFor="verification_code"
                  className={
                    errors.verification_code
                      ? "block text-sm font-poppins-400 text-red-700"
                      : "block text-sm font-poppins-400 text-gray-700"
                  }
                >
                  Verification code
                </label>
                {/* <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none  sm:text-sm"
                    />
                  </div> */}
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="verification_code"
                    {...register("verification_code", { required: true })}
                    autoComplete="verification_code"
                    className={
                      errors.verification_code
                        ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                        : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                    }
                    placeholder=""
                  />
                  <div
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <img
                      src={
                        passwordVisible
                          ? errors.verification_code
                            ? visibleEyeRed
                            : eyeopenIcon
                          : errors.verification_code
                          ? eyeIcon
                          : eyeclosedIcon
                      }
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <p className="mt-2 text-sm text-red-700" id="email-error">
                  <>
                    {errors.verification_code?.type == "required"
                      ? "Required field"
                      : ""}
                    {errors?.verification_code?.message
                      ? errors?.verification_code?.message
                      : ""}
                    {errors.verification_code?.type == "code_expire" && (
                      <Link
                        to="/reset-password"
                        // target=""
                        className="font-poppins-500 text-lavender"
                      >
                        Please request a new code
                      </Link>
                    )}
                  </>
                </p>
              </div>

              <div className="flex items-center justify-center pt-2">
                <div className="text-sm flex items-center">
                  <a href="login" className="font-poppins-500 text-lavender">
                    <img
                      src={backarrow}
                      alt="Back to log in"
                      className="inline-block mr-1 align-baseline"
                    />{" "}
                    Back to log in
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center btn-lavender "
                  style={{ opacity: loading ? 0.4 : 1 }}
                  disabled={loading ? true : false}
                >
                  Submit code
                </button>
              </div>

              {/* <div className="border-solid border-gray-300 border-b pt-1"></div> */}
            </form>
          </div>
        </div>
        <footer className="mt-auto">
          <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
            <p className="text-base font-poppins-500 text-gray-400 text-center">
              <img
                src={conveyaLogo}
                className="inline-block h-5 w-5 -mt-px"
                alt=""
              />{" "}
              Powered by <span className="text-lavender">Conveya</span> -
              Formally known as Scopesuite
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default ResetCode;
