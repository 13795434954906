import axios from "axios";
import { Navigate } from "react-router-dom";
// config
import { HOST_API } from "../config";
import apiUrl from "../utils/apiUrl";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  // headers: {
  //   'Access-Control-Allow-Origin' : '*',
  //   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  //   }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.setItem("accessToken", "");
      window.location.href = "/login";
    }
    // console.log('error', error);
    // return error;
    throw error;
    // Promise.reject('Something went wrong');
    // Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
