import { Fragment, useContext, useEffect, useState } from "react";

import LogoIconBlack from "../../../assets/Icons/LogoIconBlack.svg";
import userImage from "../../../assets/images/userImage.jpg";

import { Outlet, useLocation, Link, useNavigate } from "react-router-dom";

import { Dialog, Menu, Transition } from "@headlessui/react";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import InviteTrainer from "../../../components/InviteTrainerSlide";
import { mapKeys, camelCase } from "lodash";
import apiUrl from "../../../utils/apiUrl";
import axiosInstance from "../../../utils/axios";
import ScreenLoader from "../../../utils/screenLoader";
import NoData from "../../../utils/NoData";
import RemoveTrainerModel from "../../../components/RemoveTrainerModel";
import { searchContext } from "../Layout";
import SuccsessToaster from "../../../components/tosters/SuccsessToaster";
import TrainerRemovedToaster from "../../../components/tosters/TrainerRemovedToaster";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export interface ITrainer {
  trainerId: number;
  tp_sfid: number;
  trainerName: string;
  logo: string;
  trainerEmail: string;
  isLoading: boolean;
  isNotValidSrc: boolean;
  trainerStatus: string;
}

function Trainers() {
  let layoutSearch = useContext(searchContext);
  const [isInviteTrainer, setIsInviteTrainer] = useState(false);
  const [isInviteToaster, setIsInviteToaster] = useState(false);
  const [undo, setUndo] = useState(false);
  const [invite, setInvite] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [inviteFaile, setInviteFaile] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [trainers, setTrainers] = useState<any>([]);
  const [showRemoveTrainerModel, setShowRemoveTrainerModel] = useState(false);
  const [removeTrainerID, setRemoveTrainerID] = useState("");
  const [removeTrainer, setRemoveTrainer] = useState(" ");
  const [message, setMessage] = useState<string>("");
  const [showToaster, setShowToaster] = useState<boolean>(false);
  const navigate = useNavigate();

  const toggleInviteTrainer = (v: boolean) => {
    if (v && isInviteTrainer) {
      setIsInviteTrainer(false);
      setTimeout(() => {
        setIsInviteTrainer(true);
      }, 100);
    } else {
      setIsInviteTrainer(v);
    }
  };
  const toggleInviteTrainerToaster = (v: boolean) => {
    if (v === true) {
      setIsInviteToaster(true);
      setTimeout(() => {
        setIsInviteToaster(false);
      }, 2000);
    }
  };

  const toggleRemoveTrainerModel = (value: boolean) => {
    setShowRemoveTrainerModel(value);
    if (value === false) {
      getAllTrainers();
    }
  };

  const successfulRemoval = () => {
    setMessage('Trainer has been removed successfully.');
    setShowToaster(true);
    setTimeout(() => {

      setShowToaster(false);
      navigate("/a/trainers");
    }, 3000)
  }

  const resendInvite = async (trainer: any) => {
    console.log('trainer', trainer);
    let res = await axiosInstance.post(
      apiUrl.resendInvite,
      {
        email: [trainer.email],
      }
    );
    if (res.status === 200) {
      setInvite([
        trainer.email
      ])
      toggleInviteTrainerToaster(true);
    }



  }

  const getAllTrainers = async (searchText?: string) => {
    setLoading(true);
    let res = await axiosInstance.post(
      apiUrl.getTrainers,
      {
        search_key: searchText ?? "",
      },
      {
        params: {
          skip: 0,
          limit: 10000,
        },
      }
    );
    if (res.status === 200) {
      setLoading(false);
      setTrainers(res.data?.records);
      console.log("trainers", res.data?.records)
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (layoutSearch?.searchIn === "trainers") {
      searchOptions(layoutSearch?.search)

    }

  }, [layoutSearch?.search])
  const searchOptions = (val: string) => {
    setSearchText(val);
    if (!val) {
      getAllTrainers();
    } else {
      getAllTrainers(val);
    }
  };
  useEffect(() => {
    getAllTrainers();
  }, []);
  const controller = new AbortController();

  return (
    <>
      <div>

        <TrainerRemovedToaster
          show={showToaster}
          toggle={(val: boolean) => {
            setShowToaster(val);
          }}
          name={removeTrainer}
        />

        <RemoveTrainerModel
          show={showRemoveTrainerModel}
          toggle={toggleRemoveTrainerModel}
          name={removeTrainer}
          id={removeTrainerID}
          successfulRemoval={successfulRemoval}
        />
      </div>

      {inviteFaile && (
        <div
          id="failedInvites-toster"
          className="flex items-top w-full max-w-sm p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 fixed z-50 right-2 top-2"
          role="alert"
        >
          <div className="inline-flex items-self-start justify-center flex-shrink-0 w-8 h-8">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.47031 8.47031C8.76094 8.175 9.23906 8.175 9.52969 8.47031L12 10.9406L14.4703 8.47031C14.7609 8.175 15.2391 8.175 15.5297 8.47031C15.825 8.76094 15.825 9.23906 15.5297 9.52969L13.0594 12L15.5297 14.4703C15.825 14.7609 15.825 15.2391 15.5297 15.5297C15.2391 15.825 14.7609 15.825 14.4703 15.5297L12 13.0594L9.52969 15.5297C9.23906 15.825 8.76094 15.825 8.47031 15.5297C8.175 15.2391 8.175 14.7609 8.47031 14.4703L10.9406 12L8.47031 9.52969C8.175 9.23906 8.175 8.76094 8.47031 8.47031ZM24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12ZM12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5Z"
                fill="#F87171"
              />
            </svg>
            <span className="sr-only">Error icon</span>
          </div>
          <div className="ml-1 text-sm font-normal">
            <h2 className="font-poppins-500 text-gray-900">
              Failed to sent the invite.
            </h2>
            <p className="pt-1">
              {/* [email], [email] */}
              {invite?.map((email: any, index: any) => {
                return (
                  <span className="ml-2" key={index}>
                    {email},
                  </span>
                );
              })}
            </p>
          </div>
          <button
            type="button"
            className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            data-dismiss-target="#toast-danger"
            aria-label="Close"
          >
            <span className="sr-only">Close</span>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      )}

      {isInviteToaster && (
        <div
          id="invited-toster"
          className="flex items-top w-full max-w-sm p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 fixed z-50 right-2 top-2"
          role="alert"
        >
          <div className="inline-flex items-self-start justify-center flex-shrink-0 w-8 h-8">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5113 0.197895C17.3214 0.0664805 17.0999 0 16.8749 0C16.6829 0 16.4903 0.0491836 16.3167 0.148465L0.566664 9.11682C0.194395 9.36211 -0.0242244 9.76641 0.00214282 10.1637C0.02851 10.5609 0.296576 10.9673 0.691908 11.1322L4.32003 12.6443L6.49444 17.7947C6.54604 17.9508 6.65151 18 6.7183 18C6.79069 18 6.86142 17.9714 6.9136 17.9212L9.92824 15.0244L14.1611 16.7878C14.312 16.8508 14.4599 16.8747 14.5931 16.8747C14.8257 16.8747 15.0189 16.8011 15.1444 16.7307C15.4465 16.5609 15.6531 16.2628 15.7058 15.9203L17.9558 1.32697C18.0527 0.869062 17.8699 0.442266 17.5113 0.197895ZM1.12495 10.125L14.4878 2.48906L4.3558 11.4715L1.12495 10.125ZM7.0558 16.2527L5.31205 12.1254L13.3804 4.97461L7.42143 13.1695C7.31926 13.3102 7.28794 13.4892 7.33572 13.6561C7.38402 13.8231 7.50601 13.9582 7.66752 14.0221L8.87689 14.5058L7.0558 16.2527ZM14.6495 15.5848L8.75385 13.2448L16.6886 2.33578L14.6495 15.5848Z"
                fill="#9CA3AF"
              />
            </svg>

            {/* <span className="sr-only">Error icon</span> */}
          </div>
          <div className="ml-1 text-sm font-normal">
            <h2 className="font-poppins-500 text-gray-900">
              {invite.length > 1
                ? `${invite?.length} Trainers invited.`
                : "Trainer invited."}
            </h2>
            <p className="pt-1">
              {invite?.map((email: any, index: any) => {
                return (
                  <span className="ml-2" key={index}>
                    {`${email} invited,`}
                  </span>
                );
              })}
              {/* [email invited], [email], [email] */}
            </p>
          </div>
          <button
            type="button"
            className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            data-dismiss-target="#toast-danger"
            aria-label="Close"
          >
            <span className="sr-only">Close</span>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      )}

      <InviteTrainer
        show={isInviteTrainer}
        // toggleUndo={toggleUndo}
        toaster={toggleInviteTrainerToaster}
        toggle={toggleInviteTrainer}
        setInvite={setInvite}
        getAllTrainers={getAllTrainers}
      />

      <div className="px-4 py-10 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:items-center justify-between sm:flex-row">
          <div>
            <h3 className="text-lg font-medium font-poppins-500 leading-6 text-gray-900 ">
              Trainers
            </h3>
          </div>

          <div className="flex sm:mt-0 sm:ml-6 ">
            <button
              onClick={() => toggleInviteTrainer(true)}
              type="button"
              className="relative w-full sm:w-auto mt-2 sm:mt-0 sm:ml-3 sm:mr-3  items-center rounded-md border border-gray-300 bg-lavender px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span>Invite trainer</span>
            </button>

            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="hidden lg:block  relative rounded-md shadow-sm layout-search">
              <input
                type="text"
                autoComplete="off"
                name="search"
                id="search"
                value={searchText}
                className="block w-72 sm:w-80 rounded-md  border border-gray-300 pr-10 border-lavender-500  shadow-sm sm:text-sm"
                placeholder="Search"
                onChange={(e) => searchOptions(e.target.value ?? "")}
              />
              <div
                className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                aria-hidden="true"
              >
                <MagnifyingGlassIcon
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4 mt-6 bg-white shadow-base rounded-lg">
          {trainers.length > 0 && !loading ? (
            trainers.map((trainer: any, ind: any) => {
              // trainer = mapKeys(trainer, (v, k) =>
              //   camelCase(k)
              // ) as unknown as ITrainer;
              // trainer.isNotValidSrc = true;
              return (
                <div>
                  <Link to={trainer.active ? `/a/view-trainer/${trainer.id}` : ''}>
                    {/* <Link to={`/a/view-trainer/${trainer.tp_sfid}`}> */}
                    <div className="border-b px-4 py-4 sm:px-6">
                      <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                        <div>
                          <div className="flex items-center">
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                {(trainer?.user_profile?.photo_url) &&
                                  <img
                                    // onError={() => {
                                    //   trainers[ind].isNotValidSrc = true;
                                    //   setTrainers([...trainers]);
                                    // }}
                                    className="h-10 w-10 rounded-full object-cover"
                                    src={trainer?.user_profile?.photo_url}
                                    alt=""
                                  />
                                }
                                {/* {trainer.isNotValidSrc && ( */}

                                {!(trainer?.user_profile?.photo_url) && trainer?.trainer_name && <span className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-sm inline-flex items-center justify-center p-1">
                                  {trainer.trainer_name?.trim()?.split(" ")
                                    ?.length > 1
                                    ? trainer.trainer_name
                                      ?.split(" ")[0][0]
                                      .toUpperCase() +
                                    trainer.trainer_name
                                      ?.split(" ")
                                    [
                                      trainer.trainer_name?.split(" ")
                                        .length - 1
                                    ][0].toUpperCase()
                                    : trainer.trainer_name
                                      ?.split(" ")[0][0]
                                      .toUpperCase()}
                                </span>}
                                {/* )} */}
                                {!trainer?.user_profile?.photo_url && !trainer?.trainer_name && <div className="flex-shrink-0">
                                  <svg
                                    className="w-10 h-10 text-lavender"
                                    viewBox="0 0 18 18"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M9.00002 0.899902C4.52604 0.899902 0.900024 4.52592 0.900024 8.9999C0.900024 13.4739 4.52604 17.0999 9.00002 17.0999C13.474 17.0999 17.1 13.4739 17.1 8.9999C17.1 4.52592 13.474 0.899902 9.00002 0.899902ZM9.00002 16.0874C7.50532 16.0874 6.11946 15.6198 4.97534 14.8268C5.10506 13.2682 6.39917 12.0374 7.98752 12.0374H10.0125C11.6025 12.0374 12.8963 13.2695 13.025 14.8268C11.8793 15.6191 10.4935 16.0874 9.00002 16.0874ZM13.9265 14.0846C13.4803 12.3317 11.9015 11.0249 10.0125 11.0249H7.98752C6.09763 11.0249 4.51971 12.3317 4.07358 14.0852C2.74372 12.7683 1.91252 10.9933 1.91252 8.9999C1.91252 5.09229 5.09241 1.9124 9.00002 1.9124C12.9076 1.9124 16.0875 5.09229 16.0875 8.9999C16.0875 10.9933 15.2554 12.7683 13.9265 14.0846ZM9.00002 4.9499C7.60151 4.9499 6.46877 6.08264 6.46877 7.48115C6.46877 8.87967 7.60151 10.0124 9.00002 10.0124C10.3985 10.0124 11.5313 8.87904 11.5313 7.48115C11.5313 6.08264 10.3985 4.9499 9.00002 4.9499ZM9.00002 8.9999C8.16155 8.9999 7.48127 8.31963 7.48127 7.48115C7.48127 6.64268 8.16155 5.9624 9.00002 5.9624C9.8385 5.9624 10.5188 6.64363 10.5188 7.48115C10.5188 8.31868 9.8385 8.9999 9.00002 8.9999Z" />
                                  </svg>
                                </div>}
                              </div>
                            </div>
                            <div className="ml-4">
                              <h3 className="text-sm font-medium leading-6 text-gray-900">
                                {trainer.trainer_name}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {trainer.email}
                              </p>
                            </div>
                          </div>
                        </div>
                        {trainer.active === true && (
                          <div className="flex items-center">
                            <div className="w-40 text-center hidden md:inline-block">
                              <span className=" text-green-800 text-xs font-medium bg-green-100 px-3 py-0.5 rounded-full mx-2">
                                Registered
                              </span>
                            </div>

                            <Menu
                              as="div"
                              className="hidden md:block relative ml-3"
                            >
                              <div>
                                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm ring-lavender-500 focus:outline-none  focus:ring-offset-2 text-gray-400 hover:text-gray-600">
                                  <span className="sr-only">Open options</span>
                                  <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                                  </svg>
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to={`/a/edit-profile/${trainer.id}`}
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "flex px-4 py-2 text-sm font-poppins-400"
                                          )}
                                        >
                                          <svg
                                            className="mr-3 w-4 text-gray-400"
                                            viewBox="0 0 18 18"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M17.4024 2.07528C18.1723 2.84415 18.1723 4.08973 17.4024 4.85965L6.40899 15.8565C6.00821 16.2538 5.51602 16.5456 4.97462 16.7073L0.721412 17.9589C0.523833 18.0151 0.310329 17.9624 0.164783 17.8147C0.0192215 17.6706 -0.0352215 17.4561 0.0228778 17.2593L1.27372 13.0054C1.43297 12.4639 1.72583 11.9718 2.12485 11.571L13.1203 0.576571C13.8902 -0.19219 15.1348 -0.19219 15.9047 0.576571L17.4024 2.07528ZM11.9883 3.29872L14.6813 5.96004L16.6078 4.06512C16.9383 3.73465 16.9383 3.20028 16.6078 2.87087L15.1102 1.37216C14.7797 1.04274 14.2453 1.04274 13.9149 1.37216L11.9883 3.29872ZM11.1938 4.09325L2.92044 12.369C2.6543 12.6327 2.45919 12.9632 2.35301 13.3253L1.39325 16.5878L4.65469 15.628C5.0168 15.5225 5.34727 15.3257 5.61095 15.0585L13.8551 6.78621L11.1938 4.09325Z" />
                                          </svg>
                                          Edit profile
                                        </Link>
                                      )}
                                    </Menu.Item>

                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to=""
                                          onClick={() => {
                                            toggleRemoveTrainerModel(true);
                                            setRemoveTrainerID(trainer.id);
                                            setRemoveTrainer(
                                              trainer.trainer_name
                                            );
                                          }}
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "flex px-4 py-2 text-sm font-poppins-400"
                                          )}
                                        >
                                          <svg
                                            className="mr-3"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M5.29395 5.79395C5.47559 5.60938 5.77441 5.60938 5.95605 5.79395L7.5 7.33789L9.04395 5.79395C9.22559 5.60938 9.52441 5.60938 9.70605 5.79395C9.89062 5.97559 9.89062 6.27441 9.70605 6.45605L8.16211 8L9.70605 9.54395C9.89062 9.72559 9.89062 10.0244 9.70605 10.2061C9.52441 10.3906 9.22559 10.3906 9.04395 10.2061L7.5 8.66211L5.95605 10.2061C5.77441 10.3906 5.47559 10.3906 5.29395 10.2061C5.10938 10.0244 5.10938 9.72559 5.29395 9.54395L6.83789 8L5.29395 6.45605C5.10938 6.27441 5.10938 5.97559 5.29395 5.79395ZM15 8C15 12.1426 11.6426 15.5 7.5 15.5C3.35742 15.5 0 12.1426 0 8C0 3.85742 3.35742 0.5 7.5 0.5C11.6426 0.5 15 3.85742 15 8ZM7.5 1.4375C3.87598 1.4375 0.9375 4.37598 0.9375 8C0.9375 11.624 3.87598 14.5625 7.5 14.5625C11.124 14.5625 14.0625 11.624 14.0625 8C14.0625 4.37598 11.124 1.4375 7.5 1.4375Z"
                                              fill="#9CA3AF"
                                            />
                                          </svg>
                                          Remove
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        )}
                        {trainer.active !== true && (
                          <div className="flex items-center">
                            <div className="w-40 text-center">
                              <span className="hidden md:inline-block text-indigo-800 text-xs font-medium bg-indigo-100 px-3 py-0.5 rounded-full mx-2">
                                Invited
                              </span>
                            </div>

                            <Menu
                              as="div"
                              className="hidden md:block relative ml-3"
                            >
                              <div>
                                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm ring-lavender-500 focus:outline-none  focus:ring-offset-2 text-gray-400 hover:text-gray-600">
                                  <span className="sr-only">Open options</span>
                                  <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                                  </svg>
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to=""
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "flex px-4 py-2 text-sm font-poppins-400"
                                          )}
                                          onClick={() => {
                                            resendInvite(trainer);
                                          }}
                                        >
                                          <svg
                                            className="mr-3"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M17.5113 0.197895C17.3214 0.0664805 17.0999 0 16.8749 0C16.6829 0 16.4903 0.0491836 16.3167 0.148465L0.566664 9.11682C0.194395 9.36211 -0.0242244 9.76641 0.00214282 10.1637C0.02851 10.5609 0.296576 10.9673 0.691908 11.1322L4.32003 12.6443L6.49444 17.7947C6.54604 17.9508 6.65151 18 6.7183 18C6.79069 18 6.86142 17.9714 6.9136 17.9212L9.92824 15.0244L14.1611 16.7878C14.312 16.8508 14.4599 16.8747 14.5931 16.8747C14.8257 16.8747 15.0189 16.8011 15.1444 16.7307C15.4465 16.5609 15.6531 16.2628 15.7058 15.9203L17.9558 1.32697C18.0527 0.869062 17.8699 0.442266 17.5113 0.197895ZM1.12495 10.125L14.4878 2.48906L4.3558 11.4715L1.12495 10.125ZM7.0558 16.2527L5.31205 12.1254L13.3804 4.97461L7.42143 13.1695C7.31926 13.3102 7.28794 13.4892 7.33572 13.6561C7.38402 13.8231 7.50601 13.9582 7.66752 14.0221L8.87689 14.5058L7.0558 16.2527ZM14.6495 15.5848L8.75385 13.2448L16.6886 2.33578L14.6495 15.5848Z"
                                              fill="#9CA3AF"
                                            />
                                          </svg>
                                          Resend invite
                                        </Link>
                                      )}
                                    </Menu.Item>

                                    {/* <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to={`/a/edit-profile/${trainer.id}`}
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "flex px-4 py-2 text-sm font-poppins-400"
                                          )}
                                        >
                                          <svg
                                            className="mr-3 w-4 text-gray-400"
                                            viewBox="0 0 18 18"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M17.4024 2.07528C18.1723 2.84415 18.1723 4.08973 17.4024 4.85965L6.40899 15.8565C6.00821 16.2538 5.51602 16.5456 4.97462 16.7073L0.721412 17.9589C0.523833 18.0151 0.310329 17.9624 0.164783 17.8147C0.0192215 17.6706 -0.0352215 17.4561 0.0228778 17.2593L1.27372 13.0054C1.43297 12.4639 1.72583 11.9718 2.12485 11.571L13.1203 0.576571C13.8902 -0.19219 15.1348 -0.19219 15.9047 0.576571L17.4024 2.07528ZM11.9883 3.29872L14.6813 5.96004L16.6078 4.06512C16.9383 3.73465 16.9383 3.20028 16.6078 2.87087L15.1102 1.37216C14.7797 1.04274 14.2453 1.04274 13.9149 1.37216L11.9883 3.29872ZM11.1938 4.09325L2.92044 12.369C2.6543 12.6327 2.45919 12.9632 2.35301 13.3253L1.39325 16.5878L4.65469 15.628C5.0168 15.5225 5.34727 15.3257 5.61095 15.0585L13.8551 6.78621L11.1938 4.09325Z" />
                                          </svg>
                                          Edit profile
                                        </Link>
                                      )}
                                    </Menu.Item> */}

                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to=""
                                          onClick={() => {
                                            toggleRemoveTrainerModel(true);
                                            setRemoveTrainerID(trainer.id);
                                            setRemoveTrainer(
                                              trainer.trainer_name
                                            );
                                          }}
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "flex px-4 py-2 text-sm font-poppins-400"
                                          )}
                                        >
                                          <svg
                                            className="mr-3"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M5.29395 5.79395C5.47559 5.60938 5.77441 5.60938 5.95605 5.79395L7.5 7.33789L9.04395 5.79395C9.22559 5.60938 9.52441 5.60938 9.70605 5.79395C9.89062 5.97559 9.89062 6.27441 9.70605 6.45605L8.16211 8L9.70605 9.54395C9.89062 9.72559 9.89062 10.0244 9.70605 10.2061C9.52441 10.3906 9.22559 10.3906 9.04395 10.2061L7.5 8.66211L5.95605 10.2061C5.77441 10.3906 5.47559 10.3906 5.29395 10.2061C5.10938 10.0244 5.10938 9.72559 5.29395 9.54395L6.83789 8L5.29395 6.45605C5.10938 6.27441 5.10938 5.97559 5.29395 5.79395ZM15 8C15 12.1426 11.6426 15.5 7.5 15.5C3.35742 15.5 0 12.1426 0 8C0 3.85742 3.35742 0.5 7.5 0.5C11.6426 0.5 15 3.85742 15 8ZM7.5 1.4375C3.87598 1.4375 0.9375 4.37598 0.9375 8C0.9375 11.624 3.87598 14.5625 7.5 14.5625C11.124 14.5625 14.0625 11.624 14.0625 8C14.0625 4.37598 11.124 1.4375 7.5 1.4375Z"
                                              fill="#9CA3AF"
                                            />
                                          </svg>
                                          Remove
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          ) : loading ? (
            <ScreenLoader />
          ) : !trainers.length ? (
            <NoData />
          ) : null}

          {/* <div className="border-b px-4 py-4 sm:px-6">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div>
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-10 h-10 text-lavender"
                      viewBox="0 0 18 18"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.00002 0.899902C4.52604 0.899902 0.900024 4.52592 0.900024 8.9999C0.900024 13.4739 4.52604 17.0999 9.00002 17.0999C13.474 17.0999 17.1 13.4739 17.1 8.9999C17.1 4.52592 13.474 0.899902 9.00002 0.899902ZM9.00002 16.0874C7.50532 16.0874 6.11946 15.6198 4.97534 14.8268C5.10506 13.2682 6.39917 12.0374 7.98752 12.0374H10.0125C11.6025 12.0374 12.8963 13.2695 13.025 14.8268C11.8793 15.6191 10.4935 16.0874 9.00002 16.0874ZM13.9265 14.0846C13.4803 12.3317 11.9015 11.0249 10.0125 11.0249H7.98752C6.09763 11.0249 4.51971 12.3317 4.07358 14.0852C2.74372 12.7683 1.91252 10.9933 1.91252 8.9999C1.91252 5.09229 5.09241 1.9124 9.00002 1.9124C12.9076 1.9124 16.0875 5.09229 16.0875 8.9999C16.0875 10.9933 15.2554 12.7683 13.9265 14.0846ZM9.00002 4.9499C7.60151 4.9499 6.46877 6.08264 6.46877 7.48115C6.46877 8.87967 7.60151 10.0124 9.00002 10.0124C10.3985 10.0124 11.5313 8.87904 11.5313 7.48115C11.5313 6.08264 10.3985 4.9499 9.00002 4.9499ZM9.00002 8.9999C8.16155 8.9999 7.48127 8.31963 7.48127 7.48115C7.48127 6.64268 8.16155 5.9624 9.00002 5.9624C9.8385 5.9624 10.5188 6.64363 10.5188 7.48115C10.5188 8.31868 9.8385 8.9999 9.00002 8.9999Z" />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-sm text-gray-500">[email]</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-40 text-center">
                  <span className="hidden md:inline-block text-indigo-800 text-xs font-medium bg-indigo-100 px-3 py-0.5 rounded-full mx-2">
                    Invited
                  </span>
                </div>

                <Menu as="div" className="hidden md:block relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm ring-lavender-500 focus:outline-none  focus:ring-offset-2 text-gray-400 hover:text-gray-600">
                      <span className="sr-only">Open options</span>
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                      </svg>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to=""
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm font-poppins-400"
                              )}
                            >
                              <svg
                                className="mr-3"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.5113 0.197895C17.3214 0.0664805 17.0999 0 16.8749 0C16.6829 0 16.4903 0.0491836 16.3167 0.148465L0.566664 9.11682C0.194395 9.36211 -0.0242244 9.76641 0.00214282 10.1637C0.02851 10.5609 0.296576 10.9673 0.691908 11.1322L4.32003 12.6443L6.49444 17.7947C6.54604 17.9508 6.65151 18 6.7183 18C6.79069 18 6.86142 17.9714 6.9136 17.9212L9.92824 15.0244L14.1611 16.7878C14.312 16.8508 14.4599 16.8747 14.5931 16.8747C14.8257 16.8747 15.0189 16.8011 15.1444 16.7307C15.4465 16.5609 15.6531 16.2628 15.7058 15.9203L17.9558 1.32697C18.0527 0.869062 17.8699 0.442266 17.5113 0.197895ZM1.12495 10.125L14.4878 2.48906L4.3558 11.4715L1.12495 10.125ZM7.0558 16.2527L5.31205 12.1254L13.3804 4.97461L7.42143 13.1695C7.31926 13.3102 7.28794 13.4892 7.33572 13.6561C7.38402 13.8231 7.50601 13.9582 7.66752 14.0221L8.87689 14.5058L7.0558 16.2527ZM14.6495 15.5848L8.75385 13.2448L16.6886 2.33578L14.6495 15.5848Z"
                                  fill="#9CA3AF"
                                />
                              </svg>
                              Resend invite
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/a/edit-profile"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm font-poppins-400"
                              )}
                            >
                              <svg
                                className="mr-3 w-4 text-gray-400"
                                viewBox="0 0 18 18"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M17.4024 2.07528C18.1723 2.84415 18.1723 4.08973 17.4024 4.85965L6.40899 15.8565C6.00821 16.2538 5.51602 16.5456 4.97462 16.7073L0.721412 17.9589C0.523833 18.0151 0.310329 17.9624 0.164783 17.8147C0.0192215 17.6706 -0.0352215 17.4561 0.0228778 17.2593L1.27372 13.0054C1.43297 12.4639 1.72583 11.9718 2.12485 11.571L13.1203 0.576571C13.8902 -0.19219 15.1348 -0.19219 15.9047 0.576571L17.4024 2.07528ZM11.9883 3.29872L14.6813 5.96004L16.6078 4.06512C16.9383 3.73465 16.9383 3.20028 16.6078 2.87087L15.1102 1.37216C14.7797 1.04274 14.2453 1.04274 13.9149 1.37216L11.9883 3.29872ZM11.1938 4.09325L2.92044 12.369C2.6543 12.6327 2.45919 12.9632 2.35301 13.3253L1.39325 16.5878L4.65469 15.628C5.0168 15.5225 5.34727 15.3257 5.61095 15.0585L13.8551 6.78621L11.1938 4.09325Z" />
                              </svg>
                              Edit profile
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to=""
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm font-poppins-400"
                              )}
                            >
                              <svg
                                className="mr-3"
                                width="18"
                                height="18"
                                viewBox="0 0 16 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.29395 5.79395C5.47559 5.60938 5.77441 5.60938 5.95605 5.79395L7.5 7.33789L9.04395 5.79395C9.22559 5.60938 9.52441 5.60938 9.70605 5.79395C9.89062 5.97559 9.89062 6.27441 9.70605 6.45605L8.16211 8L9.70605 9.54395C9.89062 9.72559 9.89062 10.0244 9.70605 10.2061C9.52441 10.3906 9.22559 10.3906 9.04395 10.2061L7.5 8.66211L5.95605 10.2061C5.77441 10.3906 5.47559 10.3906 5.29395 10.2061C5.10938 10.0244 5.10938 9.72559 5.29395 9.54395L6.83789 8L5.29395 6.45605C5.10938 6.27441 5.10938 5.97559 5.29395 5.79395ZM15 8C15 12.1426 11.6426 15.5 7.5 15.5C3.35742 15.5 0 12.1426 0 8C0 3.85742 3.35742 0.5 7.5 0.5C11.6426 0.5 15 3.85742 15 8ZM7.5 1.4375C3.87598 1.4375 0.9375 4.37598 0.9375 8C0.9375 11.624 3.87598 14.5625 7.5 14.5625C11.124 14.5625 14.0625 11.624 14.0625 8C14.0625 4.37598 11.124 1.4375 7.5 1.4375Z"
                                  fill="#9CA3AF"
                                />
                              </svg>
                              Remove
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Trainers;
