import {
  PutObjectCommand,
  S3Client,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import {
  getSignedUrl,
  S3RequestPresigner,
} from "@aws-sdk/s3-request-presigner";
import {
  AWS_ACCESS_KEY_ID,
  AWS_REGION,
  AWS_SECRET_ACCESS_KEY,
  S3_BUCKET,
} from "../config";

const client = new S3Client({
  region: AWS_REGION,
  credentials: {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  },
});

function getPrefix() {
  return "tcr/" + JSON.parse(sessionStorage.getItem("user") || "")?.id;
}
export function uploadFile(file: any) {
  const key = `${getPrefix()}/${new Date().getTime() * 1000}.${file.name
    .split(".")
    .pop()}`;
  //   return;
  return new Promise((resolve, reject) => {
    const command = new PutObjectCommand({
      Bucket: S3_BUCKET,
      Key: key,
      Body: file,
    });
    client
      .send(command)
      .then(() => {
        resolve(key);
      })
      .then((err) => reject(err));
  });
}

export function getFileUrl(key: any) {
  const command = new GetObjectCommand({
    Bucket: S3_BUCKET,
    Key: key,
  });
  return getSignedUrl(client, command, { expiresIn: 3600 });
}
