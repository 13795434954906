import React, { useEffect, useState } from "react";
import conveyaLogo from "../../../assets/images/conveya-logo.svg";
import eyeclosedIcon from "../../../assets/Icons/eyeclosed.svg";
import eyeIcon from "../../../assets/Icons/eyeIcon.svg";
import visibleEyeRed from "../../../assets/Icons/visibleEyeRed.svg";
import eyeopenIcon from "../../../assets/Icons/eyeopen.svg";

import mailIcon from "../../../assets/Icons/mailIcon.svg";
import conveyaIcon from "../../../assets/images/conveya-c-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import correctIconGray from "../../../assets/Icons/correctIconGray.svg";
import correctIconGreen from "../../../assets/Icons/correctIconGreen.svg";
import crossIconRed from "../../../assets/Icons/crossIconRed.svg";
import apiUrl from "../../../utils/apiUrl";
import axios from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { setSession } from "../../../utils/jwt";

export default function RegisterByLink(props: any) {

  const { login, logout, isAuthenticated, isInitialized } = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { state }: { state: any } = useLocation();
  const emailRegisterd = state.email;
  const invitedBy = state.invited_by;
  const accountId = state.accountId;
  const trainingProviderName = state.trainingProviderName;
  console.log(state);
  console.log(accountId, trainingProviderName);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: emailRegisterd,
      new_password: '',
      passwordConfirm: ''
    }
  });
  let password = watch("new_password");
  let navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onSubmit = async (data: any) => {
    if (isAuthenticated) {
      await logout();
      sessionStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
      setSession(null);
      //  navigate

      //  return
    }

    if (
      password.length < 8 ||
      password?.search(/^(?=.*\d)/) === -1 ||
      password?.search(/^(?=.*[a-zA-Z])/) === -1
    ) {
      setError("new_password", {
        type: "min_length_required",
        message:
          // "Password should be atleast 8 characters ",
          "Password must be at least 8 characters long, include at least 1 letter, 1 number",
      });
    } else if (data.new_password !== data.passwordConfirm) {
      setError("new_password", {
        type: "not_match",
        message:
          // "Password should be atleast 8 characters ",
          "",
      });
      setError("passwordConfirm", {
        type: "not_match",
        message:
          // "Password should be atleast 8 characters ",
          "Oops, the passwords don't match. Please re-enter to confirm",
      });
    } else {
      axios.post(apiUrl.registerTrainer, {
        email: data.email,
        password: data.new_password,
        confirmedPassword: data.passwordConfirm,
        type: "link",
      }).then(response => {
        if (response.data.code === 200) {
          navigate("/register-by-link/verify-email", {
            state: { email: data.email, accountId, trainingProviderName },
          });
        }
      });
      
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col registerTrainer justify-center">
        <div className="px-4 sm:px-0 sm:w-full sm:max-w-xs lg:ml-20  xl:ml-60 mx-auto pt-16 md:pt-20">
          <img src={conveyaLogo} className="h-8" alt="Conveya" />
          <h2 className="mt-6 text-3xl font-poppins-700  text-gray-700">
            Create account...
          </h2>

          <p className="text-sm mt-2 text-gray-600">
            <b>{invitedBy}</b> invited you to create account on Conveya.{" "}
          </p>

          <form
            className="space-y-6 mt-10"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <label className="block text-sm font-poppins-400 text-gray-700">
                Email
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  className={
                    errors.email
                      ? "block w-full appearance-none rounded-md border border-red-700 pl-10 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                      : "block w-full appearance-none rounded-md border border-gray-300 pl-10 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm"
                  }
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-index">
                  <img src={mailIcon}></img>
                </div>
              </div>
              <p className="mt-2 text-sm text-red-700">
                <>
                  {errors.email?.type == "required" ? "Required field" : ""}
                  {errors.email?.type == "pattern"
                    ? "Sorry, we couldn't locate a registered account with this email address."
                    : ""}
                </>
              </p>
            </div>

            <div className="relative mt-1 rounded-md shadow-sm">
              <label
                className={
                  errors.new_password
                    ? "block text-sm font-poppins-400 text-red-700"
                    : "block text-sm font-poppins-400 text-gray-700"
                }
              >
                Password
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="new_password"
                  {...register("new_password", { required: true })}
                  autoComplete="off"
                  //  maxLength={20}
                  //  minLength={8}
                  className={
                    errors.new_password
                      ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                      : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                  }
                  placeholder=""
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={togglePasswordVisibility}
                >
                  <img
                    src={
                      passwordVisible
                        ? errors.new_password
                          ? visibleEyeRed
                          : eyeopenIcon
                        : errors.new_password
                        ? eyeIcon
                        : eyeclosedIcon
                    }
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                    alt=""
                  />
                </div>
              </div>
              <p className="mt-2 text-sm text-red-700">
                <>
                  {errors.new_password?.type == "required"
                    ? "Required field"
                    : ""}
                  {errors.new_password?.type == "min_length_required"
                    ? errors.new_password.message
                    : ""}
                </>
              </p>
            </div>

            <div>
              <label
                htmlFor="passwordConfirm"
                className={
                  errors.passwordConfirm
                    ? "block text-sm font-poppins-400 text-red-700"
                    : "block text-sm font-poppins-400 text-gray-700"
                }
              >
                Confirm password
              </label>
              {/* <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none  sm:text-sm"
                    />
                  </div> */}
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type={passwordVisible ? "text" : "password"}
                  maxLength={20}
                  id="passwordConfirm"
                  {...register("passwordConfirm", { required: true })}
                  autoComplete="current-password"
                  className={
                    errors.passwordConfirm
                      ? "block w-full appearance-none rounded-md border border-red-700 px-3 py-2 placeholder-red-700 shadow-sm  focus:outline-none  sm:text-sm"
                      : "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm  focus:outline-none  sm:text-sm"
                  }
                  placeholder=""
                />
                <div
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <img
                    src={
                      passwordVisible
                        ? errors.passwordConfirm
                          ? visibleEyeRed
                          : eyeopenIcon
                        : errors.passwordConfirm
                        ? eyeIcon
                        : eyeclosedIcon
                    }
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                    alt=""
                  />
                </div>
              </div>
              <p className="mt-2 text-sm text-red-700">
                <>
                  {errors.passwordConfirm?.type == "required"
                    ? "Required field"
                    : ""}
                  {errors.passwordConfirm?.type == "not_match"
                    ? errors.passwordConfirm.message
                    : ""}
                </>
              </p>
            </div>

            <div className="text-center text-xs text-gray-600 pt-7">
              By clicking the "Create password" button, you agree to the Conveya{" "}
              <a
                href="reset-password"
                className="font-poppins-500 text-lavender"
              >
                privacy policy.
              </a>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center btn-lavender"
              >
                Create account
              </button>
            </div>
          </form>
        </div>

        <footer className="mt-auto py-4 xl:hidden">
          <div className="mt-8 px-4  sm:px-0  sm:mx-auto sm:w-full  space-x-6">
            <p className="text-base font-poppins-500 text-gray-400 text-center">
              <img src={conveyaIcon} className="inline-block h-5 w-5 -mt-px" />{" "}
              Powered by <span className="text-lavender">Conveya</span> -
              Formally known as Scopesuite
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
