import React, { useEffect, useState } from "react";
import AppSlideOver from "./AppSlideOver";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Dialog } from "@headlessui/react";
import emailIcon from "../assets/Icons/emailIcon.svg";
import logoIconBlack from "../assets/images/logoIconBlack.svg";
import WarningIcon from "../assets/Icons/warning.svg";

import profileImage from "../assets/images/Person-Image.png";
import axios from "../utils/axios";
import apiUrl from "../utils/apiUrl";
import useAuth from "../hooks/useAuth";
import { values } from "lodash";
import { useForm } from "react-hook-form";
import SuccsessToaster from "./tosters/SuccsessToaster";

export default function AssignTrainerSlide(props: any) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    reset,
    trigger,
    control,
  } = useForm();
  const { user } = useAuth();
  const trainerId = props.trainerId;
  const learnerId = props.learnerId;

  const [trainer, setTrainer] = useState<any>([]);
  const [showToaster, setShowToaster] = useState<boolean>(false);
  const [showRemoveToaster, setShowRemoveToaster] = useState<boolean>(false);
  const [selectedTrainer, setSelectedTrainer] = useState<any>([]);
  const [showUln, setShowUln] = useState(false);
  const [ulnError, setUlnError] = useState(false);
  const [learner, setLearner] = useState<any>([]);
  const [uln, setUln] = useState<any>("");
  const [selectedTrainerIdx, setSelectedTrainerIdx] = useState<any>(0);
  const [assignedTrainers, setAssignedTrainers] = useState<any>([]);
  const [name, setName] = useState("");
  const getLearnerProfile = async () => {
    const response = await axios.post(apiUrl.learnerProfile, {
      // trainer_id: "bd076119-225b-40f9-bda6-4aaf94fbec43",
      //trainer_id: trainerId,
      // learner_id: "0033z00002fYGogAAG",
      learner_id: learnerId,
    });

    setLearner(response.data);
    setUln(response.data?.uln);
    let tempSelect: any = [];
    response.data?.courses?.map((each: any, i: any) => {
      // tempSelect.push({ id: i, text: "Select trainer..." });
      tempSelect.push({ ind: i, id: each.id, text: each.trainer_name });
    });
    setSelectedTrainer([...tempSelect]);
  };

  const getFilter = async () => {
    const response = await axios.get(apiUrl.trainerFilter);
    setTrainer([
      { trainer_name: "Select trainer..." },
      ...response.data?.trainers,
    ]);
  };

  const trainerSelected = (valu: any, index: any) => {
    if (valu !== "Select trainer...") {
      let temp: any = [];
      selectedTrainer?.map((item: any, ind: any) => {
        if (ind === index) {
          trainer?.map((each: any) => {
            if (each?.id === valu) {
              temp.push({
                ind: ind,
                id: each?.id,
                text: each?.trainer_name.trim(),
              });
            }
          });
        } else {
          temp.push(item);
        }
      });
      setSelectedTrainer(temp);
    } else {
      let temp: any = [];
      selectedTrainer?.map((item: any, ind: any) => {
        if (ind === index) {
          trainer?.map((each: any) => {
            if (each?.trainer_name === valu) {
              temp.push({ ind: ind, id: each?.id, text: each?.trainer_name });
            }
          });
        } else {
          temp.push(item);
        }
      });
      setSelectedTrainer(temp);
    }
  };



  useEffect(() => {
    getFilter();
  }, [learnerId]);


  const toggleFileToaster = () => {
    setShowToaster(true);
    setTimeout(() => {
      setShowToaster(false);
      window.location.reload();
    }, 3000);
  };

  const toggleRemoveToaster = () => {
    setShowRemoveToaster(true);
    setTimeout(() => {
      setShowRemoveToaster(false);
    }, 3000);
  };

  const addTrainerToList = () => {
    if (trainer[selectedTrainerIdx].trainer_name === 'Select trainer...') {
      return;
    }
    let assignedTrainersCopy = [...assignedTrainers];
    let flag = false;
    assignedTrainersCopy.map((each) => {
      if (each.trainer_name === trainer[selectedTrainerIdx].trainer_name) {
        flag = true;
      }
    });
    if (flag) {
      return;
    }
    assignedTrainersCopy.push({ ...trainer[selectedTrainerIdx] });
    setAssignedTrainers([...assignedTrainersCopy]);

  }

  const submitAssignTrainer = async () => {
    const response = await axios.post(apiUrl.assignTrainerToClient, {
      "trainer_ids": assignedTrainers.map((each: any) => each.tcr_sfid),
      "company_id": props.clientId
    });
    if (response.status === 200) {
      props.toggle(false);
      if(assignedTrainers.length > 1) {
        setName("");
      }
      else {
        setName(assignedTrainers[0].trainer_name);
      }
      toggleFileToaster();
    }
  }

  const removeTrainer = async (id: any) => {

    const response = await axios.delete(apiUrl.deleteAssignedTrainer, {
      data: {
        "trainer_id": id,
        "company_id": props.clientId
      }
    });

    if (response.status === 200) {
      let assignedTrainersCopy = [...assignedTrainers];
      assignedTrainersCopy = assignedTrainersCopy.filter((each: any) => {
        if (each.tcr_sfid !== id) {
          return {
            ...each
          }
        }
      });
      setAssignedTrainers([...assignedTrainersCopy]);
      toggleRemoveToaster();
    }
  }

  const filterDropdownList = () => {
    let trainerCopy = [...trainer].map((itm: any, idx: number) => {
      let flag = false;
      assignedTrainers.map((each: any) => {
        if (each.trainer_name === itm.trainer_name) {
          flag = true;
        }
      });
      let obj = { ...itm };
      obj.idx = idx;
      obj.flag = flag;
      return {
        ...obj
      }

    })
    return trainerCopy;
  }

  useEffect(() => {
    if (props.assignedTrainers) {
      let assignedTrainersCopy = [...props.assignedTrainers];
      assignedTrainersCopy = assignedTrainersCopy.map((each: any) => {
        return {
          ...each,
          email: each.trainer_email
        }
      })
      setAssignedTrainers([...assignedTrainersCopy]);
    }

  }, [props.assignedTrainers])
  return (
    <>
      <SuccsessToaster
        show={showToaster}
        toggle={(val: boolean) => {
          setShowToaster(val);
        }}
        message={"Trainers have been assigned successfully."}
        name ={name}
      // message={setMessage}
      />

      <SuccsessToaster
        show={showRemoveToaster}
        toggle={(val: boolean) => {
          setShowRemoveToaster(val);
        }}
        message={"Trainers have been removed successfully."}
      // message={setMessage}
      />
      <AppSlideOver toggle={props.toggle} show={props.show}>
        <div className="flex min-h-0 flex-1 flex-col py-6">
          <div className=" px-4 sm:px-6">
            <div className="flex items-start justify-between">
              <div className="text-lg font-inter-500 leading-7 text-gray-900">
                Assign trainer
              </div>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className=" text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={() => props.toggle(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="flex items-start">
              <p className="text-sm font-inter-400 leading-5 text-gray-500 pt-1">
                Assign trainer to help you manage client’s reports.
              </p>
            </div>
          </div>

          <div className=" relative overflow-y-auto mt-6 pb-6 flex-1 px-0">
            {/* Replace with your content */}

            <form
              className="mb-12"
              id="personal_detail"
            // onSubmit={handleSubmit(submitTrainer)}
            >
              <div className="mb-6">
                <div>
                  <div className="h-24 w-full object-cover lg:h-24 bg-lavender"></div>
                </div>
                <div className="mx-auto px-4 sm:px-6 lg:px-6">
                  <div className="-mt-10 sm:-mt-12 sm:flex sm:items-end sm:space-x-5">
                    <div className="flex h-24 w-24 rounded-lg bg-white items-center justify-center">
                      <div className="  flex items-center justify-center">
                        <img className="h-8 w-8" src={logoIconBlack} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1  min-w-0 flex-1 block">
                    <h1 className="truncate text-xl font-poppins-600 text-gray-900 mb-1">
                      {props.company?.company_name ?? props.company.CompanyName}
                    </h1>
                    <p className="truncate text-sm font-poppins-400 text-gray-400 mb-1">
                      {props.company?.company_mail ? props.company?.company_mail : props.company.CompanyEmail ? props.company.CompanyEmail : '-'}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mx-auto px-4 sm:px-6 lg:px-6">
                <div className="border-t  border-gray-200">
                  <div>
                    {
                      assignedTrainers.map((assignedTrainer: any, idx: number) => (
                        <div className="py-4 space-y-4 border-b  border-gray-200">
                          <div className="flex items-center justify-between">
                            <div className="flex px-4 py-2 sm:py-0 sm:px-0 items-center bg-gray-50 sm:bg-inherit">
                              <div>
                                <span className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-500 text-white font-inter-400 text-xs inline-flex items-center justify-center p-1 border-2 border-gray-500">
                                  {assignedTrainer.trainer_name.trim()?.split(" ")?.length > 1
                                    ? assignedTrainer.trainer_name.split(" ")[0][0].toUpperCase() +
                                    assignedTrainer.trainer_name?.split(" ")
                                    [
                                      assignedTrainer.trainer_name.split(" ").length - 1
                                    ][0].toUpperCase()
                                    : assignedTrainer.trainer_name.split(" ")[0][0].toUpperCase()}
                                </span>
                              </div>
                              <div className="ml-3">
                                <p className="text-sm font-inter-500 text-gray-700 ">
                                  {assignedTrainer.trainer_name}
                                </p>
                                <span className="text-xs gray-500">{assignedTrainer.email}</span>
                              </div>
                            </div>

                            <div className="flex-shrink-0">
                              <h3 className="flex text-sm font-poppins-500 text-lavender cursor-pointer hover:underline"
                                onClick={() => removeTrainer(assignedTrainer.tcr_sfid)}>
                                Remove
                              </h3>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div className="relative flex items-center py-4">
                    <div className="w-full">
                      <div className="relative">
                        <label
                          htmlFor="Title"
                          className="block text-sm font-poppins-400 text-gray-700"
                        >
                          Assign trainer
                        </label>

                        <div className="mt-1 relative">
                          <select
                            name=""
                            placeholder="Select trainer..."
                            className="relative block w-full rounded-md border border-gray-300 bg-transparent shadow-sm font-poppins-400 focus:z-10 focus:outline-none active:border-gray-700 focus:border-gray-700 focus:shadow-sm sm:text-sm"
                            onChange={(e: any) => setSelectedTrainerIdx(e.target.value * 1)}
                          >

                            {
                              filterDropdownList().map((each: any) =>
                                <option style={each.flag ? {
                                  display: 'none'
                                } : {
                                  display: 'block'
                                }} value={each.idx} key={each.idx}>{each.trainer_name}</option>


                              )
                            }
                          </select>

                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="mt-6 ml-2 rounded-md border border-gray-300 bg-white py-4 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
                      onClick={addTrainerToList}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.5 7.99964C15.5 8.31695 15.2404 8.57656 14.9231 8.57656H8.57692V14.9227C8.57692 15.2416 8.31889 15.5 8 15.5C7.68111 15.5 7.42308 15.24 7.42308 14.9227V8.57656H1.07692C0.758029 8.57656 0.5 8.31875 0.5 8C0.5 7.68233 0.758173 7.42272 1.07692 7.42272H7.42308V1.07656C7.42308 0.757668 7.68111 0.5 8 0.5C8.31889 0.5 8.57692 0.757813 8.57692 1.07656V7.42272H14.9231C15.2404 7.42272 15.5 7.68233 15.5 7.99964Z"
                          fill="#9CA3AF"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className=" flex flex-shrink-0 justify-end px-4 border-t pt-4 -mb-2 mt-a">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-poppins-500 text-gray-700 shadow-sm btn-white-padding hover:bg-gray-50 focus:bg-gray-50 focus:outline-none ring-lavender-500"
              onClick={() => {
                props.toggle(false)
                window.location.reload();
              }}
            >
              Cancel
            </button>
            <button
              // type="submit"
              className="ml-4 inline-flex justify-center rounded-md border border-transparent btn-lavender py-2 px-4"
              onClick={submitAssignTrainer}
            >
              Save
            </button>
          </div>
        </div>
      </AppSlideOver>
    </>
  );
}
