// import { SettingsValueProps } from './components/settings/type';
// routes
// import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API =
	process.env.REACT_APP_HOST_API_KEY ||
	'https://conveyagen2-api-new-staging-4fc41ce19d11.herokuapp.com'

export const FIREBASE_API = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APPID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

export const COGNITO_API = {
	userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
	clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
}

export const AUTH0_API = {
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
	domain: process.env.REACT_APP_AUTH0_DOMAIN
}

export const MAPBOX_API = process.env.REACT_APP_MAPBOX

export const S3_BUCKET = process.env.S3_BUCKET || 'conveya-v2'
export const AWS_REGION = process.env.AWS_REGION || 'eu-west-2'
export const AWS_ACCESS_KEY_ID =
	process.env.AWS_ACCESS_KEY_ID || 'AKIAUOVD444T4XZ2E7MF'
export const AWS_SECRET_ACCESS_KEY =
	process.env.AWS_SECRET_ACCESS_KEY ||
	'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'

// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
	MOBILE_HEIGHT: 64,
	MAIN_DESKTOP_HEIGHT: 88,
	DASHBOARD_DESKTOP_HEIGHT: 92,
	DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32
}

export const NAVBAR = {
	BASE_WIDTH: 260,
	DASHBOARD_WIDTH: 280,
	DASHBOARD_COLLAPSE_WIDTH: 88,
	//
	DASHBOARD_ITEM_ROOT_HEIGHT: 48,
	DASHBOARD_ITEM_SUB_HEIGHT: 40,
	DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
}

export const ICON = {
	NAVBAR_ITEM: 22,
	NAVBAR_ITEM_HORIZONTAL: 20
}

// SETTINGS
// Please remove `sessionStorage` when you set settings.
// ----------------------------------------------------------------------

// export const defaultSettings: SettingsValueProps = {
//   themeMode: 'light',
//   themeDirection: 'ltr',
//   themeColorPresets: 'default',
//   themeLayout: 'horizontal',
//   themeStretch: false,S
// };
